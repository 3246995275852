import { grey_2, grey_3, textColor } from '../../theme/Theme'
import { ButtonProperties, DefaultButton } from './DefaultButton'

export const GreyContainedButton = (properties: ButtonProperties) => {
    const { sx, ...rest } = properties
    return (
        <DefaultButton
            {...{
                ...rest,
                variant: 'contained',
                color: 'primary',
                sx: {
                    ...sx,
                    backgroundColor: grey_3,
                    color: textColor,
                    '&:hover': {
                        backgroundColor: grey_2,
                        color: textColor,
                    },
                    '&.Mui-disabled': {
                        backgroundColor: grey_3,
                    },
                },
            }}
        />
    )
}
