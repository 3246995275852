import { Box, styled } from '@mui/material'
import MuiGrid from '@mui/material/Unstable_Grid2'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { PasswordTextField } from 'shared/lib/components/textfields/PasswordTextField'
import { useIsMobile, useIsPhablet } from 'shared/lib/theme/BreakPointHooks'
import { mobileToolbarHeight } from 'shared/lib/theme/Theme'
import { ContentContainer } from '../../../common/layout/ContentContainer'
import { MobileHeader } from '../../../common/layout/MobileHeader'
import { useTranslation } from 'shared/lib/i18n'
import { sessionRepository } from '../../../index'
import { Paths } from '../../../routing/paths'
import { isPasswordValid } from '../../../utils/PasswordUtils'
import { DonkeyBreadcrumbItem, DonkeyBreadcrumbs } from '../DonkeyBreadcrumbs'
import { BackgroundContainer, Header } from '../SettingComponents'
import { DEFAULT_VALIDATION_TIMEOUT } from 'shared/lib/common/Constants'
import { BadRequestError } from 'shared/lib/utils/ErrorUtils'

export const ChangePasswordPage = () => {
    const translations = useTranslation()
    const navigate = useNavigate()
    const [isMobile, isPhablet] = [useIsMobile(), useIsPhablet()]

    const breadcrumbsItems: DonkeyBreadcrumbItem[] = [
        {
            title: translations('settings'),
            path: Paths.SETTINGS,
        },
        {
            title: translations('change_password'),
            path: Paths.SETTINGS_CHANGE_PASSWORD,
        },
    ]

    if (isMobile || isPhablet) {
        return (
            <>
                <MobileHeader
                    hasActions={false}
                    title={translations('change_password')}
                    onBackButtonClicked={() => navigate(Paths.SETTINGS)}
                />

                <ContentContainer mt={mobileToolbarHeight}>
                    <BackgroundContainer>
                        <Content />
                    </BackgroundContainer>
                </ContentContainer>
            </>
        )
    }

    return (
        <>
            <ContentContainer>
                <Grid container tablet={7} tabletOffset={2.5} desktop={6} desktopOffset={3}>
                    <BackgroundContainer>
                        <DonkeyBreadcrumbs breadcrumbsItems={breadcrumbsItems}></DonkeyBreadcrumbs>
                        <Header>{translations('change_password')}</Header>
                        <Content />
                    </BackgroundContainer>
                </Grid>
            </ContentContainer>
        </>
    )
}

const Content = () => {
    const translations = useTranslation()
    const navigate = useNavigate()
    const isMobile = useIsMobile()

    const [isLoading, setIsLoading] = useState(false)

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    const hasValidPassword =
        !newPassword.length || (newPassword.length && isPasswordValid(newPassword))

    const [invalidNewPassword, setInvalidNewPassword] = useState<string | null>()
    const [invalidRepeatedPassword, setInvalidRepeatedPassword] = useState<string | null>()

    const [error, setError] = useState<Error | undefined>()

    const canContinue =
        !!currentPassword.length &&
        !!newPassword.length &&
        !!repeatPassword.length &&
        newPassword === repeatPassword &&
        isPasswordValid(newPassword)

    const onNewPasswordChanged = (value: string) => {
        setInvalidNewPassword(undefined)
        setInvalidRepeatedPassword(undefined)
        setNewPassword(value)
    }

    const onRepeatPasswordChanged = (value: string) => {
        setInvalidRepeatedPassword(undefined)
        setRepeatPassword(value)
    }

    const changePassword = () => {
        if (!canContinue) return

        setIsLoading(true)

        sessionRepository
            .changePassword(currentPassword, newPassword)
            .then(() => navigate(Paths.SETTINGS, { state: { isSuccessModalVisible: true } }))
            .catch(setError)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const validateNewPassword = useCallback(() => {
        if (!hasValidPassword) {
            setInvalidNewPassword(translations('choose_password_hint'))
        }
    }, [hasValidPassword, translations])

    const validateRepeatedPassword = useCallback(() => {
        if (repeatPassword.length && newPassword.length && newPassword !== repeatPassword) {
            setInvalidRepeatedPassword(translations('passwords_do_not_match'))
        }
    }, [newPassword, repeatPassword, translations])

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            validateNewPassword()
            validateRepeatedPassword()
        }, DEFAULT_VALIDATION_TIMEOUT)

        return () => {
            clearTimeout(timeOutId)
        }
    }, [newPassword, repeatPassword, validateNewPassword, validateRepeatedPassword])

    return (
        <>
            <BoxColumn>
                <PasswordTextField
                    value={currentPassword}
                    autoFocus={!isMobile}
                    label={translations('current_password')}
                    onChanged={setCurrentPassword}
                />

                <PasswordTextField
                    value={newPassword}
                    label={translations('new_password')}
                    helperText={translations('choose_password_hint')}
                    error={invalidNewPassword !== undefined}
                    onChanged={onNewPasswordChanged}
                    onBlur={() => validateNewPassword()}
                />

                <PasswordTextField
                    value={repeatPassword}
                    label={translations('repeat_password')}
                    helperText={invalidRepeatedPassword}
                    error={invalidRepeatedPassword !== undefined}
                    onChanged={onRepeatPasswordChanged}
                    onBlur={() => validateRepeatedPassword()}
                    onEnterKeyPressed={() => changePassword()}
                />

                <BoxStart>
                    <PrimaryContainedButton
                        isLoading={isLoading}
                        title={translations('change_password')}
                        disabled={!canContinue}
                        onClick={changePassword}
                    />
                </BoxStart>
            </BoxColumn>

            <ErrorHandler
                error={error}
                badRequestError={BadRequestError.INVALID_PASSWORD}
                horizontal="center"
                translations={translations}
            />
        </>
    )
}

const Grid = styled(MuiGrid)(() => ({
    height: '100%',
}))

const BoxColumn = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
}))

const BoxStart = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
}))
