import { Link, Typography } from '@mui/material'
import { useState } from 'react'
import { HeaderTitle } from './mfa/MfaComponents'
import { isValidEmail } from '../utils/StringUtils'
import {
    PrimaryActionButtonContainer,
    PrimaryActionButtonsContainer,
} from './PrimaryActionButtonsContainer'
import { PrimaryContainedButton } from './buttons/ContainedButtons'
import { ErrorHandler } from './ErrorHandler'
import { TextField } from './textfields/TextField'
import { BackButton } from './buttons/BackButton'
import { useTranslation } from '../i18n'
import { BadRequestError } from '../utils/ErrorUtils'

interface Properties {
    showContactUsLink?: boolean
    onBackButtonClicked(): void
    onSendLinkButtonClicked(emailAddress: string): Promise<void>
}

export const PasswordResetView = ({ showContactUsLink = true, ...properties }: Properties) => {
    const translations = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [emailAddress, setEmailAddress] = useState('')
    const [invalidEmailError, setInvalidEmailError] = useState<string | undefined>()
    const [error, setError] = useState<Error | undefined>()

    const canContinue = emailAddress.trim().length > 0

    const onEmailAddressChanged = (value: string) => {
        setInvalidEmailError(undefined)
        setEmailAddress(value)
    }

    const validateEmailAddress = () => {
        const hasValidEmailAddress = isValidEmail(emailAddress)
        const message = hasValidEmailAddress
            ? undefined
            : translations?.('invalid_email_address') ?? 'Invalid email address'
        setInvalidEmailError(message)
        return hasValidEmailAddress
    }

    const onSendLinkButtonClicked = () => {
        if (!canContinue || !validateEmailAddress()) return

        setIsLoading(true)

        properties
            .onSendLinkButtonClicked(emailAddress)
            .catch(setError)
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <HeaderTitle>
                {translations?.('forgot_password_title') ?? 'Forgot Password'}
            </HeaderTitle>

            <Typography variant="body2" mt={2}>
                {translations?.('forgot_password_info') ??
                    'We will send you an email with a link to reset your password.'}
            </Typography>

            <TextField
                fullWidth={true}
                autoFocus={true}
                value={emailAddress}
                label={translations?.('email_address') ?? 'Email address'}
                error={invalidEmailError !== undefined}
                helperText={invalidEmailError}
                onChanged={onEmailAddressChanged}
                onEnterKeyPressed={() => onSendLinkButtonClicked()}
                sx={{
                    marginTop: 5,
                }}
            />

            <PrimaryActionButtonsContainer>
                <BackButton onClick={properties.onBackButtonClicked} />

                <PrimaryActionButtonContainer>
                    <PrimaryContainedButton
                        fullWidth={true}
                        isLoading={isLoading}
                        title={translations?.('send_link') ?? 'Send link'}
                        disabled={!canContinue}
                        onClick={onSendLinkButtonClicked}
                    />
                </PrimaryActionButtonContainer>
            </PrimaryActionButtonsContainer>

            {showContactUsLink && (
                <>
                    <Typography variant="body2" mt={5}>
                        {translations?.('no_access_to_email') ??
                            'Lost access to your e-mail address?'}
                    </Typography>

                    <Typography variant="body2">
                        <Link href="mailto:support@donkeymobile.app">
                            {translations?.('contact_us') ?? 'Contact us'}
                        </Link>
                    </Typography>
                </>
            )}

            <ErrorHandler
                error={error}
                badRequestError={BadRequestError.INVALID_EMAIL_ADDRESS}
                horizontal="left"
                translations={translations}
            />
        </>
    )
}
