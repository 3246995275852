import { User } from '../user/User'
import { DateTime } from 'luxon'
import { MessageDetails } from '../posts/MessageDetails'

export class Comment implements MessageDetails {
    get isEdited(): boolean {
        return this.createdAt < this.updatedAt
    }

    constructor(
        public readonly id: string,
        private readonly message: string,
        public readonly creator: User | undefined,
        public readonly createdAt: DateTime,
        public readonly updatedAt: DateTime,
        public readonly canEdit: boolean,
        public readonly hasMarkDown: boolean
    ) {}

    static fromResponse(response: Record<string, any>): Comment {
        return new Comment(
            response.id,
            response.message,
            response.creator ? User.fromApiResponse(response.creator) : undefined,
            DateTime.fromISO(response.createdAt),
            DateTime.fromISO(response.updatedAt),
            response.canEdit,
            response.canContainMarkdown
        )
    }

    getMessage(): string {
        return this.message
    }
}
