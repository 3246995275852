import React from 'react'
import { Box } from '@mui/material'
import { AvatarSkeleton } from '../../../common/detailView'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { ListItem } from './components'
import { NotificationsListItemLoadingViewModel } from '../NotificationsListViewModels'

export const NotificationListItemLoadingRowHeight = 75

interface Properties {
    viewModel: NotificationsListItemLoadingViewModel
}

export const NotificationListItemLoadingRow = ({ viewModel: { isLastRow } }: Properties) => {
    return (
        <ListItem
            sx={{
                height: NotificationListItemLoadingRowHeight,
                gap: 2,
                paddingBottom: isLastRow ? 3 : 0,
            }}
        >
            <AvatarSkeleton
                width={40}
                height={40}
                sx={{
                    minWidth: 40,
                }}
            />
            <Box display="flex" flexDirection="column" gap={1} width="100%">
                <Skeleton variant="rounded" width="100%" />
                <Skeleton variant="rounded" width="20%" />
            </Box>
        </ListItem>
    )
}
