import {
    AvatarProps,
    Badge,
    Box,
    ListItemAvatarProps,
    ListItemButtonProps,
    ListItemText,
    ListItem as MuiListItem,
    ListItemAvatar as MuiListItemAvatar,
    ListItemButton as MuiListItemButton,
    styled,
    Typography,
} from '@mui/material'
import { JSX } from 'react'
import { ReactComponent as PlusIcon } from 'shared/lib/assets/svg/plus.svg'
import { Avatar } from 'shared/lib/components/Avatar'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { useTranslation } from 'shared/lib/i18n'
import theme, { grey_1, grey_3, paperColor } from 'shared/lib/theme/Theme'
import { MinimalUserInfo } from 'shared/lib/users/MinimalUserInfo'
import { getFullName } from 'shared/lib/utils/StringUtils'
import { AvatarSkeleton } from '../../common/detailView'
import { fileRepository, sessionRepository } from '../../index'
import { UserListItemText } from './UserListItemText'

interface Properties {
    isClickable: boolean
    isFirstItem?: boolean
    isLastItem?: boolean
    useUForSignedInUser?: boolean
    user: MinimalUserInfo
    badge?: JSX.Element
    endLabel?: string | JSX.Element
    actionsButton?: JSX.Element
    onClick(): void
}

export const UserListItemRow = (properties: Properties) => {
    const translations = useTranslation()
    const signedInUser = sessionRepository.signedInUser!

    const secondaryAction = properties.actionsButton ? (
        <Box display={'flex'} alignItems={'center'} gap={1}>
            {properties.actionsButton}
        </Box>
    ) : undefined

    return (
        <ListItem
            secondaryAction={secondaryAction}
            sx={{
                paddingTop: properties.isFirstItem ? 2 : undefined,
                paddingBottom: properties.isLastItem ? 1 : undefined,
            }}
        >
            <ListItemButton
                onClick={properties.isClickable ? properties.onClick : undefined}
                isClickable={properties.isClickable}
                disableRipple={!properties.isClickable}
            >
                <Badge
                    badgeContent={properties.badge}
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <ListItemAvatar src={fileRepository.getThumbnailUrl(properties.user.image)} />
                </Badge>

                <UserListItemText
                    name={
                        properties.user.id === signedInUser.id && properties.useUForSignedInUser
                            ? translations('you')
                            : getFullName(properties.user)
                    }
                    secondaryLabel={properties.user.functions.join(', ')}
                />
                {properties.endLabel && (
                    <EndLabel mr={secondaryAction ? 2 : 1}>{properties.endLabel}</EndLabel>
                )}
            </ListItemButton>
        </ListItem>
    )
}

export const UserListAddMemberRow = ({ onClick }: { onClick(): void }) => {
    const translations = useTranslation()

    return (
        <ListItem>
            <AddMembersListItemButton isClickable={true} onClick={onClick}>
                <Avatar>
                    <AddIcon />
                </Avatar>

                <ListItemText primary={translations('add_members')} />
            </AddMembersListItemButton>
        </ListItem>
    )
}

export const UserListFooterRow = () => {
    return (
        <ListItem>
            <Box py={1} />
        </ListItem>
    )
}

export const USER_LIST_LOADING_ROW_HEIGHT = 60

export const UserListLoadingRow = () => {
    return (
        <ListItem sx={{ padding: theme.spacing(1) }}>
            <ListItemAvatar>
                <AvatarSkeleton />
            </ListItemAvatar>
            <Skeleton variant="rounded" width="75%" sx={{ marginLeft: 2 }} />
        </ListItem>
    )
}

const ListItem = styled(MuiListItem)(() => ({
    padding: 0,
    borderRadius: '16px',
}))

const ListItemButton = styled(
    ({
        isClickable,
        ...props
    }: ListItemButtonProps & {
        isClickable: boolean
    }) => <MuiListItemButton {...props} />
)(({ theme, isClickable }) => ({
    padding: theme.spacing(1),
    borderRadius: '16px',
    pointerEvents: isClickable ? 'auto' : 'none',
}))

const AddMembersListItemButton = styled(ListItemButton)(({ theme }) => ({
    '.MuiTypography-root': {
        paddingLeft: theme.spacing(2),
        color: theme.palette.primary.main,
    },

    '.MuiAvatar-root': {
        backgroundColor: grey_3,
    },
    ':hover': {
        '.MuiAvatar-root': {
            backgroundColor: paperColor,
        },
    },

    '& svg': {
        fill: theme.palette.primary.main,
    },
}))

const ListItemAvatar = styled(
    ({ src, children, ...props }: ListItemAvatarProps & Pick<AvatarProps, 'src'>) => (
        <MuiListItemAvatar {...props}>{children || <Avatar src={src} />}</MuiListItemAvatar>
    )
)(() => ({
    minWidth: 40,
}))

const EndLabel = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    color: grey_1,
    marginLeft: theme.spacing(1),
}))

const AddIcon = styled(PlusIcon)(() => ({
    height: '16px',
    width: '16px',
}))
