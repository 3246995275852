import { Box, styled, Typography, TypographyProps } from '@mui/material'
import MuiGrid from '@mui/material/Unstable_Grid2'

export const Grid = styled(MuiGrid)(() => ({
    height: '100%',
}))

export const BackgroundContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

    [theme.breakpoints.down('tablet')]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
    },
}))

export const Header = styled((props: TypographyProps) => <Typography variant={'h1'} {...props} />)(
    ({ theme }) => ({
        fontSize: '24px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    })
)
