import {
    Box,
    ListItem as MuiListItem,
    ListItemButton as MuiListItemButton,
    styled,
    Typography,
} from '@mui/material'
import { Avatar } from 'shared/lib/components/Avatar'
import { RoundedCheckbox } from 'shared/lib/components/Checkbox'
import { darkGrey, grey_1 } from 'shared/lib/theme/Theme'
import { fileRepository } from '../../../index'
import { MemberItemViewModel } from './MemberViewModel'
import { getInitials } from '../../../user/Utils'
import { getFullName } from 'shared/lib/utils/StringUtils'

interface Properties {
    viewModel: MemberItemViewModel
}

export const MemberListRow = ({ viewModel }: Properties) => {
    const user = viewModel.user

    const onUserClicked = () => {
        viewModel.onUserSelected(user)
    }

    return user ? (
        <ListItem disablePadding={true} onClick={onUserClicked}>
            <ListItemButton>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Avatar width={40} height={40} src={fileRepository.getThumbnailUrl(user.image)}>
                        {!user.image ? getInitials(user) : undefined}
                    </Avatar>
                    <TextBox>
                        <NameTextView>{getFullName(user)}</NameTextView>
                        {Boolean(user.functions.length) && (
                            <FunctionsTextView>{user.functions.join(', ')}</FunctionsTextView>
                        )}
                    </TextBox>
                </Box>

                <RoundedCheckbox checked={viewModel.isSelected} onClick={onUserClicked} />
            </ListItemButton>
        </ListItem>
    ) : null
}

const ListItem = styled(MuiListItem)(() => ({}))

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
}))

const TextBox = styled(Box)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    height: 'fit-content',
    flexDirection: 'column',
    alignItems: 'center',
}))

const NameTextView = styled(Typography)(() => ({
    fontSize: 14,
    color: darkGrey,
    fontWeight: 400,
    textAlign: 'left',
    width: '100%',
}))

const FunctionsTextView = styled(Typography)(() => ({
    fontSize: 12,
    color: grey_1,
    fontWeight: 400,
    textAlign: 'left',
    width: '100%',
}))
