import * as BlobFactory from './BlobFactory'

export const generateVideoThumbnail = (file: File): Promise<Blob> => {
    return new Promise((resolve) => {
        const canvas = document.createElement('canvas')
        const video = document.createElement('video')

        video.autoplay = true
        video.muted = true
        video.src = URL.createObjectURL(file)

        video.onloadeddata = () => {
            const ctx = canvas.getContext('2d')

            if (!ctx) return

            canvas.width = video.videoWidth
            canvas.height = video.videoHeight

            ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
            video.pause()

            return resolve(BlobFactory.fromDataURI(canvas.toDataURL('image/png')))
        }
    })
}
