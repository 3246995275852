import { DateTime } from 'luxon'
import { RRule } from 'rrule'
import { toWeekdayFromString, Weekday } from './Weekday'
import { RecurrenceRule } from './RecurrenceRule'
import { toFrequency } from './Frequency'
import { MonthlyOption, toMonthlyOption } from './MonthlyOption'

export enum SUPPORTED_FREQUENCIES {
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    CUSTOM = 'CUSTOM',
}

export enum MONTHLY_SELECTION_TYPES {
    DATE = 'DATE',
    WEEKDAY = 'WEEKDAY',
}
export enum MONTHLY_SELECTION_DAYS {
    FIRST = 1,
    SECOND = 2,
    THIRD = 3,
    FOURTH = 4,
    LAST = -1,
}

export class RecurrenceRuleFactory {
    static toRRUleString(recurrenceRule: RecurrenceRule | undefined): string | undefined {
        if (!recurrenceRule) {
            return undefined
        }

        let rruleString = 'RRULE:'

        rruleString += `FREQ=${recurrenceRule.frequency};`
        rruleString += `INTERVAL=${Math.max(1, recurrenceRule.interval)};`
        rruleString += 'WKST=SU;'

        if (recurrenceRule.until) {
            rruleString += `UNTIL=${recurrenceRule.until.toFormat("yyyyMMdd'T'HHmmss'Z'")};`
        } else if (recurrenceRule.count) {
            rruleString += `COUNT=${recurrenceRule.count};`
        }

        if (recurrenceRule.weekdays && recurrenceRule.weekdays.size > 0) {
            const sortedWeekDays = Array.from(recurrenceRule.weekdays).sort(
                (a, b) => Object.values(Weekday).indexOf(a) - Object.values(Weekday).indexOf(b)
            )
            rruleString += `BYDAY=${sortedWeekDays.join(',')};`
        } else if (recurrenceRule.monthlyOption === MonthlyOption.CURRENT_DAY) {
            rruleString += `BYMONTHDAY=${recurrenceRule.start.day};`
        } else if (recurrenceRule.monthlyOption !== null) {
            const weekDay = Weekday[recurrenceRule.start.weekdayShort as keyof typeof Weekday]
            if (weekDay) {
                rruleString += `BYDAY=${recurrenceRule.monthlyOption}${weekDay};`
            }
        }

        return rruleString.replace(/;$/, '')
    }

    static fromRRuleString(
        start: DateTime,
        rruleString: string | undefined
    ): RecurrenceRule | undefined {
        const rrule = rruleString?.replace('RRULE:', '')

        if (!rrule) return undefined

        const rruleParts = rrule.split(';')
        let recurrenceRule = new RecurrenceRule(start)

        for (const rule of rruleParts) {
            if (rule.startsWith('FREQ=')) {
                const frequency = toFrequency(rule.substring(5)) || null
                if (!frequency) return undefined
                recurrenceRule = recurrenceRule.copy({ frequency })
            }

            if (rule.startsWith('INTERVAL=')) {
                const interval = parseInt(rule.substring(9)) || 1
                recurrenceRule = recurrenceRule.copy({ interval })
            }

            if (rule.startsWith('UNTIL=')) {
                const untilString = rule.substring(6)
                const until = DateTime.fromFormat(untilString, "yyyyMMdd'T'HHmmss'Z'")
                recurrenceRule = recurrenceRule.copy({ until })
            }

            if (rule.startsWith('COUNT=')) {
                const count = parseInt(rule.substring(6)) || 0
                recurrenceRule = recurrenceRule.copy({ count })
            }

            if (rule.startsWith('BYDAY=')) {
                const weekDayStrings = rule.substring(6).split(',')
                const isMonthlyOption =
                    weekDayStrings.length === 1 && weekDayStrings[0].length === 3
                if (isMonthlyOption) {
                    const monthlyOption = toMonthlyOption(weekDayStrings[0].substring(0, 1))
                    recurrenceRule = recurrenceRule.copy({ monthlyOption })
                } else {
                    const weekDays = new Set(
                        weekDayStrings.map(toWeekdayFromString).filter(Boolean)
                    ) as Set<Weekday>
                    recurrenceRule = recurrenceRule.copy({
                        weekdays: weekDays.size > 0 ? weekDays : undefined,
                    })
                }
            } else if (rule.startsWith('BYMONTHDAY=')) {
                recurrenceRule = recurrenceRule.copy({
                    monthlyOption: MonthlyOption.CURRENT_DAY,
                })
            }
        }

        return recurrenceRule
    }

    /**
     * @deprecated use fromRRuleString and toRRuleString instead
     */
    static generateRecurrenceRule(params: {
        startDate: DateTime<true>
        frequency: SUPPORTED_FREQUENCIES
        interval?: number
        weekDays?: Weekday[]
        monthlySelectionType?: MONTHLY_SELECTION_TYPES
        monthlySelectionDay?: MONTHLY_SELECTION_DAYS
        monthlySelectionWeekDay?: Weekday
        dayOfMonth?: number
    }): RRule {
        const startDateString = params.startDate
            .toUTC()
            .set({ millisecond: 0 })
            .toISO({
                suppressMilliseconds: true,
            })!
            .replaceAll(/[-:]/g, '')

        let rruleString = `DTSTART:${startDateString}\nRRULE:FREQ=${params.frequency};INTERVAL=${params.interval ?? 1}`

        if (
            params.frequency === SUPPORTED_FREQUENCIES.WEEKLY &&
            params.weekDays &&
            params.weekDays.length
        ) {
            rruleString = rruleString.concat(`;BYDAY=${params.weekDays.join(',')}`)
        }

        if (
            params.frequency === SUPPORTED_FREQUENCIES.MONTHLY &&
            params.monthlySelectionType === MONTHLY_SELECTION_TYPES.DATE
        ) {
            rruleString = rruleString.concat(`;BYMONTHDAY=${params.dayOfMonth ?? 1}`)
        }

        if (
            params.frequency === SUPPORTED_FREQUENCIES.MONTHLY &&
            params.monthlySelectionType === MONTHLY_SELECTION_TYPES.WEEKDAY
        ) {
            rruleString = rruleString.concat(
                `;BYSETPOS=${params.monthlySelectionDay};BYDAY=${params.monthlySelectionWeekDay}`
            )
        }

        return RRule.fromString(rruleString)
    }
}
