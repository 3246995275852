import { styled, Typography, TypographyProps } from '@mui/material'
import { ReadLessTextView } from './ReadLessTextView'
import { MessageChildViewProperties } from './MessageChildViewProperties'

export const MessageHtmlView = (properties: MessageChildViewProperties) => {
    return (
        <>
            <MessageTextView
                className={properties.className}
                isCollapsed={properties.isCollapsed}
                linesToShowWhenCollapsed={properties.linesToShowWhenCollapsed}
                dangerouslySetInnerHTML={{ __html: properties.message }}
            />

            {!properties.isCollapsed && (
                <ReadLessTextView
                    translations={properties.translations}
                    onClicked={properties.onCollapseClicked}
                    paddingLeft={0}
                />
            )}
        </>
    )
}

const MessageTextView = styled(
    ({
        isCollapsed,
        linesToShowWhenCollapsed,
        ...properties
    }: TypographyProps & { isCollapsed: boolean; linesToShowWhenCollapsed?: number }) => (
        <Typography variant="body1" component="div" {...properties} />
    )
)(({ isCollapsed, linesToShowWhenCollapsed }) => ({
    display: isCollapsed ? '-webkit-box' : 'block',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: isCollapsed ? linesToShowWhenCollapsed ?? 8 : 'unset',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'unset',

    p: {
        marginBlockStart: 0,
    },

    'ul, ol': {
        display: 'flex',
        flexDirection: 'column',
        marginBlockStart: 0,
    },
}))
