import { Box } from '@mui/material'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { DetailViewContainer } from '../../common/detailView'
import { DETAIL_VIEW_HEADER_BOTTOM_Y } from '../../common/detailView/DetailViewHeader'
import { DetailViewProperties } from '../../common/detailView/DetailViewProperties'
import { useDetailView } from '../../common/detailView/hooks'
import { useTranslation } from 'shared/lib/i18n'
import { LikeListItemViewModel, LikeListLoadingViewModel } from './LikeListViewModels'
import { LikesList } from './LikesList'
import { useLikes } from './hooks'

interface Properties extends DetailViewProperties {
    postId: string
}

export const LikesListView = ({ postId, closeIcon, onCloseClicked }: Properties) => {
    const translations = useTranslation()

    const { isDetailViewDisplayedAsPopup: isDetailViewOpenAsPopup } = useDetailView()
    const { error, isLoading, likes } = useLikes(postId)

    const viewModels = isLoading
        ? Array.from({ length: 10 }, () => new LikeListLoadingViewModel())
        : likes.map((like) => new LikeListItemViewModel(like))

    return (
        <DetailViewContainer
            onCloseClicked={onCloseClicked}
            closeIcon={closeIcon}
            title={translations('involvement')}
            contentMarginTop={DETAIL_VIEW_HEADER_BOTTOM_Y}
        >
            <Box height="100%">
                <LikesList likes={viewModels} />
            </Box>

            <ErrorHandler
                error={error}
                translations={translations}
                horizontal={isDetailViewOpenAsPopup ? 'center' : 'right'}
            />
        </DetailViewContainer>
    )
}
