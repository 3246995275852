import { Box, BoxProps, styled, Typography } from '@mui/material'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useIsPhablet } from 'shared/lib/theme/BreakPointHooks'
import { grey_2 } from 'shared/lib/theme/Theme'
import { useDetailView } from '../../../common/detailView/hooks'
import { sessionRepository } from '../../../index'
import { useUsers } from '../../hooks'
import { User } from '../../User'
import { sortUsersByLastName } from '../../Utils'
import { AppAccessRequestsButton } from '../AppAccessRequestsButton'
import { NoResults } from '../NoResults'
import { UserListSearchField } from '../UserListSearchField'
import { UserListGrid } from './UserListGrid'

interface Properties {
    title: string
    users: User[]
    totalUserCount: number
    query: string
    hasNoResults: boolean
    isLoading: boolean

    onUserSelected(userId: string): void

    setQuery(query: string): void
}

export const TabletDesktopUserList = ({ isLoading, onUserSelected, ...properties }: Properties) => {
    const { isDetailViewOpen } = useDetailView()

    const isPhablet = useIsPhablet()

    const sortedUsers = sortUsersByLastName(properties.users)

    const { users: unapprovedUsers } = useUsers({
        isApproved: false,
    })

    return (
        <ListContainer>
            <HeaderContainer isDetailViewOpen={isDetailViewOpen}>
                {!isPhablet && (
                    <PageTitle variant="h1">
                        {properties.title}&nbsp;
                        {!isLoading && <MemberCount>({properties.totalUserCount})</MemberCount>}
                    </PageTitle>
                )}

                <HeaderActionContainer isDetailViewOpen={isDetailViewOpen}>
                    {sessionRepository.signedInUser?.canApproveOrDenyChurchMembers && (
                        <Box>
                            <AppAccessRequestsButton
                                withGroupBadge={false}
                                fullWidth={isPhablet}
                                appAccessRequestCount={unapprovedUsers.length}
                            />
                        </Box>
                    )}
                    <UserListSearchField
                        value={properties.query}
                        onChanged={properties.setQuery}
                        width={'100%'}
                    />
                </HeaderActionContainer>
            </HeaderContainer>
            {properties.hasNoResults ? (
                <NoResults />
            ) : (
                <Box height="100%">
                    <AutoSizer>
                        {({ height, width }) => (
                            <UserListGrid
                                users={sortedUsers}
                                isLoading={isLoading}
                                width={width}
                                height={height}
                                onUserSelected={onUserSelected}
                            />
                        )}
                    </AutoSizer>
                </Box>
            )}
        </ListContainer>
    )
}

const ListContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}))

const HeaderContainer = styled(
    ({ isDetailViewOpen, ...props }: BoxProps & { isDetailViewOpen: boolean }) => <Box {...props} />
)(({ theme, isDetailViewOpen }) => ({
    display: 'flex',
    flexDirection: isDetailViewOpen ? 'column' : 'row',
    alignItems: isDetailViewOpen ? 'start' : 'center',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 0, 3),

    [theme.breakpoints.only('phablet')]: {
        flexDirection: 'column',
        alignItems: 'start',
        flexWrap: 'wrap',
        justifyContent: 'stretch',
    },
}))

const PageTitle = styled(Typography)(({ theme }) => ({
    marginRight: theme.spacing(2),
    fontSize: 24,
    flexGrow: 2,
}))

const HeaderActionContainer = styled(
    ({ isDetailViewOpen, ...props }: BoxProps & { isDetailViewOpen: boolean }) => <Box {...props} />
)(({ theme, isDetailViewOpen }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    width: '100%',
    justifyContent: 'flex-end',

    [theme.breakpoints.only('phablet')]: {
        flexDirection: isDetailViewOpen ? 'column' : 'row',
        alignItems: isDetailViewOpen ? 'unset' : 'center',

        '& > *': {
            flexBasis: '50%',
        },
    },
}))

const MemberCount = styled('span')(() => ({
    color: grey_2,
}))
