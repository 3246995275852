import { useState } from 'react'
import { Select } from '../../components/Select'
import { TranslationFn } from '../../WithTranslations'
import { getLocalisedMonthlyOptionString, MonthlyOption } from '../MonthlyOption'
import { RecurrenceRule } from '../RecurrenceRule'
import { Box, MenuItem, Typography } from '@mui/material'

interface Properties {
    locale: string
    recurrenceRule: RecurrenceRule
    translations: TranslationFn
    onRecurrenceRuleChanged(recurrenceRule: RecurrenceRule): void
}

export const MonthlyOptionsView = ({
    recurrenceRule,
    locale,
    translations,
    onRecurrenceRuleChanged,
}: Properties) => {
    const monthlyOptions = Object.values(MonthlyOption)

    const [monthlyOption, setMonthlyOption] = useState<string>(
        (recurrenceRule.monthlyOption as MonthlyOption) ?? MonthlyOption.CURRENT_DAY
    )

    const onMonthlyOptionChanged = (value: string) => {
        setMonthlyOption(value)
        onRecurrenceRuleChanged(
            recurrenceRule.copy({
                monthlyOption: value as MonthlyOption,
            })
        )
    }

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="body1">{translations('on_every')}</Typography>

            <Box>
                <Select
                    value={monthlyOption}
                    onChange={(event) => onMonthlyOptionChanged(event.target.value as string)}
                >
                    {monthlyOptions.map((value) => (
                        <MenuItem key={value} value={value}>
                            {getLocalisedMonthlyOptionString({
                                option: value as MonthlyOption,
                                date: recurrenceRule.start,
                                locale,
                                translations,
                            })}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </Box>
    )
}
