import { Box, BoxProps, styled } from '@mui/material'
import { memo, useCallback, useEffect } from 'react'
import { useIsMobile } from 'shared/lib/theme/BreakPointHooks'
import { grey_3, spacing } from 'shared/lib/theme/Theme'
import { useDetailView } from '../common/detailView/hooks'
import { MessageView } from '../components/message/MessageView'
import { PostListItem } from './PostListComponents'
import { PostItemViewModel } from './PostViewModel'
import { PostActionBarView } from './components/PostActionBarView'
import { PostCharitiesView } from './components/PostCharitiesView'
import { PostDiscoverGroupsView } from './components/PostDiscoverGroupsView'
import { PostFundraiserView } from './components/PostFundraiserView'
import { PostLinkPreviewView } from './components/PostLinkPreviewView'
import { PostMediaView } from './components/PostMediaView'
import { PostPdfView } from './components/PostPdfView'
import { PostSharedGroupView } from './components/PostSharedGroupView'
import { PostSharedPostView } from './components/PostSharedPostView'
import { PostTopBarView } from './components/PostTopBarView'
import { OnScrolledObserver, ScrollListener } from 'shared/lib/common/ScrollListener'
import { useLocation } from 'react-router-dom'

interface Properties {
    isLastItem: boolean
    width: number
    viewModel: PostItemViewModel
    scrollListener: ScrollListener
}

const Component = ({ width, viewModel, scrollListener, ...properties }: Properties) => {
    const { isGroupNameVisible, post } = viewModel

    const location = useLocation()
    const isMobile = useIsMobile()
    const { isDetailViewOpen, openCommentsListView } = useDetailView()

    const isSelected = location.search.includes(post.id) && isDetailViewOpen
    const paddingLeftAndRight = isMobile ? 0 : spacing * 6

    const onShowCommentsClicked = () => {
        post.notifyMarkAsReadIfNeeded()
        openCommentsListView({
            resetHistory: true,
            post: viewModel.post,
        })
    }

    const onScrollObserver: OnScrolledObserver = useCallback(() => {
        const element = document.getElementById(post.id)!
        if (element.getBoundingClientRect().top < 0) {
            scrollListener.removeObserver(onScrollObserver)
            post.notifyMarkAsReadIfNeeded()
        }
    }, [post, scrollListener])

    useEffect(() => {
        if (post.isRead) {
            return
        }

        scrollListener.addObserver(onScrollObserver)

        return () => scrollListener.removeObserver(onScrollObserver)
    }, [post, scrollListener, onScrollObserver])

    return (
        <PostListItem id={post.id} disablePadding={true} isLastItem={properties.isLastItem}>
            <Container isSelected={isSelected}>
                <PostTopBarView
                    isGroupNameVisible={isGroupNameVisible}
                    isGroupNameClickable={!post.isInHomeGroup}
                    post={post}
                    onEditButtonClicked={() => viewModel.onEditPostButtonClicked(post)}
                    onDeleteButtonClicked={() => viewModel.onDeletePostButtonClicked(post.id)}
                    px={2}
                    pt={2}
                />
                <MessageView details={post} mx={2} mt={2} />
                <PostMediaView width={width} margins={paddingLeftAndRight} post={post} />
                <PostPdfView post={post} />
                <PostLinkPreviewView post={post} />
                <PostCharitiesView post={post} />
                <PostFundraiserView post={post} />
                <PostDiscoverGroupsView post={post} />
                <PostSharedGroupView
                    post={post}
                    sharedGroupId={post.sharedGroupId}
                    sharedGroup={post.sharedGroup}
                />
                <PostSharedPostView
                    width={width}
                    parentMargins={paddingLeftAndRight}
                    sharedPost={post.sharedPost}
                    sharedPostId={post.sharedPostId}
                />
                <PostActionBarView
                    isCreatingComment={viewModel.isCreatingComment}
                    post={post}
                    onPostLiked={(like) => viewModel.onLikeButtonClicked(post, like)}
                    onPostShared={viewModel.onPostShared}
                    onCreateCommentButtonClicked={(message) =>
                        viewModel.onCreateCommentButtonClicked(post, message)
                    }
                    onShowCommentsClicked={onShowCommentsClicked}
                />
            </Container>

            {isSelected && (
                <SelectedIndicatorContainer>
                    <Box height="24px" width="24px" borderRadius="0 0 16px 0" bgcolor={grey_3} />
                    <Box height="56px" />
                    <Box height="24px" width="24px" borderRadius="0 16px 0 0" bgcolor={grey_3} />
                </SelectedIndicatorContainer>
            )}
        </PostListItem>
    )
}

export const PostItemRow = memo(Component, (previous, next) => {
    return (
        previous.width === next.width &&
        previous.viewModel.isCreatingComment === next.viewModel.isCreatingComment &&
        JSON.stringify(previous.viewModel.post) === JSON.stringify(next.viewModel.post)
    )
})

const Container = styled(({ isSelected, ...properties }: BoxProps & { isSelected: boolean }) => (
    <Box {...properties} />
))(({ isSelected }) => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: isSelected ? '16px 0 16px 16px' : '16px',
    backgroundColor: 'white',
}))

const SelectedIndicatorContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    width: theme.spacing(3),
    height: '100px',
    backgroundColor: 'white',
}))
