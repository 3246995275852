import { DateTime } from 'luxon'
import { TranslationFn } from 'shared/lib/WithTranslations'
import { Image } from 'shared/lib/models/Image'
import { LinkPreview } from 'shared/lib/models/LinkPreview'
import { Pdf } from 'shared/lib/models/Pdf'
import { Video } from 'shared/lib/models/Video'
import { AppType } from '../churches/AppType'
import { Church } from '../churches/Church'
import i18n from '../i18n'
import { MarkAsReadEvent } from '../mychurch/events'
import { Cloneable } from '../utils/Misc'
import { MessageDetails } from './MessageDetails'
import { PostContentType } from './PostContentType'
import { PostFundraiser } from './PostFundraiser'
import { PostType } from './PostType'
import { SharedGroup } from './SharedGroup'
import { PostLikeInfo } from './likes/PostLikeInfo'

export class Post extends Cloneable<Post> implements MessageDetails {
    public readonly isRead: boolean

    get hasMessage(): boolean {
        return (this.message !== undefined && this.message?.length > 0) || this.isFirstInGroup
    }

    get hasGivingContent(): boolean {
        return this.contentType === PostContentType.GIVING
    }

    get hasCharities(): boolean {
        return this.hasGivingContent && this.fundraiser == null
    }

    get hasSharedGroup(): boolean {
        return !!this.sharedGroupId
    }

    get hasSharedPost(): boolean {
        return this.sharedPostId !== undefined
    }

    get sharedPost(): Post | undefined {
        return this.sharedPosts.length > 0 ? this.sharedPosts[0] : undefined
    }

    get hasMedia(): boolean {
        return this.images.length > 0 || this.videos.length > 0
    }

    get isEdited(): boolean {
        return this.updatedAt > this.createdAt
    }

    get showPopularLikes(): boolean {
        const like = this.likes?.likeFromSignedInUser
        const totalLikeCount = this.likes?.totalLikeCount ?? 0
        return (like && totalLikeCount > 1) || (!like && totalLikeCount > 0)
    }

    constructor(
        readonly id: string,
        readonly type: PostType,
        readonly contentType: PostContentType,
        readonly createdAt: DateTime,
        readonly updatedAt: DateTime,
        readonly groupId: string,
        readonly groupName: string,
        readonly groupDescription: string | undefined,
        readonly creatorId: string | undefined,
        private readonly creatorName: string | undefined,
        readonly creatorImage: Image | undefined,
        readonly fundraiser: PostFundraiser | undefined,
        readonly sharedPostId: string | undefined,
        readonly sharedPosts: Post[],
        readonly sharedGroupId: string | undefined,
        readonly sharedGroup: SharedGroup | undefined,
        readonly message: string | undefined,
        readonly hasMarkDown: boolean,
        readonly images: Image[],
        readonly videos: Video[],
        readonly pdfs: Pdf[],
        readonly linkPreviews: LinkPreview[],
        readonly numberOfComments: number,
        readonly isFirstInGroup: boolean,
        readonly isInHomeGroup: boolean,
        readonly canEdit: boolean,
        readonly postPlacedInGroupNotificationId: string | undefined,
        readonly likes: PostLikeInfo | undefined,
        readonly shareUrl: string
    ) {
        super()

        this.isRead = postPlacedInGroupNotificationId === undefined
    }

    getCreatorName(translations: TranslationFn): string {
        const translationKey = this.type === PostType.USER ? 'deleted_user' : 'deleted_source'
        return this.creatorName || translations(translationKey)
    }

    getMessage(church: Church, translations: TranslationFn): string {
        const groupDescription = this.groupDescription ? `\n\n${this.groupDescription}` : ''

        if (this.isFirstInGroup && this.isInHomeGroup) {
            if (church.appType === AppType.ORGANIZATION) {
                return translations('first_post_in_home_group_message_for_organization', [
                    this.groupName,
                ])
            }
            return translations('first_post_in_home_group_message_app_type_specific', [
                this.groupName,
                church.wordUsedForChurch(translations),
            ])
        }

        if (this.isFirstInGroup) {
            return translations('first_post_in_group_message', [
                this.getCreatorName(translations),
                this.createdAt.toLocaleString(undefined, {
                    locale: i18n.language,
                }),
                groupDescription,
            ])
        }
        return this.message || ''
    }

    notifyMarkAsReadIfNeeded() {
        if (this.isRead) {
            return
        }

        document.dispatchEvent(new MarkAsReadEvent(this))
    }

    static fromJSON(response: Record<string, any>): Post {
        return new Post(
            response.id ?? response._id,
            response.type,
            response.contentType,
            DateTime.fromISO(response.createdAt),
            DateTime.fromISO(response.updatedAt),
            response.groupId,
            response.groupName,
            response.groupDescription,
            response.creatorId,
            response.creatorName,
            response.creatorImage ? Image.fromResponse(response.creatorImage) : undefined,
            response.fundraiser ? PostFundraiser.fromResponse(response.fundraiser) : undefined,
            response.sharedPostId,
            response.sharedPosts
                ? response.sharedPosts.map((sharedPost: any) => Post.fromJSON(sharedPost))
                : [],
            response.sharedGroupId,
            response.sharedGroup ? SharedGroup.fromResponse(response.sharedGroup) : undefined,
            response.message?.replaceAll('\r\n', '\n'),
            response.canContainMarkdown,
            response.images.map((image: any) => Image.fromResponse(image)),
            response.videos.map((video: any) => Video.fromResponse(video)),
            response.pdfs.map((pdf: any) => Pdf.fromResponse(pdf)),
            response.linkPreviews.map((linkPreview: any) => LinkPreview.fromResponse(linkPreview)),
            response.numberOfComments,
            response.isFirstInGroup,
            response.isInHomeGroup,
            response.canEdit,
            response.postPlacedInGroupNotificationId,
            response.likes ? PostLikeInfo.fromResponse(response.likes) : undefined,
            response.shareUrl
        )
    }
}
