import { Box, Grow, IconButton as MuiButton, Popper, styled } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { defaultBoxShadow, defaultTransition, toolbarHeight } from 'shared/lib/theme/Theme'
import { ReactComponent as LikeFaith } from '../../assets/svg/like_faith.svg'
import { ReactComponent as LikeFlower } from '../../assets/svg/like_flower.svg'
import { ReactComponent as LikeHope } from '../../assets/svg/like_hope.svg'
import { ReactComponent as LikeLove } from '../../assets/svg/like_love.svg'
import { ReactComponent as LikeLoveOutlined } from '../../assets/svg/like_love_outlined.svg'
import { ReactComponent as LikePraise } from '../../assets/svg/like_praise.svg'
import { ReactComponent as LikePray } from '../../assets/svg/like_pray.svg'
import { ReactComponent as LikeThumbsUp } from '../../assets/svg/like_thumbs_up.svg'
import { ReactComponent as LikeChai } from '../../assets/svg/like_chai.svg'
import { AppType } from '../../churches/AppType'
import ChurchContext from '../../churches/ChurchContextProvider'
import { LikeType } from '../../posts/likes/LikeType'
import { useIsMobile } from 'shared/lib/theme/BreakPointHooks'

export interface Properties {
    likeType?: LikeType
    onPostLiked: (like?: LikeType) => void
}

export const LikeButton = (properties: Properties) => {
    const isMobile = useIsMobile()

    const [isHoveringSelector, setIsHoveringSelector] = useState(false)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [hideSelectorTimeout, setHideSelectorTimeout] = useState(0)
    const [longPressTarget, setLongPressTarget] = useState<HTMLButtonElement | null>(null)

    const church = useContext(ChurchContext)!.church
    const isFaithLikeVisible = church?.appType !== AppType.JEWISH
    const isChaiLikeVisible = church?.appType === AppType.JEWISH

    const resetState = (hideSelectorTimeout: number = 0) => {
        setIsHoveringSelector(false)
        setLongPressTarget(null)
        setAnchorEl(null)
        setHideSelectorTimeout(hideSelectorTimeout)
    }

    const onTouchStart = (event: React.TouchEvent<HTMLButtonElement>) => {
        if (!isMobile) {
            return
        }

        setLongPressTarget(event.currentTarget)
    }

    const onMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isMobile || hideSelectorTimeout === -1) {
            return
        }

        setAnchorEl(event.currentTarget)
    }

    const onClicked = (likeType?: LikeType, hideSelectorTimeout: number = 0) => {
        if (properties.likeType !== likeType) {
            resetState(hideSelectorTimeout)
            properties.onPostLiked(likeType)
        }
    }

    useEffect(() => {
        let timeOutId: NodeJS.Timeout | undefined = undefined

        if (!longPressTarget) {
            clearTimeout(timeOutId)
            return
        }

        timeOutId = setTimeout(() => {
            setAnchorEl(longPressTarget)
        }, 300)

        return () => clearTimeout(timeOutId)
    })

    useEffect(() => {
        let timeOutId: NodeJS.Timeout | undefined = undefined

        if (isHoveringSelector || hideSelectorTimeout === 0) {
            clearTimeout(timeOutId)
            return
        }

        timeOutId = setTimeout(() => {
            resetState()
        }, hideSelectorTimeout)

        return () => clearTimeout(timeOutId)
    }, [hideSelectorTimeout, isHoveringSelector])

    return (
        <>
            <LoveIconButton
                onTouchStart={onTouchStart}
                onMouseEnter={onMouseEnter}
                onMouseLeave={() => setHideSelectorTimeout(300)}
                onClick={() => onClicked(!properties.likeType ? LikeType.LOVE : undefined, -1)}
            >
                {getLikeIcon(properties.likeType)}
            </LoveIconButton>

            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement="top-start"
                disablePortal={false}
                transition={true}
                modifiers={[
                    {
                        name: 'flip',
                        options: {
                            padding: toolbarHeight,
                        },
                    },
                    {
                        name: 'offset',
                        options: {
                            offset: [isMobile ? 0 : -24, 0],
                        },
                    },
                ]}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        timeout={300}
                        style={{ transformOrigin: '0 66px 0' }}
                    >
                        <LikeButtonContainer
                            onMouseEnter={() => setIsHoveringSelector(true)}
                            onMouseLeave={() => setIsHoveringSelector(false)}
                        >
                            <LikeSelectorIconButton likeType={LikeType.LOVE} onClick={onClicked} />
                            {isChaiLikeVisible && (
                                <LikeSelectorIconButton
                                    likeType={LikeType.CHAI}
                                    onClick={onClicked}
                                />
                            )}
                            {isFaithLikeVisible && (
                                <LikeSelectorIconButton
                                    likeType={LikeType.FAITH}
                                    onClick={onClicked}
                                />
                            )}
                            <LikeSelectorIconButton likeType={LikeType.HOPE} onClick={onClicked} />
                            <LikeSelectorIconButton
                                likeType={LikeType.PRAISE}
                                onClick={onClicked}
                            />
                            <LikeSelectorIconButton
                                likeType={LikeType.THUMBS_UP}
                                onClick={onClicked}
                            />
                            <LikeSelectorIconButton
                                likeType={LikeType.FLOWER}
                                onClick={onClicked}
                            />
                            <LikeSelectorIconButton likeType={LikeType.PRAY} onClick={onClicked} />
                        </LikeButtonContainer>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

const getLikeIcon = (likeType?: LikeType) => {
    switch (likeType) {
        case LikeType.LOVE:
            return <LikeLove />
        case LikeType.FAITH:
            return <LikeFaith />
        case LikeType.HOPE:
            return <LikeHope />
        case LikeType.PRAISE:
            return <LikePraise />
        case LikeType.THUMBS_UP:
            return <LikeThumbsUp />
        case LikeType.FLOWER:
            return <LikeFlower />
        case LikeType.PRAY:
            return <LikePray />
        case LikeType.CHAI:
            return <LikeChai />
        default:
            return <LikeLoveOutlined />
    }
}

const LikeSelectorIconButton = (properties: {
    likeType: LikeType
    onClick: (likeType: LikeType) => void
}) => {
    return (
        <LikeIconButton onClick={() => properties.onClick(properties.likeType)}>
            {getLikeIcon(properties.likeType)}
        </LikeIconButton>
    )
}

const LoveIconButton = styled(MuiButton)(() => ({
    '& svg': {
        height: '20px',
        width: '20px',
    },
}))

const LikeIconButton = styled(MuiButton)(({ theme }) => ({
    padding: 0,

    '& svg': {
        height: '46px',
        width: '46px',

        [theme.breakpoints.only('mobile')]: {
            height: '35px',
            width: '35px',
        },

        transition: defaultTransition,
    },

    ':hover': {
        backgroundColor: 'transparent',
        '& svg': {
            transform: 'translateY(-23px) scale(1.3)',
        },
    },
}))

const LikeButtonContainer = styled(Box)(({ theme }) => ({
    height: '66px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '10px',
    gap: '8px',
    borderRadius: '44px',
    boxShadow: defaultBoxShadow,

    [theme.breakpoints.only('mobile')]: {
        height: '55px',
    },
}))
