import { LikeButton } from '../../mychurch/components/LikeButton'
import { PostPopularLikesView } from './PostPopularLikesView'
import { Box, styled } from '@mui/material'
import { Post } from '../Post'
import { LikeType } from '../likes/LikeType'

interface Properties {
    post: Post
    paddingLeft?: number
    resetHistoryWhenOpeningLikesList?: boolean

    onPostLiked(like?: LikeType): void
}

export const LikesRow = ({
    post,
    onPostLiked,
    paddingLeft,
    resetHistoryWhenOpeningLikesList,
}: Properties) => {
    const likeFromSignedInUser = post.likes?.likeFromSignedInUser

    return (
        <LikesContainer pl={paddingLeft}>
            <LikeButton likeType={likeFromSignedInUser} onPostLiked={onPostLiked} />
            <PostPopularLikesView
                post={post}
                resetHistoryWhenOpeningLikesList={resetHistoryWhenOpeningLikesList}
            />
        </LikesContainer>
    )
}

const LikesContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    marginLeft: theme.spacing(-0.5),
    marginBottom: theme.spacing(1),
    gap: theme.spacing(1),
}))
