import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'shared/lib/i18n'
import { grey_1 } from 'shared/lib/theme/Theme'

interface Properties {
    top: number

    onClick(): void
}
export const Placeholder = ({ top, onClick }: Properties) => {
    const translations = useTranslation()

    return (
        <Typography
            component="div"
            sx={{
                fontSize: 'inherit',
                color: grey_1,
                opacity: 1,
                position: 'absolute',
                top: `${top}px`,
            }}
            onClick={onClick}
        >
            {translations('enter_post_hint')}
        </Typography>
    )
}
