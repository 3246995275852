import { LinkPreviewView } from 'shared/lib/components/LinkPreviewView'
import { Post } from '../../posts/Post'
import { PostListItemContainer } from '../PostListComponents'
import { useTranslation } from 'shared/lib/i18n'

interface Properties {
    hasWhiteBackground?: boolean
    post: Post
}

export const PostLinkPreviewView = ({ post, ...properties }: Properties) => {
    const translations = useTranslation()

    const hasWhiteBackground = properties.hasWhiteBackground ?? false
    const linkPreview = post.linkPreviews[0]

    if (!linkPreview) {
        return <></>
    }

    return (
        <PostListItemContainer>
            <LinkPreviewView
                hasWhiteBackground={hasWhiteBackground}
                linkPreview={linkPreview}
                translations={{
                    loading_link_preview_failed: translations('loading_link_preview_failed'),
                }}
            />
        </PostListItemContainer>
    )
}
