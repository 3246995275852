
export enum GroupMemberEventKey {
    GROUP_MEMBERS_ADDED = 'groupMembersAdded',
    GROUP_MEMBER_APPROVED = 'groupMemberApproved',
    GROUP_MEMBER_DENIED = 'groupMemberDenied',
    GROUP_MEMBER_UPDATED = 'groupMemberUpdated',
    GROUP_MEMBER_DELETED = 'groupMemberDeleted',
}

export class GroupMemberAddedEvent extends CustomEvent<{
    groupId: string
    userId: string
}> {
    constructor(public readonly groupId: string, public readonly userId: string) {
        super(GroupMemberEventKey.GROUP_MEMBERS_ADDED, {
            detail: {
                groupId,
                userId,
            },
        })
    }
}

export class GroupMemberApprovedEvent extends CustomEvent<{
    groupId: string
    userId: string
}> {
    constructor(public readonly groupId: string, public readonly userId: string) {
        super(GroupMemberEventKey.GROUP_MEMBER_APPROVED, {
            detail: {
                groupId,
                userId,
            },
        })
    }
}

export class GroupMemberDeniedEvent extends CustomEvent<{
    groupId: string
    userId: string
}> {
    constructor(public readonly groupId: string, public readonly userId: string) {
        super(GroupMemberEventKey.GROUP_MEMBER_DENIED, {
            detail: {
                groupId,
                userId,
            },
        })
    }
}

export class GroupMemberUpdatedEvent extends CustomEvent<{
    groupId: string
    userId: string
    isAdmin: boolean
}> {
    constructor(groupId: string, userId: string, isAdmin: boolean) {
        super(GroupMemberEventKey.GROUP_MEMBER_UPDATED, {
            detail: {
                groupId,
                userId,
                isAdmin,
            },
        })
    }
}



export class GroupMemberDeletedEvent extends CustomEvent<{
    groupId: string
    userId: string
}> {
    constructor(public readonly groupId: string, public readonly userId: string) {
        super(GroupMemberEventKey.GROUP_MEMBER_DELETED, {
            detail: {
                groupId,
                userId,
            },
        })
    }
}
