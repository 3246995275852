import { styled, Typography } from '@mui/material'
import { grey_1, textColor } from 'shared/lib/theme/Theme'

export const FromToLabel = styled(Typography)(() => ({
    color: grey_1,

    '& > span': {
        color: textColor,
    },
}))
