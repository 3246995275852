import { SVGProps } from './SVGProps'

export const SearchIcon = (properties: SVGProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={properties.color || 'current'}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1624 15.1018C17.3101 13.717 18 11.9391 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C11.9391 18 13.717 17.3101 15.1018 16.1624L20.4697 21.5303C20.7626 21.8232 21.2374 21.8232 21.5303 21.5303C21.8232 21.2374 21.8232 20.7626 21.5303 20.4697L16.1624 15.1018ZM16.5 10C16.5 13.5899 13.5899 16.5 10 16.5C6.41015 16.5 3.5 13.5899 3.5 10C3.5 6.41015 6.41015 3.5 10 3.5C13.5899 3.5 16.5 6.41015 16.5 10Z"
                fill="current"
            />
        </svg>
    )
}
