import { Paths } from './paths'
import { matchRoutes, useLocation } from 'react-router-dom'

const routes = Object.values(Paths).map((path) => ({
    path: path as string,
}))

export const useCurrentPath = () => {
    const location = useLocation()
    const matchedRoutes = (matchRoutes(routes, location) ?? []).shift()

    return matchedRoutes?.route.path ?? ''
}

export const useSelectedRootPathName = () => {
    const supportedPathNames = [Paths.TIME_LINE, Paths.EVENTS, Paths.MEMBERS, Paths.NOTIFICATIONS]
    const currentPath = useCurrentPath()

    return supportedPathNames.find((path) => {
        return currentPath.includes(path)
    })
}
