import { EventListItem } from '../components/EventListComponents'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { Box, styled } from '@mui/material'

export const EventSectionLoadingRow = () => {
    return (
        <EventListItem isLastItem={false} hasLargePaddingTop={true}>
            <Container>
                <Skeleton variant="rounded" width={75} height={30} />
                <Skeleton
                    variant="text"
                    width={50}
                    height={25}
                    sx={{
                        marginLeft: (theme) => theme.spacing(2),
                    }}
                />
            </Container>
        </EventListItem>
    )
}

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
}))
