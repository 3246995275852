import { useEffect, useState } from 'react'
import { feedRepository } from '../index'
import { Feed } from './Feed'

export const useFeed = (feedId: string) => {
    const [isLoading, setIsLoading] = useState(true)
    const [feed, setFeed] = useState<Feed | undefined>(undefined)

    const getFeed = () => {
        setIsLoading(true)

        feedRepository
            .byId(feedId)
            .then(setFeed)
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        getFeed()
    }, [feedId]) // eslint-disable-line react-hooks/exhaustive-deps

    return { feed, isLoading }
}
