import { Box, CircularProgress, styled } from '@mui/material'

interface ActivityIndicatorProperties {
    isVisible?: boolean
    isFullScreen?: boolean
    color?: string
    size?: number
}

export const ActivityIndicator = (properties: ActivityIndicatorProperties) => {
    const isVisible = properties.isVisible === undefined ? true : properties.isVisible

    if (!isVisible) return <></>

    return (
        <Container isfullscreen={properties.isFullScreen ? 1 : 0}>
            <CircularProgress
                sx={{
                    color: properties.color,
                    margin: 'auto',
                }}
                size={properties.size}
            />
        </Container>
    )
}

const Container = styled(Box)<{ isfullscreen: number }>(({ isfullscreen }) => ({
    display: 'flex',
    width: '100%',
    height: isfullscreen ? '100dvh' : '100%',
}))
