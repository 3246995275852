import { Box, ListItem as MuiListItem, SkeletonProps, styled } from '@mui/material'
import { Skeleton } from 'shared/lib/components/Skeleton'

export const MEMBER_LOADING_ROW_HEIGHT = 74

export const MemberLoadingRow = () => {
    return (
        <ListItem disablePadding={true}>
            <PlaceHolder variant="circular" width={40} height={40} />
            <TextContainer>
                <PlaceHolder variant="text" width="50%" height={15} />
            </TextContainer>
        </ListItem>
    )
}

const PlaceHolder = (props: SkeletonProps) => <Skeleton {...props} />

const ListItem = styled(MuiListItem)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: MEMBER_LOADING_ROW_HEIGHT,
    alignItems: 'center',

    gap: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
}))

const TextContainer = styled(Box)(() => ({
    width: '300px',
}))
