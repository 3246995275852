import { DateTime } from 'luxon'
import { TranslationFn } from 'shared/lib/WithTranslations'
import i18n from '../i18n'

export const createdAtString = (createdAt: DateTime, translations: TranslationFn) => {
    const now = DateTime.now()
    const days = Math.floor(now.diff(createdAt, 'days').days)
    const hours = Math.floor(now.diff(createdAt, 'hours').hours)
    const minutes = Math.floor(now.diff(createdAt, 'minutes').minutes)

    if (days <= 0 && hours <= 0 && minutes <= 0) {
        return translations('just_placed')
    }
    if (days <= 0 && hours <= 0) {
        return translations('placed_minutes_ago', [minutes], minutes)
    }

    if (days <= 0) {
        return translations('placed_hours_ago', [hours], hours)
    }

    if (days <= 7) {
        return translations('placed_days_ago', [days], days)
    }

    return createdAt.toFormat('EEE d MMM yyyy', {
        locale: i18n.language,
    })
}
