import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import sprintf from 'i18next-sprintf-postprocessor'
import { initReactI18next } from 'react-i18next'

const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage'],
}

const getTranslationsForLanguage = (language: string) => {
    const translations = require(`./locales/${language}.json`)

    return Object.keys(translations).reduce((acc, key) => {
        if (typeof translations[key] === 'string') {
            if (translations[key] === '') {
                return acc
            }

            return {
                ...acc,
                [key]: translations[key],
            }
        }

        return {
            ...acc,
            ...Object.keys(translations[key]).reduce((flattenedKeys, subKey) => {
                if (translations[key][subKey] === '') {
                    return flattenedKeys
                }

                return {
                    ...flattenedKeys,
                    [`${key}_${subKey}`]: translations[key][subKey],
                }
            }, {}),
        }
    }, {})
}

export const SUPPORTED_LANGUAGES = ['nl', 'en', 'fy', 'fr', 'de', 'pl']

i18n.use(sprintf)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: DETECTION_OPTIONS,
        returnNull: false,
        fallbackLng: 'en',
        supportedLngs: SUPPORTED_LANGUAGES,
        compatibilityJSON: 'v4',
        resources: SUPPORTED_LANGUAGES.reduce(
            (acc, language) => ({
                ...acc,
                [language]: {
                    translation: getTranslationsForLanguage(language),
                },
            }),
            {}
        ),
    })

export default i18n
