import React from 'react'
import { ContentContainer } from '../common/layout/ContentContainer'
import { Box, styled, Typography } from '@mui/material'
import { useTranslation } from 'shared/lib/i18n'
import {
    darkGrey,
    grey_1,
    mobileBottomBarHeight,
    mobileToolbarHeight,
} from 'shared/lib/theme/Theme'
import { MobileHeader } from '../common/layout/MobileHeader'
import { useIsMobile } from 'shared/lib/theme/BreakPointHooks'

interface UnapprovedPageProperties {
    mobileHeaderTitle: string
    message: string
}

export const UnapprovedPage = (properties: UnapprovedPageProperties) => {
    const translations = useTranslation()
    const isMobile = useIsMobile()

    return (
        <>
            {isMobile && (
                <MobileHeader
                    hasBackButton={false}
                    title={properties.mobileHeaderTitle}
                    isApprovedUser={false}
                />
            )}
            <ContentContainer
                mt={isMobile ? mobileToolbarHeight : undefined}
                mb={isMobile ? mobileBottomBarHeight : undefined}
            >
                <CenteredBox>
                    <Title>{translations('unapproved_view_title')}</Title>
                    <Message>{properties.message}</Message>
                </CenteredBox>
            </ContentContainer>
        </>
    )
}

const CenteredBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
}))

const Title = styled(Typography)(() => ({
    fontWeight: 700,
    fontSize: '21px',
    color: darkGrey,
    maxWidth: '470px',
    textAlign: 'center',
}))

const Message = styled(Typography)(() => ({
    color: grey_1,
    textAlign: 'center',
    maxWidth: '470px',
}))
