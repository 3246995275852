import { isUndefined } from 'lodash'

export type XOr<T> = {
    [K in keyof T]: Pick<T, K> & Partial<Record<Exclude<keyof T, K>, never>>
}[keyof T]

export const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>

export abstract class Cloneable<T> {
    protected clone(newPropertyValues: Partial<T> = {}): T {
        const clone = new (this.constructor as { new (): T })()

        const nestedClones = Object.getOwnPropertyNames(clone).reduce((partial, propertyName) => {
            const property = Object.getOwnPropertyDescriptor(clone, propertyName)
            const isCloneable = property?.value instanceof Cloneable
            const isNotProvided = isUndefined(
                Object.getOwnPropertyDescriptor(newPropertyValues, propertyName)
            )

            if (isCloneable && isNotProvided) {
                partial[propertyName as keyof T] = property?.value.clone()
            }

            return partial
        }, {} as Partial<T>)

        return Object.assign(clone as {}, this, nestedClones, newPropertyValues) as T
    }

    cloneWith(newPropertyValues: Partial<T>): T {
        return this.clone(newPropertyValues)
    }
}

export type Optional<T> = T | undefined
