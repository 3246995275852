import { BadgeProps } from '@mui/base'
import { Badge, styled } from '@mui/material'
import { PropsWithChildren } from 'react'

export const DotBadge = styled(
    (properties: Omit<PropsWithChildren<BadgeProps>, 'color' | 'overlap' | 'variant'>) => (
        <Badge color="primary" overlap="circular" variant="dot" {...properties}>
            {properties.children}
        </Badge>
    )
)(() => ({
    '& .MuiBadge-badge': { width: '12px', height: '12px', borderRadius: '50%' },
}))
