import React from 'react'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { Box, ListItem } from '@mui/material'
import { AvatarSkeleton } from '../../common/detailView'
import { NotificationListItemLoadingRowHeight } from '../../notifications/list/rows'

export const TabletDesktopGroupListItemLoadingState = () => {
    return (
        <ListItem
            sx={{
                height: NotificationListItemLoadingRowHeight,
                gap: 2,
                paddingBottom: 0,
                paddingTop: 0,
                marginBottom: '-3px',
            }}
        >
            <AvatarSkeleton
                width={40}
                height={40}
                sx={{
                    minWidth: 40,
                }}
            />
            <Box display="flex" flexDirection="column" gap={1} width="100%">
                <Skeleton variant="rounded" width="90%" />
                <Skeleton variant="rounded" width="45%" />
            </Box>
        </ListItem>
    )
}
