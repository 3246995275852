import { NotificationType } from './NotificationType'

export class UnreadNotification {
    constructor(
        readonly id: string,
        readonly type: NotificationType,
        readonly groupId?: string,
        readonly postId?: string
    ) {}

    static fromJSON(json: Record<string, any>): UnreadNotification {
        return new UnreadNotification(json.id, json.type, json.groupId, json.postId)
    }
}
