import { useEffect, useState } from 'react'
import { firstBy } from 'thenby'
import { StyledVirtuoso } from '../../common/StyledVirtuoso'
import { useDetailView } from '../../common/detailView/hooks'
import { useTranslation } from 'shared/lib/i18n'
import { sessionRepository } from '../../index'
import { sortUsersByNameFn } from '../../user/Utils'
import {
    USER_LIST_LOADING_ROW_HEIGHT,
    UserListAddMemberRow,
    UserListFooterRow,
    UserListItemRow,
    UserListLoadingRow,
} from '../../user/userList/UserListItemRows'
import { Group } from '../Group'
import { GroupMemberActionsButton } from '../detail/GroupMemberActionsButton'
import { GroupMember } from './GroupMember'
import {
    GroupMemberIsLoadingViewModel,
    GroupMemberListAddGroupMembersViewModel,
    GroupMemberListFooterViewModel,
    GroupMemberListItemViewModel,
    GroupMemberListViewModel,
} from './GroupMemberListViewModels'
import { useLocation } from 'react-router-dom'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { AddMembersPopup } from '../AddMembersPopup'

interface Properties {
    width: number
    height: number
    isLoading: boolean
    group?: Group
    groupMembers: GroupMember[]
}

export const GroupMembersList = ({ width, height, groupMembers, ...properties }: Properties) => {
    const translations = useTranslation()
    const { openUserDetailView } = useDetailView()

    const rowsThatFitScreen = Math.ceil(height / USER_LIST_LOADING_ROW_HEIGHT)

    const canEdit = properties.group?.canEdit === true
    const signedInUserId = sessionRepository.signedInUser?.id

    const [isAddingGroupMembers, setIsAddingGroupMembers] = useState(false)
    const [viewModels, setViewModels] = useState<GroupMemberListViewModel[]>([])
    const [error, setError] = useState<Error | undefined>(undefined)

    useEffect(() => {
        if (groupMembers.length === 0) {
            setViewModels(
                Array.from({ length: rowsThatFitScreen }).map(
                    (_, index) => new GroupMemberIsLoadingViewModel()
                )
            )
            return
        }

        setViewModels([
            ...(canEdit ? [new GroupMemberListAddGroupMembersViewModel()] : []),
            ...groupMembers
                .sort(
                    firstBy((groupMember: GroupMember) => groupMember.id === signedInUserId, 'desc')
                        .thenBy('isAdmin', 'desc')
                        .thenBy(sortUsersByNameFn)
                )
                .map((groupMember) => new GroupMemberListItemViewModel(groupMember)),
            new GroupMemberListFooterViewModel(),
        ])
    }, [properties.isLoading, rowsThatFitScreen, canEdit, groupMembers, signedInUserId])

    return (
        <>
            <StyledVirtuoso
                totalCount={viewModels.length}
                itemContent={(index) => (
                    <Row
                        index={index}
                        signedInUserId={signedInUserId}
                        group={properties.group}
                        viewModels={viewModels}
                        onAddGroupMembersClicked={() => setIsAddingGroupMembers(true)}
                        onUserSelected={openUserDetailView}
                        onError={setError}
                    />
                )}
                overscan={500}
                style={{
                    height,
                    width,
                }}
            />

            {isAddingGroupMembers && properties.group && (
                <AddMembersPopup
                    groupId={properties.group.id}
                    onCloseClicked={() => setIsAddingGroupMembers(false)}
                />
            )}

            <ErrorHandler error={error} translations={translations} horizontal="right" />
        </>
    )
}

const Row = (params: {
    index: number
    signedInUserId?: string
    group?: Group
    viewModels: GroupMemberListViewModel[]
    onAddGroupMembersClicked: () => void
    onUserSelected: (userId: string) => void
    onError: (error: Error) => void
}) => {
    const location = useLocation()
    const translations = useTranslation()
    const viewModel = params.viewModels[params.index]

    const isClickable = !location.search.includes('userId')

    if (viewModel instanceof GroupMemberListItemViewModel) {
        const groupMember = viewModel.groupMember
        return (
            <UserListItemRow
                key={params.index}
                user={groupMember}
                isClickable={isClickable}
                onClick={() => params.onUserSelected(groupMember.id)}
                endLabel={groupMember.isAdmin ? translations('admin') : undefined}
                useUForSignedInUser={true}
                actionsButton={
                    groupMember.id !== params.signedInUserId && params.group?.canEdit ? (
                        <GroupMemberActionsButton
                            groupId={params.group.id}
                            groupMember={groupMember}
                            onError={params.onError}
                        />
                    ) : (
                        <></>
                    )
                }
            />
        )
    } else if (viewModel instanceof GroupMemberIsLoadingViewModel) {
        return <UserListLoadingRow />
    } else if (viewModel instanceof GroupMemberListAddGroupMembersViewModel) {
        return (
            <UserListAddMemberRow
                key={params.index}
                onClick={() => params.onAddGroupMembersClicked()}
            />
        )
    } else if (viewModel instanceof GroupMemberListFooterViewModel) {
        return <UserListFooterRow />
    }

    return null
}
