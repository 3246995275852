import { useContext, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'shared/lib/i18n'
import { useIsTabletOrBigger } from 'shared/lib/theme/BreakPointHooks'
import SessionContext from './authentication/SessionContextProvider'
import ChurchContext from './churches/ChurchContextProvider'
import { MobileBottomNavigationBar } from './common/layout/MobileBottomNavigationBar'
import { TabletDesktopHeader } from './common/layout/TabletDesktopHeader'
import { EventsPage } from './events/EventsPage'
import { GroupsContextProvider } from './groups/GroupsContextProvider'
import { MyChurchPage } from './mychurch/MyChurchPage'
import { NotificationsContextProvider } from './notifications/NotificationsContextProvider'
import { NotificationsPage } from './notifications/NotificationsPage'
import { Paths } from './routing/paths'
import { UnapprovedPage } from './unapproved/UnapprovedPage'
import { UserProfilePage } from './user/UserProfilePage'
import { SettingsPage } from './user/settings/SettingsPage'
import { ChangePasswordPage } from './user/settings/changepassword/ChangePasswordPage'
import { TwoFactorAuthenticationPage } from './user/settings/twofactorauthentication/TwoFactorAuthenticationPage'
import { UserListPage } from './user/userList/UserListPage'
import { UsersContextProvider } from './user/UsersContextProvider'

export const Main = () => {
    const navigate = useNavigate()
    const translations = useTranslation()
    const location = useLocation()
    const isTabletOrBigger = useIsTabletOrBigger()

    const { church } = useContext(ChurchContext)!
    const { getIsSignedIn, getSignedInUser } = useContext(SessionContext)!

    const isSignedIn = getIsSignedIn()
    const signedInUser = getSignedInUser()
    const isProfileCreated = signedInUser?.isProfileCreated === true
    const isApprovedUser = (signedInUser?.canSeeGroups && signedInUser.canSeeChurchMembers) === true
    const isBottomNavigationBarVisible = isBottomBarVisible(location.pathname)

    useEffect(() => {
        if (isSignedIn && !isProfileCreated) {
            navigate(Paths.CREATE_PROFILE, { replace: true })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!isSignedIn || !isProfileCreated) {
        return <></>
    }

    return (
        <UsersContextProvider>
            <GroupsContextProvider>
                <NotificationsContextProvider>
                    {isTabletOrBigger && <TabletDesktopHeader />}
                    <>
                        <Routes>
                            <Route index path={Paths.HOME} element={<RedirectToTimeLine />} />
                            <Route
                                index
                                path={Paths.GROUP_TIME_LINE}
                                element={
                                    isApprovedUser ? (
                                        <MyChurchPage />
                                    ) : (
                                        <UnapprovedPage
                                            mobileHeaderTitle={
                                                church?.getMyChurchGroupName(translations) || ''
                                            }
                                            message={translations(
                                                'unapproved_view_message_my_church'
                                            )}
                                        />
                                    )
                                }
                            />
                            <Route
                                path={Paths.GROUP_EVENTS}
                                element={
                                    isApprovedUser ? (
                                        <EventsPage />
                                    ) : (
                                        <UnapprovedPage
                                            mobileHeaderTitle={translations('page_calendar')}
                                            message={translations(
                                                'unapproved_view_message_calendar'
                                            )}
                                        />
                                    )
                                }
                            />
                            <Route
                                path={Paths.MEMBERS}
                                element={
                                    isApprovedUser ? (
                                        <UserListPage />
                                    ) : (
                                        <UnapprovedPage
                                            mobileHeaderTitle={
                                                church
                                                    ? translations(
                                                          church.getUserListPageNameTranslationKey()
                                                      )
                                                    : ''
                                            }
                                            message={translations(
                                                'unapproved_view_message_user_list'
                                            )}
                                        />
                                    )
                                }
                            />
                            <Route path={Paths.SETTINGS} element={<SettingsPage />} />
                            <Route path={Paths.PROFILE} element={<UserProfilePage />} />
                            <Route
                                path={Paths.SETTINGS_CHANGE_PASSWORD}
                                element={<ChangePasswordPage />}
                            />
                            <Route
                                path={Paths.SETTINGS_TWO_FACTOR_AUTHENTICATION}
                                element={<TwoFactorAuthenticationPage />}
                            />
                            <Route
                                path={Paths.NOTIFICATIONS}
                                element={
                                    isApprovedUser ? (
                                        <NotificationsPage />
                                    ) : (
                                        <UnapprovedPage
                                            mobileHeaderTitle={translations('notification')}
                                            message={translations(
                                                'unapproved_view_message_notification'
                                            )}
                                        />
                                    )
                                }
                            />
                            <Route path={'*'} element={<RedirectToTimeLine />} />
                        </Routes>
                    </>
                    {!isTabletOrBigger && isBottomNavigationBarVisible && (
                        <MobileBottomNavigationBar />
                    )}
                </NotificationsContextProvider>
            </GroupsContextProvider>
        </UsersContextProvider>
    )
}

export const isBottomBarVisible = (pathname: string): boolean => {
    return ![
        Paths.NOTIFICATIONS.toString(),
        Paths.PROFILE.toString(),
        Paths.SETTINGS.toString(),
        Paths.SETTINGS_TWO_FACTOR_AUTHENTICATION.toString(),
        Paths.SETTINGS_CHANGE_PASSWORD.toString(),
    ].includes(pathname)
}

const RedirectToTimeLine = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate(Paths.TIME_LINE, { replace: true })
    }, [navigate])

    return <></>
}
