import { Box, Typography, styled } from '@mui/material'
import { DateTime } from 'luxon'
import { darkGrey } from 'shared/lib/theme/Theme'
import { EventSectionViewModel } from '../EventViewModel'
import { EventListItem } from '../components/EventListComponents'
import i18n from '../../i18n'
import { capitalizeFirstLetter } from 'shared/lib/utils/StringUtils'

interface Properties {
    viewModel: EventSectionViewModel
}

export const EventSectionRow = ({ viewModel }: Properties) => {
    const date = viewModel.date.setLocale(i18n.language)
    const now = DateTime.now().setLocale(i18n.language)
    const isToday = now.hasSame(date, 'day')
    const isOtherYear = !now.hasSame(date, 'year')

    const getDayText = () => {
        switch (true) {
            case isOtherYear:
                return date.toFormat('dd LLL yyyy')
            case isToday:
                return capitalizeFirstLetter(i18n.t('today'))
            default:
                return date.toFormat('dd LLL')
        }
    }

    return (
        <EventListItem isLastItem={false} hasLargePaddingTop={true}>
            <Container>
                <DayView istoday={isToday ? 1 : 0}>
                    <DayTextView variant="body1">{getDayText()}</DayTextView>
                </DayView>

                <WeekTextView variant="body1" istoday={isToday ? 1 : 0}>
                    {capitalizeFirstLetter(date.weekdayLong!)}
                </WeekTextView>
            </Container>
        </EventListItem>
    )
}

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
}))

const DayView = styled(Box)<{ istoday: number }>(({ theme, istoday }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '24px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: istoday ? theme.palette.primary.main : darkGrey,
}))

const DayTextView = styled(Typography)(() => ({
    fontWeight: 700,
    color: 'white',
}))

const WeekTextView = styled(Typography)<{ istoday: number }>(({ theme, istoday }) => ({
    marginLeft: theme.spacing(2),
    fontWeight: 700,
    color: istoday ? theme.palette.primary.main : darkGrey,
}))
