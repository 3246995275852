import { Box, Fade, styled, Typography } from '@mui/material'
import React, { useState } from 'react'
import { grey_1 } from 'shared/lib/theme/Theme'
import { HorizontalDivider } from 'shared/lib/components/Divider'
import { Checkbox } from 'shared/lib/components/Checkbox'
import { useTranslation } from 'shared/lib/i18n'

interface Properties {
    isStartGroup: boolean
    isGroupRestricted: boolean
    onIsStartGroupChanged: (isStartGroup: boolean) => void
    setIsGroupRestricted: (isGroupRestricted: boolean) => void
}

export const AdminSettingsStepView = (properties: Properties) => {
    const translations = useTranslation()
    const [showStartGroupWarning, setShowStartGroupWarning] = useState(false)

    const onIsStartGroupChanged = (isStartGroup: boolean) => {
        properties.onIsStartGroupChanged(isStartGroup)

        setShowStartGroupWarning(isStartGroup)
    }

    return (
        <Container mb={'100px'}>
            <SettingsRow>
                <Box>
                    <Title>{translations('setting_start_group_title')}</Title>
                    <Description>{translations('setting_start_group_subtitle')}</Description>
                </Box>
                <Checkbox
                    checked={properties.isStartGroup}
                    onChange={(event) => onIsStartGroupChanged(event.target.checked)}
                ></Checkbox>
            </SettingsRow>

            <HorizontalDivider />

            <SettingsRow>
                <Box>
                    <Title>{translations('setting_restrict_group_permissions_title')}</Title>
                    <Description>
                        {translations('setting_restrict_group_permissions_subtitle')}
                    </Description>
                </Box>

                <Checkbox
                    checked={properties.isGroupRestricted}
                    onChange={(event) => properties.setIsGroupRestricted(event.target.checked)}
                ></Checkbox>
            </SettingsRow>

            <Fade in={showStartGroupWarning}>
                <Typography
                    sx={{
                        color: (theme) => theme.palette.error.main,
                    }}
                >
                    {translations('setting_start_group_warning')}
                </Typography>
            </Fade>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
}))

const SettingsRow = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
}))

const Title = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: 400,
}))

const Description = styled(Typography)(() => ({
    color: grey_1,
    fontSize: '12px',
    fontWeight: 400,
}))
