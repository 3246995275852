import { Box, Checkbox, FormControlLabel, Link, Snackbar, styled, Typography } from '@mui/material'
import { ReactComponent as BackIcon } from 'shared/lib/assets/svg/chevron_left.svg'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom'
import { ReactComponent as CheckBoxActiveIcon } from 'shared/lib/assets/svg/checkbox_active.svg'
import { ReactComponent as CheckBoxInactive } from 'shared/lib/assets/svg/checkbox_inactive.svg'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { PasswordTextField } from 'shared/lib/components/textfields/PasswordTextField'
import { TextField } from 'shared/lib/components/textfields/TextField'
import { isValidEmail } from 'shared/lib/utils/StringUtils'
import donkey from '../../assets/images/donkey.jpg'
import ChurchContext from '../../churches/ChurchContextProvider'
import { Paths } from '../../routing/paths'
import { PrimaryActionButtonContainer } from 'shared/lib/components/PrimaryActionButtonsContainer'
import SessionContext from '../SessionContextProvider'
import { SessionPage, Title } from '../SessionPage'
import { useTranslation } from 'shared/lib/i18n'
import { DEFAULT_VALIDATION_TIMEOUT } from 'shared/lib/common/Constants'
import { HorizontalDivider } from 'shared/lib/components/Divider'

export const SignInPage = () => {
    const translations = useTranslation()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()

    const sessionContext = useContext(SessionContext)!
    const { applicationId, church, getChurch } = useContext(ChurchContext)!

    const [isConfirmed, setIsConfirmed] = useState(Boolean(searchParams.get('isConfirmed')))
    const [isSigningIn, setIsSigningIn] = useState(false)
    const [invalidEmailError, setInvalidEmailError] = useState<string | undefined>()
    const [isRememberMeChecked, setIsRememberMeChecked] = useState(false)
    const [error, setError] = useState<Error | undefined>()
    const [emailAddress, setEmailAddress] = useState('')
    const [password, setPassword] = useState('')

    const canContinue =
        emailAddress.trim().length > 0 && password !== '' && isValidEmail(emailAddress)

    const onEmailAddressChanged = (value: string) => {
        setInvalidEmailError(undefined)
        setEmailAddress(value)
    }

    const validateEmailAddress = useCallback(() => {
        if (emailAddress.length === 0) {
            setInvalidEmailError(undefined)
            return
        }

        const hasValidEmailAddress = isValidEmail(emailAddress)
        const message = hasValidEmailAddress ? undefined : translations('invalid_email_address')
        if (emailAddress.trim().length > 0) {
            setInvalidEmailError(message)
        }
        return hasValidEmailAddress
    }, [emailAddress, translations])

    const signIn = () => {
        if (!church || !canContinue || !validateEmailAddress()) return

        setIsSigningIn(true)

        sessionContext
            .signIn({
                emailAddress,
                password,
                useSessionStorage: !isRememberMeChecked,
            })
            .catch(setError)
            .finally(() => setIsSigningIn(false))
    }

    const onSignInToOtherAppButtonClicked = () => {
        const path =
            applicationId?.includes('legerdesheils') || applicationId?.includes('ldh')
                ? Paths.SALVATION_ARMY
                : Paths.SELECT_CHURCH

        navigate(path, { replace: true })
    }

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            validateEmailAddress()
        }, DEFAULT_VALIDATION_TIMEOUT)

        return () => {
            clearTimeout(timeOutId)
        }
    }, [validateEmailAddress])

    useEffect(() => {
        if (!church && applicationId) {
            getChurch(applicationId)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!church) {
        return <></>
    }

    return (
        <SessionPage backgroundImage={donkey} church={church}>
            <Title variant="h1">{translations('signin')}</Title>

            <TextField
                fullWidth={true}
                autoFocus={true}
                disabled={isSigningIn}
                type="email"
                value={emailAddress}
                error={invalidEmailError !== undefined}
                helperText={invalidEmailError}
                label={translations('email_address')}
                onChanged={onEmailAddressChanged}
                sx={{
                    marginTop: 5,
                }}
            />

            <PasswordTextField
                id="password"
                name="password"
                fullWidth={true}
                disabled={isSigningIn}
                value={password}
                label={translations('password')}
                onChanged={setPassword}
                onEnterKeyPressed={() => signIn()}
                sx={{
                    marginTop: 4,
                }}
            />

            <Typography variant="body1" mt={2}>
                {translations('password_forgotten')}
                &nbsp;
                <Link component={RouterLink} to={Paths.FORGOT_PASSWORD}>
                    {translations('forgot_password_link')}
                </Link>
            </Typography>

            <RememberMeContainer>
                <FormControlLabel
                    sx={{
                        marginLeft: '-9px',
                    }}
                    control={
                        <Checkbox
                            checked={isRememberMeChecked}
                            icon={<CheckBoxInactive />}
                            checkedIcon={<CheckBoxActive />}
                            onChange={(event) => setIsRememberMeChecked(event.target.checked)}
                        />
                    }
                    label={translations('remember_on_this_device')}
                />
            </RememberMeContainer>

            <PrimaryActionButtonContainer>
                <PrimaryContainedButton
                    fullWidth={true}
                    isLoading={isSigningIn}
                    title={translations('signin')}
                    disabled={!canContinue}
                    onClick={() => signIn()}
                />
            </PrimaryActionButtonContainer>

            <Typography variant="body1" mt={5}>
                {translations('no_account_question')}
                &nbsp;
                <Link
                    onClick={() => sessionStorage.clear()}
                    component={RouterLink}
                    to={Paths.SIGN_UP}
                >
                    {translations('create_an_account')}
                </Link>
            </Typography>

            <HorizontalDivider mt={4} />

            <SignInToOtherAppContainer onClick={onSignInToOtherAppButtonClicked}>
                <ChevronLeftIcon />
                <SignInToOtherAppTextView variant="body1">
                    {translations('sign_in_to_another_app')}
                </SignInToOtherAppTextView>
            </SignInToOtherAppContainer>

            <ErrorHandler error={error} horizontal="left" translations={translations} />

            <Snackbar
                open={Boolean(isConfirmed)}
                onClose={() => setIsConfirmed(false)}
                autoHideDuration={5000}
                message={translations('message_account_confirmed')}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            />
        </SessionPage>
    )
}

const RememberMeContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
}))

const CheckBoxActive = styled(CheckBoxActiveIcon)(({ theme }) => ({
    fill: theme.palette.primary.main,
}))

const SignInToOtherAppContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: 'fit-content',
    marginTop: theme.spacing(4),
    alignItems: 'center',
    cursor: 'pointer',
}))

const ChevronLeftIcon = styled(BackIcon)(({ theme }) => ({
    height: '16px',
    width: '16px',
    fill: theme.palette.primary.main,
}))

const SignInToOtherAppTextView = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    lineHeight: 1,
}))
