import { Box, BoxProps, styled, Typography } from '@mui/material'
import { PropsWithChildren, ReactNode, useCallback, useEffect } from 'react'
import { ButtonByType, ButtonType } from '../components/buttons/ButtonType'
import { FilledCloseButtonWithWhiteCross } from '../components/buttons/CloseButton'
import { ButtonProperties, DefaultButton } from '../components/buttons/DefaultButton'
import { Popup } from '../components/Popup'
import { grey_3, textColor } from '../theme/Theme'

export type WizardPopupFooterProperties = {
    hasBackButton: boolean
    canContinue: boolean
    wrapButtons?: boolean
    nextButtonType?: ButtonType
    nextButtonTitle: string
    backButtonTitle?: string
    isNextButtonLoading?: boolean
    onBackButtonClicked?: () => void
    onNextButtonClicked: () => void
}

interface Properties {
    isVisible: boolean
    isLoading?: boolean
    withBackdrop?: boolean
    autoSizeHeight?: boolean
    hasToolbarBorder?: boolean
    desktopWidth?: string
    title: string
    headerContent?: ReactNode
    footer?: WizardPopupFooterProperties
    onCloseButtonClicked(): void
}

export const WizardPopup = ({
    onCloseButtonClicked,
    ...properties
}: PropsWithChildren<Properties>) => {
    const onKeyUpButtonClicked = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onCloseButtonClicked()
            }
        },
        [onCloseButtonClicked]
    )

    useEffect(() => {
        document.addEventListener('keyup', onKeyUpButtonClicked, false)
        return () => {
            document.removeEventListener('keyup', onKeyUpButtonClicked, false)
        }
    }, [onKeyUpButtonClicked])

    return (
        <Popup
            isVisible={properties.isVisible}
            withBackdrop={properties.withBackdrop}
            autoSizeHeight={properties.autoSizeHeight}
            desktopWidth={properties.desktopWidth}
        >
            <HeaderContainer hasBorderBottom={properties.hasToolbarBorder ?? true}>
                <TitleContainer>
                    <Title>{properties.title}</Title>
                    <FilledCloseButtonWithWhiteCross
                        buttonSize="small"
                        onClick={onCloseButtonClicked}
                    />
                </TitleContainer>
                {properties.headerContent && (
                    <HeaderContent>{properties.headerContent}</HeaderContent>
                )}
            </HeaderContainer>
            <ContentContainer>{properties.children}</ContentContainer>
            {properties.footer && (
                <FooterBox>
                    <FooterRow>
                        <BackButton
                            isHidden={!properties.footer.hasBackButton}
                            title={properties.footer.backButtonTitle}
                            width={properties.footer.wrapButtons ? undefined : '100%'}
                            onClick={properties.footer!.onBackButtonClicked}
                        />

                        <PrimaryActionButtonContainer>
                            <ButtonByType
                                isLoading={properties.isLoading}
                                isDisabled={!properties.footer.canContinue}
                                type={properties.footer.nextButtonType}
                                title={properties.footer.nextButtonTitle}
                                width={properties.footer.wrapButtons ? undefined : '100%'}
                                onButtonClicked={properties.footer!.onNextButtonClicked}
                            />
                        </PrimaryActionButtonContainer>
                    </FooterRow>
                </FooterBox>
            )}
        </Popup>
    )
}

const HeaderContainer = styled(
    ({ hasBorderBottom, ...properties }: BoxProps & { hasBorderBottom: boolean }) => (
        <Box {...properties} />
    )
)(({ hasBorderBottom }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderBottom: hasBorderBottom ? `2px solid ${grey_3}` : undefined,
}))

const HeaderContent = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(3),
}))

const TitleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.spacing(3),
}))

const Title = styled(Typography)(() => ({
    fontSize: '18px',
    fontWeight: 700,
    marginTop: 'auto',
    marginBottom: 'auto',
}))

const ContentContainer = styled(Box)(() => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
}))

const FooterBox = styled(Box)(() => ({
    borderTop: `2px solid ${grey_3}`,
}))

const FooterRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.spacing(3),
}))

const BackButton = styled(
    ({
        isHidden,
        width,
        ...properties
    }: ButtonProperties & { isHidden: boolean; width?: string }) => (
        <DefaultButton {...properties} />
    )
)(({ theme, isHidden, width }) => ({
    visibility: isHidden ? 'hidden' : 'visible',
    minWidth: '120px',
    width: width,
    backgroundColor: grey_3,
    color: textColor,
    marginRight: theme.spacing(3),
}))

const PrimaryActionButtonContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
}))
