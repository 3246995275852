import { IconButton, Typography, styled } from '@mui/material'

export const HeaderTitle = styled(Typography)(() => ({
    variant: 'h1',
    fontSize: '48px',
    fontWeight: '800',
}))

export const SessionBackButton = styled(IconButton)(({ theme }) => ({
    variant: 'contained',
    color: '#2c302e',
    padding: '16px',
    borderRadius: '16px',
    background: '#F4F4F4',
    '&& span': {
        margin: '0',
    },
    height: theme.spacing(6),
    width: theme.spacing(6),
}))
