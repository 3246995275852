import {
    IconButton,
    List as MuiList,
    ListItem,
    ListItemButton as MuiListItemButton,
    ListItemIcon as MuiListItemIcon,
    ListItemText,
    styled,
} from '@mui/material'
import theme, { darkGrey } from 'shared/lib/theme/Theme'
import { ReactComponent as ShareIcon } from 'shared/lib/assets/svg/share.svg'
import { useTranslation } from 'shared/lib/i18n'
import { CreatePostPopup } from '../posts/createpostpopup/CreatePostPopup'
import { useState } from 'react'
import { MenuPopup } from 'shared/lib/components/MenuPopup'
import { ReactComponent as GroupIcon } from 'shared/lib/assets/svg/group.svg'
import { ReactComponent as LinkIcon } from 'shared/lib/assets/svg/link.svg'
import { Group } from '../groups/Group'
import { Post } from '../posts/Post'

interface Properties {
    group: Group
    onGroupShared: (post: Post) => void
}

export const ShareGroupButton = ({ group, onGroupShared }: Properties) => {
    const translations = useTranslation()

    const [isCreatePostPopupVisible, setIsCreatePostPopupVisible] = useState<boolean>(false)
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)

    const onShareButtonClicked = (event: any) => {
        setAnchor(event.currentTarget)
    }

    const onShareInGroupClicked = () => {
        setAnchor(null)
        setIsCreatePostPopupVisible(true)
    }

    const onCopyGroupLinkClicked = async () => {
        setAnchor(null)
        await navigator.clipboard.writeText(group.shareUrl)
    }

    return (
        <>
            <IconButton sx={{ backgroundColor: 'white' }} onClick={onShareButtonClicked}>
                <Share />
            </IconButton>

            <MenuPopup
                anchor={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                popupMarginTop={theme.spacing(1)}
                arrowRightPosition={'16px'}
                onPopupClosed={() => setAnchor(null)}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={onCopyGroupLinkClicked}>
                            <ListItemIcon>
                                <LinkIcon />
                            </ListItemIcon>
                            <ListItemText primary={translations('copy_link')} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={onShareInGroupClicked}>
                            <ListItemIcon>
                                <GroupIcon sx={{ fill: darkGrey }} />
                            </ListItemIcon>
                            <ListItemText primary={translations('share_in_group')} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </MenuPopup>

            {isCreatePostPopupVisible && (
                <CreatePostPopup
                    onCloseButtonClicked={() => setIsCreatePostPopupVisible(false)}
                    onSubmitButtonClicked={(post) => {
                        setIsCreatePostPopupVisible(false)
                        onGroupShared(post)
                    }}
                    groupToShare={group}
                />
            )}
        </>
    )
}

const Share = styled(ShareIcon)(() => ({
    fill: darkGrey,
}))

const List = styled(MuiList)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
}))

const ListItemButton = styled(MuiListItemButton)(() => ({
    borderRadius: '16px',
}))

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
    minWidth: 'unset',
    marginRight: theme.spacing(2),
    '& svg': {
        height: '20px',
        width: '20px',
    },
}))
