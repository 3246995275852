import { MenuItem } from '@mui/material'
import { useState } from 'react'
import { Select } from '../components/Select'
import { grey_1 } from '../theme/constants'
import { TranslationFn } from '../WithTranslations'
import { CustomRecurringPopup } from './custom/CustomRecurringPopup'
import { Frequency } from './Frequency'
import { DateTime } from 'luxon'
import { RecurrenceRule } from './RecurrenceRule'

interface Properties {
    disabled?: boolean
    withBackdrop?: boolean
    locale: string
    start: DateTime
    recurrenceRule?: RecurrenceRule
    translations: TranslationFn
    onRecurrenceRuleChanged: (recurrenceRule: RecurrenceRule | undefined) => void
}

export const FrequencyButton = ({
    locale,
    recurrenceRule,
    translations,
    ...properties
}: Properties) => {
    const [isCustomRecurringPopupVisible, setIsCustomRecurringPopupVisible] = useState(false)

    const endString =
        recurrenceRule?.hasEnd === true
            ? ` ∙  ${recurrenceRule.toEndString({
                  shortStyle: false,
                  locale,
                  translations,
              })}`
            : ''
    const recurrenceString = recurrenceRule
        ? `${recurrenceRule?.toString({ locale, translations })}${endString}`
        : ''

    const selectedMenuItem =
        recurrenceRule?.isCustom === true ? 'CUSTOM' : recurrenceRule?.frequency || 'NEVER'
    const menuItems: { key: string; value: string }[] = [
        {
            key: 'NEVER',
            value: translations('does_not_repeat'),
        },
        {
            key: Frequency.DAILY,
            value: translations('every_day'),
        },
        {
            key: Frequency.WEEKLY,
            value: translations('every_week'),
        },
        {
            key: Frequency.MONTHLY,
            value: translations('every_month'),
        },
        {
            key: Frequency.YEARLY,
            value: translations('every_year'),
        },
        {
            key: 'CUSTOM',
            value: translations('custom'),
        },
    ]

    const onMenuItemSelected = (event: any) => {
        const value = event.target.value as string

        if (value === 'NEVER') {
            properties.onRecurrenceRuleChanged(undefined)
        } else if (value === 'CUSTOM') {
            onCustomMenuItemClicked()
        } else {
            properties.onRecurrenceRuleChanged(
                new RecurrenceRule(recurrenceRule?.start ?? properties.start, 1, value as Frequency)
            )
        }
    }

    const onCustomMenuItemClicked = () => {
        setIsCustomRecurringPopupVisible(true)
    }

    return (
        <>
            <Select
                disabled={properties.disabled}
                value={selectedMenuItem}
                onChange={onMenuItemSelected}
                renderValue={(value) => {
                    return (
                        <>
                            {value === 'CUSTOM'
                                ? recurrenceString
                                : menuItems.find((item) => item.key === value)?.value}{' '}
                        </>
                    )
                }}
                sx={{
                    width: '100%',
                    color: selectedMenuItem === 'NEVER' ? grey_1 : undefined,
                }}
            >
                {menuItems.map((item) => (
                    <MenuItem
                        key={item.key}
                        value={item.key}
                        onClick={item.key === 'CUSTOM' ? onCustomMenuItemClicked : undefined}
                    >
                        {item.value}
                    </MenuItem>
                ))}
            </Select>

            {isCustomRecurringPopupVisible && (
                <CustomRecurringPopup
                    withBackdrop={properties.withBackdrop}
                    start={properties.start}
                    recurrenceRule={recurrenceRule}
                    locale={locale}
                    translations={translations}
                    onCloseButtonClicked={() => setIsCustomRecurringPopupVisible(false)}
                    onDoneButtonClicked={(recurrenceRule) => {
                        setIsCustomRecurringPopupVisible(false)
                        properties.onRecurrenceRuleChanged(recurrenceRule)
                    }}
                />
            )}
        </>
    )
}
