import { useTranslation } from '../../i18n'
import { MarkdownTextView, READ_LESS_LINK } from './MarkdownTextView'
import { MessageChildViewProperties } from './MessageChildViewProperties'

export const MessageMarkdownView = ({
    isCollapsed,
    linesToShowWhenCollapsed,
    ...properties
}: MessageChildViewProperties) => {
    const translations = useTranslation()

    const readLessMarkdown = isCollapsed ? '' : ` [${translations('read_less')}](${READ_LESS_LINK})`

    return (
        <MarkdownTextView
            isCollapsed={isCollapsed}
            maxLines={linesToShowWhenCollapsed ?? 8}
            className={properties.className}
            translations={properties.translations}
            onCollapseClicked={properties.onCollapseClicked}
        >
            {properties.message + readLessMarkdown}
        </MarkdownTextView>
    )
}
