import {
    avatarClasses,
    Box,
    Link,
    LinkBaseProps,
    styled,
    Typography,
    TypographyProps,
} from '@mui/material'
import { Avatar, AvatarProperties } from 'shared/lib/components/Avatar'
import { grey_1, grey_3 } from 'shared/lib/theme/Theme'
import { fileRepository } from '../..'
import { useDetailView } from '../../common/detailView/hooks'
import { useTranslation } from 'shared/lib/i18n'
import { Post } from '../../posts/Post'
import { PostType } from '../../posts/PostType'
import { createdAtString } from '../../utils/DateTimeUtils'
import { PostActionsButton } from './PostActionsButton'
import { FilledCloseButtonWithWhiteCross } from 'shared/lib/components/buttons/CloseButton'
import { ReactNode } from 'react'

interface Properties {
    isEditable?: boolean
    isUnreadable?: boolean
    isCreatorClickable?: boolean
    isGroupNameVisible: boolean
    isGroupNameClickable?: boolean
    isCloseButtonDisabled?: boolean
    post: Post
    px?: number
    pt?: number
    resetHistoryWhenClicked?: boolean
    onEditButtonClicked?: () => void
    onDeleteButtonClicked?: () => void
    onCloseButtonClicked?: () => void
}

export const PostTopBarView = ({ post, ...properties }: Properties) => {
    const translations = useTranslation()
    const { openUserDetailView, openGroupDetailView, openFeedDetailView } = useDetailView()

    const creatorId = post.creatorId
    const hasCreator = !!creatorId
    const isEditable = properties.isEditable ?? true
    const isUnreadable = properties.isUnreadable ?? true
    const isCreatorClickable = (properties.isCreatorClickable ?? true) && hasCreator
    const isGroupNameClickable = properties.isGroupNameClickable ?? true

    const onCreatorClicked = () => {
        if (!creatorId) {
            return
        }

        post.notifyMarkAsReadIfNeeded()

        switch (post.type) {
            case PostType.USER:
                return openUserDetailView(creatorId, {
                    resetHistory: properties.resetHistoryWhenClicked ?? true,
                })
            case PostType.GROUP:
                return openGroupDetailView(creatorId, {
                    resetHistory: properties.resetHistoryWhenClicked ?? true,
                })
            case PostType.FEED:
                return openFeedDetailView(creatorId, {
                    resetHistory: properties.resetHistoryWhenClicked ?? true,
                })
        }
    }

    const onGroupNameClicked = () => {
        post.notifyMarkAsReadIfNeeded()

        openGroupDetailView(post.groupId, {
            resetHistory: properties.resetHistoryWhenClicked ?? true,
        })
    }

    return (
        <Container px={properties.px} pt={properties.pt}>
            <CreatorAvatar
                isClickable={isCreatorClickable}
                src={fileRepository.getThumbnailUrl(post.creatorImage)}
                onClick={onCreatorClicked}
            />

            <ContentView>
                <Box display="flex">
                    <CreatorNameTextView
                        isClickable={isCreatorClickable}
                        onClick={onCreatorClicked}
                    >
                        {post.getCreatorName(translations)}
                    </CreatorNameTextView>
                </Box>

                <Box display="flex" flexDirection="row">
                    {properties.isGroupNameVisible && (
                        <GroupNameTextView
                            isClickable={isGroupNameClickable}
                            onClick={onGroupNameClicked}
                        >
                            {post.groupName}
                        </GroupNameTextView>
                    )}

                    <CreatedAtTextView variant="body2">
                        {createdAtString(post.createdAt, translations)}
                    </CreatedAtTextView>

                    <IsUpdatedText variant="body2">
                        {post.isEdited && translations('edited')}
                    </IsUpdatedText>
                </Box>
            </ContentView>

            {isUnreadable && !post.isRead && <UnreadBadge />}

            {isEditable &&
                post.canEdit &&
                properties.onEditButtonClicked &&
                properties.onDeleteButtonClicked && (
                    <Box ml={2}>
                        <PostActionsButton
                            post={post}
                            onEditButtonClicked={properties.onEditButtonClicked}
                            onDeleteButtonClicked={properties.onDeleteButtonClicked}
                        />
                    </Box>
                )}
            {properties.onCloseButtonClicked && (
                <FilledCloseButtonWithWhiteCross
                    disabled={properties.isCloseButtonDisabled}
                    onClick={() => {
                        properties.onCloseButtonClicked && properties.onCloseButtonClicked()
                    }}
                />
            )}
        </Container>
    )
}

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}))

const CreatorAvatar = styled(
    ({ isClickable, ...properties }: AvatarProperties & { isClickable: boolean }) => (
        <Avatar {...properties} />
    )
)(({ isClickable }) => ({
    backgroundColor: grey_3,
    cursor: isClickable ? 'pointer' : 'default',
    pointerEvents: isClickable ? 'auto' : 'none',

    [`& .${avatarClasses.fallback}`]: {
        fill: grey_1,
    },
}))

const ContentView = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    overflow: 'hidden',
}))

const CreatorNameTextView = styled(
    ({ isClickable, ...properties }: TypographyProps & { isClickable: boolean }) => (
        <Typography variant="body1" {...properties} />
    )
)(({ isClickable }) => ({
    fontWeight: 700,
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: isClickable ? 'pointer' : 'default',
    pointerEvents: isClickable ? 'auto' : 'none',
}))

const GroupNameTextView = styled(
    ({
        isClickable,
        children,
        ...properties
    }: LinkBaseProps & { isClickable: boolean; children: ReactNode; onClick: () => void }) => (
        <Link component="button" {...properties}>
            {children}
        </Link>
    )
)(({ theme, isClickable }) => ({
    marginRight: theme.spacing(1),
    fontSize: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: isClickable ? theme.palette.primary.main : grey_1,
    pointerEvents: isClickable ? 'auto' : 'none',
}))

const CreatedAtTextView = styled(Typography)(({ theme }) => ({
    fontSize: '12px',
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(1),
}))

const IsUpdatedText = styled(Typography)(() => ({
    fontSize: '12px',
    whiteSpace: 'nowrap',
}))

const UnreadBadge = styled(Box)(({ theme }) => ({
    height: '14px',
    width: '14px',
    minWidth: '14px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
}))
