import { User } from '../../../user/User'

export interface MemberViewModel {}

export class MemberItemViewModel implements MemberViewModel {
    constructor(
        readonly user: User,
        readonly isSelected: boolean | undefined,
        readonly onUserSelected: (user: User) => void
    ) {}
}

export class MemberLoadingViewModel implements MemberViewModel {}
