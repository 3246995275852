import { LocalImage } from 'shared/lib/forms/LocalImage'
import { LocalVideo } from 'shared/lib/forms/LocalVideo'
import { LocalFile } from 'shared/lib/forms/LocalFile'
import { LinkPreview } from 'shared/lib/models/LinkPreview'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'

export class CreatePostBody {
    constructor(
        readonly groupId: string,
        readonly message: string,
        readonly localFiles: LocalFile[],
        readonly linkPreview: LinkPreview | undefined,
        readonly localPdfFile: LocalFile | undefined,
        readonly sharedPostId: string | undefined,
        readonly sharedGroupId: string | undefined
    ) {}

    toFormData(): FormData {
        const formData = new FormData()

        formData.append('groupId', this.groupId)

        formData.append('message', this.message)

        this.localFiles.forEach((file) => {
            if (file instanceof LocalImage) {
                formData.append('imageFiles', file.file)
            } else if (file instanceof LocalVideo) {
                formData.append('videoFiles', file.file)
                formData.append('videoImageFiles', file.thumbnail.file)
            }
        })

        if (this.linkPreview) {
            formData.append('linkPreviews[]{}', JSON.stringify(this.linkPreview))
        }

        if (this.localPdfFile) {
            if (this.localPdfFile instanceof LocalPdf) {
                formData.append('pdfFiles', this.localPdfFile.file)
                formData.append('pdfImageFiles', this.localPdfFile.thumbnail.file)
            }
        }

        if (this.sharedPostId) {
            formData.append('sharedPostId', this.sharedPostId)
        }
        if (this.sharedGroupId) {
            formData.append('sharedGroupId', this.sharedGroupId)
        }

        return formData
    }
}
