import { Box, styled } from '@mui/material'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { DetailViewContainer } from '../common/detailView'
import { DETAIL_VIEW_HEADER_BOTTOM_Y } from '../common/detailView/DetailViewHeader'
import { DetailViewProperties } from '../common/detailView/DetailViewProperties'
import { useGroupAccessRequests } from '../groups/hooks'
import { useTranslation } from 'shared/lib/i18n'
import { GroupsWithAccessRequestsList } from './groupsWithAccessRequestsList/GroupsWithAccessRequestsList'

interface Properties extends DetailViewProperties {}

export const AllGroupAccessRequestsListView = ({ closeIcon, onCloseClicked }: Properties) => {
    const translations = useTranslation()

    const { groupAccessRequests, isLoading, onApproveGroupMember, onDenyGroupMember, error } =
        useGroupAccessRequests()

    return (
        <DetailViewContainer
            onCloseClicked={onCloseClicked}
            closeIcon={closeIcon}
            title={translations('access_requests_groups')}
            contentMarginTop={DETAIL_VIEW_HEADER_BOTTOM_Y}
        >
            <Container>
                <GroupsWithAccessRequestsList
                    groupAccessRequests={groupAccessRequests}
                    isLoading={isLoading}
                    onApproveGroupMember={onApproveGroupMember}
                    onDenyGroupMember={onDenyGroupMember}
                />
            </Container>
            <ErrorHandler error={error} translations={translations} horizontal="right" />
        </DetailViewContainer>
    )
}

const Container = styled(Box)(({ theme }) => ({
    textAlign: 'left',
    padding: theme.spacing(0, 2),
}))
