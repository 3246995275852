import { Box, styled } from '@mui/material'
import { PostListItem } from './PostListComponents'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { PostTopBarLoadingView } from './components/PostTopBarLoadingView'

interface Properties {
    isLastItem: boolean
}

export const POST_LOADING_ROW_HEIGHT = 250

export const PostLoadingRow = (properties: Properties) => {
    return (
        <PostListItem isLastItem={properties.isLastItem}>
            <Card>
                <PostTopBarLoadingView />

                <Skeleton variant="text" width="100%" sx={{ mt: 2 }} />

                <Skeleton variant="rounded" width="100%" height={160} sx={{ mt: 2 }} />
            </Card>
        </PostListItem>
    )
}

const Card = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    backgroundColor: 'white',
    padding: theme.spacing(2),
}))
