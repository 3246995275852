import {
    CircularProgress,
    IconButton,
    List as MuiList,
    ListItem,
    ListItemButton as MuiListItemButton,
    ListItemIcon as MuiListItemIcon,
    ListItemText,
    styled,
} from '@mui/material'
import { useState } from 'react'
import { ReactComponent as More } from 'shared/lib/assets/svg/more.svg'
import { MenuPopup } from 'shared/lib/components/MenuPopup'
import { grey_1, textColor } from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'
import { ReactComponent as PlusContained } from 'shared/lib/assets/svg/plus_outlined.svg'
import { ReactComponent as MinusContained } from 'shared/lib/assets/svg/minus_contained.svg'
import { GroupMemberInfo } from '../members/GroupMember'
import { ReactComponent as Delete } from 'shared/lib/assets/svg/delete.svg'
import { getFullName } from 'shared/lib/utils/StringUtils'
import { groupRepository } from '../../index'

interface Properties {
    groupId: string
    groupMember: GroupMemberInfo
    onError: (error: Error) => void
}

export const GroupMemberActionsButton = (properties: Properties) => {
    const translations = useTranslation()

    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    const onMoreButtonClicked = (event: any) => {
        setAnchor(event.currentTarget)
    }

    const onDeleteUser = () => {
        setAnchor(null)
        setIsLoading(true)
        groupRepository
            .deleteGroupMember({
                groupId: properties.groupId,
                userId: properties.groupMember.id,
            })
            .catch(properties.onError)
            .finally(() => setIsLoading(false))
    }

    const onMakeAdmin = () => {
        setAnchor(null)
        setIsLoading(true)
        groupRepository
            .updateGroupMember({
                groupId: properties.groupId,
                userId: properties.groupMember.id,
                isAdmin: !properties.groupMember.isAdmin,
            })
            .catch(properties.onError)
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            {isLoading ? (
                <CircularProgress
                    size={20}
                    sx={{
                        color: grey_1,
                        marginRight: 1,
                    }}
                />
            ) : (
                <IconButton onClick={onMoreButtonClicked}>
                    <MoreIcon />
                </IconButton>
            )}

            <MenuPopup
                anchor={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                popupMarginTop="8px"
                arrowRightPosition="16px"
                onPopupClosed={() => setAnchor(null)}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={onMakeAdmin}>
                            <ListItemIcon>
                                {properties.groupMember.isAdmin ? (
                                    <MinusContainedIcon />
                                ) : (
                                    <PlusContained fill={textColor} />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    color: (theme) =>
                                        properties.groupMember.isAdmin
                                            ? theme.palette.error.main
                                            : undefined,
                                }}
                                primary={
                                    properties.groupMember.isAdmin
                                        ? translations('remove_group_admin')
                                        : translations('make_group_admin')
                                }
                            />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={onDeleteUser}>
                            <ListItemIcon>
                                <DeleteUser />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    color: (theme) => theme.palette.error.main,
                                }}
                                primary={translations('delete_group_member', [
                                    getFullName(properties.groupMember).split(' ')[0],
                                ])}
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </MenuPopup>
        </>
    )
}

const MoreIcon = styled(More)(() => ({
    fill: grey_1,
    transform: 'rotate(90deg)',
}))

const DeleteUser = styled(Delete)(({ theme }) => ({
    fill: theme.palette.error.main,
}))

const MinusContainedIcon = styled(MinusContained)(({ theme }) => ({
    fill: theme.palette.error.main,
}))

const List = styled(MuiList)(({ theme }) => ({
    padding: theme.spacing(2, 1),
}))

const ListItemButton = styled(MuiListItemButton)(() => ({
    borderRadius: '16px',
}))

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
    minWidth: 'unset',
    marginRight: theme.spacing(2),
    '& svg': {
        height: '20px',
        width: '20px',
    },
}))
