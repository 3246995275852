import {
    ListItemButton as MuiListItemButton,
    ListItemText as MuiListItemText,
    ListItemTextProps,
    styled,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'shared/lib/i18n'
import { ReactComponent as ChevronRight } from 'shared/lib/assets/svg/chevron_right.svg'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

interface Properties {
    translationKey: string
    totalMembersCount: number
    sx?: SxProps<Theme>

    onClick(): void
}

export const SeeAllButton = ({ translationKey, totalMembersCount, onClick, sx }: Properties) => {
    const translations = useTranslation()

    return (
        <ListItemButton onClick={onClick} sx={sx}>
            <ListItemText primary={translations(translationKey, [totalMembersCount])} />
            <ChevronRight />
        </ListItemButton>
    )
}

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    borderRadius: '16px',

    '&:hover': {
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer',
    },
}))

const ListItemText = styled((props: Omit<ListItemTextProps, 'primaryTypographyProps'>) => (
    <MuiListItemText
        {...props}
        primaryTypographyProps={{
            noWrap: true,
        }}
    />
))(() => ({
    overflow: 'hidden',

    '& .MuiListItemText-primary': {
        textOverflow: 'ellipsis',
    },
}))
