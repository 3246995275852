import { Comment } from './Comment'

export enum CommentEventKey {
    COMMENT_CREATED = 'commentCreated',
    COMMENT_UPDATED = 'commentUpdated',
    COMMENT_DELETED = 'commentDeleted',
}

export class CommentCreatedEvent extends CustomEvent<{ postId: string; comment: Comment }> {
    constructor(postId: string, comment: Comment) {
        super(CommentEventKey.COMMENT_CREATED, {
            detail: {
                postId,
                comment,
            },
        })
    }
}

export class CommentUpdatedEvent extends CustomEvent<{ postId: string; comment: Comment }> {
    constructor(postId: string, comment: Comment) {
        super(CommentEventKey.COMMENT_UPDATED, {
            detail: {
                postId,
                comment,
            },
        })
    }
}

export class CommentDeletedEvent extends CustomEvent<{ postId: string }> {
    constructor(postId: string) {
        super(CommentEventKey.COMMENT_DELETED, {
            detail: {
                postId,
            },
        })
    }
}
