import { Box, styled } from '@mui/material'
import React from 'react'
import { ContentSectionContainer, HeaderContainer } from './components'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { ReactComponent as ClockIcon } from '../../assets/svg/clock.svg'
import { ReactComponent as DescriptionIcon } from '../../assets/svg/description.svg'
import { HorizontalDivider as Divider } from 'shared/lib/components/Divider'

export const EventLoadingView = () => {
    return (
        <Box width="100%">
            <HeaderContainer>
                <Box display="flex" flexDirection="column" alignItems="start" gap={1}>
                    <Skeleton variant="rounded" width="100%" height={21} />
                    <Skeleton variant="rounded" width="100%" />
                </Box>
            </HeaderContainer>
            <HorizontalDivider />

            <ContentSectionContainer>
                <ClockIcon />
                <Box display="flex" flexDirection="column" alignItems="start" gap={1} width="100%">
                    <Skeleton variant="rounded" width="100%" />
                    <Skeleton variant="rounded" width="100%" />
                </Box>
            </ContentSectionContainer>

            <HorizontalDivider />

            <ContentSectionContainer>
                <DescriptionIcon />
                <Box display="flex" flexDirection="column" alignItems="start" gap={1} width="100%">
                    <Skeleton variant="rounded" width="100%" />
                    <Skeleton variant="rounded" width="100%" />
                </Box>
            </ContentSectionContainer>
        </Box>
    )
}

const HorizontalDivider = styled(Divider)(() => ({
    width: '100%',
}))
