export enum Paths {
    HOME = '/',
    TIME_LINE = '/posts',
    GROUP_TIME_LINE = '/posts/:groupId?',
    SELECT_CHURCH = '/select-church',
    SALVATION_ARMY = '/leger-des-heils',
    SIGN_IN = '/sign-in',
    CONFIRM_SIGN_IN = '/sign-in/confirm',
    SIGN_UP = '/sign-up',
    CHANGE_PASSWORD = '/change-password',
    FORGOT_PASSWORD = '/forgot-password',
    FORGOT_PASSWORD_SEND = '/forgot-password/send',
    CREATE_PROFILE = '/create-profile',
    EVENTS = '/events',
    GROUP_EVENTS = '/events/:groupId?',
    MEMBERS = '/members',
    SETTINGS = '/settings',
    SETTINGS_CHANGE_PASSWORD = '/settings/change-password',
    SETTINGS_TWO_FACTOR_AUTHENTICATION = '/settings/two-factor-authentication',
    PROFILE = '/profile',
    NOTIFICATIONS = '/notifications',
}
