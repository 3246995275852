import { styled } from '@mui/material'
import { DateTime } from 'luxon'
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers'
import { CalendarIconOutlined } from '../../assets/svg/CalendarIconOutlined'
import { grey_3 } from '../../theme/constants'

export const DatePicker = styled(
    ({
        variant,
        helperText,
        onBlur,
        ...properties
    }: DatePickerProps<DateTime> & {
        variant?: 'filled' | 'outlined'
        helperText?: React.ReactNode
        maxDate?: DateTime
        onBlur?(): void
    }) => (
        <MuiDatePicker
            slots={{
                openPickerIcon: CalendarIconOutlined,
            }}
            maxDate={properties.maxDate}
            slotProps={{
                desktopPaper: {
                    sx: {
                        padding: 0,
                        borderRadius: '16px',
                    },
                },
                mobilePaper: {
                    sx: {
                        padding: 0,
                    },
                },
                textField: {
                    variant: variant || 'filled',
                    helperText,
                    onBlur,
                },
                day: {
                    sx: {
                        '&.MuiPickersDay-root.Mui-selected': {
                            color: 'white !important',
                        },
                    },
                },
            }}
            {...properties}
        />
    )
)(({ theme }) => ({
    width: '100%',
    '& .MuiFilledInput-root': {
        paddingRight: theme.spacing(2),
        '&:before, &:after': {
            borderBottom: 'none',
        },
        '&:hover:before': {
            borderBottom: 'none !important',
        },
        '&.Mui-focused:before, &.Mui-focused:after': {
            borderBottom: 'none',
        },
        '&.MuiInputAdornment-positionEnd': {
            marginRight: theme.spacing(1),
        },
        '& .MuiIconButton-root': {
            marginRight: theme.spacing(-0.5),
        },
        '& svg': {
            fill: theme.palette.text.primary,
        },
    },

    '& .MuiOutlinedInput-root': {
        paddingRight: theme.spacing(2),
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: grey_3,
        },
        '& svg': {
            fill: theme.palette.text.primary,
        },
    },
}))
