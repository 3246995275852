export enum NotificationEventKey {
    NOTIFICATION_PAGE_MOUNTED = 'notification-page-mounted',
    NOTIFICATION_PAGE_UNMOUNTED = 'notification-page-unmounted',
    NOTIFICATIONS_MARKED_AS_READ = 'notifications-marked-as-read',
}

export class NotificationPageMountedEvent extends Event {
    constructor() {
        super(NotificationEventKey.NOTIFICATION_PAGE_MOUNTED)
    }
}

export class NotificationPageUnmountedEvent extends Event {
    constructor() {
        super(NotificationEventKey.NOTIFICATION_PAGE_UNMOUNTED)
    }
}

export class NotificationsMarkedAsRead extends Event {
    constructor() {
        super(NotificationEventKey.NOTIFICATIONS_MARKED_AS_READ)
    }
}
