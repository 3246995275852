import { Skeleton } from 'shared/lib/components/Skeleton'
import { Box, styled } from '@mui/material'
import { grey_3 } from 'shared/lib/theme/Theme'

const CARD_HEIGHT = 65

interface Properties {
    doubleAttachmentButton?: boolean
}

export const CreateButtonLoadingRow = ({ doubleAttachmentButton }: Properties) => {
    return (
        <Card>
            <Button
                sx={{
                    paddingLeft: (theme) => theme.spacing(4),
                }}
            >
                <Skeleton variant="text" width="30%" />
            </Button>

            <Box display="flex" height="100%">
                {doubleAttachmentButton && (
                    <>
                        <VerticalDivider />

                        <Button
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '65px',
                                width: '56px',
                            }}
                        >
                            <Skeleton variant="text" width={20} height={40} />
                        </Button>
                    </>
                )}
                <VerticalDivider />

                <Button
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '65px',
                        width: '56px',
                    }}
                >
                    <Skeleton variant="text" width={20} height={40} />
                </Button>
            </Box>
        </Card>
    )
}

const Card = styled(Box)(() => ({
    height: CARD_HEIGHT,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '16px',
    backgroundColor: 'white',
}))

const Button = styled(Box)(({ theme }) => ({
    height: CARD_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    overflow: 'hidden',
}))

const VerticalDivider = styled(Box)(() => ({
    height: '52px',
    width: '2px',
    marginTop: '6px',
    backgroundColor: grey_3,
}))
