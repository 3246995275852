import { Notification } from '../Notification'

export enum NotificationTimeSpan {
    TODAY = 'TODAY',
    THIS_WEEK = 'THIS_WEEK',
    THIS_MONTH = 'THIS_MONTH',
    EARLIER = 'EARLIER',
}

export interface NotificationsListViewModel {}

export class NotificationsListItemViewModel implements NotificationsListViewModel {
    constructor(
        public readonly notification: Notification,
        public readonly isPreviousUnread: boolean,
        public readonly isNextUnread: boolean
    ) {}
}

export class NotificationsListGroupHeaderViewModel implements NotificationsListViewModel {
    constructor(public readonly timeSpan: NotificationTimeSpan) {}
}

export class NotificationsListGroupHeaderLoadingViewModel implements NotificationsListViewModel {}
export class NotificationsListFooterViewModel implements NotificationsListViewModel {}
export class NotificationsListItemLoadingViewModel implements NotificationsListViewModel {
    constructor(public readonly isLastRow: boolean) {}
}
