import { MediaPreview } from 'shared/lib/components/media/MediaPreview'
import { MediaItem } from 'shared/lib/models/MediaItem'
import { spacing } from 'shared/lib/theme/Theme'
import { fileRepository } from '../..'
import { Post } from '../../posts/Post'
import { PostListItemContainer } from '../PostListComponents'
import { useContext } from 'react'
import { FullScreenMediaContext } from 'shared/lib/components/media/FullScreenMediaContextProvider'

interface Properties {
    width: number
    margins: number
    post: Post
}

export const PostMediaView = ({ post, ...properties }: Properties) => {
    const { openFullscreenMedia } = useContext(FullScreenMediaContext)!

    const mediaItems = MediaItem.mapToMediaItems(
        {
            videos: post.videos,
            images: post.images,
        },
        fileRepository
    )

    if (mediaItems.length === 0) {
        return <></>
    }

    const marginLeftAndRight = properties.margins + spacing * 4

    return (
        <PostListItemContainer>
            <MediaPreview
                width={properties.width - marginLeftAndRight}
                mediaItems={mediaItems}
                onMediaItemSelected={openFullscreenMedia}
            />
        </PostListItemContainer>
    )
}
