export const reorderList = <T>(l: T[], fromIndex: number, toIndex: number) => {
    const newArray = [...l]

    const [movedItem] = newArray.splice(fromIndex, 1)
    newArray.splice(toIndex, 0, movedItem)

    return newArray
}

export function range(start: number, end: number) {
    return Array.from({ length: end - start }, (v, k) => k + start)
}
