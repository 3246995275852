import {
    Box,
    List as MuiList,
    ListItem,
    ListItemButton as MuiListItemButton,
    styled,
    Typography,
} from '@mui/material'
import { ReactComponent as RoundedCheckIcon } from 'shared/lib/assets/svg/rounded_check.svg'
import { MenuPopup } from 'shared/lib/components/MenuPopup'
import { grey_3 } from 'shared/lib/theme/Theme'
import { fileRepository } from '../..'
import { useTranslation } from 'shared/lib/i18n'
import { Group, MinimalGroupInfo } from '../Group'
import { GroupAvatar } from '../GroupAvatar'

interface Properties {
    isVisible: boolean
    anchor: HTMLElement | null
    groups: Group[]
    selectedGroup: MinimalGroupInfo | undefined

    onDismissed: () => void
    onGroupSelected: (group: Group) => void
}

export const SelectGroupPopup = (properties: Properties) => {
    const translations = useTranslation()

    if (!properties.isVisible) {
        return <></>
    }

    return (
        <MenuPopup
            anchor={properties.anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            popupWidth={{
                desktop: '20%',
                tablet: '35%',
                phablet: '45%',
                mobile: 'calc(100% - 48px)',
            }}
            popupMarginTop={'16px'}
            arrowLeftPosition={'32px'}
            onPopupClosed={properties.onDismissed}
        >
            <TitleTextView>{translations('select_a_group')}</TitleTextView>
            <List>
                {properties.groups.map((group) => (
                    <ListItem
                        key={group.id}
                        disablePadding={true}
                        secondaryAction={properties.selectedGroup === group && <RoundedCheck />}
                    >
                        <ListItemButton
                            key={group.id}
                            selected={properties.selectedGroup === group}
                            onClick={() => properties.onGroupSelected(group)}
                        >
                            <GroupAvatar src={fileRepository.getThumbnailUrl(group.image)} />

                            <NameAndMembersContainer>
                                <NameTextView variant="body1">{group.name}</NameTextView>

                                <NumberOfMembersTextView variant="body2">
                                    {`${group.numberOfMembers} ${translations('church_members')}`}
                                </NumberOfMembersTextView>
                            </NameAndMembersContainer>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </MenuPopup>
    )
}

const TitleTextView = styled(Typography)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.08)',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    fontSize: '14px',
    fontWeight: 700,
}))

const List = styled(MuiList)(({ theme }) => ({
    maxHeight: '400px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflow: 'auto',

    [theme.breakpoints.only('mobile')]: {
        maxHeight: '350px',
    },
}))

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: '16px',

    '&.Mui-selected': {
        backgroundColor: grey_3,
    },
}))

const NameAndMembersContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
}))

const NameTextView = styled(Typography)(() => ({
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    wordBreak: 'break-word',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: 1.2,
}))

const NumberOfMembersTextView = styled(Typography)(() => ({
    fontSize: '12px',
}))

const RoundedCheck = styled(RoundedCheckIcon)(({ theme }) => ({
    fill: theme.palette.primary.main,
    height: '24px',
    width: '24px',
}))
