import { fileRepository } from '../../index'
import { UserListItemText } from '../../user/userList/UserListItemText'
import { getFullName } from 'shared/lib/utils/StringUtils'
import React from 'react'
import {
    AvatarProps,
    IconButton,
    IconButtonProps,
    ListItem as MuiListItem,
    ListItemAvatar as MuiListItemAvatar,
    ListItemAvatarProps,
    styled,
} from '@mui/material'
import { Avatar } from 'shared/lib/components/Avatar'
import { ReactComponent as DenyIcon } from 'shared/lib/assets/svg/close_filled.svg'
import { ReactComponent as ApproveIcon } from 'shared/lib/assets/svg/rounded_check.svg'
import { darkGrey, defaultTransition, grey_1 } from 'shared/lib/theme/Theme'
import { User } from '../../user/User'
import { MinimalUserInfo } from 'shared/lib/users/MinimalUserInfo'
import { useDetailView } from '../detailView/hooks'

type UnApprovedUser = MinimalUserInfo & {
    emailAddress: User['emailAddress']
}
type SecondaryLabelOptions = keyof Omit<UnApprovedUser, 'image' | 'address' | 'lrpId'>

interface Properties<T extends SecondaryLabelOptions> {
    userToApprove: UnApprovedUser
    propertyToUseForSecondaryLabel: T

    onApprove(userId: string): void

    onDeny(userId: string): void
}

export const UnapprovedUserListItem = <T extends SecondaryLabelOptions>({
    userToApprove,
    propertyToUseForSecondaryLabel,
    ...properties
}: Properties<T>) => {
    const { openUserDetailView } = useDetailView()

    const secondaryLabelValue = userToApprove[propertyToUseForSecondaryLabel]
    const onClickDeny = () => properties.onDeny(userToApprove.id)
    const onClickApprove = () => properties.onApprove(userToApprove.id)

    const onClickRow = () => openUserDetailView(userToApprove.id)

    return (
        <ListItem onClick={onClickRow}>
            <ListItemAvatar src={fileRepository.getThumbnailUrl(userToApprove.image)} />

            <UserListItemText
                name={getFullName(userToApprove)}
                secondaryLabel={
                    Array.isArray(secondaryLabelValue)
                        ? secondaryLabelValue.join(', ')
                        : secondaryLabelValue
                }
            />
            <DenyButton onClick={onClickDeny} />
            <ApproveButton onClick={onClickApprove} />
        </ListItem>
    )
}

const ListItem = styled(MuiListItem)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: '16px',

    '&:hover': {
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer',
    },
    transition: defaultTransition,
}))

const ListItemAvatar = styled(
    ({ src, children, ...props }: ListItemAvatarProps & Pick<AvatarProps, 'src'>) => (
        <MuiListItemAvatar {...props}>{children || <Avatar src={src} />}</MuiListItemAvatar>
    )
)(() => ({
    minWidth: 40,
}))

const DenyButton = styled(({ onClick, ...props }: Omit<IconButtonProps, 'children'>) => {
    const onClickButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation()

        if (onClick) onClick(event)
    }

    return (
        <IconButton {...props} onClick={onClickButton}>
            <DenyIcon />
        </IconButton>
    )
})(({ theme }) => ({
    padding: 0,
    marginLeft: theme.spacing(2),

    '& svg': {
        fill: grey_1,
    },
    '& svg:hover': {
        fill: darkGrey,
    },
}))

const ApproveButton = styled(({ onClick, ...props }: Omit<IconButtonProps, 'children'>) => {
    const onClickButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation()

        if (onClick) onClick(event)
    }

    return (
        <IconButton {...props} onClick={onClickButton}>
            <ApproveIcon />
        </IconButton>
    )
})(({ theme }) => ({
    padding: 0,
    marginLeft: theme.spacing(2),
    '& svg': {
        fill: theme.palette.primary.main,
    },
    '& svg:hover': {
        fill: theme.palette.primary.dark,
    },
}))
