import { Box, styled } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { DetailViewContainer } from '../common/detailView'
import { DETAIL_VIEW_HEADER_BOTTOM_Y } from '../common/detailView/DetailViewHeader'
import { DetailViewProperties } from '../common/detailView/DetailViewProperties'
import { useTranslation } from 'shared/lib/i18n'
import { sessionRepository } from '../index'
import { UserEventKey } from '../user/UserEvents'
import { useUsers } from '../user/hooks'
import { AppAccessRequestsList } from './AppAccessRequestsList'

interface Properties extends DetailViewProperties {}

export const AppAccessRequestsListView = ({ closeIcon, onCloseClicked }: Properties) => {
    const translations = useTranslation()

    const { users: unapprovedUsers, isLoading: isLoadingUnapprovedUsers } = useUsers({
        isApproved: false,
    })
    const [appAccessRequestCount, setAppAccessRequestCount] = useState(0)

    const onAppAccessRequestHandled = useCallback(() => {
        setAppAccessRequestCount((prevState) => {
            const newCount = prevState - 1

            if (newCount === 0) {
                onCloseClicked()
            }

            return newCount
        })
    }, [onCloseClicked])

    useEffect(() => {
        document.addEventListener(UserEventKey.USER_APPROVED, onAppAccessRequestHandled)
        document.addEventListener(UserEventKey.USER_DENIED, onAppAccessRequestHandled)

        return () => {
            document.removeEventListener(UserEventKey.USER_APPROVED, onAppAccessRequestHandled)
            document.removeEventListener(UserEventKey.USER_DENIED, onAppAccessRequestHandled)
        }
    }, [onAppAccessRequestHandled])

    useEffect(() => {
        setAppAccessRequestCount(unapprovedUsers.length)
    }, [unapprovedUsers.length])

    if (!sessionRepository.canApproveOrDenyChurchMembers) {
        onCloseClicked()
        return null
    }

    return (
        <DetailViewContainer
            onCloseClicked={onCloseClicked}
            closeIcon={closeIcon}
            title={translations(
                'plural_access_requests',
                [appAccessRequestCount],
                appAccessRequestCount
            )}
            contentMarginTop={DETAIL_VIEW_HEADER_BOTTOM_Y}
        >
            <Container>
                <AppAccessRequestsList
                    users={unapprovedUsers}
                    isLoading={isLoadingUnapprovedUsers}
                />
            </Container>
        </DetailViewContainer>
    )
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    padding: theme.spacing(0, 4),
    paddingBottom: 0,
}))
