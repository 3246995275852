import { User } from '../../User'

export interface MobileUserListViewModel {}

export class MobileUserSectionViewModel implements MobileUserListViewModel {
    constructor(readonly letter: string) {}
}

export class MobileUserItemViewModel implements MobileUserListViewModel {
    constructor(readonly user: User) {}
}

export class MobileAppAccessRequestsViewModel implements MobileUserListViewModel {
    constructor(readonly appAccessRequestsCount: number) {}
}
