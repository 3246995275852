import { WebAppHttpRepository } from '../common/WebAppHttpRepository'
import { SessionRepository } from '../authentication/SessionRepository'
import { Feed } from './Feed'

export class FeedRepository extends WebAppHttpRepository {
    constructor(private readonly sessionRepository: SessionRepository) {
        super()
    }

    async byId(id: string): Promise<Feed> {
        const response = await this.sessionRepository.withAccessToken(() =>
            this.get<Record<string, any>>(`${this.apiUrl}/api/v1/inspire/feeds/${id}`)
        )

        return Feed.fromJSON(response)
    }
}
