import { Event } from './Event'
import { DateTime } from 'luxon'

export enum EventEventKey {
    EVENT_CREATED = 'event_created',
    EVENT_UPDATED = 'event_updated',
    EVENT_DELETED = 'event_deleted',
    CHILD_EVENT_DELETED = 'child_event_deleted',
    THIS_AND_FOLLOWING_EVENT_DELETED = 'this_and_following_event_deleted',
}

export class EventCreatedEvent extends CustomEvent<{
    event: Event
}> {
    constructor(public readonly event: Event) {
        super(EventEventKey.EVENT_CREATED, {
            detail: {
                event,
            },
        })
    }
}

export class EventUpdatedEvent extends CustomEvent<{
    event: Event
}> {
    constructor(public readonly event: Event) {
        super(EventEventKey.EVENT_UPDATED, {
            detail: {
                event,
            },
        })
    }
}

export class EventDeletedEvent extends CustomEvent<{
    eventId: string
}> {
    constructor(public readonly eventId: string) {
        super(EventEventKey.EVENT_DELETED, {
            detail: {
                eventId,
            },
        })
    }
}

export class ChildEventDeletedEvent extends CustomEvent<{
    eventId: string
    excludedDates: DateTime[]
}> {
    constructor(
        public readonly eventId: string,
        public readonly excludedDates: DateTime[]
    ) {
        super(EventEventKey.CHILD_EVENT_DELETED, {
            detail: {
                eventId,
                excludedDates,
            },
        })
    }
}

export class ThisAndFollowingEventDeletedEvent extends CustomEvent<{
    eventId: string
    from: DateTime
}> {
    constructor(
        public readonly eventId: string,
        public readonly from: DateTime
    ) {
        super(EventEventKey.THIS_AND_FOLLOWING_EVENT_DELETED, {
            detail: {
                eventId,
                from,
            },
        })
    }
}
