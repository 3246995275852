import { Box, styled } from '@mui/material'

export const PrimaryActionButtonsContainer = styled(Box)<{ direction?: 'row' | 'column' }>(
    ({ theme, direction }) => ({
        display: 'flex',
        flexDirection: direction || 'row',
        gap: theme.spacing(2),
        marginTop: theme.spacing(4),
        backgroundColor: 'white',
    })
)

export const PrimaryActionButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
}))
