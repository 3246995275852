import { ButtonProperties, DefaultButton } from './DefaultButton'
import theme, { errorColor, errorColorDisabled, errorColorHover } from '../../theme/Theme'

export const RedContainedButton = (properties: ButtonProperties) => {
    const { sx, ...rest } = properties
    return (
        <DefaultButton
            {...{
                ...rest,
                variant: 'contained',
                color: 'error',
                sx: {
                    ...sx,
                    backgroundColor: errorColor,
                    color: 'white',
                    [theme.breakpoints.up('phablet')]: {
                        '&:hover': {
                            backgroundColor: errorColorHover,
                            color: 'white',
                        },
                    },
                    '&.Mui-disabled': {
                        backgroundColor: errorColorDisabled,
                        color: 'white',
                    },
                },
            }}
        />
    )
}
