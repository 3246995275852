import { useMediaQuery, useTheme } from '@mui/material'

export const useIsMobile = () => {
    const { breakpoints } = useTheme()

    return useMediaQuery(breakpoints.down('phablet'))
}

export const useIsPhablet = () => {
    const { breakpoints } = useTheme()

    return useMediaQuery(breakpoints.up('phablet') && breakpoints.down('tablet'))
}

export const useIsTablet = () => {
    const { breakpoints } = useTheme()

    return useMediaQuery(breakpoints.between('tablet', 'desktop'))
}

export const useIsDesktop = () => {
    const { breakpoints } = useTheme()

    return useMediaQuery(breakpoints.up('desktop'))
}

export const useIsTabletOrBigger = () => {
    const { breakpoints } = useTheme()

    return useMediaQuery(breakpoints.up('tablet'))
}
