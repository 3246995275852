import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import { ReactComponent as ChevronRight } from 'shared/lib/assets/svg/chevron_right.svg'
import { textColor } from 'shared/lib/theme/Theme'
import { CommentTextField } from '../../comments/CommentTextField'
import { useTranslation } from 'shared/lib/i18n'
import { Post } from '../../posts/Post'
import { LikeType } from '../../posts/likes/LikeType'
import { PostLikeAndShareRowView } from './PostLikeAndShareRowView'

interface Properties {
    isCreatingComment: boolean
    post: Post
    onPostLiked: (like?: LikeType) => void
    onPostShared: () => void
    onCreateCommentButtonClicked: (message: string) => void
    onShowCommentsClicked: () => void
}

export const PostActionBarView = ({ post, ...properties }: Properties) => {
    const translations = useTranslation()

    const numberOfComments = post.numberOfComments

    return (
        <Container numberofcomments={numberOfComments}>
            <PostLikeAndShareRowView
                resetHistoryWhenOpeningLikesList={true}
                post={post}
                onPostLiked={properties.onPostLiked}
                onPostShared={properties.onPostShared}
            />

            <CommentTextField
                postId={post.id}
                groupId={post.groupId}
                onClick={() => post.notifyMarkAsReadIfNeeded()}
                notificationContainerAnchorId="postList"
            />

            {numberOfComments > 0 && (
                <CommentsCountContainer onClick={properties.onShowCommentsClicked}>
                    <ReadAllCommentsTextView variant="body2">
                        {translations('read_all_comments')}
                    </ReadAllCommentsTextView>

                    {numberOfComments > 0 && (
                        <Typography variant="body2">{numberOfComments}</Typography>
                    )}
                    <CommentsCountChevronRight />
                </CommentsCountContainer>
            )}
        </Container>
    )
}

const Container = styled(Box)<{ numberofcomments: number }>(({ theme, numberofcomments }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(numberofcomments > 0 ? 1 : 2),
}))

const CommentsCountContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: 'pointer',
    alignItems: 'center',

    ':hover > *': {
        color: textColor,
        fill: textColor,
    },
}))

const ReadAllCommentsTextView = styled(Typography)(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    marginRight: theme.spacing(1),
}))

const CommentsCountChevronRight = styled(ChevronRight)(({ theme }) => ({
    height: '16px',
    width: '16px',
    marginLeft: theme.spacing(1),
    fill: theme.palette.text.secondary,
}))
