import { Box, Dialog, FormControlLabel, Radio, RadioGroup, styled, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { TranslationFn } from '../WithTranslations'
import { PrimaryContainedButton } from '../components/buttons/ContainedButtons'
import { GreyContainedButton } from '../components/buttons/GreyButtons'
import { EditOrDeleteRecurringEventOption } from './EditOrDeleteRecurringEventOption'

interface Properties {
    type: 'edit' | 'delete'
    isVisible: boolean
    options: EditOrDeleteRecurringEventOption[]
    translations: TranslationFn
    onContinueButtonClicked: (option: EditOrDeleteRecurringEventOption) => void
    onCancelButtonClicked: () => void
}

export const EditOrDeleteEventDialog = ({ options, translations, ...properties }: Properties) => {
    const [selectedOption, setSelectedOption] = useState(options[0])

    useEffect(() => {
        setSelectedOption(options[0])
    }, [JSON.stringify(options)]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Dialog
            hideBackdrop
            open={properties.isVisible}
            onClose={properties.onCancelButtonClicked}
            maxWidth={'tablet'}
            PaperProps={{
                style: {
                    padding: '32px',
                },
            }}
        >
            <Title>{translations('edit_recurring_event')}</Title>

            <RadioGroup
                value={selectedOption}
                onChange={(event) => {
                    setSelectedOption(event.target.value as EditOrDeleteRecurringEventOption)
                }}
            >
                {options.includes(EditOrDeleteRecurringEventOption.THIS_EVENT) && (
                    <FormControlLabel
                        key={EditOrDeleteRecurringEventOption.THIS_EVENT}
                        value={EditOrDeleteRecurringEventOption.THIS_EVENT}
                        control={<Radio />}
                        label={translations('only_this_event')}
                    />
                )}
                {options.includes(EditOrDeleteRecurringEventOption.THIS_AND_FOLLOWING_EVENTS) && (
                    <FormControlLabel
                        key={EditOrDeleteRecurringEventOption.THIS_AND_FOLLOWING_EVENTS}
                        value={EditOrDeleteRecurringEventOption.THIS_AND_FOLLOWING_EVENTS}
                        control={<Radio />}
                        label={translations('this_and_following_events')}
                    />
                )}
                {options.includes(EditOrDeleteRecurringEventOption.ALL_EVENTS) && (
                    <FormControlLabel
                        key={EditOrDeleteRecurringEventOption.ALL_EVENTS}
                        value={EditOrDeleteRecurringEventOption.ALL_EVENTS}
                        control={<Radio />}
                        label={translations('all_events')}
                    />
                )}
            </RadioGroup>

            <ButtonContainer>
                <GreyContainedButton
                    sx={{ height: '40px' }}
                    fullWidth
                    onClick={properties.onCancelButtonClicked}
                >
                    {translations('cancel')}
                </GreyContainedButton>
                <PrimaryContainedButton
                    disabled={!selectedOption}
                    sx={{ height: '40px' }}
                    fullWidth
                    onClick={() => {
                        if (selectedOption) {
                            properties.onContinueButtonClicked(selectedOption)
                        }
                    }}
                >
                    {translations(properties.type)}
                </PrimaryContainedButton>
            </ButtonContainer>
        </Dialog>
    )
}

const Title = styled(Typography)(({ theme }) => ({
    fontSize: '18px',
    fontWeight: 700,
    marginTop: 'auto',
    marginBottom: theme.spacing(1),
}))

const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
}))
