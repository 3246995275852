/**
 * https://github.com/onurzorluer/react-image-file-resizer
 */
export class ImageResizer {
    private static changeHeightWidth(
        height: number,
        maxHeight: number,
        width: number,
        maxWidth: number,
        minWidth: number | null,
        minHeight: number | null
    ) {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width)
            width = maxWidth
        }
        if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height)
            height = maxHeight
        }
        if (minWidth && width < minWidth) {
            height = Math.round((height * minWidth) / width)
            width = minWidth
        }
        if (minHeight && height < minHeight) {
            width = Math.round((width * minHeight) / height)
            height = minHeight
        }
        return { height, width }
    }

    private static resizeAndRotateImage(
        image: any,
        maxWidth: number,
        maxHeight: number,
        minWidth: number | null,
        minHeight: number | null,
        compressFormat = 'jpeg',
        quality = 100,
        rotation = 0
    ) {
        var qualityDecimal = quality / 100
        var canvas = document.createElement('canvas')

        var width = image.width
        var height = image.height

        var newHeightWidth = this.changeHeightWidth(
            height,
            maxHeight,
            width,
            maxWidth,
            minWidth,
            minHeight
        )
        if (rotation && (rotation === 90 || rotation === 270)) {
            canvas.width = newHeightWidth.height
            canvas.height = newHeightWidth.width
        } else {
            canvas.width = newHeightWidth.width
            canvas.height = newHeightWidth.height
        }

        width = newHeightWidth.width
        height = newHeightWidth.height

        var ctx = canvas.getContext('2d')!
        ctx.fillStyle = '#fff'
        ctx.fillRect(0, 0, width, height)

        if (ctx.imageSmoothingEnabled && ctx.imageSmoothingQuality) {
            ctx.imageSmoothingQuality = 'high'
        }

        if (rotation) {
            ctx.rotate((rotation * Math.PI) / 180)
            if (rotation === 90) {
                ctx.translate(0, -canvas.width)
            } else if (rotation === 180) {
                ctx.translate(-canvas.width, -canvas.height)
            } else if (rotation === 270) {
                ctx.translate(-canvas.height, 0)
            } else if (rotation === 0 || rotation === 360) {
                ctx.translate(0, 0)
            }
        }
        ctx.drawImage(image, 0, 0, width, height)

        return canvas.toDataURL(`image/${compressFormat}`, qualityDecimal)
    }

    private static b64toByteArrays(b64Data, contentType) {
        contentType = contentType || 'image/jpeg'
        var sliceSize = 512

        var byteCharacters = atob(
            b64Data.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, '')
        )
        var byteArrays = [] as Uint8Array[]

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize)

            var byteNumbers = new Array(slice.length)
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            var byteArray = new Uint8Array(byteNumbers)

            byteArrays.push(byteArray)
        }
        return byteArrays
    }

    private static b64toBlob(b64Data, contentType) {
        const byteArrays = this.b64toByteArrays(b64Data, contentType)
        var blob = new Blob(byteArrays, { type: contentType })
        return blob
    }

    private static b64toFile(b64Data, fileName, contentType) {
        const byteArrays = this.b64toByteArrays(b64Data, contentType)
        const file = new File(byteArrays, fileName, { type: contentType })
        return file
    }

    static createResizedImage(
        file: any,
        maxWidth: number,
        maxHeight: number,
        compressFormat: string,
        quality: number,
        rotation: number,
        responseUriFunc: (blob: Blob | string) => void,
        outputType = 'blob',
        minWidth = null,
        minHeight = null
    ) {
        const reader = new FileReader()
        if (file) {
            if (file.type && !file.type.includes('image')) {
                throw Error('File Is NOT Image!')
            } else {
                reader.readAsDataURL(file)
                reader.onload = () => {
                    var image = new Image()
                    image.src = reader.result as string
                    image.onload = function () {
                        var resizedDataUrl = ImageResizer.resizeAndRotateImage(
                            image,
                            maxWidth,
                            maxHeight,
                            minWidth,
                            minHeight,
                            compressFormat,
                            quality,
                            rotation
                        )
                        const contentType = `image/${compressFormat}`
                        switch (outputType) {
                            case 'blob':
                                const blob = ImageResizer.b64toBlob(resizedDataUrl, contentType)
                                responseUriFunc(blob)
                                break
                            case 'base64':
                                responseUriFunc(resizedDataUrl)
                                break
                            case 'file':
                                let fileName = file.name
                                let fileNameWithoutFormat = fileName
                                    .toString()
                                    .replace(/(png|jpeg|jpg|webp)$/i, '')
                                let newFileName = fileNameWithoutFormat.concat(
                                    compressFormat.toString()
                                )
                                const newFile = ImageResizer.b64toFile(
                                    resizedDataUrl,
                                    newFileName,
                                    contentType
                                )
                                responseUriFunc(newFile)
                                break
                            default:
                                responseUriFunc(resizedDataUrl)
                        }
                    }
                }
                reader.onerror = (error: any) => {
                    throw Error(error)
                }
            }
        } else {
            throw Error('File Not Found!')
        }
    }
}
