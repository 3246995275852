import { useEffect, useState } from 'react'
import { useTranslation } from 'shared/lib/i18n'
import { useNavigate } from 'react-router-dom'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { useSessionStorage } from 'usehooks-ts'
import donkey from '../../assets/images/donkey.jpg'
import { LinearProgressBar } from '../../common/LinearProgressBar'
import { churchRepository, sessionRepository } from '../../index'
import { Paths } from '../../routing/paths'
import { SessionPage } from '../SessionPage'
import { ChoosePasswordStep } from './ChoosePasswordStep'
import { ConfirmAccountStep } from './ConfirmAccountStep'
import { EnterEmailAddressStep } from './EnterEmailAddressStep'
import i18n from '../../i18n'
import { BadRequestError } from 'shared/lib/utils/ErrorUtils'

const SESSION_STORAGE_KEY = 'signUpState'

export enum SIGN_UP_STEPS {
    CHOOSE_EMAIL_ADDRESS = 1,
    CHOOSE_PASSWORD = 2,
    CONFIRM_ACCOUNT = 3,
}

interface SignUpState {
    emailAddress: string
    activeStep: SIGN_UP_STEPS
}

export const SignUpPage = () => {
    const translations = useTranslation()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | undefined>()
    const [signUpState, setSignUpState] = useSessionStorage<SignUpState>(SESSION_STORAGE_KEY, {
        emailAddress: '',
        activeStep: SIGN_UP_STEPS.CHOOSE_EMAIL_ADDRESS,
    })
    const [isEmailAlreadyRegistered, setIsEmailAlreadyRegistered] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')

    const church = churchRepository.church

    const createAccount = async () => {
        setIsLoading(true)

        try {
            await sessionRepository.createAccount({
                emailAddress: signUpState.emailAddress,
                password,
                locale: `${i18n.language}-NL`,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const onEmailAddressChanged = (value: string) => {
        setSignUpState((prevState) => ({
            ...prevState,
            emailAddress: value,
        }))
    }

    const onNextClicked = () => {
        switch (signUpState.activeStep) {
            case SIGN_UP_STEPS.CHOOSE_EMAIL_ADDRESS:
                setSignUpState((prevState) => ({
                    ...prevState,
                    activeStep: isEmailAlreadyRegistered
                        ? SIGN_UP_STEPS.CONFIRM_ACCOUNT
                        : SIGN_UP_STEPS.CHOOSE_PASSWORD,
                }))
                break
            case SIGN_UP_STEPS.CHOOSE_PASSWORD:
                createAccount()
                    .then(() => {
                        setSignUpState((prevState) => ({
                            ...prevState,
                            activeStep: SIGN_UP_STEPS.CONFIRM_ACCOUNT,
                        }))
                    })
                    .catch(setError)
                break
        }
    }

    const onBackClicked = () => {
        switch (signUpState.activeStep) {
            case SIGN_UP_STEPS.CHOOSE_EMAIL_ADDRESS:
                navigate(Paths.SIGN_IN)

                sessionStorage.removeItem(SESSION_STORAGE_KEY)
                break
            case SIGN_UP_STEPS.CONFIRM_ACCOUNT:
                setSignUpState((prevState) => ({
                    ...prevState,
                    activeStep: SIGN_UP_STEPS.CHOOSE_EMAIL_ADDRESS,
                }))
                break
            default:
                setSignUpState((prevState) => ({
                    ...prevState,
                    activeStep: prevState.activeStep - 1,
                }))
        }
    }

    const onResendConfirmation = async () => {
        sessionRepository.resendSignUpConfirmation(signUpState.emailAddress).catch(setError)
    }

    useEffect(() => {
        if (sessionRepository.isSignedIn) {
            navigate(Paths.TIME_LINE)
        } else if (!church) {
            navigate(Paths.SIGN_IN)
        }
    }, [church]) // eslint-disable-line react-hooks/exhaustive-deps

    if (sessionRepository.isSignedIn || !church) {
        return <></>
    }

    return (
        <SessionPage backgroundImage={donkey} church={church}>
            <LinearProgressBar variant="determinate" value={25 * signUpState.activeStep} />
            {signUpState.activeStep === SIGN_UP_STEPS.CHOOSE_EMAIL_ADDRESS && (
                <EnterEmailAddressStep
                    isLoading={isLoading}
                    emailAddress={signUpState.emailAddress}
                    setIsEmailAlreadyRegistered={setIsEmailAlreadyRegistered}
                    setIsLoading={setIsLoading}
                    setError={setError}
                    onEmailAddressChanged={onEmailAddressChanged}
                    onNextButtonClicked={onNextClicked}
                    onBackButtonClicked={onBackClicked}
                />
            )}
            {signUpState.activeStep === SIGN_UP_STEPS.CHOOSE_PASSWORD && (
                <ChoosePasswordStep
                    password={password}
                    passwordConfirmation={passwordConfirmation}
                    isLoading={isLoading}
                    setPassword={setPassword}
                    setPasswordConfirmation={setPasswordConfirmation}
                    onNextButtonClicked={onNextClicked}
                    onBackButtonClicked={onBackClicked}
                />
            )}
            {signUpState.activeStep === SIGN_UP_STEPS.CONFIRM_ACCOUNT && (
                <ConfirmAccountStep
                    emailAddress={signUpState.emailAddress}
                    isLoading={isLoading}
                    resendSignUpConfirmation={onResendConfirmation}
                    onNextButtonClicked={onNextClicked}
                    onBackButtonClicked={onBackClicked}
                />
            )}
            <ErrorHandler
                error={error}
                badRequestError={BadRequestError.INVALID_EMAIL_ADDRESS}
                horizontal="left"
                translations={translations}
            />
        </SessionPage>
    )
}
