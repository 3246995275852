import {
    AvatarGroup,
    Box,
    Avatar as MuiAvatar,
    Typography,
    avatarClasses,
    styled,
} from '@mui/material'
import { useDetailView } from '../../common/detailView/hooks'
import { useTranslation } from 'shared/lib/i18n'
import { Post } from '../Post'
import { LikeIconOutlined } from '../likes/LikeIcon'

interface Properties {
    post: Post
    resetHistoryWhenOpeningLikesList?: boolean
}

export const PostPopularLikesView = ({ post, resetHistoryWhenOpeningLikesList }: Properties) => {
    const translations = useTranslation()

    const { openLikesListView } = useDetailView()

    const popularLikes = post.likes?.mostPopularLikes ?? []

    if (!post.showPopularLikes) {
        return <></>
    }

    const onClick = () =>
        openLikesListView(post.id, {
            resetHistory: resetHistoryWhenOpeningLikesList,
        })

    return (
        <Container onClick={onClick}>
            <AvatarGroup>
                {popularLikes.map((like, index) => (
                    <Avatar key={`${like}_${index}`}>
                        <LikeIconOutlined likeType={like} />
                    </Avatar>
                ))}
            </AvatarGroup>

            <PostCreatorNamesTextView variant="body2">
                {post.likes?.getPostCreators(translations)}
            </PostCreatorNamesTextView>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    cursor: 'pointer',
    overflow: 'hidden',
}))

const Avatar = styled(MuiAvatar)(() => ({
    height: '20px',
    width: '20px',

    [`&.${avatarClasses.root}`]: {
        border: 0,
        backgroundColor: 'white',
    },
}))

const PostCreatorNamesTextView = styled(Typography)(() => ({
    fontSize: '12px',
    lineHeight: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
}))
