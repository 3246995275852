import { IconButton, ListItem as MuiListItem, ListItemText, styled } from '@mui/material'
import theme, { grey_1, grey_3 } from 'shared/lib/theme/Theme'
import { ReactElement } from 'react'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'

export interface MFAMethodProperties {
    hasTopDivider: boolean
    title: string
    subTitle: string
    subTitleColor?: string
    rightIcon?: ReactElement
    isButton?: boolean
    buttonTitle?: string
    isLoading?: boolean
    onItemClicked: () => void
}

export const MFAMethodItem = (properties: MFAMethodProperties) => {
    return (
        <ListItem
            hastopdivider={properties.hasTopDivider ? 1 : 0}
            disablePadding={true}
            secondaryAction={
                properties.isButton ? (
                    <PrimaryContainedButton
                        onClick={properties.onItemClicked}
                        isLoading={properties.isLoading}
                        sx={{
                            paddingLeft: theme.spacing(3),
                            paddingRight: theme.spacing(3),
                            paddingTop: theme.spacing(2),
                            paddingBottom: theme.spacing(2),
                        }}
                    >
                        {properties.buttonTitle}
                    </PrimaryContainedButton>
                ) : (
                    <IconButton onClick={properties.onItemClicked}>
                        {properties.rightIcon}
                    </IconButton>
                )
            }
        >
            <ListItemText
                primary={properties.title}
                secondary={properties.subTitle}
                secondaryTypographyProps={{
                    color: properties.subTitleColor ?? grey_1,
                }}
            />
        </ListItem>
    )
}

const ListItem = styled(MuiListItem)<{
    hastopdivider: number
}>(({ theme, hastopdivider }) => ({
    borderTop: hastopdivider ? `2px solid ${grey_3}` : 'none',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&:hover': {
        backgroundColor: 'transparent',
    },
    transition: 'opacity 1s ease-in-out',
}))
