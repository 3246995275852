import { Box, CssBaseline, styled, ThemeProvider } from '@mui/material'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { FileRepository } from 'shared/lib/files/FileRepository'
import Theme, { grey_3 } from 'shared/lib/theme/Theme'
import { Main } from './Main'
import { SessionContextProvider } from './authentication/SessionContextProvider'
import { SessionRepository } from './authentication/SessionRepository'
import { ConfirmChangePasswordPage } from './authentication/password/ConfirmChangePasswordPage'
import { ForgotPasswordPage } from './authentication/password/ForgotPasswordPage'
import { ForgotPasswordSendPage } from './authentication/password/ForgotPasswordSendPage'
import { SignUpPage } from './authentication/registration/SignUpPage'
import { ConfirmSignInPage } from './authentication/signin/ConfirmSignInPage'
import { SignInPage } from './authentication/signin/SignInPage'
import { ChurchContextProvider } from './churches/ChurchContextProvider'
import { ChurchRepository } from './churches/ChurchRepository'
import { SelectChurchPage } from './churches/SelectChurches/SelectChurchPage'
import config from './config'
import { GroupRepository } from './groups/GroupRepository'
import i18n from './i18n'
import './index.css'
import { Paths } from './routing/paths'
import { UserRepository } from './user/UserRepository'
import { CreateProfilePage } from './authentication/registration/CreateProfilePage'
import { PostRepository } from './posts/PostRepository'
import { CommentRepository } from './comments/CommentRepository'
import { WebAppLinkPreviewRepository } from './linkpreview/WebAppLinkPreviewRepository'
import { LinkPreviewHandler } from 'shared/lib/linkPreviews/LinkPreviewHandler'
import { FeedRepository } from './feed/FeedRepository'
import { NotificationRepository } from './notifications/NotificationRepository'
import { EventRepository } from './events/api/EventRepository'
import { GivingRepository } from './giving/GivingRepository'
import { ConsoleAdvertisement } from './misc/ConsoleAdvertisement'

export const sessionRepository = new SessionRepository()
export const churchRepository = new ChurchRepository(sessionRepository)
export const userRepository = new UserRepository(sessionRepository)
export const groupRepository = new GroupRepository(sessionRepository)
export const postRepository = new PostRepository(sessionRepository)
export const commentRepository = new CommentRepository(sessionRepository)
export const givingRepository = new GivingRepository(sessionRepository)
export const feedRepository = new FeedRepository(sessionRepository)
export const notificationRepository = new NotificationRepository(sessionRepository)
export const fileRepository = new FileRepository(config.cdnUrl)
export const linkPreviewHandler = new LinkPreviewHandler(
    new WebAppLinkPreviewRepository(sessionRepository)
)
export const eventRepository = new EventRepository(sessionRepository)

const Container = styled(Box)(() => ({
    maxWidth: '1440px',
    margin: '0 auto',
    background: grey_3,
    boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.12)',
}))

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={Theme}>
            <CookiesProvider>
                <CssBaseline />
                <ConsoleAdvertisement />
                <BrowserRouter>
                    <ChurchContextProvider>
                        <SessionContextProvider>
                            <Container>
                                <Routes>
                                    <Route index path="/*" element={<Main />} />

                                    <Route
                                        path={Paths.SELECT_CHURCH}
                                        element={<SelectChurchPage />}
                                    />
                                    <Route
                                        path={Paths.SALVATION_ARMY}
                                        element={<SelectChurchPage isForSalvationArmy={true} />}
                                    />
                                    <Route path={Paths.SIGN_IN} element={<SignInPage />} />
                                    <Route
                                        path={Paths.CONFIRM_SIGN_IN}
                                        element={<ConfirmSignInPage />}
                                    />
                                    <Route
                                        path={Paths.FORGOT_PASSWORD}
                                        element={<ForgotPasswordPage />}
                                    />
                                    <Route
                                        path={Paths.FORGOT_PASSWORD_SEND}
                                        element={<ForgotPasswordSendPage />}
                                    />
                                    <Route
                                        path={Paths.CHANGE_PASSWORD}
                                        element={<ConfirmChangePasswordPage />}
                                    />
                                    <Route path={Paths.SIGN_UP} element={<SignUpPage />} />
                                    <Route
                                        path={Paths.CREATE_PROFILE}
                                        element={<CreateProfilePage />}
                                    />
                                </Routes>
                            </Container>
                        </SessionContextProvider>
                    </ChurchContextProvider>
                </BrowserRouter>
            </CookiesProvider>
        </ThemeProvider>
    </I18nextProvider>
)
