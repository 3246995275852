import { Link, Typography, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from '../i18n'

interface Properties {
    onClicked: () => void
}

export const SendAgainTextView = (properties: Properties) => {
    const translations = useTranslation()

    const [secondsBeforeResendIsActive, setSecondsBeforeResendIsActive] = useState(30)

    const onClicked = () => {
        setSecondsBeforeResendIsActive(30)
        properties.onClicked()
    }

    useEffect(() => {
        if (secondsBeforeResendIsActive === 0) {
            return
        }

        const interval = setInterval(() => {
            setSecondsBeforeResendIsActive(secondsBeforeResendIsActive - 1)
        }, 1000)

        return () => clearInterval(interval)
    }, [secondsBeforeResendIsActive])

    if (secondsBeforeResendIsActive > 0) {
        return (
            <DisabledTextView variant="body2">
                <span>{translations('send_again')}</span>
                <>&nbsp;&nbsp;{secondsBeforeResendIsActive}s</>
            </DisabledTextView>
        )
    }

    return (
        <Link component="button" onClick={onClicked}>
            {translations('send_again')}
        </Link>
    )
}

const DisabledTextView = styled(Typography)(({ theme }) => ({
    '& span': {
        color: `${theme.palette.primary.main}40`,
    },
}))
