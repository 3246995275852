import { Box, styled, TextField as MuiTextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useEffect, useState } from 'react'
import { grey_3 } from '../../theme/Theme'

interface Properties {
    length: number
    error?: string
    centerErrorMessage?: boolean
    onConfirmationCodeEntered: (code: string) => void
    onConfirmationCodeChanged: () => void
}

export const ConfirmationCodeTextField = (properties: Properties) => {
    const { length } = properties

    const [confirmationCode, setConfirmationCode] = useState(Array.from({ length }, () => ''))

    const getTextFieldId = (index: number) => `confirmation-code-input-${index}`

    const focusTextFieldAtIndex = (index: number) => {
        const inputField = document.getElementById(getTextFieldId(index))

        if (inputField) {
            inputField.focus()
        }
    }

    const fillInFromClipboard = (event: any) => {
        event.preventDefault()
        const pastedData = event.clipboardData.getData('text/plain').replace(/[^0-9]/g, '')
        const pastedValues = pastedData.slice(0, length)

        const newInputValues = [...confirmationCode]
        for (let i = 0; i < length; i++) {
            if (pastedValues[i]) {
                newInputValues[i] = pastedValues[i]
            }
        }

        setConfirmationCode(newInputValues)
    }

    const onConfirmationCodeChanged = (index: number, value: string) => {
        const digit = value.replace(/[^0-9]/g, '')
        const newInputValues = [...confirmationCode]

        newInputValues[index] = digit
        setConfirmationCode(newInputValues)

        if (digit) focusTextFieldAtIndex(index + 1)
    }

    const onInputKeyDown = (index: number, event: any) => {
        properties.onConfirmationCodeChanged && properties.onConfirmationCodeChanged()

        if ((event.key === 'Backspace' || event.key === 'ArrowLeft') && index > 0) {
            focusTextFieldAtIndex(confirmationCode[index] ? index : index - 1)
        } else if (event.key === 'ArrowRight' && index < 5 && !confirmationCode[index]) {
            focusTextFieldAtIndex(index - 1)
        }
    }

    useEffect(() => {
        if (properties.error) {
            setConfirmationCode(Array.from({ length }, () => ''))
            focusTextFieldAtIndex(0)
        }
    }, [properties.error, length]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const confirmationCodeValue = confirmationCode.join('')
        if (confirmationCodeValue.length === 6) {
            properties.onConfirmationCodeEntered(confirmationCodeValue)
        }
    }, [confirmationCode]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box mb={1}>
            <Grid container spacing={1}>
                {confirmationCode.map((value, index) => (
                    <Grid mobile={2} key={index}>
                        <TextField
                            id={getTextFieldId(index)}
                            variant="outlined"
                            autoFocus={index === 0}
                            inputProps={{
                                maxLength: 1,
                                min: 0,
                                max: 9,
                                style: { textAlign: 'center' },
                            }}
                            value={value}
                            placeholder="_"
                            onChange={(event) =>
                                onConfirmationCodeChanged(index, event.target.value)
                            }
                            onKeyDown={(event) => onInputKeyDown(index, event)}
                            onPaste={fillInFromClipboard}
                            haserror={!!properties.error ? 1 : 0}
                        />
                    </Grid>
                ))}
            </Grid>
            {!!properties.error && (
                <Typography
                    color="error"
                    mt={2}
                    sx={{
                        textAlign: properties.centerErrorMessage ? 'center' : 'left',
                    }}
                >
                    {properties.error}
                </Typography>
            )}
        </Box>
    )
}

const TextField = styled(MuiTextField)<{ haserror: number }>(({ theme, haserror }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '16px',
        background: grey_3,
        height: '64px',
        '&:hover fieldset, fieldset': {
            borderColor: 'var(--neutral-grey-2, #C9C9C9)',
            border: '2px solid var(--neutral-grey-2, #C9C9C9)',
            ...(haserror && {
                borderColor: (theme.palette.error.main || '#CE5865') + ' !important',
            }),
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main || 'var(--primary-100, #AB804B)',
        },
    },
}))
