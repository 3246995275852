import { Box, List, styled } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { default as i18n, default as i18next } from 'i18next'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PopupModal } from 'shared/lib/components/PopupModal'
import { SelectLanguageButton } from 'shared/lib/components/buttons/SelectLanguageButton'
import { useTranslation } from 'shared/lib/i18n'
import { useIsMobile, useIsPhablet, useIsTabletOrBigger } from 'shared/lib/theme/BreakPointHooks'
import { errorColor, mobileToolbarHeight } from 'shared/lib/theme/Theme'
import { ReactComponent as RightChevron } from 'shared/src/assets/svg/chevron_right.svg'
import { ReactComponent as Delete } from '../../assets/svg/delete.svg'
import { ReactComponent as Keys } from '../../assets/svg/keys.svg'
import { ReactComponent as ResetPasswordLock } from '../../assets/svg/reset_password_lock.svg'
import { useHistory } from '../../common/HistoryHook'
import { ContentContainer } from '../../common/layout/ContentContainer'
import { MobileHeader } from '../../common/layout/MobileHeader'
import { SUPPORTED_LANGUAGES } from '../../i18n'
import { sessionRepository } from '../../index'
import { Paths } from '../../routing/paths'
import { BackgroundContainer, Header } from './SettingComponents'
import { SettingItem, SettingItemProperties } from './SettingItem'
import { DeleteOrDenyAccountDialog } from './deleteaccount/DeleteOrDenyAccountDialog'

interface SuccessModalProperties {
    isSuccessModalVisible: boolean
}

export const SettingsPage = () => {
    const translations = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const [isMobile, isPhablet, isTabletOrBigger] = [
        useIsMobile(),
        useIsPhablet(),
        useIsTabletOrBigger(),
    ]

    const { getFromPath } = useHistory()

    const successModalState: SuccessModalProperties = location.state ? location.state : undefined
    const isSuccessModalVisible = successModalState?.isSuccessModalVisible === true

    const [popupModalText, setPopupModalText] = useState<string | undefined>()
    const [isDeleteAccountDialogVisible, setIsDeleteAccountDialogVisible] = useState(false)

    useEffect(() => {
        if (!isSuccessModalVisible) return setPopupModalText(undefined)

        if (isSuccessModalVisible) {
            setPopupModalText(
                translations('message_password_successfully_changed').replace(/\./g, '')
            )
        }
    }, [isSuccessModalVisible]) // eslint-disable-line react-hooks/exhaustive-deps

    const list: SettingItemProperties[] = [
        {
            hasTopDivider: isTabletOrBigger,
            title: translations('change_password'),
            leftIcon: <ResetPasswordLock />,
            rightIcon: <RightChevronIcon />,
            onItemClicked: () => navigate(Paths.SETTINGS_CHANGE_PASSWORD),
        },
        {
            hasTopDivider: true,
            title: translations('two_steps_verification'),
            leftIcon: <Keys />,
            rightIcon: <RightChevronIcon />,
            onItemClicked: () => {
                navigate(Paths.SETTINGS_TWO_FACTOR_AUTHENTICATION)
            },
        },
        {
            hasTopDivider: true,
            title: translations('delete_account'),
            leftIcon: <Delete />,
            color: errorColor,
            onItemClicked: () => setIsDeleteAccountDialogVisible(true),
        },
    ].filter(Boolean) as SettingItemProperties[]

    const PopupModalView = () => {
        return (
            <PopupModal
                message={popupModalText}
                vertical={'center'}
                horizontal={'center'}
                onClose={() => setPopupModalText(undefined)}
            />
        )
    }

    if (isMobile || isPhablet) {
        return (
            <>
                <MobileHeader
                    hasActions={false}
                    hasLanguageButton={true}
                    title={translations('settings')}
                    onBackButtonClicked={() => navigate(getFromPath())}
                />

                <ContentContainer mt={mobileToolbarHeight}>
                    <BackgroundContainer>
                        <SettingsList list={list} />
                    </BackgroundContainer>
                </ContentContainer>

                <PopupModalView />

                <DeleteOrDenyAccountDialog
                    user={isDeleteAccountDialogVisible ? sessionRepository.signedInUser : undefined}
                    onCancel={() => setIsDeleteAccountDialogVisible(false)}
                />
            </>
        )
    }

    return (
        <>
            <ContentContainer mt={isPhablet ? mobileToolbarHeight : undefined}>
                <Grid
                    container
                    phablet={8}
                    phabletOffset={2}
                    tablet={7}
                    tabletOffset={2.5}
                    desktop={6}
                    desktopOffset={3}
                >
                    <BackgroundContainer>
                        <Box display="flex" justifyContent="space-between">
                            <Header>{translations('settings')}</Header>
                            <SelectLanguageButton
                                changeLanguage={async (language: string) => {
                                    await i18next.changeLanguage(language)
                                }}
                                currentLanguage={i18n.language}
                                hasNegativeMargin={true}
                                supportedLanguages={SUPPORTED_LANGUAGES}
                            />
                        </Box>
                        <SettingsList list={list} />
                    </BackgroundContainer>
                </Grid>
            </ContentContainer>

            <DeleteOrDenyAccountDialog
                user={isDeleteAccountDialogVisible ? sessionRepository.signedInUser : undefined}
                onCancel={() => setIsDeleteAccountDialogVisible(false)}
            />

            <PopupModalView />
        </>
    )
}

const SettingsList = (properties: { list: SettingItemProperties[] }) => {
    return (
        <List>
            {properties.list.map((item, index) => (
                <SettingItem key={index} {...item} />
            ))}
        </List>
    )
}

const RightChevronIcon = styled(RightChevron)(({ theme }) => ({
    fill: theme.palette.text.primary,
}))
