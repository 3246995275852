import { Box, styled, Typography } from '@mui/material'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { paperColor } from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'
import { User } from '../user/User'
import { AppAccessRequestsList } from './AppAccessRequestsList'

interface Properties {
    users: User[]
    isLoading: boolean
}

export const AppAccessRequestsCard = (properties: Properties) => {
    const translations = useTranslation()

    return properties.users.length ? (
        <Card>
            <Typography variant="h3">{translations('access_requests_app')}</Typography>
            <Typography variant="body2" mt={1}>
                {translations('approve_church_members_info')}
            </Typography>
            <AppAccessRequestsList {...properties} maxUsersToShow={3} />
            <ErrorHandler error={undefined} translations={translations} />
        </Card>
    ) : null
}

const Card = styled(Box)(({ theme }) => ({
    borderRadius: '24px 0 0 24px',
    backgroundColor: paperColor,
    padding: theme.spacing(3, 3, 1),

    [theme.breakpoints.up('desktop')]: {
        borderRadius: 24,
    },
}))
