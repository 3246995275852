import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { sessionRepository } from '../..'
import vault from '../../assets/images/vault.jpg'
import { Paths } from '../../routing/paths'
import ChurchContext from '../../churches/ChurchContextProvider'
import SessionContext from '../SessionContextProvider'
import { SessionPage } from '../SessionPage'
import { PasswordResetSendView } from 'shared/lib/components/PasswordResetSendView'

export const ForgotPasswordSendPage = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const isSignedIn = useContext(SessionContext)!.getIsSignedIn()
    const { church } = useContext(ChurchContext)!

    const emailAddress = location.state?.emailAddress

    const onClickSendAgain = () => {
        return sessionRepository.passwordForgotten(emailAddress)
    }

    useEffect(() => {
        if (!emailAddress) {
            navigate(Paths.SIGN_IN)
        }
    }, [emailAddress, navigate])

    if (isSignedIn || !emailAddress) {
        return <></>
    }

    return (
        <SessionPage backgroundImage={vault} church={church}>
            <PasswordResetSendView
                emailAddress={emailAddress}
                onSignInButtonClicked={() => navigate(Paths.SIGN_IN)}
                onClickSendAgain={onClickSendAgain}
            />
        </SessionPage>
    )
}
