import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import type { MenuRenderFn, MenuResolution, TriggerFn } from './LexicalTypeaheadMenu'
import { LexicalTypeaheadMenu, MenuOption, useMenuAnchorRef } from './LexicalTypeaheadMenu'
import {
    $getSelection,
    $isRangeSelection,
    $isTextNode,
    COMMAND_PRIORITY_LOW,
    CommandListenerPriority,
    LexicalEditor,
    RangeSelection,
    TextNode,
} from 'lexical'
import * as React from 'react'
import { JSX, useCallback, useEffect, useState } from 'react'

function getTextUpToAnchor(selection: RangeSelection): string | null {
    const anchor = selection.anchor
    if (anchor.type !== 'text') {
        return null
    }
    const anchorNode = anchor.getNode()
    if (!anchorNode.isSimpleText()) {
        return null
    }
    const anchorOffset = anchor.offset
    return anchorNode.getTextContent().slice(0, anchorOffset)
}

function tryToPositionRange(leadOffset: number, range: Range, editorWindow: Window): boolean {
    const domSelection = editorWindow.getSelection()
    if (domSelection === null || !domSelection.isCollapsed) {
        return false
    }
    const anchorNode = domSelection.anchorNode
    const startOffset = leadOffset
    const endOffset = domSelection.anchorOffset

    if (anchorNode == null || endOffset == null) {
        return false
    }

    try {
        range.setStart(anchorNode, startOffset)
        range.setEnd(anchorNode, endOffset)
    } catch (error) {
        return false
    }

    return true
}

function getQueryTextForSearch(editor: LexicalEditor): string | null {
    let text: string | null = null
    editor.getEditorState().read(() => {
        const selection = $getSelection()
        if (!$isRangeSelection(selection)) {
            return
        }
        text = getTextUpToAnchor(selection)
    })
    return text
}

function isSelectionOnEntityBoundary(editor: LexicalEditor, offset: number): boolean {
    if (offset !== 0) {
        return false
    }
    return editor.getEditorState().read(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
            const anchor = selection.anchor
            const anchorNode = anchor.getNode()
            const prevSibling = anchorNode.getPreviousSibling()
            return $isTextNode(prevSibling) && prevSibling.isTextEntity()
        }
        return false
    })
}

function startTransition(callback: () => void) {
    if (React.startTransition) {
        React.startTransition(callback)
    } else {
        callback()
    }
}

export type TypeaheadMenuPluginProps<TOption extends MenuOption> = {
    onQueryChange: (matchingString: string | null) => void
    onSelectOption: (
        option: TOption,
        textNodeContainingQuery: TextNode | null,
        closeMenu: () => void,
        matchingString: string
    ) => void
    options: Array<TOption>
    menuRenderFn: MenuRenderFn<TOption>
    triggerFn: TriggerFn
    onOpen?: (resolution: MenuResolution) => void
    onClose?: () => void
    anchorClassName?: string
    commandPriority?: CommandListenerPriority
    parent?: HTMLElement
}

export function LexicalTypeaheadMenuPlugin<TOption extends MenuOption>({
    options,
    onQueryChange,
    onSelectOption,
    onOpen,
    onClose,
    menuRenderFn,
    triggerFn,
    anchorClassName,
    commandPriority = COMMAND_PRIORITY_LOW,
    parent,
}: TypeaheadMenuPluginProps<TOption>): JSX.Element | null {
    const [editor] = useLexicalComposerContext()
    const [resolution, setResolution] = useState<MenuResolution | null>(null)
    const anchorElementRef = useMenuAnchorRef(resolution, setResolution, anchorClassName, parent)

    const closeTypeahead = useCallback(() => {
        setResolution(null)
        if (onClose != null && resolution !== null) {
            onClose()
        }
    }, [onClose, resolution])

    const openTypeahead = useCallback(
        (res: MenuResolution) => {
            setResolution(res)
            if (onOpen != null && resolution === null) {
                onOpen(res)
            }
        },
        [onOpen, resolution]
    )

    useEffect(() => {
        const updateListener = () => {
            editor.getEditorState().read(() => {
                const editorWindow = editor._window || window
                const range = editorWindow.document.createRange()
                const selection = $getSelection()
                const text = getQueryTextForSearch(editor)

                if (
                    !$isRangeSelection(selection) ||
                    !selection.isCollapsed() ||
                    text === null ||
                    range === null
                ) {
                    closeTypeahead()
                    return
                }

                const match = triggerFn(text, editor)
                onQueryChange(match ? match.matchingString : null)

                if (match !== null && !isSelectionOnEntityBoundary(editor, match.leadOffset)) {
                    const isRangePositioned = tryToPositionRange(
                        match.leadOffset,
                        range,
                        editorWindow
                    )

                    if (isRangePositioned !== null) {
                        const rangeRect = range.getBoundingClientRect()

                        startTransition(() =>
                            openTypeahead({
                                getRect: () => new DOMRect(16, rangeRect.y, undefined, 32),
                                match,
                            })
                        )
                        return
                    }
                }
                closeTypeahead()
            })
        }

        const removeUpdateListener = editor.registerUpdateListener(updateListener)

        return () => {
            removeUpdateListener()
        }
    }, [editor, triggerFn, onQueryChange, resolution, closeTypeahead, openTypeahead])

    return resolution === null || editor === null ? null : (
        <LexicalTypeaheadMenu
            close={closeTypeahead}
            resolution={resolution}
            editor={editor}
            anchorElementRef={anchorElementRef}
            options={options}
            menuRenderFn={menuRenderFn}
            shouldSplitNodeWithQuery={true}
            onSelectOption={onSelectOption}
            commandPriority={commandPriority}
        />
    )
}
