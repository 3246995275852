import { Box, Typography, TypographyProps, styled } from '@mui/material'
import MuiGrid from '@mui/material/Unstable_Grid2'
import { useNavigate } from 'react-router-dom'
import { useIsMobile, useIsPhablet, useIsTabletOrBigger } from 'shared/lib/theme/BreakPointHooks'
import { mobileToolbarHeight } from 'shared/lib/theme/Theme'
import { useHistory } from '../common/HistoryHook'
import { ContentContainer } from '../common/layout/ContentContainer'
import { MobileHeader } from '../common/layout/MobileHeader'
import { useTranslation } from 'shared/lib/i18n'
import { UserEditProfileView } from './UserEditProfileView'

export const UserProfilePage = () => {
    const navigate = useNavigate()
    const translations = useTranslation()
    const [isMobile, isPhablet, isTabletOrBigger] = [
        useIsMobile(),
        useIsPhablet(),
        useIsTabletOrBigger(),
    ]

    const { getFromPath } = useHistory()

    if (isMobile || isPhablet) {
        const scrollabelContainerId = 'user-profile-scrollable-container'

        return (
            <>
                <MobileHeader
                    hasActions={false}
                    title={translations('page_user_profile')}
                    scrollableContainerId={scrollabelContainerId}
                    onBackButtonClicked={() => navigate(getFromPath())}
                />

                <ContentContainer mt={mobileToolbarHeight}>
                    <BackgroundContainer id={scrollabelContainerId}>
                        <UserEditProfileView />
                    </BackgroundContainer>
                </ContentContainer>
            </>
        )
    }

    return (
        <ContentContainer>
            <Grid container tablet={8.5} tabletOffset={1.75} desktop={6} desktopOffset={3}>
                <BackgroundContainer>
                    {isTabletOrBigger && <Header>{translations('page_user_profile')}</Header>}
                    <UserEditProfileView />
                </BackgroundContainer>
            </Grid>
        </ContentContainer>
    )
}

export const BackgroundContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: 'white',
    padding: theme.spacing(3, 4),

    [theme.breakpoints.down('tablet')]: {
        paddingTop: theme.spacing(1),
    },
}))

export const Grid = styled(MuiGrid)(() => ({
    height: '100%',
}))

export const Header = styled((props: TypographyProps) => <Typography variant={'h1'} {...props} />)(
    ({ theme }) => ({
        fontSize: '24px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    })
)
