import { Skeleton } from 'shared/lib/components/Skeleton'
import { SkeletonProps, styled } from '@mui/material'
import { Avatar } from 'shared/lib/components/Avatar'

const AvatarStyle = {
    width: '100%',
    height: 'auto',
    maxWidth: 260,
    maxHeight: 260,
    aspectRatio: '1/1',
}

export const AvatarSkeleton = styled((props: SkeletonProps) => (
    <Skeleton {...props} variant="circular" />
))(AvatarStyle)
export const StyledAvatar = styled(Avatar)(AvatarStyle)
