import { Avatar, Box, styled, Typography } from '@mui/material'
import { memo } from 'react'
import { Church } from '../Church'
import { createSearchParams, useNavigate } from 'react-router-dom'
import {
    defaultHoverState,
    defaultInnerDropShadow,
    grey_1,
    grey_3,
    textColor,
} from 'shared/lib/theme/Theme'
import { fileRepository } from '../../index'
import { Paths } from '../../routing/paths'

const Component = ({ church }: { church: Church }) => {
    const navigate = useNavigate()

    return (
        <Card
            onClick={() =>
                navigate({
                    pathname: Paths.SIGN_IN,
                    search: createSearchParams({
                        applicationId: church.applicationId,
                    }).toString(),
                })
            }
        >
            <ChurchImage src={fileRepository.getThumbnailUrl(church.image)} variant="rounded" />
            <Box display="flex" flexDirection="column" overflow="hidden">
                <ChurchName noWrap={true}>{church.name}</ChurchName>
                {church.developerName && (
                    <DeveloperName noWrap={true}>{church.developerName}</DeveloperName>
                )}
            </Box>
        </Card>
    )
}

export const SelectChurchCard = memo(Component, (prevProps, nextProps) => {
    return (
        prevProps.church.name === nextProps.church.name &&
        prevProps.church.developerName === nextProps.church.developerName &&
        prevProps.church.image === nextProps.church.image
    )
})

const Card = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    borderRadius: '16px',
    border: `solid 1px ${grey_3}`,
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',

    '&:hover': defaultHoverState,
}))

const ChurchImage = styled(Avatar)(() => ({
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.08) inset',
    minWidth: 40,
    width: 40,
    height: 40,
    borderRadius: '8px',

    '& img': {
        objectFit: 'contain',
        boxShadow: defaultInnerDropShadow,
    },
}))

const ChurchName = styled(Typography)(() => ({
    fontSize: 14,
    color: textColor,
}))

const DeveloperName = styled(Typography)(() => ({
    fontSize: 12,
    color: grey_1,
}))
