import { useEffect, useRef } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VirtuosoHandle } from 'react-virtuoso'
import theme from 'shared/lib/theme/Theme'
import { StyledVirtuoso } from '../common/StyledVirtuoso'
import { Comment } from './Comment'
import {
    CommentListItemViewModel,
    CommentListLoadingViewModel,
    CommentListViewModel,
} from './CommentListViewModels'
import { CommentRow, CommentRowLoading } from './CommentRow'

interface Properties {
    comments: CommentListViewModel[]
    indexToScrollTo?: number
    onScrollEnded: () => void
    onEditButtonClicked: (comment: Comment) => void
    onDeleteButtonClicked: (commentId: string) => void
}

export const CommentsList = ({
    comments,
    indexToScrollTo,
    onScrollEnded,
    onEditButtonClicked,
    onDeleteButtonClicked,
}: Properties) => {
    const listRef = useRef<VirtuosoHandle | null>(null)

    const getRow = (index: number) => {
        const isLastItem = index === comments.length - 1
        const viewModel = comments[index]

        return viewModel instanceof CommentListLoadingViewModel ? (
            <CommentRowLoading paddingBottom={isLastItem ? theme.spacing(3) : '0'} />
        ) : (
            <CommentRow
                viewModel={viewModel as CommentListItemViewModel}
                paddingBottom={isLastItem ? theme.spacing(3) : '0'}
                onEditButtonClicked={onEditButtonClicked}
                onDeleteButtonClicked={onDeleteButtonClicked}
            />
        )
    }
    useEffect(() => {
        if (indexToScrollTo === undefined) {
            return
        }

        listRef.current?.scrollToIndex({
            index: indexToScrollTo ?? 0,
            align: 'center',
            behavior: 'smooth',
        })
        onScrollEnded()
    }, [indexToScrollTo, onScrollEnded])

    return (
        <AutoSizer>
            {({ height, width }) => (
                <StyledVirtuoso
                    totalCount={comments.length}
                    itemContent={getRow}
                    overscan={500}
                    style={{
                        height,
                        width,
                    }}
                    ref={listRef}
                />
            )}
        </AutoSizer>
    )
}
