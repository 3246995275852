export enum LikeType {
    FAITH = 'FAITH',
    FLOWER = 'FLOWER',
    HOPE = 'HOPE',
    LOVE = 'LOVE',
    PRAISE = 'PRAISE',
    PRAY = 'PRAY',
    THUMBS_UP = 'THUMBS_UP',
    CHAI = 'CHAI',
}
