import { DateTime } from 'luxon'
import { firstBy } from 'thenby'
import { MY_CHURCH_GROUP_ID } from '../groups/Group'
import { Event } from './Event'
import {
    CreateEventViewModel,
    EventItemLoadingViewModel,
    EventItemViewModel,
    EventSectionViewModel,
    EventViewModel,
    ZeroEventsViewModel,
} from './EventViewModel'

export class EventViewModelBuilder {
    get isMyChurchGroup() {
        return this.selectedGroupId === MY_CHURCH_GROUP_ID
    }

    constructor(
        readonly canCreateEvents: boolean,
        readonly selectedGroupId: string | undefined,
        readonly selectedGroupName: string | undefined
    ) {}

    build(params: { events: Event[]; nextEventStart: string | undefined }) {
        let viewModels: EventViewModel[] = []

        if (this.canCreateEvents) {
            viewModels.push(
                new CreateEventViewModel(this.isMyChurchGroup ? undefined : this.selectedGroupId)
            )
        }

        const dates = params.events.reduce<DateTime[]>((dates, event) => {
            const eventDate = event.start

            if (!dates.some((date) => date.hasSame(eventDate, 'day'))) {
                return [...dates, eventDate]
            }

            return dates
        }, [])

        const eventsByDate = dates.reduce<Record<string, Event[]>>((eventsByDate, currentDate) => {
            const eventsForDate = params.events
                .filter((event) => event.start.hasSame(currentDate, 'day'))
                .sort(firstBy('isAllDay', 'desc').thenBy('start', 'asc'))

            return {
                ...eventsByDate,
                [currentDate.toISO()!]: eventsForDate,
            }
        }, {})

        Object.keys(eventsByDate).forEach((dateString, sectionIndex) => {
            const date = DateTime.fromISO(dateString)
            const events = eventsByDate[dateString]
            viewModels.push(new EventSectionViewModel(date))
            viewModels.push(
                ...events.map((event, index) => {
                    const isFirstEventOnSameDay = index === 0
                    const isLastEventOnSameDay = index === events.length - 1
                    return new EventItemViewModel(
                        sectionIndex + index,
                        this.isMyChurchGroup,
                        isFirstEventOnSameDay,
                        isLastEventOnSameDay,
                        event
                    )
                })
            )
        })

        if (params.nextEventStart) {
            viewModels.push(new EventItemLoadingViewModel())
        } else if (params.events.length === 0 && this.selectedGroupId && this.selectedGroupName) {
            viewModels.push(
                new ZeroEventsViewModel({ id: this.selectedGroupId, name: this.selectedGroupName })
            )
        }

        return viewModels
    }
}
