import { Box, styled, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { RoundedFilledButton } from '../../components/buttons/RoundedFilledButton'
import { TranslationFn } from '../../WithTranslations'
import { RecurrenceRule } from '../RecurrenceRule'
import { getLocalisedWeekdayFirstChar, Weekday } from '../Weekday'

interface Properties {
    locale: string
    recurrences: RecurrenceRule
    translations: TranslationFn
    onRecurrenceRuleChanged: (recurrenceRule: RecurrenceRule) => void
}

export const WeekdayView = ({ recurrences, translations, ...properties }: Properties) => {
    const weekDays = Object.values(Weekday)

    const [selectedWeekdays, setSelectedWeekdays] = useState(new Set<Weekday>(recurrences.weekdays))

    const getDayTranslation = (day: Weekday): string => {
        switch (day) {
            case Weekday.SUNDAY:
                return getLocalisedWeekdayFirstChar({ index: 6, locale: properties.locale })
            case Weekday.MONDAY:
                return getLocalisedWeekdayFirstChar({ index: 0, locale: properties.locale })
            case Weekday.TUESDAY:
                return getLocalisedWeekdayFirstChar({ index: 1, locale: properties.locale })
            case Weekday.WEDNESDAY:
                return getLocalisedWeekdayFirstChar({ index: 2, locale: properties.locale })
            case Weekday.THURSDAY:
                return getLocalisedWeekdayFirstChar({ index: 3, locale: properties.locale })
            case Weekday.FRIDAY:
                return getLocalisedWeekdayFirstChar({ index: 4, locale: properties.locale })
            case Weekday.SATURDAY:
                return getLocalisedWeekdayFirstChar({ index: 5, locale: properties.locale })
        }
    }

    const onWeekdaySelected = (day: Weekday) => {
        setSelectedWeekdays((previous) => {
            let next = new Set(previous)
            previous.has(day) ? next.delete(day) : next.add(day)
            return next
        })
    }

    useEffect(() => {
        if (selectedWeekdays === recurrences.weekdays) return

        properties.onRecurrenceRuleChanged(recurrences.copy({ weekdays: selectedWeekdays }))
    }, [selectedWeekdays, properties, recurrences])

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="body1">{translations('on_every')}</Typography>

            <DaySelector>
                {weekDays.map((day) => (
                    <RoundedFilledButton
                        key={day}
                        onClick={() => onWeekdaySelected(day)}
                        isSelected={selectedWeekdays.has(day)}
                    >
                        {getDayTranslation(day)}
                    </RoundedFilledButton>
                ))}
            </DaySelector>
        </Box>
    )
}

const DaySelector = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
}))
