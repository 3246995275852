import { Box, FormControlLabel, MenuItem, Radio, RadioGroup, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { Select } from '../../components/Select'
import { DatePicker } from '../../components/textfields/DatePicker'
import { range } from '../../utils/ArrayUtil'
import { TranslationFn } from '../../WithTranslations'
import { RecurrenceRule } from '../RecurrenceRule'

interface Properties {
    recurrenceRule: RecurrenceRule
    translations: TranslationFn
    onRecurrenceRuleChanged(recurrenceRule: RecurrenceRule): void
}

enum EndsOnOption {
    NEVER = 'NEVER',
    AFTER = 'AFTER',
    ON = 'ON',
}

export const EndsOnView = ({
    recurrenceRule,
    translations,
    onRecurrenceRuleChanged,
}: Properties) => {
    const getSelectedOption = () => {
        if (recurrenceRule.count) {
            return EndsOnOption.AFTER
        } else if (recurrenceRule.until) {
            return EndsOnOption.ON
        } else {
            return EndsOnOption.NEVER
        }
    }

    const [selectedEndOption, setSelectedEndOption] = useState(getSelectedOption())
    const [count, setCount] = useState(recurrenceRule.count ?? 1)
    const [until, setUntil] = useState(recurrenceRule.until ?? DateTime.now().plus({ month: 1 }))

    const onEndOptionChanged = (value: EndsOnOption) => {
        setSelectedEndOption(value)
        if (value === EndsOnOption.NEVER) {
            onRecurrenceRuleChanged(recurrenceRule.copy({ count: undefined, until: undefined }))
        } else if (value === EndsOnOption.AFTER) {
            onRecurrenceRuleChanged(recurrenceRule.copy({ count: count, until: undefined }))
        } else {
            onRecurrenceRuleChanged(recurrenceRule.copy({ count: undefined, until: until }))
        }
    }

    const onCountChanged = (value: number) => {
        setCount(value)
        setSelectedEndOption(EndsOnOption.AFTER)
        onRecurrenceRuleChanged(recurrenceRule.copy({ count: value, until: undefined }))
    }

    const onUntilChanged = (value: DateTime) => {
        setUntil(value)
        setSelectedEndOption(EndsOnOption.ON)
        onRecurrenceRuleChanged(recurrenceRule.copy({ count: undefined, until: value }))
    }

    return (
        <>
            <Typography variant="body1">{translations('ends')}</Typography>

            <RadioGroup
                value={selectedEndOption}
                onChange={(event) => onEndOptionChanged(event.target.value as EndsOnOption)}
            >
                <Box display="flex" mt={2} gap={2}>
                    <FormControlLabel
                        value={EndsOnOption.NEVER}
                        control={<Radio />}
                        label={translations('never')}
                    />
                </Box>

                <Box display="flex" mt={1} alignItems="center">
                    <FormControlLabel
                        value={EndsOnOption.AFTER}
                        control={<Radio />}
                        label={translations('after')}
                    />
                    <Select
                        MenuProps={{
                            sx: {
                                maxHeight: '350px',
                            },
                        }}
                        value={count}
                        onChange={(event) => onCountChanged(event.target.value as number)}
                    >
                        {range(1, 100).map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                    <Typography variant="body1" sx={{ ml: 2 }}>
                        {translations('plural_occurrences', [], count)}
                    </Typography>
                </Box>

                <Box display="flex" mt={1} alignItems="center">
                    <FormControlLabel
                        value={EndsOnOption.ON}
                        control={<Radio />}
                        label={translations('on')}
                    />
                    <Box>
                        <DatePicker
                            variant="outlined"
                            value={until}
                            onChange={(date) => {
                                if (date) onUntilChanged(date)
                            }}
                        />
                    </Box>
                </Box>
            </RadioGroup>
        </>
    )
}
