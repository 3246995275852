import AutoSizer from 'react-virtualized-auto-sizer'
import React from 'react'
import { StyledVirtuoso } from '../../common/StyledVirtuoso'
import {
    LikeListItemViewModel,
    LikeListLoadingViewModel,
    LikeListViewModel,
} from './LikeListViewModels'
import { Box, styled } from '@mui/material'
import { UserListItemRow, UserListLoadingRow } from '../../user/userList/UserListItemRows'
import { LikeIcon } from './LikeIcon'
import { useDetailView } from '../../common/detailView/hooks'

interface Properties {
    likes: LikeListViewModel[]
}

export const LikesList = ({ likes }: Properties) => {
    const { openUserDetailView } = useDetailView()

    const getRow = (index: number) => {
        const isLastItem = index === likes.length - 1
        const viewModel = likes[index]

        if (viewModel instanceof LikeListLoadingViewModel) {
            return <UserListLoadingRow />
        }

        const like = (viewModel as LikeListItemViewModel).like

        return (
            <UserListItemRow
                user={like.creator}
                badge={<LikeIcon likeType={like.type} />}
                onClick={() => openUserDetailView(like.creator!.id)}
                isClickable={true}
                isLastItem={isLastItem}
            />
        )
    }

    return (
        <Container>
            <AutoSizer>
                {({ height, width }) => (
                    <StyledVirtuoso
                        totalCount={likes.length}
                        itemContent={getRow}
                        overscan={500}
                        style={{
                            height,
                            width,
                        }}
                    />
                )}
            </AutoSizer>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    height: `100%`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.only('mobile')]: {
        paddingBottom: theme.spacing(1),
    },
}))
