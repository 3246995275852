import { styled, Typography } from '@mui/material'
import { grey_1 } from 'shared/lib/theme/Theme'

export const FunctionsTextView = styled(Typography)(() => ({
    color: grey_1,
    fontSize: 13,

    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
}))
