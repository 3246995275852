import { SVGProps } from 'react'

export const TimeIcon = (properties: SVGProps<any>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={properties.fill || 'current'}
        {...properties}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.96512 12C2.96512 7.01017 7.01017 2.96512 12 2.96512C16.9898 2.96512 21.0349 7.01017 21.0349 12C21.0349 16.9898 16.9898 21.0349 12 21.0349C7.01017 21.0349 2.96512 16.9898 2.96512 12ZM12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5ZM12.7326 6.13953C12.7326 5.73495 12.4046 5.40698 12 5.40698C11.5954 5.40698 11.2674 5.73495 11.2674 6.13953V12C11.2674 12.2775 11.4242 12.5311 11.6724 12.6552L15.5794 14.6087C15.9412 14.7896 16.3813 14.643 16.5622 14.2811C16.7431 13.9192 16.5965 13.4792 16.2346 13.2983L12.7326 11.5473V6.13953Z"
            fill={properties.fill || 'current'}
        />
    </svg>
)
