import { NotificationType } from './NotificationType'
import { LikeType } from '../posts/likes/LikeType'
import { MinimalGroupInfo } from '../groups/Group'
import { PostType } from '../posts/PostType'
import { Image } from 'shared/lib/models/Image'
import { DateTime } from 'luxon'
import { Cloneable } from '../utils/Misc'
import { getFullName } from 'shared/lib/utils/StringUtils'

export class Notification extends Cloneable<Notification> {
    constructor(
        public readonly id: string,
        public readonly type: NotificationType,
        public readonly isRead: boolean,
        public readonly sender:
            | {
                  id: string
                  name: string
                  image?: Image
              }
            | undefined,
        public readonly group: MinimalGroupInfo | undefined,
        public readonly post:
            | {
                  id: string
                  creatorId: string
                  type: PostType
              }
            | undefined,
        public readonly createdAt: DateTime,
        public readonly numberOfOthers: number = 0,
        public readonly commentId?: string,
        public readonly likeType?: LikeType,
        public readonly postIdOfFirstPostInHomeGroup?: string,
        public readonly isUserTagged: boolean = false
    ) {
        super()
    }

    static fromJSON(json: Record<string, any>): Notification {
        return new Notification(
            json.id,
            json.type,
            !!json.readAt,
            json.sender
                ? {
                      id: json.sender.id,
                      name:
                          json.sender.type === PostType.USER &&
                          json.sender.firstName &&
                          json.sender.lastName
                              ? getFullName(json.sender)
                              : json.sender.name,
                      image: json.sender.image,
                  }
                : undefined,
            json.group,
            json.post,
            DateTime.fromISO(json.createdAt),
            json.numberOfOthers,
            json.commentId,
            json.likeType,
            json.postIdOfFirstPostInHomeGroup,
            !!json.isUserTagged
        )
    }
}
