import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import {
    $convertFromMarkdownString,
    $convertToMarkdownString,
    LINK,
    TEXT_FORMAT_TRANSFORMERS,
    UNORDERED_LIST,
} from '@lexical/markdown'
import { URL_MATCHER } from '@lexical/react/LexicalAutoEmbedPlugin'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { AutoLinkPlugin, createLinkMatcherWithRegExp } from '@lexical/react/LexicalAutoLinkPlugin'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { Box } from '@mui/material'
import {
    $getSelection,
    CLEAR_EDITOR_COMMAND,
    COMMAND_PRIORITY_LOW,
    KEY_ESCAPE_COMMAND,
    LexicalEditor,
    TextNode,
} from 'lexical'
import { forwardRef, JSX, useEffect, useImperativeHandle, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { hideScrollbar } from 'shared/lib/theme/constants'
import { MinimalUserInfo } from 'shared/lib/users/MinimalUserInfo'
import { getRandomInt } from 'shared/lib/utils/NumberUtils'
import { Placeholder } from './Placeholder'
import { USER_TAG_REGEX } from './tagging'
import {
    NonGroupMemberTaggedNotification,
    OnlyGroupMembersNotification,
} from './tagging/Notifications'
import { TaggingPlugin } from './tagging/TaggingPlugin'
import { TagNode, TagNodeTransformer } from './tagging/TagNode'

type TaggableUser = MinimalUserInfo & { isGroupMember: boolean }

interface Properties {
    value: string
    placeholder: string
    readonly?: boolean
    apiUrl: string
    applicationId: string
    taggableUsers: TaggableUser[]
    postAttachments?: JSX.Element
    fontSize?: number
    autoFocus?: boolean
    notificationContainerAnchorId?: string

    onChanged(value: string): void
    onFocus?(): void
    onClose?(): void
}

export interface MarkdownInputWithTaggingSupportRefMethods {
    clear(): void
    startTagging(): void
}

export const MarkdownInputWithTaggingSupport = forwardRef<
    MarkdownInputWithTaggingSupportRefMethods,
    Properties
>(
    (
        {
            value,
            apiUrl,
            applicationId,
            onChanged,
            taggableUsers,
            postAttachments,
            onClose,
            fontSize = 24,
            autoFocus = true,
            notificationContainerAnchorId,
            onFocus,
        },
        ref
    ) => {
        const editorRef = useRef<LexicalEditor | null>(null)
        const [mostRecentTaggedUser, setMostRecentTaggedUser] = useState<TaggableUser | undefined>()
        const [showOnlyGroupMembersNotification, setShowOnlyGroupMembersNotification] =
            useState(false)
        const notificationContainer = notificationContainerAnchorId
            ? document.getElementById(notificationContainerAnchorId)
            : document.body

        const transformers = [
            ...TEXT_FORMAT_TRANSFORMERS,
            UNORDERED_LIST,
            TagNodeTransformer({ apiUrl, applicationId }),
            LINK,
        ]

        useEffect(() => {
            if (!onClose || !editorRef.current) {
                return
            }

            return editorRef.current.registerCommand(
                KEY_ESCAPE_COMMAND,
                () => {
                    onClose()
                    return false
                },
                COMMAND_PRIORITY_LOW
            )
        }, [onClose, editorRef])

        useEffect(() => {
            let timeOutId: NodeJS.Timeout | undefined
            if (mostRecentTaggedUser) {
                timeOutId = setTimeout(() => {
                    setMostRecentTaggedUser(undefined)
                }, 3000)
            }

            return () => {
                if (timeOutId) {
                    clearTimeout(timeOutId)
                }
            }
        }, [mostRecentTaggedUser])

        useEffect(() => {
            const nonGroupMembersAreTagged = [
                ...new Set(
                    [...value.matchAll(new RegExp(USER_TAG_REGEX, 'g'))].map(
                        ([_, __, userId]) => userId
                    )
                ),
            ].some(
                (userId) =>
                    taggableUsers.find((user) => user.id === userId)?.isGroupMember === false
            )

            setShowOnlyGroupMembersNotification(nonGroupMembersAreTagged)

            const timeOutId = setTimeout(() => {
                setShowOnlyGroupMembersNotification(false)
            }, 3000)

            return () => {
                clearTimeout(timeOutId)
            }
        }, [JSON.stringify(taggableUsers)]) // eslint-disable-line react-hooks/exhaustive-deps

        useImperativeHandle(ref, () => ({
            clear() {
                editorRef.current?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
            },
            startTagging() {
                editorRef.current?.update(() => {
                    const selection = $getSelection()

                    if (!selection) {
                        return
                    }

                    const nodeToChange = selection.getNodes().pop()
                    const text = nodeToChange?.getTextContent() ?? ''
                    const lastCharOfSelection = text.slice(-1)

                    selection.insertNodes([
                        new TextNode(!text || lastCharOfSelection === ' ' ? '@' : ' @'),
                    ])
                })
            },
        }))

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: 'inherit',
                    maxHeight: 'inherit',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        fontSize: `${fontSize}px`,
                        height: '100%',
                        maxHeight: 'inherit',
                        width: '100%',
                        display: 'flex',
                        position: 'relative',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        overflowY: 'scroll',
                        textAlign: 'left',
                        ...hideScrollbar,

                        '& a': {
                            color: (theme) => theme.palette.primary.main,
                            textDecoration: 'none',
                        },
                    }}
                >
                    <LexicalComposer
                        initialConfig={{
                            namespace: `messageInput-${getRandomInt(1000)}`,
                            onError: console.error,
                            nodes: [LinkNode, AutoLinkNode, TagNode, ListNode, ListItemNode],
                            editorState: () => $convertFromMarkdownString(value, transformers),
                        }}
                    >
                        <EditorRefPlugin editorRef={editorRef} />
                        <RichTextPlugin
                            contentEditable={
                                <ContentEditable style={{ outline: 'none' }} onFocus={onFocus} />
                            }
                            placeholder={
                                <Placeholder
                                    top={fontSize}
                                    onClick={() => {
                                        editorRef.current?.focus()
                                    }}
                                />
                            }
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <HistoryPlugin />
                        {autoFocus && <AutoFocusPlugin />}
                        <ClearEditorPlugin />
                        <AutoLinkPlugin matchers={[createLinkMatcherWithRegExp(URL_MATCHER)]} />
                        {postAttachments ?? null}
                        <TaggingPlugin
                            taggableUsers={taggableUsers}
                            onUserTagged={(userId) =>
                                setMostRecentTaggedUser(
                                    taggableUsers.find((user) => user.id === userId)
                                )
                            }
                        />
                        <MarkdownShortcutPlugin transformers={transformers} />
                        <OnChangePlugin
                            onChange={(editorState) => {
                                editorState.read(() => {
                                    onChanged($convertToMarkdownString(transformers))
                                })
                            }}
                        />
                    </LexicalComposer>
                </Box>

                {notificationContainer && showOnlyGroupMembersNotification && (
                    <>
                        {ReactDOM.createPortal(
                            <OnlyGroupMembersNotification
                                closeNotification={() => setShowOnlyGroupMembersNotification(false)}
                            />,
                            notificationContainer
                        )}
                    </>
                )}

                {notificationContainer &&
                    !showOnlyGroupMembersNotification &&
                    mostRecentTaggedUser?.isGroupMember === false && (
                        <>
                            {ReactDOM.createPortal(
                                <NonGroupMemberTaggedNotification
                                    user={mostRecentTaggedUser}
                                    closeNotification={() => setMostRecentTaggedUser(undefined)}
                                />,
                                notificationContainer
                            )}
                        </>
                    )}
            </Box>
        )
    }
)
