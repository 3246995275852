import { LinearProgress as MuiLinearProgress, linearProgressClasses, styled } from '@mui/material'
import { grey_3 } from 'shared/lib/theme/Theme'

interface Properties {
    value: number
    variant: 'determinate' | 'indeterminate' | 'buffer' | 'query'
}

export const LinearProgressBar = (properties: Properties) => {
    return <LinearProgress variant={properties.variant} value={properties.value} />
}

const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
    height: theme.spacing(1),
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: grey_3,
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 8,
    },
}))
