import { ListItemText as MuiListItemText, listItemTextClasses, styled } from '@mui/material'
import { useTranslation } from 'shared/lib/i18n'
import {
    NotificationsListGroupHeaderViewModel,
    NotificationTimeSpan,
} from '../NotificationsListViewModels'
import { capitalizeFirstLetter } from 'shared/lib/utils/StringUtils'
import { ListItem } from './components'

interface Properties {
    viewModel: NotificationsListGroupHeaderViewModel
}

export const NotificationListGroupHeaderRow = ({ viewModel: { timeSpan } }: Properties) => {
    const translations = useTranslation()

    const getTitle = () => {
        switch (timeSpan) {
            case NotificationTimeSpan.TODAY:
                return translations('today')
            case NotificationTimeSpan.THIS_WEEK:
                return translations('this_week')
            case NotificationTimeSpan.THIS_MONTH:
                return translations('this_month')
            case NotificationTimeSpan.EARLIER:
                return translations('earlier')
            default:
                return ''
        }
    }

    return (
        <ListItem
            sx={{
                paddingTop: 3,
            }}
        >
            <HeaderTitle primary={capitalizeFirstLetter(getTitle())} />
        </ListItem>
    )
}

const HeaderTitle = styled(MuiListItemText)(() => ({
    margin: 0,

    [`.${listItemTextClasses.primary}`]: {
        fontSize: 18,
        fontWeight: 700,
    },
}))
