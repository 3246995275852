import React from 'react'
import { Box, ListItem, styled } from '@mui/material'
import { UserSectionRow } from './UserSectionRow'
import { paperColor } from 'shared/lib/theme/Theme'
import { Skeleton } from 'shared/lib/components/Skeleton'

interface Properties {
    index: number
}

export const SkeletonRow = ({index }: Properties) =>
    index === 0 ? (
        <UserSectionRow viewModel={{ letter: 'A' }} />
    ) : (
        <Row>
            <Skeleton variant="circular" width={40} height={40} />
            <TextContainer>
                <Skeleton variant="text" width="100%" height={20} />
                <Skeleton variant="text" width="100%" height={10} />
            </TextContainer>
        </Row>
    )

const Row = styled(ListItem)(({ theme }) => ({
    backgroundColor: paperColor,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
}))

const TextContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
}))
