import { TranslationFn } from '../WithTranslations'

export enum Frequency {
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export function toFrequency(freq: string): Frequency | undefined {
    switch (freq) {
        case 'DAILY':
            return Frequency.DAILY
        case 'WEEKLY':
            return Frequency.WEEKLY
        case 'MONTHLY':
            return Frequency.MONTHLY
        case 'YEARLY':
            return Frequency.YEARLY
        default:
            return undefined
    }
}

export function toFrequencyLongString(params: {
    frequency: Frequency
    translations: TranslationFn
}): string {
    switch (params.frequency) {
        case Frequency.DAILY:
            return params.translations('every_day')
        case Frequency.WEEKLY:
            return params.translations('every_week')
        case Frequency.MONTHLY:
            return params.translations('every_month')
        case Frequency.YEARLY:
            return params.translations('every_year')
    }
}
