import { Post } from '../../posts/Post'
import { PostContentType } from '../../posts/PostContentType'
import { PostListItemContainer } from '../PostListComponents'
import { UnsupportedContentView } from './UnsupportedContentView'

interface Properties {
    hasWhiteBackground?: boolean
    post: Post
}

export const PostDiscoverGroupsView = ({ post, ...properties }: Properties) => {
    const hasWhiteBackground = properties.hasWhiteBackground ?? false

    if (post.contentType !== PostContentType.DISCOVER_GROUPS) {
        return <></>
    }

    return (
        <PostListItemContainer>
            <UnsupportedContentView hasWhiteBackground={hasWhiteBackground} />
        </PostListItemContainer>
    )
}
