import { filterByQuery } from '../utils/SearchFieldUtils'
import { getFullName, removeDiacritics } from '../utils/StringUtils'
import { getAllCombinations } from '../utils/Utils'
import { MinimalUserInfo } from './MinimalUserInfo'
import Fuse from 'fuse.js'

export const filterUsers = <T extends MinimalUserInfo>(
    users: T[],
    query: string,
    keys?: string[],
    options?: Fuse.IFuseOptions<T>
): T[] => {
    if (!query) return users

    return filterByQuery<T>(users, `^"${removeDiacritics(query)}"`, {
        findAllMatches: false,
        isCaseSensitive: false,
        useExtendedSearch: true,
        keys: keys || ['emailAddress', 'name', 'functions'],
        getFn: (user, path) => {
            switch (path[0]) {
                case 'name':
                    return [
                        getAllCombinations(user.firstName),
                        getAllCombinations(user.lastName),
                        getAllCombinations(getFullName(user)),
                    ]
                case 'functions':
                    return user.functions.map((f) => getAllCombinations(f)).flat()
                case 'address':
                    return user.address
                        ? [
                              getAllCombinations(user.address.street),
                              getAllCombinations(user.address.zipCode),
                              getAllCombinations(user.address.city),
                          ]
                        : []
                default:
                    return user[path as string]?.toString()
            }
        },
        ...options,
    })
}
