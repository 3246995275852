import { Typography, TypographyProps } from '@mui/material'
import { DateTime } from 'luxon'
import { useTranslation } from 'shared/lib/i18n'
import { Event } from '../../Event'
import { FromToLabel } from './FromToLabel'
import { RecurrenceRuleFactory } from 'shared/lib/recurring/RecurrenceRuleFactory'
import i18next from 'i18next'

interface Properties {
    event: Omit<Event, 'start'>
    start: DateTime
}

export const TimeTextView = ({ event, start }: Properties) => {
    const translations = useTranslation()

    const recurrenceRule = RecurrenceRuleFactory.fromRRuleString(
        event.initialStart,
        event.recurrenceRule
    )
    const endsString =
        recurrenceRule?.hasEnd === true
            ? ` ∙ ${recurrenceRule.toEndString({ shortStyle: true, locale: i18next.language, translations })}`
            : ''
    const recurrenceRuleString = recurrenceRule
        ? `${event.isAllDay ? ' ∙ ' : ''} ${recurrenceRule.toString({ locale: i18next.language, translations })}${endsString}`
        : ''

    if (!event.isSameDay) {
        if (event.hasDurationOfMultipleDays) {
            return (
                <Typography variant="body2">
                    {event.isAllDay ? translations('agenda_all_day') : ''} {recurrenceRuleString}
                </Typography>
            )
        }

        return null
    }

    return event.isAllDay ? (
        <Typography variant="body2">
            {translations('agenda_all_day')}
            {recurrenceRuleString}
        </Typography>
    ) : (
        <>
            <StyledDateLabel>
                {translations('from_to', [
                    <span>{start.toLocaleString(DateTime.TIME_24_SIMPLE)}</span>,
                    <span>{event.end.toLocaleString(DateTime.TIME_24_SIMPLE)}</span>,
                ])}
            </StyledDateLabel>
            <Typography variant="body2">{recurrenceRuleString}</Typography>
        </>
    )
}

const StyledDateLabel = ({ children, ...props }: TypographyProps) => (
    <FromToLabel
        dangerouslySetInnerHTML={{
            __html: children as string,
        }}
        {...props}
    />
)
