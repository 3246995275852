import { Box, CircularProgress, IconButton, IconButtonProps, styled } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { defaultTransition, grey_3 } from 'shared/lib/theme/Theme'
import { commentRepository, groupRepository } from '..'
import { ReactComponent as SendIcon } from '../assets/svg/send.svg'
import { useTranslation } from 'shared/lib/i18n'
import ChurchContext from '../churches/ChurchContextProvider'
import {
    MarkdownInputWithTaggingSupport,
    MarkdownInputWithTaggingSupportRefMethods,
} from '../posts/createpostpopup/input/MarkdownInputWithTaggingSupport'
import { UsersContext } from '../user/UsersContextProvider'

interface Properties {
    postId: string
    groupId?: string
    commentToEdit?: {
        id: string
        message: string
    }
    notificationContainerAnchorId: string

    onIsCreatingOrUpdating?(isCreatingOrUpdating: boolean): void
    onClick?(): void
}

export const CommentTextField = ({ postId, groupId, commentToEdit, ...properties }: Properties) => {
    const translations = useTranslation()

    const textFieldRef = useRef<HTMLTextAreaElement | null>(null)
    const { church } = useContext(ChurchContext)!

    const { approvedUsers } = useContext(UsersContext)!

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error>()
    const [message, setMessage] = useState(commentToEdit?.message || '')
    const [groupMemberIdsInGroup, setGroupMemberIdsInGroup] = useState<string[]>([])
    const inputRef = useRef<MarkdownInputWithTaggingSupportRefMethods>(null)

    const hasMessage = message.trim().length > 0
    const canContinue = commentToEdit?.message !== message && hasMessage

    const onFocus = () => {
        if (!groupId || groupMemberIdsInGroup.length > 0) {
            return
        }

        groupRepository.getGroupMemberIds(groupId).then(setGroupMemberIdsInGroup)
    }

    const onCreateOrUpdateButtonClicked = () => {
        setIsLoading(true)
        properties.onIsCreatingOrUpdating?.(true)

        if (commentToEdit) {
            commentRepository
                .updateComment(postId, commentToEdit.id, message)
                .then(() => {
                    inputRef.current?.clear()
                })
                .catch(setError)
                .finally(() => {
                    setIsLoading(false)
                    properties.onIsCreatingOrUpdating?.(false)
                })
            return
        }

        commentRepository
            .createComment(postId, message)
            .then(() => {
                inputRef.current?.clear()
            })
            .catch(setError)
            .finally(() => {
                setIsLoading(false)
                properties.onIsCreatingOrUpdating?.(false)
            })
    }

    useEffect(() => {
        setMessage(commentToEdit?.message || '')
        if (commentToEdit) {
            textFieldRef.current?.focus()
        }
    }, [commentToEdit])

    return (
        <>
            <WriteCommentContainer>
                <MarkdownInputWithTaggingSupport
                    ref={inputRef}
                    value={message}
                    placeholder={translations('enter_comment_hint')}
                    applicationId={church!.applicationId}
                    apiUrl={church!.apiUrl!}
                    taggableUsers={approvedUsers.map((user) => ({
                        ...user,
                        isGroupMember: groupMemberIdsInGroup.includes(user.id),
                    }))}
                    onChanged={setMessage}
                    fontSize={14}
                    autoFocus={!!commentToEdit}
                    notificationContainerAnchorId={properties.notificationContainerAnchorId}
                    onFocus={onFocus}
                />

                <CreateCommentButton
                    isVisible={hasMessage}
                    isDisabled={!canContinue || isLoading}
                    size="large"
                    onClick={onCreateOrUpdateButtonClicked}
                >
                    {isLoading ? (
                        <CircularProgress sx={{ color: 'white' }} thickness={6} size={20} />
                    ) : (
                        <SendIcon />
                    )}
                </CreateCommentButton>
            </WriteCommentContainer>

            <ErrorHandler error={error} translations={translations} />
        </>
    )
}

const WriteCommentContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    borderRadius: '24px',
    backgroundColor: grey_3,
    maxHeight: 250,
}))

const CreateCommentButton = styled(
    ({
        isVisible,
        isDisabled,
        ...properties
    }: IconButtonProps & { isVisible: boolean; isDisabled: boolean }) => (
        <IconButton {...properties} />
    )
)(({ theme, isVisible, isDisabled }) => ({
    height: '36px',
    width: '36px',
    opacity: isVisible ? (isDisabled ? 0.4 : 1) : 0,
    backgroundColor: theme.palette.primary.main,
    pointerEvents: isVisible && !isDisabled ? 'auto' : 'none',
    alignSelf: 'flex-end',
    padding: theme.spacing(1),
    transition: defaultTransition,
    position: 'sticky',
    margin: theme.spacing(1),

    ':hover': {
        backgroundColor: theme.palette.primary.light,
    },
}))
