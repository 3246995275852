import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

/**
 * @deprecated Use FetchHttpRepository instead
 */
export abstract class AxiosHttpRepository {
    protected abstract getConfig(withAuthorization: boolean): AxiosRequestConfig

    constructor(
        protected readonly axios: AxiosInstance,
        protected readonly apiUrl: string
    ) {}

    protected isCanceled(error: any): boolean {
        return axios.isCancel(error)
    }

    protected async post<T>(url: string, data?: any, abortSignal?: AbortSignal): Promise<T> {
        return this.axios
            .post<T>(url, data, { ...this.getConfig(true), signal: abortSignal })
            .then((response: AxiosResponse<T>) => response.data)
    }

    protected async postPath<T>(path: string, data?: any, abortSignal?: AbortSignal): Promise<T> {
        return this.axios
            .post<T>(`${this.apiUrl}${path}`, data, {
                ...this.getConfig(true),
                signal: abortSignal,
            })
            .then((response: AxiosResponse<T>) => response.data)
    }

    protected async postWithoutAuthorization<T>(
        url: string,
        data?: any,
        abortSignal?: AbortSignal
    ): Promise<T> {
        return this.axios
            .post(url, data, { ...this.getConfig(false), signal: abortSignal })
            .then((response: AxiosResponse<T>) => response.data)
    }

    protected async postPathWithoutAuthorization<T>(
        path: string,
        data?: any,
        abortSignal?: AbortSignal
    ): Promise<T> {
        return this.axios
            .post(`${this.apiUrl}${path}`, data, { ...this.getConfig(false), signal: abortSignal })
            .then((response: AxiosResponse<T>) => response.data)
    }

    protected async get<T>(url: string, abortSignal?: AbortSignal): Promise<T> {
        return this.axios
            .get(url, { ...this.getConfig(true), signal: abortSignal })
            .then((response: AxiosResponse<T>) => response.data)
    }

    protected async getPath<T>(
        path: string,
        queryParams?: any,
        abortSignal?: AbortSignal
    ): Promise<T> {
        return this.axios
            .get(`${this.apiUrl}${path}`, {
                ...this.getConfig(true),
                params: queryParams,
                signal: abortSignal,
            })
            .then((response: AxiosResponse<T>) => response.data)
    }

    protected async getWithoutAuthorization<T>(url: string, abortSignal?: AbortSignal): Promise<T> {
        return this.axios
            .get(url, { ...this.getConfig(false), signal: abortSignal })
            .then((response: AxiosResponse<T>) => response.data)
    }

    protected async put<T>(url: string, data?: any, abortSignal?: AbortSignal) {
        return this.axios
            .put(url, data, { ...this.getConfig(true), signal: abortSignal })
            .then((response: AxiosResponse<T>) => response.data)
    }

    protected async patch<T>(url: string, data?: any, abortSignal?: AbortSignal) {
        return this.axios
            .patch(url, data, { ...this.getConfig(true), signal: abortSignal })
            .then((response: AxiosResponse<T>) => response.data)
    }

    protected async patchPath<T>(path: string, data?: any, abortSignal?: AbortSignal) {
        return this.axios
            .patch(`${this.apiUrl}${path}`, data, { ...this.getConfig(true), signal: abortSignal })
            .then((response: AxiosResponse<T>) => response.data)
    }

    protected async delete<T>(url: string, abortSignal?: AbortSignal): Promise<T> {
        return this.axios
            .delete(url, { ...this.getConfig(true), signal: abortSignal })
            .then((response: AxiosResponse<T>) => response.data)
    }

    protected async deletePath<T>(path: string, abortSignal?: AbortSignal): Promise<T> {
        return this.axios
            .delete(`${this.apiUrl}${path}`, { ...this.getConfig(true), signal: abortSignal })
            .then((response: AxiosResponse<T>) => response.data)
    }
}
