import { TranslationFn } from '../WithTranslations'

export enum EditOrDeleteRecurringEventOption {
    THIS_EVENT = 'THIS_EVENT',
    THIS_AND_FOLLOWING_EVENTS = 'THIS_AND_FOLLOWING_EVENTS',
    ALL_EVENTS = 'ALL_EVENTS',
}

export const toEditOrDeleteEventStringValue = (
    type: EditOrDeleteRecurringEventOption,
    translations: TranslationFn
) => {
    switch (type) {
        case EditOrDeleteRecurringEventOption.THIS_EVENT:
            return translations('only_this_event')
        case EditOrDeleteRecurringEventOption.THIS_AND_FOLLOWING_EVENTS:
            return translations('this_and_following_events')
        case EditOrDeleteRecurringEventOption.ALL_EVENTS:
            return translations('all_events')
    }
}
