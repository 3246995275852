import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSessionStorage } from 'usehooks-ts'

export const useHistory = () => {
    const location = useLocation()

    const from = location.state?.from
    const [fromPath, setFromPath] = useSessionStorage('from', '/')

    const getFromPath = () => {
        const path = fromPath
        setFromPath('/')
        return path
    }

    useEffect(() => {
        if (from) {
            setFromPath(from)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return {
        getFromPath,
    }
}
