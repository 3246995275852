import { Box, styled, Typography } from '@mui/material'
import { forwardRef, Ref, useState } from 'react'
import { FilledCloseButtonWithWhiteCross } from 'shared/lib/components/buttons/CloseButton'
import theme from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'
import { Comment } from './Comment'
import { CommentTextField } from './CommentTextField'

interface Properties {
    isUserCreatorFromSelectedComment: boolean
    postId: string
    groupId?: string
    commentToEdit: Comment | undefined
    onScrollToCommentButtonClicked?: () => void
    onCloseButtonClicked: () => void
}

export const CreateOrEditCommentView = forwardRef(
    (properties: Properties, ref: Ref<HTMLDivElement>) => {
        const translations = useTranslation()

        const { postId, groupId, commentToEdit } = properties

        const [isLoading, setIsLoading] = useState(false)

        const renderTextField = () => {
            if (!commentToEdit) {
                return (
                    <TextFieldContainer>
                        <CommentTextField
                            postId={postId}
                            groupId={groupId}
                            onIsCreatingOrUpdating={setIsLoading}
                            notificationContainerAnchorId="comments-list-view"
                        />
                    </TextFieldContainer>
                )
            }

            return (
                <>
                    <EditCommentContainer>
                        <Box display="flex" flexGrow={1}>
                            {translations('edit')}
                            <YourComment onClick={properties.onScrollToCommentButtonClicked}>
                                {properties.isUserCreatorFromSelectedComment
                                    ? translations('your_response')
                                    : translations('response')}
                            </YourComment>
                        </Box>
                        <FilledCloseButtonWithWhiteCross
                            opacity={isLoading ? 0.4 : 1}
                            disabled={isLoading}
                            color={theme.palette.primary.main}
                            onClick={properties.onCloseButtonClicked}
                        />
                    </EditCommentContainer>

                    <TextFieldContainer>
                        <CommentTextField
                            postId={postId}
                            groupId={groupId}
                            commentToEdit={
                                commentToEdit
                                    ? {
                                          id: commentToEdit.id,
                                          message: commentToEdit.getMessage(),
                                      }
                                    : undefined
                            }
                            onIsCreatingOrUpdating={setIsLoading}
                            notificationContainerAnchorId="comments-list-view"
                        />
                    </TextFieldContainer>
                </>
            )
        }

        return <CreateCommentContainer ref={ref}>{renderTextField()}</CreateCommentContainer>
    }
)

const CreateCommentContainer = styled(Box)(() => ({
    width: '100%',
    backgroundColor: 'white',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    boxShadow: '0px -2px 16px 0px rgba(0, 0, 0, 0.08)',
    display: 'flex',
    flexDirection: 'column',
}))

const EditCommentContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    padding: theme.spacing(2, 2, 0, 5),
}))

const YourComment = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
}))

const TextFieldContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
}))
