import { Box, styled } from '@mui/material'
import MuiGrid from '@mui/material/Unstable_Grid2'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { useIsTabletOrBigger } from 'shared/lib/theme/BreakPointHooks'
import { defaultTransition, mobileToolbarHeight } from 'shared/lib/theme/Theme'
import { useHistory } from '../common/HistoryHook'
import { DetailView } from '../common/detailView/DetailView'
import { useDetailView } from '../common/detailView/hooks'
import { ContentContainer } from '../common/layout/ContentContainer'
import { MobileHeader } from '../common/layout/MobileHeader'
import { useGroupAccessRequests } from '../groups/hooks'
import {
    GroupMemberApprovedEvent,
    GroupMemberDeniedEvent,
    GroupMemberEventKey,
} from '../groups/members/events'
import { useTranslation } from 'shared/lib/i18n'
import { UserEventKey } from '../user/UserEvents'
import { useUsers } from '../user/hooks'
import { AppAccessRequestsCard } from './AppAccessRequestsCard'
import { GroupAccessRequestsCard } from './GroupAccessRequestsCard'
import { NotificationPageMountedEvent, NotificationPageUnmountedEvent } from './events'
import { NOTIFICATION_LIST_ID, NotificationsList } from './list/NotificationsList'

export const NotificationsPage = () => {
    const translations = useTranslation()
    const navigate = useNavigate()

    const { getFromPath } = useHistory()
    const { isDetailViewOpen, isDetailViewDisplayedAsPopup } = useDetailView()

    const isTabletOrBigger = useIsTabletOrBigger()

    const {
        users: unapprovedUsers,
        isLoading: isLoadingUnapprovedUsers,
        getUsers,
    } = useUsers({
        isApproved: false,
    })

    const {
        groupAccessRequests,
        getGroupAccessRequests,
        setGroupAccessRequests,
        onApproveGroupMember,
        onDenyGroupMember,
        error,
    } = useGroupAccessRequests()

    const totalGroupAccessRequests = groupAccessRequests.reduce(
        (total, { members }) => total + members.length,
        0
    )

    const hasAdditionalContent =
        (isDetailViewOpen && !isDetailViewDisplayedAsPopup) ||
        Boolean(unapprovedUsers.length) ||
        Boolean(groupAccessRequests.length)

    const onGroupMemberApprovedOrDenied = useCallback(
        (event: Event) => {
            const { userId, groupId } = (event as GroupMemberApprovedEvent | GroupMemberDeniedEvent)
                .detail

            setGroupAccessRequests((prevState) =>
                prevState.map(({ members, ...group }) => {
                    if (group.id !== groupId) {
                        return { members, ...group }
                    }

                    return {
                        ...group,
                        members: members.filter((member) => member.id !== userId),
                    }
                })
            )
        },
        [setGroupAccessRequests]
    )

    useEffect(() => {
        document.addEventListener(UserEventKey.USER_APPROVED, getUsers)
        document.addEventListener(UserEventKey.USER_DENIED, getUsers)
        document.addEventListener(
            GroupMemberEventKey.GROUP_MEMBER_APPROVED,
            onGroupMemberApprovedOrDenied
        )
        document.addEventListener(
            GroupMemberEventKey.GROUP_MEMBER_DENIED,
            onGroupMemberApprovedOrDenied
        )

        return () => {
            document.removeEventListener(UserEventKey.USER_APPROVED, getUsers)
            document.removeEventListener(UserEventKey.USER_DENIED, getUsers)
            document.removeEventListener(
                GroupMemberEventKey.GROUP_MEMBER_APPROVED,
                onGroupMemberApprovedOrDenied
            )
            document.removeEventListener(
                GroupMemberEventKey.GROUP_MEMBER_DENIED,
                onGroupMemberApprovedOrDenied
            )
        }
    }, [getUsers, getGroupAccessRequests, onGroupMemberApprovedOrDenied])

    useEffect(() => {
        document.dispatchEvent(new NotificationPageMountedEvent())

        return () => {
            document.dispatchEvent(new NotificationPageUnmountedEvent())
        }
    }, [])

    return (
        <>
            {!isTabletOrBigger && (
                <MobileHeader
                    hasActions={false}
                    scrollableContainerId={NOTIFICATION_LIST_ID}
                    title={translations('notifications')}
                    onBackButtonClicked={() => navigate(getFromPath())}
                />
            )}

            <ContentContainer mt={isTabletOrBigger ? undefined : mobileToolbarHeight}>
                <Grid container columns={{ mobile: 4, phablet: 4, tablet: 8, desktop: 12 }}>
                    <Grid
                        mobile={4}
                        phablet={isDetailViewOpen ? 2 : 4}
                        tablet={5}
                        desktop={5}
                        tabletOffset={hasAdditionalContent ? 0 : 1.5}
                        desktopOffset={hasAdditionalContent ? 2 : 3.5}
                        sx={(theme) => ({
                            [theme.breakpoints.only('phablet')]: {
                                padding: theme.spacing(0, 2),
                            },
                        })}
                    >
                        <AutoSizer disableWidth={true}>
                            {({ height }) => (
                                <NotificationsList
                                    height={height}
                                    unapprovedUsersCount={unapprovedUsers.length}
                                    groupAccessRequestsCount={totalGroupAccessRequests}
                                />
                            )}
                        </AutoSizer>
                    </Grid>

                    {((isTabletOrBigger && !isDetailViewOpen) || isDetailViewDisplayedAsPopup) &&
                    (unapprovedUsers.length || groupAccessRequests.length) ? (
                        <Grid tablet={3} desktop={4} pl={2} pt={3}>
                            <Box display="flex" flexDirection="column" height="100%" gap={2}>
                                <AppAccessRequestsCard
                                    users={unapprovedUsers}
                                    isLoading={isLoadingUnapprovedUsers}
                                />
                                <GroupAccessRequestsCard
                                    groupAccessRequests={groupAccessRequests}
                                    onApproveGroupMember={onApproveGroupMember}
                                    onDenyGroupMember={onDenyGroupMember}
                                />
                            </Box>
                        </Grid>
                    ) : null}

                    {isDetailViewOpen && (
                        <Grid
                            mobile={4}
                            phablet={2}
                            tablet={3}
                            desktop={5}
                            sx={(theme) => ({
                                [theme.breakpoints.not('phablet')]: {
                                    paddingLeft: theme.spacing(2),
                                },
                            })}
                        >
                            <DetailView />
                        </Grid>
                    )}
                </Grid>
            </ContentContainer>

            <ErrorHandler error={error} translations={translations} horizontal="right" />
        </>
    )
}

const Grid = styled(MuiGrid)(() => ({
    height: '100%',
    transition: defaultTransition,
}))
