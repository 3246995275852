import { DateTime, Info } from 'luxon'

export enum Weekday {
    SUNDAY = 'SU',
    MONDAY = 'MO',
    TUESDAY = 'TU',
    WEDNESDAY = 'WE',
    THURSDAY = 'TH',
    FRIDAY = 'FR',
    SATURDAY = 'SA',
}

export function toWeekdayFromString(day: string): Weekday | undefined {
    switch (day) {
        case 'SU':
            return Weekday.SUNDAY
        case 'MO':
            return Weekday.MONDAY
        case 'TU':
            return Weekday.TUESDAY
        case 'WE':
            return Weekday.WEDNESDAY
        case 'TH':
            return Weekday.THURSDAY
        case 'FR':
            return Weekday.FRIDAY
        case 'SA':
            return Weekday.SATURDAY
        default:
            return undefined
    }
}

export function toWeekdayFromDate(date: DateTime): Weekday | undefined {
    switch (date.weekday) {
        case 7:
            return Weekday.SUNDAY
        case 1:
            return Weekday.MONDAY
        case 2:
            return Weekday.TUESDAY
        case 3:
            return Weekday.WEDNESDAY
        case 4:
            return Weekday.THURSDAY
        case 5:
            return Weekday.FRIDAY
        case 6:
            return Weekday.SATURDAY
        default:
            return undefined
    }
}

export function toDateTimeWeekday(day: Weekday): number {
    switch (day) {
        case Weekday.SUNDAY:
            return 6
        case Weekday.MONDAY:
            return 0
        case Weekday.TUESDAY:
            return 1
        case Weekday.WEDNESDAY:
            return 2
        case Weekday.THURSDAY:
            return 3
        case Weekday.FRIDAY:
            return 4
        case Weekday.SATURDAY:
            return 5
    }
}

/**
 * index 0 is Monday, index 6 is Sunday
 */
export function getLocalisedWeekdayFirstChar(params: { index: number; locale: string }): string {
    return Info.weekdays('long', { locale: params.locale })[params.index].charAt(0).toUpperCase()
}

export function toLocalisedWeekdayFullString(params: { day: Weekday; locale: string }): string {
    return Info.weekdays('long', { locale: params.locale })[toDateTimeWeekday(params.day)]
}

export function toLocalisedWeekdayShortString(params: { day: Weekday; locale: string }): string {
    return Info.weekdays('short', { locale: params.locale })[toDateTimeWeekday(params.day)]
}
