import { Typography } from '@mui/material'
import { DateTime } from 'luxon'
import i18n from '../../../i18n'

interface Properties {
    isSameDay: boolean
    start: DateTime
}

export const StartDateSingleDay = (properties: Properties) => {
    if (!properties.isSameDay) {
        return null
    }

    return (
        <Typography>
            {properties.start.toLocaleString(
                {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                },
                {
                    locale: i18n.language,
                }
            )}
        </Typography>
    )
}
