import { removeDiacritics } from './StringUtils'

export type Dictionary = { [key: string]: any }

export const CurrencyFormatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
})

export function isUndefined(arg: any) {
    return typeof arg === 'undefined'
}

export function isUndefinedOrEmpty(value: string) {
    return value === undefined || value === ''
}

export function isNumber(value: any) {
    return typeof value === 'number' || value instanceof Number
}

export function merge(a: any, b: any) {
    return Object.assign({}, a, b)
}

export const getEnumValues = <T extends any>(enumType: any): T => {
    return Object.keys(enumType)
        .filter((key) => isNaN(Number(key)))
        .map((key: string) => enumType[key]) as T
}

export async function delay(millis: number) {
    return new Promise((resolve) => setTimeout(resolve, millis))
}

export function getAllCombinations(str: string) {
    const combinations: string[] = []
    const stringWithoutDiacritics = removeDiacritics(str)
    const wordsWithoutDiacritics = removeDiacritics(str).split(' ')

    wordsWithoutDiacritics.forEach((word, i) => {
        wordsWithoutDiacritics.slice(i + 1).forEach((nextWord) => {
            combinations.push([word, nextWord].join(' '))
        })
    })

    return [
        ...combinations,
        stringWithoutDiacritics,
        ...stringWithoutDiacritics.split(' '),
        ...stringWithoutDiacritics.replace(/[^a-zA-Z0-9\s]/g, '').split(' '),
    ]
}

export function tryParseJSON(json: string, fallbackValue?: any) {
    try {
        return JSON.parse(json)
    } catch {
        return fallbackValue
    }
}
