import { Image } from 'shared/lib/models/Image'
import { GroupType } from 'shared/lib/groups/GroupType'
import { GroupMemberState } from '../groups/Group'

export class SharedGroup {
    constructor(
        readonly id: string,
        readonly name: string,
        readonly type: GroupType,
        readonly description: string | undefined,
        readonly image: Image | undefined,
        readonly numberOfMembers: number,
        readonly groupMemberState: GroupMemberState
    ) {}

    static fromResponse(response: any): SharedGroup {
        return new SharedGroup(
            response.id,
            response.name,
            response.type,
            response.description,
            response.image ? Image.fromResponse(response.image) : undefined,
            response.numberOfMembers,
            response.groupMemberState
        )
    }
}
