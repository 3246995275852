import { Box, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DetailViewContainer } from '../../common/detailView'
import { DETAIL_VIEW_HEADER_BOTTOM_Y } from '../../common/detailView/DetailViewHeader'
import { DetailViewProperties } from '../../common/detailView/DetailViewProperties'
import { useTranslation } from 'shared/lib/i18n'
import { groupRepository } from '../../index'
import { sortUsersByLastName } from '../../user/Utils'
import { Group } from '../Group'
import { useGroupMembers } from '../hooks'
import { UnapprovedGroupMembersList } from './UnapprovedGroupMembersList'

interface Properties extends DetailViewProperties {
    groupId: string
}

export const UnapprovedGroupMembersListView = ({ groupId, ...properties }: Properties) => {
    const translations = useTranslation()
    const location = useLocation()
    const groupFromState = location.state?.group as Group | undefined

    const { isLoading: isLoadingGroupMembers, groupMembers } = useGroupMembers(groupId, {
        isApproved: false,
    })

    const [group, setGroup] = useState(groupFromState)

    const sortedGroupMembers = sortUsersByLastName(groupMembers)
    const numberOfUnapprovedGroupMembers = isLoadingGroupMembers
        ? group?.numberOfUnapprovedMembers || 0
        : sortedGroupMembers.length

    useEffect(() => {
        if (!groupFromState) {
            groupRepository.getGroup(groupId).then(setGroup)
        }
    }, [groupId, groupFromState])

    return (
        <DetailViewContainer
            onCloseClicked={properties.onCloseClicked}
            closeIcon={properties.closeIcon}
            title={translations(
                'plural_access_requests',
                [numberOfUnapprovedGroupMembers],
                numberOfUnapprovedGroupMembers
            )}
            contentMarginTop={DETAIL_VIEW_HEADER_BOTTOM_Y}
        >
            {group && (
                <Container>
                    <UnapprovedGroupMembersList
                        groupId={groupId}
                        groupMembers={groupMembers}
                        showTitle={false}
                    />
                </Container>
            )}
        </DetailViewContainer>
    )
}

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
}))
