import React from 'react'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { ListItem } from './components'
import { Box } from '@mui/material'

export const NotificationListGroupHeaderLoadingRow = () => {
    return (
        <Box pt={1} className="groupHeader">
            <ListItem>
                <Skeleton variant="rounded" width="20%" />
            </ListItem>
        </Box>
    )
}
