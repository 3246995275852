import { ReactComponent as ChevronRightIcon } from 'shared/lib/assets/svg/chevron_right.svg'
import { Box, ButtonProps, Button as MuiButton, Typography, styled } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { defaultHoverState, paperColor, textColor } from 'shared/lib/theme/Theme'
import ChurchContext from '../../churches/ChurchContextProvider'
import { useDetailView } from '../../common/detailView/hooks'
import { GroupBadge } from '../../groups/GroupBadge'
import { useTranslation } from 'shared/lib/i18n'
import { UserEventKey } from '../UserEvents'

interface Properties {
    withGroupBadge: boolean
    withRoundedCorners?: boolean
    fullWidth?: boolean
    appAccessRequestCount: number
}

export const AppAccessRequestsButton = (properties: Properties) => {
    const { openAppAccessRequestsView } = useDetailView()

    const [appAccessRequestCount, setAppAccessRequestCount] = useState(
        properties.appAccessRequestCount
    )

    const onAppAccessRequestHandled = () => {
        setAppAccessRequestCount((prevState) => prevState - 1)
    }

    useEffect(() => {
        document.addEventListener(UserEventKey.USER_APPROVED, onAppAccessRequestHandled)
        document.addEventListener(UserEventKey.USER_DENIED, onAppAccessRequestHandled)

        return () => {
            document.removeEventListener(UserEventKey.USER_APPROVED, onAppAccessRequestHandled)
            document.removeEventListener(UserEventKey.USER_DENIED, onAppAccessRequestHandled)
        }
    }, [])

    useEffect(() => {
        setAppAccessRequestCount(properties.appAccessRequestCount)
    }, [properties.appAccessRequestCount])

    return appAccessRequestCount ? (
        <Button
            fullWidth={properties.fullWidth}
            withRoundedCorners={properties.withRoundedCorners ?? true}
            withGroupBadge={properties.withGroupBadge}
            accessRequestCount={appAccessRequestCount}
            onClick={openAppAccessRequestsView}
        />
    ) : null
}

const Button = styled(
    ({
        withRoundedCorners,
        withGroupBadge,
        accessRequestCount,
        ...props
    }: Omit<ButtonProps, 'startIcon' | 'children'> & {
        withGroupBadge: boolean
        withRoundedCorners?: boolean
        accessRequestCount: number
    }) => {
        const translations = useTranslation()
        const { church } = useContext(ChurchContext)!

        const startIcon = withGroupBadge ? (
            <GroupBadge number={accessRequestCount} image={church?.image} verticalPosition="top" />
        ) : (
            <AppAccessRequestsCount>{accessRequestCount}</AppAccessRequestsCount>
        )

        return (
            <MuiButton {...props} startIcon={startIcon} endIcon={<ChevronRightIcon />}>
                <Typography>{translations('access_requests_app')}</Typography>
            </MuiButton>
        )
    }
)(({ theme, withRoundedCorners }) => ({
    backgroundColor: paperColor,
    height: '100%',
    padding: theme.spacing(1, 2),
    color: textColor,
    fontWeight: 400,
    borderRadius: withRoundedCorners ? theme.spacing(2) : 0,

    '& .MuiButton-root': {
        marginRight: theme.spacing(1),
    },

    '& .MuiBadge-badge': {
        color: paperColor,
        width: '24px',
        height: '24px',
        borderRadius: '50px',
    },

    '& .MuiTypography-root': {
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textAlign: 'left',
        wordBreak: 'break-all',
    },

    '&:hover': defaultHoverState,

    [theme.breakpoints.down('tablet')]: {
        justifyContent: 'start',
        padding: theme.spacing(2, 2.5),

        '& .MuiTypography-root': {
            flexGrow: 1,
            textAlign: 'left',
            paddingLeft: theme.spacing(2),
        },
    },
}))

const AppAccessRequestsCount = styled(Box)(({ theme }) => ({
    color: paperColor,
    backgroundColor: theme.palette.primary.main,
    height: '24px',
    width: '24px',
    borderRadius: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px !important',
}))
