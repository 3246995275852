import { Group } from '../Group'
import { useMemo } from 'react'

export interface ClickableGroupListViewModel {
    onClick(): void
}

export interface GroupListViewModel {}

export class GroupListItemViewModel implements GroupListViewModel, ClickableGroupListViewModel {
    constructor(readonly group: Group, readonly onClick: () => void) {}
}

export class GroupListCreateGroupViewModel
    implements GroupListViewModel, ClickableGroupListViewModel
{
    constructor(public readonly onClick: () => void) {}
}

export class GroupListItemLoadingViewModel implements GroupListViewModel {}

export const useGroupListViewModels = (properties: {
    groups: Group[]
    canCreateGroups: boolean
    selectedGroupId?: string
    onGroupSelected: (groupId: string) => void
    onCreateGroupClicked?: () => void
}) =>
    useMemo(() => {
        const groups = properties.groups
        const viewModels: GroupListViewModel[] = groups.map(
            (group) => new GroupListItemViewModel(group, () => properties.onGroupSelected(group.id))
        )

        if (properties.canCreateGroups && properties.onCreateGroupClicked && groups.length > 0) {
            viewModels.push(new GroupListCreateGroupViewModel(properties.onCreateGroupClicked))
        }

        return viewModels
    }, [properties.groups, properties.canCreateGroups, properties.selectedGroupId]) // eslint-disable-line react-hooks/exhaustive-deps
