import {
    Box,
    Dialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,
    styled,
} from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { ActivityIndicator } from './ActivityIndicator'
import { ButtonByType, ButtonType } from './buttons/ButtonType'
import { GreyContainedButton } from './buttons/GreyButtons'

interface Properties {
    isLoading?: boolean
    isVisible: boolean
    isContinueDisabled?: boolean
    title: string
    titleIcon?: ReactNode
    message?: string | ReactElement
    continueButtonType?: ButtonType
    continueButtonTitle: string
    cancelButtonType?: ButtonType
    cancelButtonTitle: string
    onContinueButtonClicked: () => void
    onCancelButtonClicked: () => void
}

export const AlertDialog = (properties: Properties) => {
    const { continueButtonTitle, onContinueButtonClicked } = properties
    const { isVisible, isLoading, isContinueDisabled } = properties

    const buttonHeight = '40px'

    return (
        <Dialog
            PaperProps={{
                style: {
                    padding: '32px',
                },
            }}
            open={isVisible}
            onClose={properties.onCancelButtonClicked}
            maxWidth={'tablet'}
        >
            <Box display={'flex'}>
                <Box pr={properties.titleIcon ? 2 : 0} pt={'3px'}>
                    {properties.titleIcon}
                </Box>
                <Box>
                    <DialogTitle>{properties.title}</DialogTitle>
                    <DialogContent>{properties.message}</DialogContent>
                </Box>
            </Box>

            <DialogActions>
                {isLoading && (
                    <Box>
                        <ActivityIndicator />
                    </Box>
                )}
                {!isLoading && (
                    <DialogActionsContainer>
                        <GreyContainedButton
                            sx={{ height: buttonHeight }}
                            title={properties.cancelButtonTitle}
                            onClick={properties.onCancelButtonClicked}
                        />
                        <ButtonByType
                            isDisabled={isContinueDisabled}
                            type={properties.continueButtonType || ButtonType.NONE}
                            height={buttonHeight}
                            title={continueButtonTitle}
                            onButtonClicked={onContinueButtonClicked}
                        />
                    </DialogActionsContainer>
                )}
            </DialogActions>
        </Dialog>
    )
}

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
}))

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
    paddingLeft: 0,
    paddingTop: theme.spacing(2),
    paddingRight: 0,
    paddingBottom: theme.spacing(3),
    whiteSpace: 'pre-wrap',
}))

const DialogActions = styled(MuiDialogActions)(() => ({
    padding: 0,
}))

const DialogActionsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    overflow: 'hidden',
}))
