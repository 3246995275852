import React from 'react'
import {
    ListItemAvatar,
    ListItemText as MuiListItemText,
    ListItemTextProps,
    styled,
} from '@mui/material'
import { sessionRepository } from '../../../index'
import { useTranslation } from 'shared/lib/i18n'
import { NotificationType } from '../../NotificationType'
import { ListItem, NotificationAvatar } from './components'
import { PostType } from '../../../posts/PostType'
import { createdAtString } from '../../../utils/DateTimeUtils'
import { NotificationsListItemViewModel } from '../NotificationsListViewModels'
import { useDetailView } from '../../../common/detailView/hooks'
import { Modify } from 'shared/lib/utils/Misc'

interface Properties {
    viewModel: NotificationsListItemViewModel

    markNotificationAsRead(notificationId: string): void
}

export const NotificationListItemRow = ({
    viewModel: { notification, isPreviousUnread, isNextUnread },
    markNotificationAsRead,
}: Properties) => {
    const translations = useTranslation()
    const { openPostDetailView, openGroupDetailView } = useDetailView()

    const isOwnPost = notification.post?.creatorId === sessionRepository.signedInUser!.id

    const onClick = () => {
        if (!notification.isRead) {
            markNotificationAsRead(notification.id)
        }

        switch (notification.type) {
            case NotificationType.POST_PLACED_IN_GROUP:
            case NotificationType.POST_LIKED:
                openPostDetailView(notification.post!.id)
                break
            case NotificationType.COMMENT_ON_POST:
                openPostDetailView(notification.post!.id, {
                    highlightedCommentId: notification.commentId,
                })
                break
            case NotificationType.USER_APPROVED:
                openPostDetailView(notification.postIdOfFirstPostInHomeGroup!)
                break
            case NotificationType.USER_ACCESS_GRANTED_TO_GROUP:
            case NotificationType.USER_REQUESTED_ACCESS_TO_GROUP:
            case NotificationType.USER_ADDED_TO_GROUP:
            case NotificationType.USER_ACCESS_DENIED_TO_GROUP:
                openGroupDetailView(notification.group!.id, { resetHistory: false })
                break
        }
    }

    const getMessage = () => {
        switch (notification.type) {
            case NotificationType.POST_PLACED_IN_GROUP:
                if (notification.isUserTagged) {
                    return notification.post!.type === PostType.USER
                        ? translations('notification_message_user_tagged_in_post', [
                              <strong>
                                  {notification.sender?.name || translations('deleted_user')}
                              </strong>,
                              <strong>{notification.group!.name}</strong>,
                          ])
                        : translations('notification_message_user_tagged_in_post_no_sender_name', [
                              <strong>{notification.group!.name}</strong>,
                          ])
                }

                return notification.post!.type === PostType.USER
                    ? translations('notification_message_post_placed_in_group', [
                          <strong>
                              {notification.sender?.name || translations('deleted_user')}
                          </strong>,
                          <strong>{notification.group!.name}</strong>,
                      ])
                    : translations('notification_message_post_placed_in_group_no_sender_name', [
                          <strong>
                              {notification.group!.name || translations('deleted_source')}
                          </strong>,
                      ])
            case NotificationType.COMMENT_ON_POST:
                if (notification.isUserTagged) {
                    return translations(
                        isOwnPost
                            ? 'notification_message_user_tagged_in_comment_your_post'
                            : 'notification_message_user_tagged_in_comment',
                        [
                            <strong>
                                {notification.sender?.name || translations('deleted_user')}
                            </strong>,
                            <strong>{notification.group!.name}</strong>,
                        ]
                    )
                }

                return notification.numberOfOthers >= 1
                    ? translations(
                          isOwnPost
                              ? 'notification_message_comment_on_my_post_multiple'
                              : 'notification_message_comment_like_others_multiple',
                          [
                              <strong>{notification.sender?.name}</strong>,
                              notification.numberOfOthers,
                              <strong>{notification.group!.name}</strong>,
                          ]
                      )
                    : translations(
                          isOwnPost
                              ? 'notification_message_comment_on_my_post_zero'
                              : 'notification_message_comment_like_others_zero',
                          [
                              <strong>{notification.sender?.name}</strong>,
                              <strong>{notification.group!.name}</strong>,
                          ]
                      )
            case NotificationType.POST_LIKED:
                return notification.numberOfOthers! >= 1
                    ? translations('notification_message_post_liked_with_others', [
                          <strong>{notification.sender?.name}</strong>,
                          <strong>{notification.group!.name}</strong>,
                      ])
                    : translations('notification_message_post_liked', [
                          <strong>{notification.sender?.name}</strong>,
                          <strong>{notification.group!.name}</strong>,
                      ])
            case NotificationType.USER_ADDED_TO_GROUP:
                return translations('added_to_group', [<strong>{notification.group!.name}</strong>])
            case NotificationType.USER_ACCESS_DENIED_TO_GROUP:
                return translations('user_requests_access_to_group_denied', [
                    <strong>{notification.group!.name}</strong>,
                ])
            case NotificationType.USER_REQUESTED_ACCESS_TO_GROUP:
                switch (notification.numberOfOthers) {
                    case 0:
                        return translations(
                            'notification_message_user_requested_access_to_group_zero',
                            [
                                <strong>{notification.sender?.name}</strong>,
                                <strong>{notification.group!.name}</strong>,
                            ],
                            notification.numberOfOthers
                        )
                    case 1:
                        return translations(
                            'notification_message_user_requested_access_to_group_one',
                            [
                                <strong>{notification.sender?.name}</strong>,
                                <strong>{notification.group!.name}</strong>,
                            ],
                            notification.numberOfOthers
                        )
                    default:
                        return translations(
                            'notification_message_user_requested_access_to_group_multiple',
                            [
                                <strong>{notification.sender?.name}</strong>,
                                notification.numberOfOthers,
                                <strong>{notification.group!.name}</strong>,
                            ],
                            notification.numberOfOthers
                        )
                }

            case NotificationType.USER_ACCESS_GRANTED_TO_GROUP:
                return translations('user_requests_access_to_group_granted_no_approver_name', [
                    <strong>{notification.group!.name}</strong>,
                ])
            case NotificationType.USER_APPROVED:
                return translations('notification_message_welcome_to_app')
            default:
                return notification.type
        }
    }

    return (
        <ListItem
            isRead={notification.isRead}
            sx={{
                ...(!notification.isRead
                    ? {
                          borderTopLeftRadius: isPreviousUnread ? 0 : undefined,
                          borderTopRightRadius: isPreviousUnread ? 0 : undefined,
                          borderBottomLeftRadius: isNextUnread ? 0 : undefined,
                          borderBottomRightRadius: isNextUnread ? 0 : undefined,
                      }
                    : {}),
            }}
            onClick={onClick}
        >
            <ListItemAvatar>
                <NotificationAvatar notification={notification} />
            </ListItemAvatar>
            <ListItemText
                primary={getMessage()}
                secondary={createdAtString(notification.createdAt, translations)}
            />
        </ListItem>
    )
}

const ListItemText = styled(
    ({ primary, ...props }: Modify<ListItemTextProps, { primary: string | TrustedHTML }>) => (
        <MuiListItemText
            {...props}
            primary={
                <div
                    style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                    dangerouslySetInnerHTML={{
                        __html: primary,
                    }}
                />
            }
        />
    )
)(() => ({
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    overflow: 'hidden',
}))
