import {
    IconButton,
    List as MuiList,
    ListItem,
    ListItemButton as MuiListItemButton,
    ListItemIcon as MuiListItemIcon,
    ListItemText,
    styled,
} from '@mui/material'
import { useState } from 'react'
import { ReactComponent as More } from 'shared/lib/assets/svg/more.svg'
import { ReactComponent as Edit } from 'shared/lib/assets/svg/write.svg'
import { MenuPopup } from 'shared/lib/components/MenuPopup'
import { grey_1 } from 'shared/lib/theme/Theme'
import { ReactComponent as Delete } from 'shared/lib/assets/svg/delete_bin.svg'
import { useTranslation } from 'shared/lib/i18n'
import { Post } from '../../posts/Post'

interface Properties {
    post: Post
    onEditButtonClicked: () => void
    onDeleteButtonClicked: () => void
}

export const PostActionsButton = (properties: Properties) => {
    const translations = useTranslation()

    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)

    const onMoreButtonClicked = (event: any) => {
        properties.post.notifyMarkAsReadIfNeeded()
        setAnchor(event.currentTarget)
    }

    const onEditButtonClicked = () => {
        setAnchor(null)
        properties.onEditButtonClicked()
    }

    const onDeleteButtonClicked = () => {
        setAnchor(null)
        properties.onDeleteButtonClicked()
    }

    return (
        <>
            <IconButton onClick={onMoreButtonClicked}>
                <MoreIcon />
            </IconButton>

            <MenuPopup
                anchor={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                popupMarginTop={'8px'}
                arrowRightPosition={'16px'}
                onPopupClosed={() => setAnchor(null)}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={onEditButtonClicked}>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary={translations('edit_post')} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={onDeleteButtonClicked}>
                            <ListItemIcon>
                                <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    color: (theme) => theme.palette.error.main,
                                }}
                                primary={translations('delete_post')}
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </MenuPopup>
        </>
    )
}

const MoreIcon = styled(More)(() => ({
    fill: grey_1,
}))

const EditIcon = styled(Edit)(({ theme }) => ({
    fill: theme.palette.text.primary,
}))

const DeleteIcon = styled(Delete)(({ theme }) => ({
    fill: theme.palette.error.main,
}))

const List = styled(MuiList)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
}))

const ListItemButton = styled(MuiListItemButton)(() => ({
    borderRadius: '16px',
}))

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
    minWidth: 'unset',
    marginRight: theme.spacing(2),
    '& svg': {
        height: '20px',
        width: '20px',
    },
}))
