import { LocalImage } from 'shared/lib/forms/LocalImage'
import { LocalVideo } from 'shared/lib/forms/LocalVideo'
import { LocalFile } from 'shared/lib/forms/LocalFile'
import { LinkPreview } from 'shared/lib/models/LinkPreview'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { Image } from 'shared/lib/models/Image'
import { Video } from 'shared/lib/models/Video'
import { Pdf } from 'shared/lib/models/Pdf'

export class EditPostBody {
    constructor(
        readonly id: string,
        readonly message: string,
        readonly images: Image[],
        readonly videos: Video[],
        readonly localImagesAndVideos: LocalFile[],
        readonly linkPreview: LinkPreview | undefined,
        readonly pdf: Pdf | undefined,
        readonly localPdfFile: LocalFile | undefined,
        readonly sharedPostId: string | undefined,
        readonly sharedGroupId: string | undefined
    ) {}

    toFormData(): FormData {
        const formData = new FormData()

        formData.append('message', this.message)

        if (this.images.length === 0) {
            formData.append('images[]', '')
        } else {
            this.images.forEach((image) => {
                formData.append('images[]{}', JSON.stringify(image))
            })
        }

        if (this.videos.length === 0) {
            formData.append('videos[]', '')
        } else {
            this.videos.forEach((video) => {
                formData.append(
                    'videos[]{}',
                    JSON.stringify({ key: video.key, imageKey: video.imageKey })
                )
            })
        }

        this.localImagesAndVideos.forEach((file) => {
            if (file instanceof LocalImage) {
                formData.append('imageFiles', file.file)
            } else if (file instanceof LocalVideo) {
                formData.append('videoFiles', file.file)
                formData.append('videoImageFiles', file.thumbnail.file)
            }
        })

        if (this.linkPreview) {
            formData.append('linkPreviews[]{}', JSON.stringify(this.linkPreview))
        }

        if (this.pdf) {
            formData.append('pdfs[]{}', JSON.stringify(this.pdf))
        } else {
            formData.append('pdfs[]', '')
        }

        if (this.localPdfFile) {
            if (this.localPdfFile instanceof LocalPdf) {
                formData.append('pdfFiles', this.localPdfFile.file)
                formData.append('pdfImageFiles', this.localPdfFile.thumbnail.file)
            }
        }

        if (this.sharedPostId) {
            formData.append('sharedPostId', this.sharedPostId)
        }

        if (this.sharedGroupId) {
            formData.append('sharedGroupId', this.sharedGroupId)
        }

        return formData
    }
}
