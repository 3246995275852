import { Box, styled } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { ReactComponent as CloseIcon } from 'shared/lib/assets/svg/close_filled.svg'
import { paperColor } from 'shared/lib/theme/Theme'
import { DetailViewHeader } from './DetailViewHeader'

interface Properties {
    id?: string
    title?: string | React.JSX.Element
    closeIcon?: React.JSX.Element
    contentMarginTop?: number
    overflow?: string
    actionButton?: React.JSX.Element
    shareButton?: React.JSX.Element

    onCloseClicked(): void
}

export const DetailViewContainer = (properties: PropsWithChildren<Properties>) => {
    return (
        <Container id={properties.id}>
            <DetailViewHeader
                title={properties.title}
                onCloseClicked={properties.onCloseClicked}
                closeIcon={properties.closeIcon || <CloseIcon fill={paperColor} />}
                actionButton={properties.actionButton}
                shareButton={properties.shareButton}
            />
            <Content
                mt={properties.contentMarginTop ? `${properties.contentMarginTop}px` : undefined}
                overflow={properties.overflow}
            >
                {properties.children}
            </Content>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    backgroundColor: paperColor,
    flexDirection: 'column',
    paddingBottom: 0,
    textAlign: 'center',
    overflow: 'hidden',
    flexGrow: 1,

    [theme.breakpoints.only('mobile')]: {
        padding: 0,
        height: `100dvh`,
    },
}))

const Content = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'scroll',
}))
