import axiosStatic, { AxiosRequestConfig } from 'axios'
import { ACCESS_TOKEN_KEY } from '../authentication/SessionRepository'
import config from '../config'
import { WebAppDonkeyAppInfo } from './WebAppDonkeyAppInfo'
import { Cookies } from 'react-cookie'
import { AxiosHttpRepository } from 'shared/lib/common/AxiosHttpRepository'

const APPLICATION_ID_KEY = 'applicationId'

export abstract class WebAppHttpRepository extends AxiosHttpRepository {
    protected cookies = new Cookies()

    protected get applicationId(): string {
        return localStorage.getItem(APPLICATION_ID_KEY) || ''
    }

    protected getConfig(withAuthorization: boolean): AxiosRequestConfig {
        return {
            headers: {
                Authorization:
                    this.bearerToken && withAuthorization
                        ? `Bearer ${this.bearerToken}`
                        : undefined,
                'donkey-app-info': JSON.stringify(new WebAppDonkeyAppInfo(this.applicationId)),
                'Access-Control-Allow-Origin': '*',
            },
        }
    }

    protected get bearerToken() {
        return this.cookies.get(ACCESS_TOKEN_KEY) || sessionStorage.getItem(ACCESS_TOKEN_KEY)
    }

    constructor() {
        super(axiosStatic.create(), config.apiUrl)
    }

    protected updateApplicationId(applicationId?: string) {
        if (applicationId) {
            localStorage.setItem(APPLICATION_ID_KEY, applicationId)
        } else {
            localStorage.removeItem(APPLICATION_ID_KEY)
        }
    }
}
