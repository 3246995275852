import { generateRandomId } from '../utils/StringUtils'

export abstract class LocalFile {
    readonly id = generateRandomId(5)

    constructor(public readonly file: File) {}

    public get name(): string {
        return this.file.name
    }

    public get source(): string {
        return URL.createObjectURL(this.file)
    }

    public get type(): string {
        return this.file.type
    }

    public static filterOnType = <T extends LocalFile>(
        items: any[],
        typeT: new (...params: any[]) => T
    ): T[] => {
        return items.filter((item) => item instanceof typeT)
    }
}
