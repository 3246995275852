import { ListItem as MuiListItem, ListItemButton, ListItemButtonProps, styled } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { grey_3, paperColor } from 'shared/lib/theme/Theme'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

type Properties = PropsWithChildren<{
    isRead?: boolean
    onClick?: ListItemButtonProps['onClick']
    sx?: SxProps<Theme>
}>

export const ListItem = styled(({ isRead, onClick, ...props }: Properties) =>
    onClick ? <ListItemButton onClick={onClick} {...props} /> : <MuiListItem {...props} />
)(({ theme, isRead }) => ({
    backgroundColor:
        isRead || isRead === undefined ? paperColor : `${theme.palette.primary.main}40`,
    padding: theme.spacing(1.5),
    borderRadius: 16,

    '&:hover': {
        backgroundColor:
            isRead === undefined ? undefined : isRead ? grey_3 : `${theme.palette.primary.main}60`,
    },
}))
