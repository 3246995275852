export class CreateGroupBody {
    constructor(
        readonly name: string,
        readonly description: string,
        readonly fullSizeImage: File | undefined,
        readonly thumbnailImage: File | undefined,
        readonly isStart: boolean,
        readonly isRestricted: boolean,
        readonly members: string[]
    ) {}

    toFormData(): FormData {
        const formData = new FormData()
        formData.append('name', this.name)
        formData.append('description', this.description)

        if (this.fullSizeImage) {
            formData.append('fullSizeImage', this.fullSizeImage)
        }

        if (this.thumbnailImage) {
            formData.append('thumbnailImage', this.thumbnailImage)
        }

        formData.append('isStart', this.isStart.toString())
        formData.append('isRestricted', this.isRestricted.toString())

        this.members.forEach((member) => formData.append('userIds[]{}', member))

        return formData
    }
}
