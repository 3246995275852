import { TextFieldProps } from '@mui/material'
import { Ref, forwardRef } from 'react'
import { grey_1 } from '../../theme/Theme'
import { TextField, TextFieldProperties } from './TextField'

export const TextFieldWithNoBorders = forwardRef(
    (
        {
            height,
            fontSize,
            lineHeight,
            textColor,
            backgroundColor,
            borderRadius,
            ...properties
        }: Omit<
            TextFieldProperties &
                TextFieldProps & {
                    height?: any
                    fontSize?: string
                    lineHeight?: number
                    textColor?: string
                    backgroundColor?: string
                    borderRadius?: any
                },
            'variant'
        >,
        ref: Ref<HTMLTextAreaElement>
    ) => {
        return (
            <TextField
                {...properties}
                ref={ref}
                variant="outlined"
                fullWidth={true}
                inputProps={{
                    style: {
                        minHeight: height,
                    },
                }}
                sx={{
                    '& input, & textarea': {
                        fontSize,
                        lineHeight,
                        backgroundColor: backgroundColor || 'white',
                        borderRadius,
                        alignContent: 'center',
                        padding: (theme) => (height ? theme.spacing(0, 2) : theme.spacing(1.5, 2)),
                        '&::placeholder': {
                            fontSize,
                            color: textColor || grey_1,
                            opacity: 1,
                        },
                    },

                    '& .MuiOutlinedInput-root, & .MuiFilledInput-root': {
                        background: backgroundColor || 'white',
                        padding: 0,

                        '& fieldset': {
                            borderColor: backgroundColor || 'white',
                        },
                        '&:hover fieldset': {
                            borderColor: backgroundColor || 'white',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: backgroundColor || 'white',
                        },
                    },

                    ...properties.sx,
                }}
            />
        )
    }
)
