import { LikesRow } from '../../posts/components/LikesRow'
import { SharePostButton } from '../../share/SharePostButton'
import { Box, BoxProps, styled } from '@mui/material'
import { LikeType } from '../../posts/likes/LikeType'
import { Post } from '../../posts/Post'

interface PostLikeAndShareRowViewProps {
    resetHistoryWhenOpeningLikesList?: boolean
    paddingLeft?: number
    post: Post
    onPostLiked: (like?: LikeType) => void
    onPostShared: (post) => void
}

export const PostLikeAndShareRowView = (properties: PostLikeAndShareRowViewProps) => {
    const hasLikesFromOthers =
        properties.post.likes?.mostRecentLikerNameOtherThanSignedInUser !== undefined

    return (
        <Container hasLikesFromOthers={hasLikesFromOthers}>
            <LikesRow
                paddingLeft={properties.paddingLeft}
                post={properties.post}
                onPostLiked={properties.onPostLiked}
                resetHistoryWhenOpeningLikesList={properties.resetHistoryWhenOpeningLikesList}
            />

            <SharePostButton post={properties.post} onPostShared={properties.onPostShared} />
        </Container>
    )
}

const Container = styled(
    ({ hasLikesFromOthers, ...props }: BoxProps & { hasLikesFromOthers: boolean }) => (
        <Box {...props} />
    )
)(({ hasLikesFromOthers, theme }) => ({
    display: 'flex',
    paddingTop: theme.spacing(1),
    gap: hasLikesFromOthers ? 2 : 1,
}))
