import { Avatar, AvatarProps } from '@mui/material'
import { ReactComponent as GroupIcon } from '../assets/svg/group.svg'
import { Ref, forwardRef } from 'react'

export const GroupAvatar = forwardRef(
    ({ children, ...props }: AvatarProps, ref: Ref<HTMLDivElement>) => {
        return (
            <Avatar ref={ref} {...props}>
                {children || <GroupIcon fill="white" />}
            </Avatar>
        )
    }
)
