import { Box } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DEFAULT_VALIDATION_TIMEOUT } from 'shared/lib/common/Constants'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { PasswordTextField } from 'shared/lib/components/textfields/PasswordTextField'
import { useTranslation } from 'shared/lib/i18n'
import vault from '../../assets/images/vault.jpg'
import ChurchContext from '../../churches/ChurchContextProvider'
import { Paths } from '../../routing/paths'
import * as PasswordUtils from '../../utils/PasswordUtils'
import SessionContext from '../SessionContextProvider'
import { SessionPage, Title } from '../SessionPage'

export const ConfirmChangePasswordPage = () => {
    const translations = useTranslation()
    const navigate = useNavigate()

    const { confirmChangePassword, getIsSignedIn } = useContext(SessionContext)!
    const { church } = useContext(ChurchContext)!

    const [searchParams] = useSearchParams()

    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [repeatedPassword, setRepeatedPassword] = useState('')
    const [passwordError, setPasswordError] = useState<string | undefined>()
    const [passwordConfirmationError, setPasswordConfirmationError] = useState<string | undefined>()
    const [error, setError] = useState<Error | undefined>()
    const emailAddressParam = searchParams.get('emailAddress') || undefined
    const emailAddress = emailAddressParam ? encodeURI(emailAddressParam) : undefined
    const confirmationCode = searchParams.get('confirmationCode') || undefined

    const isSignedIn = getIsSignedIn()

    const canChangePassword = emailAddress && confirmationCode
    const hasValidPassword = password.length && PasswordUtils.isPasswordValid(password)
    const hasValidPasswordConfirmation = password === repeatedPassword
    const canContinue =
        password.length > 0 &&
        repeatedPassword.length > 0 &&
        password === repeatedPassword &&
        hasValidPassword &&
        hasValidPasswordConfirmation

    const onPasswordChanged = (value: string) => {
        updatePasswordErrors(true)
        setPassword(value)
    }

    const onRepeatPasswordChanged = (value: string) => {
        updatePasswordErrors(true)
        setRepeatedPassword(value)
    }

    const updatePasswordErrors = (reset: boolean = false) => {
        if (password.length === 0 && repeatedPassword.length === 0) {
            return
        }

        setPasswordError(
            hasValidPassword || reset ? undefined : translations('choose_password_hint')
        )
        setPasswordConfirmationError(
            hasValidPasswordConfirmation || repeatedPassword === '' || reset
                ? undefined
                : translations('passwords_do_not_match')
        )
    }

    const onSubmitButtonClicked = () => {
        if (!canContinue || !emailAddress || !confirmationCode) {
            return
        }

        setIsLoading(true)

        confirmChangePassword({ emailAddress, password, confirmationCode })
            .catch(setError)
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (!isSignedIn && !canChangePassword) {
            navigate(Paths.SIGN_IN)
        }
    }, [isSignedIn, canChangePassword, navigate])

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            updatePasswordErrors(false)
        }, DEFAULT_VALIDATION_TIMEOUT)

        return () => {
            clearTimeout(timeOutId)
        }
    }, [password, repeatedPassword]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isSignedIn || !canChangePassword) {
        return <></>
    }

    return (
        <SessionPage backgroundImage={vault} church={church}>
            <Title variant="h1">{translations('choose_new_password_title')}</Title>

            <Box mt={4} />

            <PasswordTextField
                fullWidth={true}
                value={password}
                autoFocus={true}
                disabled={isLoading}
                label={translations('password')}
                placeholder={translations('choose_password_hint')}
                error={passwordError !== undefined}
                helperText={passwordError}
                onChanged={onPasswordChanged}
            />

            <Box mt={4} />

            <PasswordTextField
                fullWidth={true}
                value={repeatedPassword}
                disabled={isLoading}
                label={translations('repeat_password')}
                placeholder={translations('repeat_password')}
                error={passwordConfirmationError !== undefined}
                helperText={passwordConfirmationError}
                onChanged={onRepeatPasswordChanged}
                onEnterKeyPressed={onSubmitButtonClicked}
            />

            <Box mt={4} />

            <PrimaryContainedButton
                fullWidth={true}
                title={translations('signin')}
                isLoading={isLoading}
                disabled={!canContinue}
                onClick={onSubmitButtonClicked}
            />

            <ErrorHandler error={error} horizontal="left" translations={translations} />
        </SessionPage>
    )
}
