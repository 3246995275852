import { createContext, PropsWithChildren } from 'react'
import { User } from './User'
import { useUsers } from './hooks'

export const UsersContext = createContext<{
    isLoading: boolean
    approvedUsers: User[]
}>({ isLoading: false, approvedUsers: [] })

export const UsersContextProvider = ({ children }: PropsWithChildren) => {
    const { users: approvedUsers, isLoading } = useUsers({
        isApproved: true,
    })

    return (
        <UsersContext.Provider
            value={{
                isLoading,
                approvedUsers,
            }}
        >
            {children}
        </UsersContext.Provider>
    )
}
