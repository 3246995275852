import { Button, CircularProgress } from '@mui/material'
import { ButtonProps } from '@mui/material/Button'
import React, { PropsWithChildren } from 'react'
import { defaultButtonHeight, defaultTransition } from '../../theme/Theme'

export interface ButtonProperties extends ButtonProps {
    isVisible?: boolean
    isLoading?: boolean
    title?: string
    innerRef?: React.Ref<HTMLButtonElement>
    enablePointerEventsEvenWhenDisabled?: boolean
}

export interface IconButtonProperties {
    buttonSize?: 'small' | 'medium'
    iconSize?: 'inherit' | 'default' | 'small' | 'large'
    color?: string
    opacity?: number
    disabled?: boolean
    edge?: 'start' | 'end' | false
    onClick: (event) => void
}

export const DefaultButton = ({
    children,
    title,
    isVisible,
    isLoading,
    innerRef,
    enablePointerEventsEvenWhenDisabled,
    sx,
    ...properties
}: PropsWithChildren<ButtonProperties>) => {
    const content = isLoading ? (
        <CircularProgress sx={{ color: 'white' }} thickness={6} size={24} />
    ) : (
        children || title
    )

    if (isLoading) {
        delete properties.onClick
    }

    const muiDisabled = sx && sx['&.Mui-disabled'] ? sx['&.Mui-disabled'] : {}

    return (
        <Button
            style={isVisible === undefined || isVisible ? {} : { display: 'none' }}
            component="div"
            disableElevation={true}
            ref={innerRef}
            {...properties}
            sx={{
                ...sx,
                ...{
                    '&.Mui-disabled': {
                        ...muiDisabled,
                        pointerEvents: enablePointerEventsEvenWhenDisabled ? 'all' : 'none',
                    },
                },
                ...(isLoading
                    ? {
                          transition: defaultTransition,
                          width: defaultButtonHeight,
                          minWidth: defaultButtonHeight,
                          padding: 0,
                      }
                    : {}),
                pointerEvents: isLoading ? 'none' : 'inherit',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }}
        >
            {content}
        </Button>
    )
}
