import { ReactComponent as PlusIcon } from '../../assets/svg/plus_outlined.svg'
import { Box, BoxProps, styled } from '@mui/material'
import path from 'path'
import { useState } from 'react'
import { getImageSize } from 'react-image-size'
import { WithTranslations } from '../../WithTranslations'
import { LocalFile } from '../../forms/LocalFile'
import { LocalImage } from '../../forms/LocalImage'
import { LocalVideo } from '../../forms/LocalVideo'
import { darkGrey, errorColor, grey_2, grey_3 } from '../../theme/Theme'
import { generateVideoThumbnail } from '../../utils/VideoUtil'
import { ImageHelper } from '../../images/ImageHelper'
import { MediaItemType } from '../../models/MediaItem'
import { MAX_UPLOAD_SIZE_BYTES } from '../../common/Constants'
import { generatePdfThumbnail } from '../../utils/PdfUtil'
import { LocalPdf } from '../../forms/LocalPdf'
import { getPathFileNameWithoutExtension } from '../../utils/StringUtils'
import { PopupModal } from '../PopupModal'
import { ReactComponent as AlertIcon } from '../../assets/svg/alert.svg'
import { ReactComponent as CloseIconSVG } from '../../assets/svg/close.svg'
import IconButton from '@mui/material/IconButton'

interface Properties {
    isEnabled?: boolean
    canSelectMultipleFiles?: boolean
    acceptedFiles?: MediaItemType[]
    onFilesSelected: (files: LocalFile[]) => void
    button?: React.ReactNode
    showErrorPopup?: boolean
    verticalPositionPopup?: 'top' | 'center' | 'bottom'
    horizontalPositionPopup?: 'left' | 'center' | 'right'
    sizeFromContainingItems?: number
    onSelectedFilesTooLarge?: () => void
}

export const AddAttachmentButton = (properties: WithTranslations<Properties>) => {
    const translation = properties.translations

    const [uploadFailedError, setUploadFailedError] = useState<string | undefined>(undefined)

    const isEnabled = properties.isEnabled === undefined ? true : properties.isEnabled
    const canSelectMultipleFiles = properties.canSelectMultipleFiles ?? true
    const acceptedFiles = properties.acceptedFiles || [
        MediaItemType.IMAGE,
        MediaItemType.VIDEO,
        MediaItemType.PDF,
    ]
    const id = `input_${acceptedFiles.join('_')}_${Math.random()}`

    const acceptedTypes = () => {
        const types: string[] = []
        if (acceptedFiles.includes(MediaItemType.IMAGE)) {
            types.push('image/*')
        }

        if (acceptedFiles.includes(MediaItemType.VIDEO)) {
            types.push('video/*')
        }

        if (acceptedFiles.includes(MediaItemType.PDF)) {
            types.push('application/pdf')
        }

        return types.join()
    }

    const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const element = event.target as HTMLInputElement
        element.value = ''
    }

    const onFileSelected = async (fileList: FileList | null) => {
        if (fileList === null) {
            return
        }

        const files: LocalFile[] = []

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i)

            if (file === null) {
                continue
            }

            if (
                file.size + (properties.sizeFromContainingItems ?? 0) > MAX_UPLOAD_SIZE_BYTES &&
                properties.showErrorPopup
            ) {
                setUploadFailedError(translation?.('file_too_large_message') ?? 'File too large')
                properties.onSelectedFilesTooLarge?.()
                break
            }

            if (file.type.includes('image/')) {
                const { width, height } = await getImageSize(URL.createObjectURL(file))
                files.push(new LocalImage(file, width, height))
            } else if (file.type.includes('video/')) {
                const thumbnailBlob = await generateVideoThumbnail(file)
                const thumbnail = await ImageHelper.compressImageToThumbnail({
                    blob: thumbnailBlob,
                    fileName: path.basename(`${getPathFileNameWithoutExtension(file.name)}.jpeg`),
                })
                files.push(new LocalVideo(file, thumbnail))
            } else if (file.type === 'application/pdf') {
                const thumbnailBlob = await generatePdfThumbnail(file)
                const thumbnail = await ImageHelper.compressImageToThumbnail({
                    blob: thumbnailBlob,
                    fileName: path.basename(`${getPathFileNameWithoutExtension(file.name)}.jpeg`),
                })
                files.push(new LocalPdf(file, thumbnail))
            }
        }

        properties.onFilesSelected(files)
    }

    return (
        <>
            <input
                multiple={canSelectMultipleFiles}
                disabled={!isEnabled}
                id={id}
                accept={acceptedTypes()}
                type="file"
                onClick={onInputClick}
                onChange={(event) => onFileSelected(event.target.files)}
                style={{ display: 'none' }}
            />
            <label htmlFor={id}>
                {properties.button ? (
                    properties.button
                ) : (
                    <AttachmentsButton isEnabled={isEnabled}>
                        <PlusIcon fill={isEnabled ? darkGrey : grey_2} />
                    </AttachmentsButton>
                )}
            </label>

            {properties.showErrorPopup && (
                <PopupModal
                    message={uploadFailedError}
                    vertical={properties.verticalPositionPopup}
                    horizontal={properties.horizontalPositionPopup}
                    onClose={() => setUploadFailedError(undefined)}
                    icon={<AlertIcon />}
                    iconColor={errorColor}
                    duration={null}
                    closeButton={
                        <IconButton
                            sx={{ pointerEvents: 'auto' }}
                            onClick={() => setUploadFailedError(undefined)}
                        >
                            <CloseIcon />
                        </IconButton>
                    }
                />
            )}
        </>
    )
}

const AttachmentsButton = styled(
    ({ isEnabled, ...properties }: BoxProps & { isEnabled: boolean }) => <Box {...properties} />
)(({ isEnabled }) => ({
    backgroundColor: grey_3,
    display: 'flex',
    width: '56px',
    height: '56px',
    borderRadius: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    cursor: isEnabled ? 'pointer' : '',
}))

const CloseIcon = styled(CloseIconSVG)(() => ({
    fill: `${darkGrey} !important`,
}))
