import { useTranslation } from 'shared/lib/i18n'
import { churchRepository } from '../../index'
import { MessageDetails } from '../../posts/MessageDetails'
import { MessageView as SharedMessageView } from 'shared/lib/components/message/MessageView'
import { useDetailView } from '../../common/detailView/hooks'
import React from 'react'

interface Properties {
    details: MessageDetails
    mx?: number
    mt?: number
    backgroundColor?: string
    linesToShowWhenCollapsed?: number
}

export const MessageView = ({ details, ...properties }: Properties) => {
    const translations = useTranslation()

    const church = churchRepository.church
    const message = church ? details.getMessage(church, translations) : ''
    const hasMarkdown = details.hasMarkDown
    const { openUserDetailView } = useDetailView()

    const onClickInMessage = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (!(event.target instanceof HTMLAnchorElement)) {
            return
        }

        const shareUserFinds = [
            ...(event.target as HTMLAnchorElement).href.matchAll(
                /https:\/\/.*donkeymobile\.com\/share\/app\.donkeymobile\..+\?userId=([a-z0-9]+)/g
            ),
        ]

        if (!shareUserFinds.length) {
            return
        }

        const taggedUserId = shareUserFinds[0][1]

        event.preventDefault()
        event.stopPropagation()

        openUserDetailView(taggedUserId)
    }

    return (
        <SharedMessageView
            details={{
                id: details.id,
                hasMarkDown: hasMarkdown,
                message: message,
            }}
            mx={properties.mx}
            mt={properties.mt}
            backgroundColor={properties.backgroundColor}
            linesToShowWhenCollapsed={properties.linesToShowWhenCollapsed}
            translations={{
                readLess: translations('read_less'),
                readMore: translations('read_more'),
            }}
            onClick={onClickInMessage}
        />
    )
}
