import { Box, styled } from '@mui/material'
import { User } from '../../../user/User'
import { useHorizontalScroll } from '../../hooks/scrolling'
import { SelectedMemberCell } from './SelectedMemberCell'

interface Properties {
    users: User[]
    onUserSelected: (user: User) => void
}

export const SelectedMemberList = ({ users, onUserSelected }: Properties) => {
    const scroll = useHorizontalScroll<HTMLDivElement>()

    return (
        <ListContainer ref={scroll}>
            {users.map((user) => (
                <SelectedMemberCell key={user.id} user={user} onUserSelected={onUserSelected} />
            ))}
        </ListContainer>
    )
}

const ListContainer = styled(Box)(({ theme }) => ({
    height: '67px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
    overflowY: 'hidden',
}))
