import { Box, Paper, styled } from '@mui/material'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ElevateOnScroll } from 'shared/lib/components/ElevateOnScroll'
import { User } from '../../User'
import { groupByAlphabeticKey } from '../../Utils'
import { useUsers } from '../../hooks'
import { NoResults } from '../NoResults'
import { UserListSearchField } from '../UserListSearchField'
import { MOBILE_USER_LIST_ID, MobileUserList } from './MobileUserList'
import {
    MobileAppAccessRequestsViewModel,
    MobileUserItemViewModel,
    MobileUserListViewModel,
    MobileUserSectionViewModel,
} from './MobileUserViewModels'

interface Properties {
    users: User[]
    query: string
    hasNoResults: boolean
    isLoading: boolean

    onUserSelected(userId: string): void

    setQuery(query: string): void
}

export const MobileUserListView = ({
    users,
    query,
    hasNoResults,
    isLoading,
    onUserSelected,
    setQuery,
}: Properties) => {
    const { users: unapprovedUsers } = useUsers({ isApproved: false })

    const groupedUsers = groupByAlphabeticKey(users)
    const viewModels = Object.keys(groupedUsers).reduce((acc, key) => {
        if (!groupedUsers[key].length) {
            return [...acc]
        }

        return [
            ...acc,
            new MobileUserSectionViewModel(key),
            ...groupedUsers[key].map((user) => new MobileUserItemViewModel(user)),
        ]
    }, [] as MobileUserListViewModel[])

    if (unapprovedUsers.length) {
        viewModels.unshift(new MobileAppAccessRequestsViewModel(unapprovedUsers.length))
    }

    return (
        <Container>
            <ElevateOnScroll targetId={MOBILE_USER_LIST_ID}>
                <Paper sx={{ zIndex: 1, backgroundColor: 'transparent', padding: 1 }}>
                    <UserListSearchField
                        value={query}
                        disabled={isLoading}
                        onChanged={setQuery}
                        width="100%"
                    />
                </Paper>
            </ElevateOnScroll>

            <ListContainer>
                <AutoSizer disableWidth={true}>
                    {({ height }) => (
                        <MobileUserList
                            viewModels={viewModels}
                            height={height}
                            isLoading={isLoading}
                            onUserSelected={onUserSelected}
                        />
                    )}
                </AutoSizer>
            </ListContainer>
            {hasNoResults && (
                <Box flexGrow={4}>
                    <NoResults />
                </Box>
            )}
        </Container>
    )
}

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
}))

const ListContainer = styled(Box)(() => ({
    height: '100%',
    flex: 1,
}))
