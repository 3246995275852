import { SessionRepository } from '../authentication/SessionRepository'
import { WebAppHttpRepository } from '../common/WebAppHttpRepository'
import { Notification } from './Notification'
import { DateTime } from 'luxon'
import { NotificationType } from './NotificationType'
import { UnreadNotification } from './UnreadNotification'
import _ from 'lodash'
import { NotificationsMarkedAsRead } from './events'

const READ_NOTIFICATIONS_KEY = 'read_notifications'

export class NotificationRepository extends WebAppHttpRepository {
    private unreadNotifications: UnreadNotification[] = []
    private locallyReadNotifications: UnreadNotification[] = []

    get hasUnreadNotifications(): boolean {
        return (
            _.difference(
                this.unreadNotifications.map((notification) => notification.id),
                this.locallyReadNotifications.map((notification) => notification.id)
            ).length > 0
        )
    }

    constructor(private readonly sessionRepository: SessionRepository) {
        super()

        const readNotifications = JSON.parse(localStorage.getItem(READ_NOTIFICATIONS_KEY) || '[]')
        this.locallyReadNotifications = readNotifications.map(UnreadNotification.fromJSON)
    }

    async getNotifications({
        from,
        abortSignal,
    }: { from?: string; abortSignal?: AbortSignal } = {}): Promise<{
        nextFrom?: string
        notifications: Notification[]
    }> {
        const querystring = new URLSearchParams()

        Object.values(NotificationType).forEach((type) =>
            querystring.append('notificationTypes[]', type)
        )

        if (from) querystring.append('from', from)

        const response = await this.sessionRepository.withAccessToken(async () =>
            this.get<{
                nextFrom?: string
                notifications: Record<string, any>[]
            }>(`${this.apiUrl}/api/v2/notifications?${querystring}`, abortSignal)
        )

        return {
            nextFrom: response.nextFrom,
            notifications: response.notifications.map(Notification.fromJSON),
        }
    }

    async loadUnreadNotifications(): Promise<void> {
        try {
            const response = await this.sessionRepository.withAccessToken(() =>
                this.get<Record<string, any>>(`${this.apiUrl}/api/v1/notifications/unread`)
            )

            this.unreadNotifications = response.map(UnreadNotification.fromJSON)
        } catch (error) {}
    }

    async markAsReadFromNotificationCenter(): Promise<void> {
        this.locallyReadNotifications = this.unreadNotifications
        localStorage.setItem(READ_NOTIFICATIONS_KEY, JSON.stringify(this.unreadNotifications))

        try {
            await this.sessionRepository.withAccessToken(() =>
                this.patch<void>(`${this.apiUrl}/api/v1/notifications/markasread`, {
                    isFromNotificationCenter: true,
                })
            )
        } catch (error) {}
    }

    async markNotificationAsRead(notificationId: string): Promise<void> {
        try {
            this.markUnreadNotificationsAsRead([notificationId])
            await this.sessionRepository.withAccessToken(() =>
                this.patch<void>(`${this.apiUrl}/api/v1/notifications/markasread`, {
                    isFromNotificationCenter: false,
                    notificationIds: [notificationId],
                })
            )
        } catch (error) {}
    }

    async markAllGroupNotificationsAsRead(groupId: string): Promise<void> {
        const types = [
            NotificationType.USER_ADDED_TO_GROUP,
            NotificationType.USER_REQUESTED_ACCESS_TO_GROUP,
            NotificationType.USER_ACCESS_GRANTED_TO_GROUP,
            NotificationType.USER_ACCESS_DENIED_TO_GROUP,
        ]

        const body = types.map((type) => ({
            groupId,
            type,
        }))

        await this.markNotificationsAsRead(body)
    }

    async markNotificationsAsRead(
        items: { type: NotificationType; groupId: string; fromDate?: DateTime }[]
    ): Promise<void> {
        const notificationBodies = items.map((item) => ({
            type: item.type,
            groupId: item.groupId,
            from: item.fromDate,
        }))
        const notificationIds = items.flatMap((item) =>
            this.unreadNotifications
                .filter(
                    (notification) =>
                        notification.groupId === item.groupId && notification.type === item.type
                )
                .map((notification) => notification.id)
        )
        this.markUnreadNotificationsAsRead(notificationIds)

        try {
            await this.sessionRepository.withAccessToken(() =>
                this.patch<void>(`${this.apiUrl}/api/v1/notifications/markasread`, {
                    isFromNotificationCenter: false,
                    notifications: notificationBodies,
                })
            )
        } catch (error) {}
    }

    markUnreadAddedToGroupOrAccessGrantedToGroupNotificationsAsRead(groupId: String) {
        const types = [
            NotificationType.USER_ADDED_TO_GROUP,
            NotificationType.USER_ACCESS_GRANTED_TO_GROUP,
        ]
        const notificationIds = types
            .map((type) => {
                return this.unreadNotifications.find(
                    (notification) => notification.groupId === groupId && notification.type === type
                )?.id
            })
            .filter(Boolean) as string[]

        this.markUnreadNotificationsAsRead(notificationIds)
    }

    clearData() {
        this.locallyReadNotifications = []
        this.unreadNotifications = []
        localStorage.removeItem(READ_NOTIFICATIONS_KEY)
    }

    private markUnreadNotificationsAsRead(notificationIds: string[]) {
        if (notificationIds.length === 0) return

        this.unreadNotifications = this.unreadNotifications.filter(
            (unreadNotification) => !notificationIds.includes(unreadNotification.id)
        )

        this.locallyReadNotifications = this.unreadNotifications

        localStorage.setItem(READ_NOTIFICATIONS_KEY, JSON.stringify(this.locallyReadNotifications))

        document.dispatchEvent(new NotificationsMarkedAsRead())
    }
}
