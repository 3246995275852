import { GroupMemberInfo } from './GroupMember'
import { MinimalUserInfo } from 'shared/lib/users/MinimalUserInfo'

export interface GroupMemberListViewModel {}

export class GroupMemberListItemViewModel implements GroupMemberListViewModel, MinimalUserInfo {
    constructor(public readonly groupMember: GroupMemberInfo) {}

    get id() {
        return this.groupMember.id
    }

    get isAdmin() {
        return this.groupMember.isAdmin
    }

    get firstName() {
        return this.groupMember.firstName
    }

    get lastName() {
        return this.groupMember.lastName
    }

    get image() {
        return this.groupMember.image
    }

    get functions() {
        return this.groupMember.functions
    }
}
export class GroupMemberIsLoadingViewModel implements GroupMemberListViewModel {}
export class GroupMemberListSeeAllViewModel implements GroupMemberListViewModel {}
export class GroupMemberListAddGroupMembersViewModel implements GroupMemberListViewModel {}
export class GroupMemberListFooterViewModel implements GroupMemberListViewModel {}
