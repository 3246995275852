import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemButton as MuiListItemButton,
    ListItemIcon as MuiListItemIcon,
    ListItemText,
    styled,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as SignOutIcon } from 'shared/lib/assets/svg/logout.svg'
import { MenuPopup } from 'shared/lib/components/MenuPopup'
import { useIsTabletOrBigger } from 'shared/lib/theme/BreakPointHooks'
import { darkGrey } from 'shared/lib/theme/Theme'
import { ReactComponent as Profile } from '../../assets/svg/profile.svg'
import { ReactComponent as Settings } from '../../assets/svg/settings.svg'
import { SignedInUser } from '../../authentication/models/SignedInUser'
import { fileRepository, sessionRepository } from '../../index'
import { Paths } from '../../routing/paths'
import { useTranslation } from 'shared/lib/i18n'
import { getFullName } from 'shared/lib/utils/StringUtils'

interface ProfileNavigationMenuProperties {
    user: SignedInUser
}

export const ProfileButton = ({ user }: ProfileNavigationMenuProperties) => {
    const translations = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const isTabletOrBigger = useIsTabletOrBigger()

    const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null)

    const toggleProfileMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (menuAnchor === null) {
            return setMenuAnchor(event.currentTarget)
        }
        setMenuAnchor(null)
    }

    const navigateTo = (path: string) => {
        setMenuAnchor(null)
        navigate(path, {
            state: {
                from: location.pathname,
            },
        })
    }

    return (
        <>
            <IconButton onClick={toggleProfileMenu}>
                <ProfileImage src={fileRepository.getImageUrl(user?.image)} />
            </IconButton>

            <MenuPopup
                anchor={menuAnchor}
                popupMarginTop={{
                    desktop: '32px',
                    tablet: '32px',
                    mobile: '16px',
                }}
                arrowRightPosition={{
                    mobile: '20px',
                }}
                onPopupClosed={() => setMenuAnchor(null)}
            >
                <PopoverContent>
                    <PopoverUserProfile>
                        <PopoverAvatar src={fileRepository.getImageUrl(user?.image)} />
                        <Box ml={1} minWidth={0}>
                            <Typography noWrap fontWeight={700}>
                                {getFullName(user)}
                            </Typography>
                            <Typography noWrap>{user.emailAddress}</Typography>
                        </Box>
                    </PopoverUserProfile>

                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigateTo(Paths.PROFILE)}>
                                <ListItemIcon>
                                    <Profile />
                                </ListItemIcon>
                                <ListItemText primary={translations('page_user_profile')} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigateTo(Paths.SETTINGS)}>
                                <ListItemIcon>
                                    <Settings />
                                </ListItemIcon>
                                <ListItemText primary={translations('settings')} />
                            </ListItemButton>
                        </ListItem>
                        {!isTabletOrBigger && (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => sessionRepository.signOut()}>
                                    <ListItemIcon>
                                        <SignOutIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={translations('sign_out')} />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </PopoverContent>
            </MenuPopup>
        </>
    )
}

const ProfileImage = styled(Avatar)(() => ({
    width: 32,
    height: 32,
}))

const PopoverContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    backgroundColor: 'white',
    borderRadius: '16px',
    maxWidth: '280px',
}))

const PopoverUserProfile = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
}))

const PopoverAvatar = styled(Avatar)(() => ({
    alignSelf: 'center',
    width: '48px',
    height: '48px',
}))

const ListItemButton = styled(MuiListItemButton)(() => ({
    borderRadius: '16px',
}))

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
    minWidth: 'unset',
    marginRight: theme.spacing(2),
    '& svg': {
        height: '20px',
        width: '20px',
        fill: darkGrey,
    },
}))
