import { DateTime } from 'luxon'
import { Frequency, toFrequencyLongString } from './Frequency'
import { MonthlyOption, toMonthlyOptionStringValue } from './MonthlyOption'
import { toLocalisedWeekdayFullString, toLocalisedWeekdayShortString, Weekday } from './Weekday'
import { TranslationFn } from '../WithTranslations'

export class RecurrenceRule {
    constructor(
        readonly start: DateTime = DateTime.now(),
        readonly interval: number = 1,
        readonly frequency: Frequency = Frequency.WEEKLY,
        readonly weekdays: Set<Weekday> | undefined = undefined,
        readonly monthlyOption: MonthlyOption | undefined = undefined,
        readonly count: number | undefined = undefined,
        readonly until: DateTime | undefined = undefined
    ) {}

    get isCustom(): boolean {
        return (
            this.interval > 1 ||
            this.weekdays !== undefined ||
            this.monthlyOption !== undefined ||
            this.count !== undefined ||
            this.until !== undefined
        )
    }

    get hasEnd(): boolean {
        return this.count !== undefined || this.until !== undefined
    }

    toString({ locale, translations }: { locale: string; translations: TranslationFn }): string {
        if (!this.isCustom) {
            return toFrequencyLongString({ frequency: this.frequency, translations })
        }

        const everyString = `${translations('every')} ${this.interval}`

        if (this.frequency === Frequency.DAILY) {
            if (this.interval === 1) {
                return translations('every_day')
            }
            return `${everyString} ${translations('plural_days', [this.interval], this.interval)}`
        }

        if (this.frequency === Frequency.WEEKLY) {
            const intervalString =
                this.interval === 1
                    ? translations('every_week')
                    : `${everyString} ${translations('plural_weeks', [this.interval], this.interval)}`

            const weekdaysString = this.weekdays
                ? this.weekdays.size === 1
                    ? toLocalisedWeekdayFullString({ day: [...this.weekdays][0], locale })
                    : Array.from(this.weekdays)
                          .sort(
                              (a, b) =>
                                  Object.values(Weekday).indexOf(a) -
                                  Object.values(Weekday).indexOf(b)
                          )
                          .map((weekday) => toLocalisedWeekdayShortString({ day: weekday, locale }))
                          .join(', ')
                : null

            const lastPart = weekdaysString
                ? ` ${translations('on').toLowerCase()} ${weekdaysString}`
                : ''

            return `${intervalString}${lastPart}`
        }

        if (this.frequency === Frequency.MONTHLY) {
            const everyMonthString =
                this.interval === 1
                    ? translations('every_month')
                    : `${everyString} ${translations('plural_months', [this.interval], this.interval)}`

            const onTheString = translations('on_the')
            const monthlyString =
                !this.monthlyOption || this.monthlyOption === MonthlyOption.CURRENT_DAY
                    ? null
                    : toMonthlyOptionStringValue({
                          option: this.monthlyOption,
                          date: this.start,
                          translations,
                      })

            const dayOfWeekString = this.start.toFormat('EEEE', { locale })

            const lastPart = monthlyString
                ? ` ${onTheString} ${monthlyString} ${dayOfWeekString}`
                : ''

            return `${everyMonthString}${lastPart}`
        }

        if (this.frequency === Frequency.YEARLY) {
            if (this.interval === 1) {
                return translations('every_year')
            }
            const yearsString = translations('plural_years', [this.interval], this.interval)
            return `${everyString} ${yearsString}`
        }

        return ''
    }

    toEndString({
        shortStyle,
        locale,
        translations,
    }: {
        shortStyle: boolean
        locale: string
        translations: TranslationFn
    }): string {
        const endsString = `${translations('ends')}`

        if (this.count !== undefined) {
            return `${endsString} ${translations('after').toLowerCase()} ${this.count} ${translations('plural_occurrences', [], this.count)}`
        }

        if (this.until !== undefined) {
            const dateText = shortStyle
                ? this.until.toFormat('dd-MM-yyyy', { locale })
                : this.until.toFormat('dd MMM yyyy', { locale })
            return `${endsString} ${translations('on').toLowerCase()} ${dateText}`
        }

        return ''
    }

    copy(updates: Partial<RecurrenceRule>): RecurrenceRule {
        return new RecurrenceRule(
            updates.start ?? this.start,
            updates.interval ?? this.interval,
            updates.frequency ?? this.frequency,
            updates.weekdays ?? this.weekdays,
            updates.monthlyOption ?? this.monthlyOption,
            updates.count ?? this.count,
            updates.until ?? this.until
        )
    }
}
