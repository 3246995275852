import { groupRepository, notificationRepository } from '../../index'
import { NotificationType } from '../../notifications/NotificationType'

export const useApproveGroupMember = () => {
    const approveGroupMember = async (params: { groupId: string; userId: string }) => {
        await groupRepository.approveGroupMember(params)
        await notificationRepository.markNotificationsAsRead([
            { type: NotificationType.USER_REQUESTED_ACCESS_TO_GROUP, groupId: params.groupId },
        ])
    }

    return { approveGroupMember }
}

export const useDenyGroupMember = () => {
    const denyGroupMember = async (params: { groupId: string; userId: string }) => {
        await groupRepository.denyGroupMember(params)
        await notificationRepository.markNotificationsAsRead([
            { type: NotificationType.USER_REQUESTED_ACCESS_TO_GROUP, groupId: params.groupId },
        ])
    }

    return { denyGroupMember }
}
