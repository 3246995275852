import { Notification } from '../Notification'
import {
    NotificationsListFooterViewModel,
    NotificationsListGroupHeaderViewModel,
    NotificationsListItemLoadingViewModel,
    NotificationsListItemViewModel,
    NotificationsListViewModel,
    NotificationTimeSpan,
} from './NotificationsListViewModels'
import { DateTime } from 'luxon'
import { getKeys } from '../../utils/Misc'

export class NotificationsListViewModelBuilder {
    static buildViewModels(
        notifications: Notification[],
        moreNotificationsAvailable: boolean
    ): NotificationsListViewModel[] {
        const notificationsByTimeSpan = this.getNotificationsByTimeSpan(notifications)

        const viewModels = getKeys(notificationsByTimeSpan).reduce((acc, timeSpan) => {
            return [
                ...acc,
                new NotificationsListGroupHeaderViewModel(timeSpan as NotificationTimeSpan),
                ...notificationsByTimeSpan[timeSpan].map((notification, index) => {
                    const previousNotification = notificationsByTimeSpan[timeSpan][index - 1]
                    const nextNotification = notificationsByTimeSpan[timeSpan][index + 1]

                    return new NotificationsListItemViewModel(
                        notification,
                        previousNotification?.isRead === false,
                        nextNotification?.isRead === false
                    )
                }),
            ]
        }, [] as NotificationsListViewModel[])

        if (moreNotificationsAvailable) {
            viewModels.push(new NotificationsListItemLoadingViewModel(true))
        }

        viewModels.push(new NotificationsListFooterViewModel())

        return viewModels
    }

    private static getNotificationsByTimeSpan(
        notifications: Notification[]
    ): Record<NotificationTimeSpan, Notification[]> {
        const today = DateTime.now()
        const dateWeekBack = today.minus({ days: 6 }).startOf('day')
        const dateMonthBack = today.minus({ month: 1 }).startOf('day')

        const sections: Record<NotificationTimeSpan, Notification[]> = {} as Record<
            NotificationTimeSpan,
            Notification[]
        >

        const notificationsCreatedToday = notifications.filter((notification) =>
            notification.createdAt.hasSame(today, 'day')
        )
        const notificationsOlderThanToday = notifications.filter(
            (notification) => !notification.createdAt.hasSame(today, 'day')
        )
        const notificationsCreatedThisWeek = notifications.filter(
            (notification) => notification.createdAt >= dateWeekBack
        )
        const notificationsOlderThanThisWeek = notifications.filter(
            (notification) => notification.createdAt < dateWeekBack
        )
        const notificationsCreatedThisMonth = notifications.filter(
            (notification) => notification.createdAt >= dateMonthBack
        )
        const notificationsOlderThanThisMonth = notifications.filter(
            (notification) => notification.createdAt < dateMonthBack
        )
        const notificationsCountCreatedToday = notificationsCreatedToday.length
        const notificationsCountCreatedThisWeek = notificationsCreatedThisWeek.length
        const notificationsCountCreatedThisMonth = notificationsCreatedThisMonth.length

        if (notificationsCountCreatedToday === 1 && notifications.length === 1) {
            sections[NotificationTimeSpan.TODAY] = notificationsCreatedToday
        } else if (notificationsCountCreatedThisWeek === 1 && notifications.length === 1) {
            sections[NotificationTimeSpan.THIS_WEEK] = notificationsCreatedThisWeek
        } else if (notificationsCountCreatedThisMonth === 1 && notifications.length === 1) {
            sections[NotificationTimeSpan.THIS_MONTH] = notificationsCreatedThisMonth
        } else if (notificationsCountCreatedToday >= 2) {
            sections[NotificationTimeSpan.TODAY] = notificationsCreatedToday
        } else if (notificationsCountCreatedThisWeek >= 2) {
            sections[NotificationTimeSpan.THIS_WEEK] = notificationsCreatedThisWeek
        } else if (notificationsCountCreatedThisMonth >= 2) {
            sections[NotificationTimeSpan.THIS_MONTH] = notificationsCreatedThisMonth
        } else if (notifications.length > 0) {
            sections[NotificationTimeSpan.EARLIER] = notifications
        }

        if (notificationsCountCreatedToday >= 2 && notificationsOlderThanToday.length > 0) {
            sections[NotificationTimeSpan.EARLIER] = notificationsOlderThanToday
        } else if (
            notificationsCountCreatedThisWeek >= 2 &&
            notificationsOlderThanThisWeek.length > 0
        ) {
            sections[NotificationTimeSpan.EARLIER] = notificationsOlderThanThisWeek
        } else if (
            notificationsCountCreatedThisMonth >= 2 &&
            notificationsOlderThanThisMonth.length > 0
        ) {
            sections[NotificationTimeSpan.EARLIER] = notificationsOlderThanThisMonth
        }

        return sections
    }
}
