import { PropsWithChildren } from 'react'
import { darkGrey } from '../../theme/Theme'
import { grey_2, grey_3, textColor } from '../../theme/constants'
import { ButtonProperties, DefaultButton } from './DefaultButton'

interface RoundedButtonProperties extends ButtonProperties {
    isSelected?: boolean
}

export const RoundedFilledButton = ({
    isSelected,
    ...properties
}: PropsWithChildren<RoundedButtonProperties>) => (
    <DefaultButton
        disableRipple={true}
        sx={{
            borderRadius: '100%',
            width: '40px',
            height: '40px',
            minWidth: 'auto',
            color: isSelected ? 'white' : darkGrey,
            backgroundColor: (theme) => (isSelected ? theme.palette.primary.main : grey_3),
            fontSize: '14px',
            fontWeight: 400,

            '&:hover': {
                color: isSelected ? 'white' : textColor,
                backgroundColor: (theme) => (isSelected ? theme.palette.primary.main : grey_2),
            },
        }}
        {...properties}
    />
)
