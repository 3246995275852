import { Badge as MuiBadge, styled } from '@mui/material'
import React from 'react'
import { Image } from 'shared/lib/models/Image'
import { fileRepository } from '../index'
import { GroupAvatar } from './GroupAvatar'

interface Properties {
    verticalPosition: 'top' | 'bottom'
    number?: number
    image?: Image
}

const Badge = ({ verticalPosition, number, image, ...badgeSpecificProps }: Properties) => {
    return (
        <MuiBadge
            overlap="circular"
            anchorOrigin={{ vertical: verticalPosition, horizontal: 'right' }}
            color="primary"
            badgeContent={number}
            {...badgeSpecificProps}
        >
            <GroupAvatar src={fileRepository.getThumbnailUrl(image)} />
        </MuiBadge>
    )
}

export const GroupBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
        color: 'white',
        fontSize: 12,
        width: 24,
        height: 24,
        borderRadius: 12.5,
    },
}))
