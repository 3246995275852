import { Box, styled, Typography } from '@mui/material'
import { memo, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { getImageSize } from 'react-image-size'
import { ReactComponent as UploadIcon } from 'shared/lib/assets/svg/boxed-arrow.svg'
import { LocalImage } from 'shared/lib/forms/LocalImage'
import { Image } from 'shared/lib/models/Image'
import { grey_1, grey_2 } from 'shared/lib/theme/Theme'
import { fileRepository } from '../..'
import { useTranslation } from 'shared/lib/i18n'

interface Properties {
    id: string
    isEnabled?: boolean
    image?: Image
    imageFile?: LocalImage
    onImageChanged: (image: LocalImage) => void
}

const Component = (properties: Properties) => {
    const translations = useTranslation()

    const [error, setError] = useState<string | undefined>(undefined)

    const onFileSelected = async (file: any) => {
        setError(undefined)

        if (!file) return

        const source = URL.createObjectURL(file)
        const { width, height } = await getImageSize(source)

        properties.onImageChanged(new LocalImage(file, width, height))
    }

    return (
        <Container>
            <FileUploader
                handleChange={onFileSelected}
                name={`dropimagezone-fileuploader-${properties.id}`}
                types={['JPG', 'JPEG', 'PNG']}
                disabled={properties.isEnabled === false}
                onTypeError={() => setError(translations('invalid_file_type'))}
            >
                <FileUploaderContainer>
                    <UploadContainer>
                        {properties.imageFile || properties.image ? (
                            <ImageContainer>
                                <ImageView
                                    src={
                                        properties.imageFile?.source ??
                                        fileRepository.getImageUrl(properties.image)
                                    }
                                />
                            </ImageContainer>
                        ) : (
                            <UploadIcon />
                        )}
                    </UploadContainer>

                    <DragAndDropText variant="body1">
                        {translations('drag_image_or')}
                        <DragAndDropLabel htmlFor={`dropimagezone-fileuploader-${properties.id}`}>
                            {translations('pick_image')}
                        </DragAndDropLabel>
                    </DragAndDropText>
                </FileUploaderContainer>
            </FileUploader>

            {error && (
                <Typography variant="body2" color="error" mt={1}>
                    {error}
                </Typography>
            )}
        </Container>
    )
}

export const DropProfileImageZone = memo(Component, (previous, next) => {
    return (
        previous.id === next.id &&
        previous.isEnabled === next.isEnabled &&
        previous.image === next.image &&
        previous.imageFile === next.imageFile
    )
})

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
    alignItems: 'center',
}))

const FileUploaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(3),
}))

const ImageContainer = styled(Box)(() => ({
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    width: '120px',
    height: '120px',
    borderRadius: '24px',
    cursor: 'pointer',
}))

const ImageView = styled('img')(() => ({
    width: '100%',
    height: '100%',
    borderRadius: '24px',
    objectFit: 'cover',
}))

const UploadContainer = styled(Box)(({ theme }) => ({
    width: '120px',
    height: '120px',
    border: `dashed 2px ${grey_2}`,
    borderRadius: '24px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: theme.spacing(1),
}))

const DragAndDropText = styled(Typography)(() => ({
    color: grey_1,
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
}))

const DragAndDropLabel = styled('label')(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
}))
