export type OnScrolledObserver = () => void

export class ScrollListener {
    observers: OnScrolledObserver[] = []

    addObserver(observer: OnScrolledObserver) {
        this.observers.push(observer)
    }

    removeObserver(observer: OnScrolledObserver) {
        this.observers = this.observers.filter((currentObserver) => currentObserver !== observer)
    }

    notify() {
        this.observers.forEach((observer) => observer())
    }
}
