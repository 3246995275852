import { useContext, useState } from 'react'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { LocalImage } from 'shared/lib/forms/LocalImage'
import { WizardPopup, WizardPopupFooterProperties } from 'shared/lib/wizard/WizardPopup'
import { useTranslation } from 'shared/lib/i18n'
import { groupRepository, sessionRepository } from '../../index'
import { User } from '../../user/User'
import { CreateGroupBody } from '../CreateGroupBody'
import { GroupsContext } from '../GroupsContextProvider'
import { AdminSettingsStepView } from './AdminSettingsStepView'
import { CreateOrEditGroupStep } from './CreateOrEditGroupStep'
import { GroupInfoStepView } from './GroupInfoStepView'
import { SearchUsers, SelectMembersStepView } from './selectMemberStep/SelectMembersStepView'

interface Properties {
    isVisible: boolean

    onGroupCreated(createdGroupId: string): void

    onCloseClicked(): void
}

export const CreateGroupPopup = ({ isVisible, onGroupCreated, onCloseClicked }: Properties) => {
    const translations = useTranslation()

    const { getGroups } = useContext(GroupsContext)!

    const [groupName, setGroupName] = useState<string>('')
    const [groupDescription, setGroupDescription] = useState<string>('')
    const [groupImageFiles, setGroupImageFiles] = useState<
        | {
              imageFile: LocalImage
              thumbnailFile: LocalImage
          }
        | undefined
    >()
    const [isStartGroup, setIsStartGroup] = useState<boolean>(false)
    const [isGroupRestricted, setIsGroupRestricted] = useState<boolean>(false)
    const [selectedUsers, setSelectedUsers] = useState<User[]>([])

    const [step, setStep] = useState<CreateOrEditGroupStep>(
        CreateOrEditGroupStep.CHOOSE_IMAGE_NAME_DESCRIPTION
    )

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>(undefined)

    const signedInUser = sessionRepository.signedInUser
    const canContinue = groupName.trim().length > 0

    const [query, setQuery] = useState('')

    const getTitle = () => {
        switch (step) {
            case CreateOrEditGroupStep.CHOOSE_IMAGE_NAME_DESCRIPTION:
                return translations('create_group')
            case CreateOrEditGroupStep.CHOOSE_ADMIN_SETTINGS:
                return translations('admin_settings')
            case CreateOrEditGroupStep.CHOOSE_MEMBERS:
                return translations('add_members')
        }
    }

    const getFooter = (): WizardPopupFooterProperties => {
        switch (step) {
            case CreateOrEditGroupStep.CHOOSE_IMAGE_NAME_DESCRIPTION:
                return {
                    hasBackButton: false,
                    canContinue: canContinue,
                    nextButtonTitle: translations('next'),
                    onNextButtonClicked,
                }
            case CreateOrEditGroupStep.CHOOSE_ADMIN_SETTINGS:
                return {
                    hasBackButton: true,
                    backButtonTitle: translations('back'),
                    canContinue: true,
                    onBackButtonClicked,
                    onNextButtonClicked,
                    nextButtonTitle: isStartGroup ? translations('create') : translations('next'),
                }
            case CreateOrEditGroupStep.CHOOSE_MEMBERS:
                return {
                    hasBackButton: true,
                    backButtonTitle: translations('back'),
                    canContinue: true,
                    onBackButtonClicked,
                    onNextButtonClicked,
                    nextButtonTitle: translations('create'),
                }
        }
    }

    const onImageSelected = (params: { imageFile: LocalImage; thumbnailFile: LocalImage }) => {
        setGroupImageFiles(params)
    }

    const onUserSelected = (user: User) => {
        if (selectedUsers.find((u) => u.id === user.id)) {
            setSelectedUsers([...selectedUsers.filter((u) => u.id !== user.id)])
            return
        }
        setSelectedUsers([...selectedUsers, user])
    }

    const onNextButtonClicked = async () => {
        switch (step) {
            case CreateOrEditGroupStep.CHOOSE_IMAGE_NAME_DESCRIPTION:
                signedInUser?.canCreateOrEditRestrictedGroups === true ||
                signedInUser?.canCreateOrEditStartGroups === true
                    ? setStep(CreateOrEditGroupStep.CHOOSE_ADMIN_SETTINGS)
                    : setStep(CreateOrEditGroupStep.CHOOSE_MEMBERS)
                break
            case CreateOrEditGroupStep.CHOOSE_ADMIN_SETTINGS:
                if (isStartGroup) {
                    await submit()
                    return
                }

                setStep(CreateOrEditGroupStep.CHOOSE_MEMBERS)
                break
            case CreateOrEditGroupStep.CHOOSE_MEMBERS:
                await submit()
                break
        }
    }

    const onBackButtonClicked = () => {
        switch (step) {
            case CreateOrEditGroupStep.CHOOSE_ADMIN_SETTINGS:
                setStep(CreateOrEditGroupStep.CHOOSE_IMAGE_NAME_DESCRIPTION)
                break
            case CreateOrEditGroupStep.CHOOSE_MEMBERS:
                signedInUser?.canCreateOrEditRestrictedGroups === true ||
                signedInUser?.canCreateOrEditStartGroups === true
                    ? setStep(CreateOrEditGroupStep.CHOOSE_ADMIN_SETTINGS)
                    : setStep(CreateOrEditGroupStep.CHOOSE_IMAGE_NAME_DESCRIPTION)
                break
        }
    }

    const submit = async () => {
        setIsLoading(true)
        const group = new CreateGroupBody(
            groupName,
            groupDescription,
            groupImageFiles?.imageFile.file,
            groupImageFiles?.thumbnailFile.file,
            isStartGroup,
            isGroupRestricted,
            isStartGroup ? [] : selectedUsers.map((selectedUser) => selectedUser.id)
        )

        try {
            const createdGroup = await groupRepository.createGroup(group)
            await getGroups()
            onGroupCreated(createdGroup.id)
        } catch (error: any) {
            setError(error.message)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <WizardPopup
                isLoading={isLoading}
                isVisible={isVisible}
                title={getTitle()}
                footer={getFooter()}
                onCloseButtonClicked={onCloseClicked}
                headerContent={
                    step === CreateOrEditGroupStep.CHOOSE_MEMBERS && (
                        <SearchUsers
                            onUserSelected={onUserSelected}
                            selectedUsers={selectedUsers}
                            query={query}
                            setQuery={setQuery}
                        />
                    )
                }
            >
                {step === CreateOrEditGroupStep.CHOOSE_IMAGE_NAME_DESCRIPTION && (
                    <GroupInfoStepView
                        groupName={groupName}
                        groupDescription={groupDescription}
                        groupImageFiles={groupImageFiles}
                        setGroupName={setGroupName}
                        setGroupDescription={setGroupDescription}
                        onImageSelected={onImageSelected}
                    />
                )}
                {step === CreateOrEditGroupStep.CHOOSE_ADMIN_SETTINGS && (
                    <AdminSettingsStepView
                        isStartGroup={isStartGroup}
                        isGroupRestricted={isGroupRestricted}
                        onIsStartGroupChanged={setIsStartGroup}
                        setIsGroupRestricted={setIsGroupRestricted}
                    />
                )}
                {step === CreateOrEditGroupStep.CHOOSE_MEMBERS && !isStartGroup && (
                    <SelectMembersStepView
                        onUserSelected={onUserSelected}
                        selectedUsers={selectedUsers}
                        query={query}
                        setQuery={setQuery}
                    />
                )}
            </WizardPopup>

            <ErrorHandler error={error} translations={translations} />
        </>
    )
}
