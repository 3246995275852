import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import { ReactComponent as SearchIcon } from '../../assets/svg/search_white.svg'
import { useTranslation } from 'shared/lib/i18n'

export const NoResults = () => {
    const translations = useTranslation()

    return (
        <Container>
            <SearchIcon />
            <Box display="flex" flexDirection="column" ml={2}>
                <Title variant="body1">{translations('no_results')}</Title>
                <SubTitle variant="body2">{translations('change_search_query')}</SubTitle>
            </Box>
        </Container>
    )
}

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
}))

const Title = styled(Typography)(() => ({
    fontSize: 21,
    fontWeight: 700,
}))

const SubTitle = styled(Typography)(() => ({
    fontSize: 12,
}))
