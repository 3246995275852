import { Select as MuiSelect, SelectProps } from '@mui/material'
import { ChevronDownIcon } from '../assets/svg'
import { textColor } from '../theme/constants'

export const Select = (properties: SelectProps) => {
    const IconComponent = (props: any) => (
        <ChevronDownIcon {...props} height={16} width={16} fill={textColor} />
    )

    return <MuiSelect variant="outlined" IconComponent={IconComponent} {...properties} />
}
