import { ReactComponent as ChevronRightIcon } from 'shared/lib/assets/svg/chevron_right.svg'
import { Box, Link as MuiLink, styled, Typography, TypographyProps } from '@mui/material'
import { useTranslation } from 'shared/lib/i18n'
import { useDetailView } from '../../common/detailView/hooks'
import { Group } from '../../groups/Group'
import { EventListItem } from '../components/EventListComponents'

interface Properties {
    group: Pick<Group, 'id' | 'name'>
}

export const GroupHeaderRow = ({ group }: Properties) => {
    const translations = useTranslation()

    const { openGroupDetailView } = useDetailView()

    return (
        <EventListItem isLastItem={false}>
            <Box display="flex" justifyContent="space-between" width="100%">
                <GroupTitle>{group.name}</GroupTitle>
                <Link onClick={() => openGroupDetailView(group.id)}>
                    {translations('groups_info')} <ChevronRightIcon />
                </Link>
            </Box>
        </EventListItem>
    )
}

const GroupTitle = styled((props: Omit<TypographyProps, 'variant'>) => (
    <Typography {...props} variant="h3" />
))(() => ({
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}))

const Link = styled(MuiLink)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    cursor: 'pointer',
    verticalAlign: 'center',
    textWrap: 'nowrap',
    paddingLeft: theme.spacing(2),

    svg: {
        marginLeft: theme.spacing(1),
        width: 16,
        height: 16,
    },
}))
