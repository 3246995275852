import { styled } from '@mui/material'
import { forwardRef, memo, useEffect, useRef, useState } from 'react'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'
import { VirtuosoGrid, VirtuosoGridHandle } from 'react-virtuoso'
import { User } from '../../User'
import { SkeletonCard } from './SkeletonCard'
import { UserListCard } from './UserListCard'
import { useDetailView } from '../../../common/detailView/hooks'
import { defaultTransitionDuration } from 'shared/lib/theme/Theme'
import { useSearchParams } from 'react-router-dom'

interface Properties {
    users: User[]
    isLoading: boolean
    width: number
    height: number

    onUserSelected(userId: string): void
}

const CARD_HEIGHT = 300

export const USER_LIST_GRID_ID = `user-list-grid-${Math.random()}`

const Component = ({ users, isLoading, width, height, ...properties }: Properties) => {
    const [searchParams] = useSearchParams()
    const gridRef = useRef<VirtuosoGridHandle>(null)

    const { isDetailViewOpen } = useDetailView()

    const [lastSelectedUserId, setLastSelectedUserId] = useState<User['id'] | undefined>(undefined)

    const columnCount = getColumnCount(width)
    const rowCount = isLoading ? Math.ceil(height / CARD_HEIGHT) * columnCount : users.length
    const selectedUserId = searchParams.get('userId')

    const [needsToOpenDetailView, setNeedsToOpenDetailView] = useState(false)

    const onUserSelected = (userId: string) => {
        setNeedsToOpenDetailView(!isDetailViewOpen)
        setLastSelectedUserId(userId)
        properties.onUserSelected(userId)
    }

    const getRow = (index: number) => {
        if (isLoading) {
            return <SkeletonCard />
        }

        const user = users[index]
        return (
            <UserListCard
                user={user}
                isHighlighted={selectedUserId === user.id}
                onClick={onUserSelected}
            />
        )
    }

    useEffect(() => {
        const indexOfUser = users.findIndex((user) => user.id === lastSelectedUserId)

        if (indexOfUser < 0 || !needsToOpenDetailView) {
            return
        }

        // Wait for the detail view to be opened/closed
        const timeoutId = setTimeout(() => {
            gridRef.current?.scrollToIndex({
                index: indexOfUser,
                align: 'start',
                behavior: 'smooth',
            })
        }, defaultTransitionDuration)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [isDetailViewOpen, lastSelectedUserId]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <VirtuosoGrid
            id={USER_LIST_GRID_ID}
            ref={gridRef}
            totalCount={rowCount}
            itemContent={getRow}
            components={{
                Item: (props) => <ItemContainer columnCount={columnCount} {...props} />,
                List: forwardRef((props, ref) => <ListContainer {...props} ref={ref} />),
            }}
            style={{
                height: height,
                width: width,
            }}
        />
    )
}

export const UserListGrid = memo(Component, (previous: Properties, next: Properties) => {
    return (
        previous.width === next.width &&
        previous.height === next.height &&
        previous.isLoading === next.isLoading &&
        previous.users === next.users
    )
})

const getColumnCount = (availableWidth: number) => {
    switch (true) {
        case availableWidth < 500:
            return 2
        case availableWidth > 1000:
            return 5
        case availableWidth > 700:
            return 4
        case availableWidth > 500:
            return 3
        default:
            return 5
    }
}

const GAP = 2

const ListContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(GAP),
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 3),
}))

const ItemContainer = styled(({ columnCount, ...props }: DefaultComponentProps<any>) => (
    <div {...props} />
))(({ theme, columnCount }) => ({
    width: `calc((100% - ${theme.spacing((columnCount - 1) * GAP)}) / ${columnCount})`,
    height: CARD_HEIGHT,
}))
