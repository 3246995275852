import { forwardRef, Ref, useCallback, useEffect } from 'react'
import { VirtuosoHandle } from 'react-virtuoso'
import { backgroundColor } from 'shared/lib/theme/Theme'
import { StyledVirtuoso } from '../common/StyledVirtuoso'
import { PostItemRow } from './PostItemRow'
import { POST_LOADING_ROW_HEIGHT, PostLoadingRow } from './PostLoadingRow'
import {
    CreatePostLoadingViewModel,
    CreatePostViewModel,
    GroupHeaderViewModel,
    LoadPostsErrorViewModel,
    PostItemViewModel,
    PostLoadingViewModel,
    PostViewModel,
} from './PostViewModel'
import { CreatePostRow } from './CreatePostRow'
import { ScrollListener } from 'shared/lib/common/ScrollListener'
import { FullScreenMediaContextProvider } from 'shared/lib/components/media/FullScreenMediaContextProvider'
import { CreateButtonLoadingRow } from '../events/rows/CreateButtonLoadingRow'
import { GroupHeaderRow } from './GroupHeaderRow'
import { PostListItem } from './PostListComponents'
import { Box } from '@mui/material'

interface Properties {
    canCreatePosts: boolean
    height: number
    width: number
    viewModels: PostViewModel[]
    scrollListener: ScrollListener

    onLoadMore(): void
}

export const POST_LIST_ID = `post-list-${Math.random()}`

export const PostList = forwardRef((properties: Properties, ref: Ref<VirtuosoHandle>) => {
    const rowsThatFitScreen = Math.ceil(properties.height / POST_LOADING_ROW_HEIGHT)
    const viewModels =
        properties.viewModels.length > 0
            ? properties.viewModels
            : Array.from({ length: rowsThatFitScreen }).map((_, index) =>
                  index === 0 && properties.canCreatePosts
                      ? new CreatePostLoadingViewModel()
                      : new PostLoadingViewModel()
              )

    const onScrolled = useCallback(() => {
        properties.scrollListener.notify()
    }, [properties.scrollListener])

    const onEndReached = () => {
        if (properties.viewModels.length > 0) {
            properties.onLoadMore()
        }
    }

    useEffect(() => {
        const list = document.getElementById(POST_LIST_ID)!
        list.addEventListener('scroll', onScrolled)
        return () => list.removeEventListener('scroll', onScrolled)
    }, [onScrolled])

    return (
        <FullScreenMediaContextProvider>
            <StyledVirtuoso
                id={POST_LIST_ID}
                ref={ref}
                totalCount={viewModels.length}
                itemContent={(index) =>
                    getRow({
                        width: properties.width,
                        index,
                        viewModels,
                        scrollListener: properties.scrollListener,
                    })
                }
                overscan={1500}
                endReached={onEndReached}
                style={{
                    height: properties.height,
                    width: properties.width,
                    backgroundColor: backgroundColor,
                }}
            />
        </FullScreenMediaContextProvider>
    )
})

const getRow = (properties: {
    width: number
    index: number
    viewModels: PostViewModel[]
    scrollListener: ScrollListener
}) => {
    const viewModel = properties.viewModels[properties.index]
    const isLastItem = properties.index === properties.viewModels.length - 1

    if (viewModel instanceof PostItemViewModel) {
        return (
            <PostItemRow
                isLastItem={isLastItem}
                width={properties.width}
                viewModel={viewModel}
                scrollListener={properties.scrollListener}
            />
        )
    } else if (viewModel instanceof PostLoadingViewModel) {
        return <PostLoadingRow isLastItem={isLastItem} />
    } else if (viewModel instanceof CreatePostViewModel) {
        return <CreatePostRow viewModel={viewModel} />
    } else if (viewModel instanceof CreatePostLoadingViewModel) {
        return (
            <PostListItem isLastItem={false}>
                <CreateButtonLoadingRow doubleAttachmentButton />
            </PostListItem>
        )
    } else if (viewModel instanceof GroupHeaderViewModel) {
        return <GroupHeaderRow group={viewModel.group} />
    } else if (viewModel instanceof LoadPostsErrorViewModel) {
        return <Box height={10} width={'100%'}></Box>
    }

    return <></>
}
