import { Box, styled, Typography } from '@mui/material'
import { ConfirmationCodeTextField } from 'shared/lib/components/textfields/ConfirmationCodeTextField'
import { useTranslation } from 'shared/lib/i18n'

interface ConfirmSoftwareTokenProperties {
    errorMessage: string | undefined
    onConfirmSoftwareToken: (code: string) => void
    clearErrorMessage: () => void
}

export const ConfirmSoftwareTokenStep = (properties: ConfirmSoftwareTokenProperties) => {
    const { errorMessage } = properties
    const translations = useTranslation()

    return (
        <Container>
            <Typography
                variant={'body1'}
                mb={3}
                sx={{
                    textAlign: 'center',
                }}
            >
                {translations('use_verification_code_from_app')}
            </Typography>

            <ConfirmationCodeTextField
                length={6}
                error={errorMessage}
                centerErrorMessage={true}
                onConfirmationCodeEntered={(code: string) =>
                    properties.onConfirmSoftwareToken(code)
                }
                onConfirmationCodeChanged={() => properties.clearErrorMessage()}
            ></ConfirmationCodeTextField>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
}))
