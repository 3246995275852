import { Badge, ButtonProps, Button as MuiButton, Typography, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { ReactComponent as ChevronRightIcon } from 'shared/lib/assets/svg/chevron_right.svg'
import { useTranslation } from 'shared/lib/i18n'
import { grey_2, paperColor, textColor } from 'shared/lib/theme/Theme'
import { ReactComponent as GroupIcon } from '../../assets/svg/group.svg'
import { useDetailView } from '../../common/detailView/hooks'
import { GroupMemberEventKey } from '../../groups/members/events'

interface Properties {
    groupAccessRequestsCount: number
}

export const MobileGroupAccessRequestsButton = (properties: Properties) => {
    const { openGroupAccessRequests } = useDetailView()

    const [accessRequestCount, setAccessRequestCount] = useState(
        properties.groupAccessRequestsCount
    )

    const onGroupAccessRequestHandled = () => {
        setAccessRequestCount((prevState) => prevState - 1)
    }

    useEffect(() => {
        document.addEventListener(
            GroupMemberEventKey.GROUP_MEMBER_APPROVED,
            onGroupAccessRequestHandled
        )
        document.addEventListener(
            GroupMemberEventKey.GROUP_MEMBER_DENIED,
            onGroupAccessRequestHandled
        )

        return () => {
            document.removeEventListener(
                GroupMemberEventKey.GROUP_MEMBER_APPROVED,
                onGroupAccessRequestHandled
            )
            document.removeEventListener(
                GroupMemberEventKey.GROUP_MEMBER_DENIED,
                onGroupAccessRequestHandled
            )
        }
    }, [])

    useEffect(() => {
        setAccessRequestCount(properties.groupAccessRequestsCount)
    }, [properties.groupAccessRequestsCount])

    return accessRequestCount ? (
        <Button
            accessRequestCount={accessRequestCount}
            onClick={() => openGroupAccessRequests({ resetHistory: true, groupId: 'all' })}
        />
    ) : null
}

const Button = styled(
    ({
        accessRequestCount,
        ...props
    }: Omit<ButtonProps, 'startIcon' | 'children'> & { accessRequestCount: number }) => {
        const translations = useTranslation()

        const startIcon = (
            <Badge badgeContent={accessRequestCount} color="primary">
                <GroupIcon />
            </Badge>
        )

        return (
            <MuiButton {...props} startIcon={startIcon} endIcon={<ChevronRightIcon />}>
                <Typography>{translations('access_requests_groups')}</Typography>
            </MuiButton>
        )
    }
)(({ theme }) => ({
    backgroundColor: paperColor,
    height: '100%',
    padding: theme.spacing(2, 2.5),
    justifyContent: 'start',
    color: textColor,

    '& .MuiTypography-root': {
        flexGrow: 1,
        textAlign: 'left',
        paddingLeft: theme.spacing(2),
    },

    '& .MuiBadge-root': {
        backgroundColor: grey_2,
        borderRadius: 20,
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',

        '& svg': {
            fill: paperColor,
            width: 30,
            height: 30,
        },
    },

    '& .MuiBadge-badge': {
        color: paperColor,
        width: '24px',
        height: '24px',
        borderRadius: '50px',
    },
}))
