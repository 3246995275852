import { ImageResizer } from './ImageResizer'
import { Area } from 'react-easy-crop/types'
import { getImageSize } from 'react-image-size'
import { LocalImage } from '../forms/LocalImage'

/**
 * https://github.com/ricardo-ch/react-easy-crop
 */
export class ImageHelper {
    public static async cropImage(params: { src: string; pixelCrop: Area }): Promise<Blob> {
        try {
            return await this.cropImageWithMax(params.src, params.pixelCrop)
        } catch (error) {
            // https://github.com/jhildenbiddle/canvas-size#test-results
            return await this.cropImageWithMax(params.src, params.pixelCrop, 4000)
        }
    }

    private static async cropImageWithMax(
        src: string,
        pixelCrop: Area,
        maxSafeArea?: number
    ): Promise<Blob> {
        const image = await this.createImage(src)
        const canvas = document.createElement('canvas')
        const canvasContext = canvas.getContext('2d')!

        const maxSize = Math.max(image.width, image.height)
        const safeArea = maxSafeArea || Math.min(maxSize, 2 * ((maxSize / 2) * Math.sqrt(2)))

        canvas.width = safeArea
        canvas.height = safeArea

        canvasContext.fillStyle = '#ffffff'
        canvasContext.fillRect(0, 0, canvas.width, canvas.height)
        canvasContext.translate(safeArea / 2, safeArea / 2)
        canvasContext.rotate(this.getRadianAngle(0))
        canvasContext.translate(-safeArea / 2, -safeArea / 2)

        canvasContext.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        )
        const data = canvasContext.getImageData(0, 0, safeArea, safeArea)

        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height

        canvasContext.putImageData(
            data,
            Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
            Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
        )

        return new Promise((resolve) =>
            canvas.toBlob((blob: Blob | null) => resolve(blob!), 'image/jpeg')
        )
    }

    public static async compressImage(params: {
        blob: Blob
        fileName: string
    }): Promise<LocalImage> {
        const blob = await this.resizeImage({ blob: params.blob })
        const file = new File([blob], params.fileName, { type: blob.type })
        const size = await ImageHelper.getImageSize(file)
        return new LocalImage(file, size.width, size.height)
    }

    public static async compressImageToThumbnail(params: {
        blob: Blob
        fileName: string
    }): Promise<LocalImage> {
        const blob = await this.resizeImage({
            blob: params.blob,
            maxWidth: 150,
            maxHeight: 150,
            quality: 100,
        })
        const file = new File([blob], params.fileName, { type: blob.type })
        const size = await ImageHelper.getImageSize(file)
        return new LocalImage(file, size.width, size.height)
    }

    public static async getImageSize(file): Promise<{ width: number; height: number }> {
        return getImageSize(URL.createObjectURL(file))
    }

    private static async resizeImage(params: {
        blob: Blob
        maxWidth?: number
        maxHeight?: number
        quality?: number
    }): Promise<Blob> {
        return new Promise((resolve) => {
            ImageResizer.createResizedImage(
                params.blob,
                params.maxHeight || 1000,
                params.maxWidth || 1000,
                'JPEG',
                params.quality || 75,
                0,
                (blob) => resolve(blob as Blob),
                'blob'
            )
        })
    }

    private static createImage(src: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', (error) => reject(error))
            image.src = src
        })
    }

    private static getRadianAngle = (degreeValue: number) => {
        return (degreeValue * Math.PI) / 180
    }
}
