export class LinkPreview {
    constructor(
        readonly url: string,
        readonly title: string,
        readonly description: string | undefined,
        readonly thumbnailUrl: string | undefined
    ) {}

    static fromResponse(response: any): LinkPreview {
        return new LinkPreview(
            response.url,
            response.title,
            response.description,
            response.thumbnailUrl
        )
    }
}
