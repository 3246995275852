import { Image } from 'shared/lib/models/Image'

export class Feed {
    constructor(
        readonly id: string,
        readonly name: string,
        readonly description: string,
        readonly image: Image
    ) {}

    static fromJSON(response: Record<string, any>): Feed {
        return new Feed(
            response.id,
            response.name,
            response.description,
            Image.fromResponse(response.image)
        )
    }
}
