import { styled } from '@mui/material'
import MuiGrid from '@mui/material/Unstable_Grid2'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useIsTabletOrBigger } from 'shared/lib/theme/BreakPointHooks'
import {
    defaultTransition,
    mobileBottomBarHeight,
    mobileHeaderWithGroupsHeight,
    smartBannerHeight,
} from 'shared/lib/theme/Theme'
import ChurchContext from '../churches/ChurchContextProvider'
import { DetailView } from '../common/detailView/DetailView'
import { useDetailView } from '../common/detailView/hooks'
import { ContentContainer } from '../common/layout/ContentContainer'
import { MobileHeaderWithGroups } from '../common/layout/MobileHeaderWithGroups'
import { MY_CHURCH_GROUP_ID } from '../groups/Group'
import { GroupsContext } from '../groups/GroupsContextProvider'
import { CreateGroupPopup } from '../groups/createOrEditGroupPopup/CreateGroupPopup'
import { useGroupListViewModels } from '../groups/groupList/GroupListViewModels'
import { TabletDesktopGroupsSideBar } from '../groups/sidebar/TabletDesktopGroupsSideBar'
import { Paths } from '../routing/paths'
import { POST_LIST_ID } from './PostList'
import { PostListContainer } from './PostListContainer'

export const MyChurchPage = () => {
    const navigate = useNavigate()
    const params = useParams()

    const { isSmartBannerOpen, church } = useContext(ChurchContext)!
    const { isLoading, groups, getGroups } = useContext(GroupsContext)

    const isTabletOrBigger = useIsTabletOrBigger()
    const { isDetailViewOpen, isDetailViewDisplayedAsPopup, openGroupDetailView } = useDetailView()

    const [isCreateGroupPopupVisible, setIsCreateGroupPopupVisible] = useState(false)

    const heightOfSmartBanner = isSmartBannerOpen ? smartBannerHeight : 0

    const selectedGroupId =
        groups.length === 1
            ? groups[0].id
            : groups.length > 1
            ? params.groupId || MY_CHURCH_GROUP_ID
            : undefined
    const selectedGroup = groups.find((group) => group.id === selectedGroupId)
    const homeGroupId = groups.find((group) => group.isHome)?.id

    const onGroupSelected = (groupId: string) => {
        if (groupId === selectedGroupId) {
            openGroupDetailView(groupId, { resetHistory: true })
            return
        }

        navigate(
            Paths.GROUP_TIME_LINE.replace(':groupId', groupId === MY_CHURCH_GROUP_ID ? '' : groupId)
        )
    }

    const groupListViewModels = useGroupListViewModels({
        groups,
        canCreateGroups: Boolean(church?.canCreateGroups),
        selectedGroupId,
        onGroupSelected,
        onCreateGroupClicked: () => setIsCreateGroupPopupVisible(true),
    })

    const onGroupCreated = (createdGroupId: string) => {
        setIsCreateGroupPopupVisible(false)
        onGroupSelected(createdGroupId)
    }

    useEffect(() => {
        getGroups()
    }, [getGroups])

    useEffect(() => {
        if (isLoading || groups.length === 0) {
            return
        }

        const selectedGroup = groups.find((group) => group.id === selectedGroupId)
        if (!selectedGroup) {
            navigate(Paths.TIME_LINE)
        }
    }, [isLoading, selectedGroupId, groups, navigate])

    return (
        <>
            {!isTabletOrBigger && (
                <MobileHeaderWithGroups
                    isBadgeVisible={true}
                    canShowSmartBanner={true}
                    groupListViewModels={groupListViewModels}
                    selectedGroupId={selectedGroupId}
                    scrollableContainerId={POST_LIST_ID}
                />
            )}

            <ContentContainer
                mt={
                    !isTabletOrBigger
                        ? mobileHeaderWithGroupsHeight + heightOfSmartBanner
                        : undefined
                }
                mb={!isTabletOrBigger ? mobileBottomBarHeight : undefined}
            >
                <Grid container columns={{ mobile: 4, phablet: 4, tablet: 8, desktop: 12 }}>
                    {isTabletOrBigger && (
                        <Grid
                            tablet={isDetailViewOpen && !isDetailViewDisplayedAsPopup ? 1 : 2}
                            desktop={3}
                        >
                            <TabletDesktopGroupsSideBar
                                isBadgeVisible={true}
                                viewModels={groupListViewModels}
                                selectedGroupId={selectedGroupId}
                            />
                        </Grid>
                    )}

                    <Grid
                        mobile={4}
                        phablet={isDetailViewOpen && !isDetailViewDisplayedAsPopup ? 2 : 4}
                        tablet={4}
                        desktop={5}
                    >
                        {selectedGroup && (
                            <PostListContainer
                                selectedGroup={selectedGroup}
                                homeGroupId={homeGroupId}
                            />
                        )}
                    </Grid>

                    {isDetailViewOpen && (
                        <Grid phablet={2} tablet={3} desktop={4}>
                            <DetailView />
                        </Grid>
                    )}
                </Grid>
            </ContentContainer>

            {isCreateGroupPopupVisible && (
                <CreateGroupPopup
                    isVisible={true}
                    onGroupCreated={onGroupCreated}
                    onCloseClicked={() => setIsCreateGroupPopupVisible(false)}
                />
            )}
        </>
    )
}

const Grid = styled(MuiGrid)(() => ({
    height: '100%',
    transition: defaultTransition,
}))
