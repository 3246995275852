import { Box, styled, Typography } from '@mui/material'
import { isAndroid, isWindows } from 'mobile-device-detect'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FilledCloseButtonWithWhiteCross } from 'shared/lib/components/buttons/CloseButton'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { useIsMobile } from 'shared/lib/theme/BreakPointHooks'
import { grey_1, grey_3 } from 'shared/lib/theme/Theme'
import ChurchContext from '../churches/ChurchContextProvider'
import { useTranslation } from 'shared/lib/i18n'
import { fileRepository, sessionRepository } from '../index'
import { Paths } from '../routing/paths'

const SMART_BANNER_CLOSED_DATE_KEY = 'smartBannerClosedDate'

export const SmartBanner = () => {
    const translations = useTranslation()
    const navigate = useNavigate()

    const isMobile = useIsMobile()

    const { isSmartBannerOpen, church, setIsSmartBannerOpen } = useContext(ChurchContext)!

    const storeName = isAndroid || isWindows ? 'Play Store' : 'App Store'

    const openInStore = () => {
        window.open(church!.appOrPlayStoreUrl, '_blank')
    }

    const onClose = () => {
        const today = new Date()
        const dateString = today.toISOString().split('T')[0]
        localStorage.setItem(SMART_BANNER_CLOSED_DATE_KEY, dateString)
        setIsSmartBannerOpen(false)
    }

    useEffect(() => {
        if (!church || !isMobile) {
            setIsSmartBannerOpen(false)
            return
        }

        const smartBannerClosedDate = localStorage.getItem(SMART_BANNER_CLOSED_DATE_KEY)
        const today = new Date().toISOString().split('T')[0]
        const isOpen = smartBannerClosedDate !== today

        setIsSmartBannerOpen(isOpen)
    }, [isSmartBannerOpen, church, isMobile, setIsSmartBannerOpen])

    if (!isSmartBannerOpen || !church || !isMobile) {
        return <></>
    }

    return (
        <Container>
            <FilledCloseButtonWithWhiteCross onClick={onClose} edge={'start'} />

            <ChurchLogoImageView
                src={fileRepository.getThumbnailUrl(church.image)}
                onClick={() => {
                    sessionRepository.clearData()
                    navigate(`${Paths.SIGN_IN}?applicationId=${church.applicationId}`)
                }}
            />
            <TextContainer>
                <TitleTextView>{church.name}</TitleTextView>
                <DescriptionTextView>
                    {translations('download_for_best_experience', [storeName])}
                </DescriptionTextView>
            </TextContainer>
            <ButtonContainer onClick={openInStore} title={translations('download')} />
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    background: grey_3,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100px',
}))

const ChurchLogoImageView = styled('img')(({ theme }) => ({
    width: '48px',
    height: '48px',
    borderRadius: '16px',
    objectFit: 'fill',
    cursor: 'pointer',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
}))

const TextContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    overflow: 'hidden',
}))

const TitleTextView = styled(Typography)(() => ({
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '15px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}))

const DescriptionTextView = styled(Typography)(({ theme }) => ({
    color: grey_1,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '13px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: theme.spacing(0.5),
}))

const ButtonContainer = styled(PrimaryContainedButton)(({ theme }) => ({
    borderRadius: '24px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: '38px',
}))
