import { Avatar as MuiAvatar, styled, Theme } from '@mui/material'
import { PropsWithChildren } from 'react'
import { SxProps } from '@mui/system'

interface Properties {
    src?: string
    width?: number
    height?: number
    onClick?: () => void

    sx?: SxProps<Theme>
}

export type AvatarProperties = PropsWithChildren<Properties>

export const Avatar = ({ width, height, sx, ...props }: AvatarProperties) => {
    return (
        <StyledAvatar
            sx={{
                width,
                height,
                ...(sx || {}),
            }}
            {...props}
        />
    )
}

const StyledAvatar = styled(MuiAvatar)(({ theme, src }) => ({
    backgroundColor: src ? 'unset' : theme.palette.primary.main,
}))
