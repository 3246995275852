import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { GreyContainedButton } from 'shared/lib/components/buttons/GreyButtons'
import { PasswordTextField } from 'shared/lib/components/textfields/PasswordTextField'
import { useTranslation } from 'shared/lib/i18n'
import { isPasswordValid } from '../../utils/PasswordUtils'
import {
    PrimaryActionButtonContainer,
    PrimaryActionButtonsContainer,
} from 'shared/lib/components/PrimaryActionButtonsContainer'
import { Title } from '../SessionPage'
import { SignUpStepProperties } from './SignUpStepProperties'
import { DEFAULT_VALIDATION_TIMEOUT } from 'shared/lib/common/Constants'

interface Properties extends SignUpStepProperties {
    password: string
    passwordConfirmation: string

    setPassword: (password: string) => void
    setPasswordConfirmation: (password: string) => void
}

export const ChoosePasswordStep = (properties: Properties) => {
    const { password, passwordConfirmation } = properties
    const translations = useTranslation()

    const [passwordError, setPasswordError] = useState<string | undefined>()
    const [passwordConfirmationError, setPasswordConfirmationError] = useState<string | undefined>()

    const hasValidPassword = password.length && isPasswordValid(password)
    const hasValidPasswordConfirmation = password === passwordConfirmation
    const canContinue =
        password.length > 0 &&
        passwordConfirmation.length > 0 &&
        hasValidPassword &&
        hasValidPasswordConfirmation

    const onPasswordChanged = (value: string) => {
        updatePasswordErrors(true)
        properties.setPassword(value)
    }

    const onPasswordConfirmationChanged = (value: string) => {
        updatePasswordErrors(true)
        properties.setPasswordConfirmation(value)
    }

    const updatePasswordErrors = (reset: boolean = false) => {
        setPasswordError(
            hasValidPassword || reset ? undefined : translations('choose_password_hint')
        )
        setPasswordConfirmationError(
            hasValidPasswordConfirmation || reset
                ? undefined
                : translations('passwords_do_not_match')
        )
    }

    const onSubmitButtonClicked = () => {
        updatePasswordErrors(false)
        if (!canContinue) return
        properties.onNextButtonClicked()
    }

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            updatePasswordErrors(false)
        }, DEFAULT_VALIDATION_TIMEOUT)

        return () => {
            clearTimeout(timeOutId)
        }
    }, [password, passwordConfirmation]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Title variant="h1" mt={8}>
                {translations('choose_password_title')}
            </Title>

            <Typography variant="body2" mt={2}>
                {translations('choose_password_hint')}
            </Typography>

            <PasswordTextField
                autoFocus={true}
                value={password}
                error={passwordError !== undefined}
                helperText={passwordError}
                label={translations('password')}
                onChanged={onPasswordChanged}
                onBlur={() => updatePasswordErrors()}
                sx={{
                    marginTop: 5,
                }}
            />

            <PasswordTextField
                autoFocus={false}
                label={translations('repeat_password')}
                value={passwordConfirmation}
                error={passwordConfirmationError !== undefined}
                helperText={passwordConfirmationError}
                onChanged={onPasswordConfirmationChanged}
                onBlur={() => updatePasswordErrors()}
                onEnterKeyPressed={() => onSubmitButtonClicked()}
                sx={{
                    marginTop: 4,
                }}
            />

            <PrimaryActionButtonsContainer>
                <GreyContainedButton
                    fullWidth={true}
                    title={translations('back')}
                    onClick={properties.onBackButtonClicked}
                />

                <PrimaryActionButtonContainer>
                    <PrimaryContainedButton
                        fullWidth={true}
                        isLoading={properties.isLoading}
                        title={translations('next')}
                        disabled={!canContinue}
                        onClick={onSubmitButtonClicked}
                    />
                </PrimaryActionButtonContainer>
            </PrimaryActionButtonsContainer>
        </>
    )
}
