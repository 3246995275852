import { DateTime } from 'luxon'
import { Group } from '../groups/Group'
import { Event } from './Event'

export interface EventViewModel {}

export class EventSectionViewModel implements EventViewModel {
    constructor(readonly date: DateTime) {}
}

export class EventItemViewModel implements EventViewModel {
    constructor(
        readonly index: number,
        readonly isGroupNameVisible: boolean,
        readonly isFirstEventOnSameDay: boolean,
        readonly isLastEventOnSameDay: boolean,
        readonly event: Event
    ) {}
}

export class CreateEventViewModel implements EventViewModel {
    constructor(readonly groupId: string | undefined) {}
}

export class EventItemLoadingViewModel implements EventViewModel {}

export class EventSectionLoadingViewModel implements EventViewModel {}

export class CreateEventLoadingViewModel implements EventViewModel {}

export class ZeroEventsViewModel implements EventViewModel {
    constructor(readonly group: Pick<Group, 'id' | 'name'>) {}
}

export class GroupHeaderViewModel implements EventViewModel {
    constructor(public readonly group: Pick<Group, 'id' | 'name'>) {}
}

export class LoadEventsErrorViewModel implements EventViewModel {}
