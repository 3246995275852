import { Box, CircularProgress, FormControlLabel, styled, Typography } from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ReactComponent as Alert } from 'shared/lib/assets/svg/alert.svg'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { Checkbox } from 'shared/lib/components/Checkbox'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { useTranslation } from 'shared/lib/i18n'
import { errorColor, grey_3 } from 'shared/lib/theme/Theme'
import ChurchContext from '../../../churches/ChurchContextProvider'
import { sessionRepository, userRepository } from '../../../index'

interface DeleteAccountPopupProperties {
    isDenying?: boolean
    user?: { id: string; firstName: string }
    onCancel: () => void
    onContinueButtonClicked?: () => void
}

export const DeleteOrDenyAccountDialog = ({
    user,
    ...properties
}: DeleteAccountPopupProperties) => {
    const translations = useTranslation()
    const church = useContext(ChurchContext)!.church

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const [error, setError] = useState<Error | undefined>()
    const [hasBalance, setHasBalance] = useState<boolean | undefined>(undefined)

    const isSignedInUser = sessionRepository.signedInUser?.id === user?.id

    const canDelete = hasBalance === false || isChecked
    const canContinueWhenDeletingOtherUser = user && canDelete
    const canContinueWhenDeletingOwnAccount = !user && canDelete

    const canContinue = canContinueWhenDeletingOtherUser || canContinueWhenDeletingOwnAccount

    const getHasBalance = useCallback(() => {
        setHasBalance(undefined)
        if (user) {
            userRepository.hasBalance(user.id).then((bool) => setHasBalance(bool))
        }
    }, [user])

    const onContinueButtonClicked = () => {
        setIsLoading(true)

        if (!user) return

        if (!isSignedInUser && properties.isDenying) {
            userRepository
                .denyUser(user.id)
                .catch(setError)
                .finally(() => {
                    properties.onContinueButtonClicked?.()
                    setIsLoading(false)
                })
            return
        }

        if (!isSignedInUser) {
            userRepository
                .deleteUser(user.id)
                .catch(setError)
                .finally(() => {
                    properties.onContinueButtonClicked?.()
                    setIsLoading(false)
                })
            return
        }

        sessionRepository
            .deleteAccount()
            .catch(setError)
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        getHasBalance()
    }, [getHasBalance])

    return (
        <>
            <AlertDialog
                isVisible={!!user}
                isLoading={isLoading}
                isContinueDisabled={!canContinue}
                continueButtonType={ButtonType.RED}
                cancelButtonType={ButtonType.RED}
                titleIcon={<Alert fill={errorColor} />}
                title={translations('are_you_sure')}
                continueButtonTitle={translations(properties.isDenying ? 'deny' : 'delete')}
                cancelButtonTitle={translations('cancel')}
                onContinueButtonClicked={onContinueButtonClicked}
                onCancelButtonClicked={properties.onCancel}
                message={
                    <Box maxWidth={'500px'} minWidth={'250px'}>
                        {user && hasBalance === undefined ? (
                            <Box display={'flex'} justifyContent={'center'}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {isSignedInUser && (
                                    <Typography>{translations('delete_account_info')}</Typography>
                                )}

                                {hasBalance ? (
                                    <Box>
                                        <MessageContainer>
                                            <Typography>
                                                <WarningMessage>
                                                    {translations('warning')}:
                                                </WarningMessage>
                                                {isSignedInUser
                                                    ? translations('open_balance_myself')
                                                    : translations('open_balance_other_user', [
                                                          user?.firstName || '',
                                                      ])}
                                            </Typography>
                                        </MessageContainer>
                                        <FormControlLabel
                                            sx={{
                                                marginLeft: 0,
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={(event) => {
                                                        setIsChecked(event.target.checked)
                                                    }}
                                                />
                                            }
                                            label={translations(
                                                'delete_account_open_balance_free_to_spend',
                                                [church?.wordUsedForChurch(translations) || '']
                                            )}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Typography>
                                            {!isSignedInUser &&
                                                translations('confirm_delete_user', [
                                                    user?.firstName || '',
                                                ])}
                                        </Typography>
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                }
            />

            <ErrorHandler error={error} horizontal="center" translations={translations} />
        </>
    )
}

const MessageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(3),
    whiteSpace: 'pre-wrap',
    background: grey_3,
    borderRadius: '24px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}))

const WarningMessage = styled('span')(() => ({
    color: errorColor,
    fontWeight: 'bold',
    marginRight: '8px',
}))
