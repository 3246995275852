import { useEffect, useRef } from 'react'

export const useHorizontalScroll = <T extends HTMLElement>() => {
    const ref = useRef<T>(null)

    useEffect(() => {
        const element = ref.current
        if (!element) {
            return
        }

        const onWheel = (event: WheelEvent) => {
            if (event.deltaY === 0) return

            event.preventDefault()

            element.scrollTo({
                left: element.scrollLeft + event.deltaY,
            })
        }

        element.addEventListener('wheel', onWheel)

        return () => element.removeEventListener('wheel', onWheel)
    }, [])

    return ref
}
