import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import {
    $createTextNode,
    COMMAND_PRIORITY_HIGH,
    COMMAND_PRIORITY_NORMAL,
    KEY_ENTER_COMMAND,
    KEY_ESCAPE_COMMAND,
    TextNode,
} from 'lexical'
import { checkForMentions } from 'lexical-beautiful-mentions'
import {
    $getSelectionInfo,
    DEFAULT_PUNCTUATION,
    PRE_TRIGGER_CHARS,
} from 'lexical-beautiful-mentions/mention-utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as ReactDOM from 'react-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import { MinimalUserInfo } from 'shared/lib/users/MinimalUserInfo'
import { LexicalTypeaheadMenuPlugin } from '../../../../lexical/LexicalTypeaheadMenuPlugin'
import { filterUsers } from 'shared/lib/users/UserUtils'
import { TagUserList } from '../../TagUserList'
import { $createTagNode } from './TagNode'
import { TagOption } from './TagOption'

interface Properties {
    taggableUsers: (MinimalUserInfo & { isGroupMember: boolean })[]

    onUserTagged(userId: string): void
}

export const TaggingPlugin = ({ taggableUsers, onUserTagged }: Properties) => {
    const [editor] = useLexicalComposerContext()
    const justSelectedAnOption = useRef(false)

    const allowSpaces = true
    const punctuation = DEFAULT_PUNCTUATION
    const triggers = useMemo(() => ['@'], [])
    const preTriggerChars = PRE_TRIGGER_CHARS

    const [trigger, setTrigger] = useState<string | undefined>(undefined)
    const [query, setQuery] = useState<string | undefined>(undefined)

    const checkForTagMatch = useCallback(
        (text: string) => {
            // Don't show the menu if the next character is a word character
            const selectionInfo = $getSelectionInfo(triggers, punctuation)
            if (selectionInfo?.isTextNode && selectionInfo.wordCharAfterCursor) {
                return null
            }

            const queryMatch = checkForMentions(
                text,
                triggers,
                preTriggerChars,
                punctuation,
                allowSpaces
            )

            if (queryMatch) {
                const { replaceableString, matchingString } = queryMatch
                const index = replaceableString.lastIndexOf(matchingString)
                const trigger =
                    index === -1
                        ? replaceableString
                        : replaceableString.substring(0, index) +
                          replaceableString.substring(index + matchingString.length)

                setTrigger(trigger || undefined)

                if (queryMatch.replaceableString) {
                    return queryMatch
                }
            } else {
                setTrigger(undefined)
            }

            return null
        },
        [preTriggerChars, allowSpaces, punctuation, triggers]
    )

    const onQueryChanged = (query: string | null) => {
        setQuery(query ?? undefined)
    }

    const onOptionSelected = (
        selectedOption: TagOption,
        nodeToReplace: TextNode | null,
        closeMenu?: () => void
    ) => {
        if (!trigger) {
            return
        }

        handleSelectOption(selectedOption, nodeToReplace, closeMenu)
    }

    const handleSelectOption = useCallback(
        (selectedOption: TagOption, nodeToReplace: TextNode | null, closeMenu?: () => void) => {
            editor.update(() => {
                if (!trigger) {
                    return
                }
                const user = selectedOption.user

                const tagNode = $createTagNode(trigger, {
                    id: user.id,
                    name: [user.firstName, user.lastName].join(' '),
                })
                onUserTagged(user.id)

                if (nodeToReplace) {
                    const newNode = nodeToReplace.replace(tagNode)

                    if (!newNode.getNextSibling()?.getTextContent().startsWith(' ')) {
                        newNode.insertAfter($createTextNode(' '))
                    }
                }
                closeMenu?.()
                justSelectedAnOption.current = true
            })
        },
        [editor, trigger, onUserTagged]
    )

    const options = useMemo(
        () =>
            taggableUsers.map(
                (user) =>
                    new TagOption(
                        '@',
                        user.id,
                        [user.firstName, user.lastName].join(', '),
                        JSON.parse(JSON.stringify(user))
                    )
            ),
        [taggableUsers]
    )

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                KEY_ENTER_COMMAND,
                () => {
                    return !trigger
                },
                COMMAND_PRIORITY_HIGH
            ),
            editor.registerCommand(
                KEY_ESCAPE_COMMAND,
                () => {
                    if (!trigger) {
                        return false
                    }

                    setQuery(undefined)
                    setTrigger(undefined)
                    return true
                },
                COMMAND_PRIORITY_HIGH
            )
        )
    }, [editor]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AutoSizer disableHeight>
            {({ width }) => (
                <LexicalTypeaheadMenuPlugin
                    onQueryChange={onQueryChanged}
                    onSelectOption={onOptionSelected}
                    options={options}
                    parent={editor.getRootElement()?.parentElement as HTMLElement}
                    menuRenderFn={(anchorElementRef, { selectOptionAndCleanUp }) => {
                        const filteredUsers = filterUsers(taggableUsers, query ?? '')

                        if (!trigger || !anchorElementRef.current || !filteredUsers.length) {
                            return null
                        }

                        return ReactDOM.createPortal(
                            <TagUserList
                                users={filterUsers(taggableUsers, query ?? '')}
                                width={width}
                                onUserClicked={(userId) => {
                                    const selectedOptions = options.find(
                                        (option) => option.value === userId
                                    )
                                    if (selectedOptions) {
                                        selectOptionAndCleanUp(selectedOptions)
                                    }
                                }}
                            />,
                            anchorElementRef.current
                        )
                    }}
                    triggerFn={checkForTagMatch}
                    commandPriority={COMMAND_PRIORITY_NORMAL}
                />
            )}
        </AutoSizer>
    )
}
