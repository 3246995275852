import { SVGProps } from 'react'
import { Checkbox as MuiCheckbox, CheckboxProps, styled } from '@mui/material'
import { disabledPrimaryColor, grey_2 } from '../theme/Theme'
import { ReactComponent as CheckBoxInactive } from 'shared/lib/assets/svg/checkbox_inactive.svg'
import { ReactComponent as CheckBoxActiveIcon } from 'shared/lib/assets/svg/checkbox_active.svg'
import { ReactComponent as RoundedCheckBoxInactiveIcon } from 'shared/lib/assets/svg/rounded_checkbox_inactive.svg'
import { ReactComponent as RoundedCheckBoxActiveIcon } from 'shared/lib/assets/svg/rounded_checkbox_active.svg'

export const Checkbox = (properties: CheckboxProps) => {
    return (
        <MuiCheckbox
            icon={<CheckBoxInactive />}
            checkedIcon={<CheckBoxActive disabled={properties.disabled} />}
            sx={{ color: grey_2 }}
            {...properties}
        />
    )
}

const CheckBoxActive = styled(
    ({ disabled, ...properties }: SVGProps<SVGElement> & { disabled?: boolean }) => (
        <CheckBoxActiveIcon {...properties} />
    )
)(({ disabled, theme }) => ({
    fill: disabled ? disabledPrimaryColor : theme.palette.primary.main,
}))

export const RoundedCheckbox = (properties: CheckboxProps) => {
    return (
        <MuiCheckbox
            icon={<RoundedCheckBoxInactive />}
            checkedIcon={<RoundedCheckBoxActive />}
            sx={{ color: grey_2 }}
            {...properties}
        />
    )
}

const RoundedCheckBoxInactive = styled(RoundedCheckBoxInactiveIcon)(({ theme }) => ({
    fill: theme.palette.primary.main,
}))

const RoundedCheckBoxActive = styled(RoundedCheckBoxActiveIcon)(({ theme }) => ({
    fill: theme.palette.primary.main,
}))
