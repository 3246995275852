import { PdfView } from 'shared/lib/components/PdfView'
import { fileRepository } from '../..'
import { useTranslation } from 'shared/lib/i18n'
import { Post } from '../../posts/Post'
import { PostListItemContainer } from '../PostListComponents'

interface Properties {
    hasWhiteBackground?: boolean
    post: Post
}

export const PostPdfView = ({ post, ...properties }: Properties) => {
    const translations = useTranslation()

    const hasWhiteBackground = properties.hasWhiteBackground ?? false
    const pdf = post.pdfs[0]

    if (!pdf) {
        return <></>
    }

    return (
        <PostListItemContainer>
            <PdfView
                hasWhiteBackground={hasWhiteBackground}
                pdf={pdf}
                fileRepository={fileRepository}
                translations={translations}
            />
        </PostListItemContainer>
    )
}
