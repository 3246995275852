import { useMemo, useRef } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VirtuosoHandle } from 'react-virtuoso'
import { Group } from '../groups/Group'
import { EventList } from './EventList'
import { useEvents } from './hooks'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { useTranslation } from 'shared/lib/i18n'

interface Properties {
    selectedGroup: Group
    homeGroupId?: string
}

export const EventsGrid = ({ selectedGroup, homeGroupId }: Properties) => {
    const translations = useTranslation()

    const listRef = useRef<VirtuosoHandle | null>(null)

    const currentListRef = useMemo(() => listRef.current, [listRef])

    const { viewModels, error, loadMoreEventsIfNeeded } = useEvents({
        selectedGroup,
        homeGroupId,
        listRef: currentListRef,
    })

    return (
        <>
            <AutoSizer>
                {({ height, width }) => (
                    <EventList
                        ref={listRef}
                        height={height}
                        width={width}
                        viewModels={viewModels}
                        onLoadMore={loadMoreEventsIfNeeded}
                    />
                )}
            </AutoSizer>

            <ErrorHandler error={error} translations={translations} />
        </>
    )
}
