import { Box, ListItem, styled } from '@mui/material'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { UserListLoadingRow } from '../../user/userList/UserListItemRows'

export const GroupDetailMemberListLoadingState = () => {
    return (
        <Container>
            <ListItem disableGutters disablePadding sx={{ marginBottom: 2 }}>
                <Skeleton variant="text" width={60} />
            </ListItem>

            <UserListLoadingRow key={0} />
            <UserListLoadingRow key={1} />
            <UserListLoadingRow key={2} />
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    width: `calc(100% - ${theme.spacing(6)})`,
}))
