import { DateTime } from 'luxon'

export class DateTimeValidator {
    public static MAX_DATE = DateTime.utc(2099, 12, 31, 23, 59, 59, 999)

    public static isValidPeriod(start: DateTime, end?: DateTime, isAllDay?: boolean) {
        if (!end) {
            return start.isValid
        }

        const isValidStartDate = start.isValid && ((isAllDay && start <= end) || start < end)

        const isValidEndDate =
            end.isValid && end <= this.MAX_DATE && ((isAllDay && end >= start) || end > start)

        return isValidStartDate && isValidEndDate
    }

    public static startDateErrorKey(
        start: DateTime,
        end?: DateTime,
        isAllDay?: boolean,
        { allowStartInPast = false }: { allowStartInPast?: boolean } = {}
    ) {
        if (!start.isValid || (!allowStartInPast && !(start >= DateTime.utc().startOf('day')))) {
            return 'invalid_date'
        }

        const isSameOrAfter = end ? (isAllDay ? start > end : start >= end) : false

        if (!end || !isSameOrAfter) return undefined

        return isAllDay
            ? 'start_date_same_or_after_end_date'
            : 'start_date_time_same_or_after_end_date_time'
    }

    public static endDateErrorKey(end: DateTime | undefined, start: DateTime, isAllDay?: boolean) {
        if (end === undefined) return undefined

        if (!end.isValid || end > this.MAX_DATE) return 'invalid_date'

        const isSameOrBefore = isAllDay ? end < start : end <= start
        if (!isSameOrBefore) return undefined

        return isAllDay
            ? 'end_date_same_or_before_start_date'
            : 'end_date_time_same_or_before_start_date_time'
    }
}
