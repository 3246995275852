import { churchRepository } from '../index'
import { useEffect, useState } from 'react'
import { Church } from './Church'
import { delay } from 'shared/lib/utils/Utils'

export const useChurches = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [churches, setChurches] = useState<Church[]>([])

    useEffect(() => {
        Promise.all([churchRepository.getAll().then(setChurches), delay(300)]).finally(() =>
            setIsLoading(false)
        )
    }, [])

    return { isLoading, churches }
}
