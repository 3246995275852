import React from 'react'
import {
    DescriptionTextView,
    DetailViewContainer,
    StyledAvatar,
    TitleTextView,
} from '../../common/detailView'
import { Box, styled } from '@mui/material'
import { useFeed } from '../hooks'
import { FeedDetailLoadingState } from './FeedDetailLoadingState'
import { fileRepository } from '../../index'
import { DetailViewProperties } from '../../common/detailView/DetailViewProperties'

interface FeedListViewProperties extends DetailViewProperties {
    feedId: string
}

export const FeedListView = ({ feedId, closeIcon, onCloseClicked }: FeedListViewProperties) => {
    const { feed, isLoading } = useFeed(feedId)

    if (isLoading) {
        return (
            <DetailViewContainer onCloseClicked={onCloseClicked} closeIcon={closeIcon}>
                <Container>
                    <FeedDetailLoadingState />
                </Container>
            </DetailViewContainer>
        )
    }

    if (!feed) {
        onCloseClicked()

        return <></>
    }

    return (
        <DetailViewContainer onCloseClicked={onCloseClicked} closeIcon={closeIcon}>
            <Container>
                <StyledAvatar src={fileRepository.getImageUrl(feed.image)}>
                    {feed.name}
                </StyledAvatar>
                <TitleTextView>{feed.name}</TitleTextView>
                <DescriptionTextView>{feed.description}</DescriptionTextView>
            </Container>
        </DetailViewContainer>
    )
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    padding: theme.spacing(4),
}))
