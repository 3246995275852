import { ListItem as MuiListItem, ListItemProps, styled } from '@mui/material'

export const EventListItem = styled(
    ({
        isLastItem,
        hasLargePaddingTop,
        hasMobilePaddingLeft,
        ...properties
    }: ListItemProps & {
        isLastItem: boolean
        hasLargePaddingTop?: boolean
        hasMobilePaddingLeft?: boolean
    }) => <MuiListItem {...properties} />
)(({ theme, isLastItem, hasLargePaddingTop, hasMobilePaddingLeft }) => ({
    paddingTop:
        hasLargePaddingTop === undefined
            ? theme.spacing(1)
            : hasLargePaddingTop
            ? theme.spacing(3)
            : '2px',
    paddingLeft: hasMobilePaddingLeft ?? true ? theme.spacing(2) : theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: isLastItem ? theme.spacing(1) : 0,

    [theme.breakpoints.up('phablet')]: {
        paddingTop: hasLargePaddingTop ?? true ? theme.spacing(3) : '2px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: isLastItem ? theme.spacing(3) : 0,
    },
}))
