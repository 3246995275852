import { Box, BoxProps, styled, Typography } from '@mui/material'
import { grey_3 } from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'
import { fileRepository, groupRepository } from '../..'
import { useNavigate } from 'react-router-dom'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { GroupType } from 'shared/lib/groups/GroupType'
import { GroupMemberState } from '../../groups/Group'
import { Paths } from '../../routing/paths'
import { ReactComponent as GroupIcon } from '../../assets/svg/group.svg'
import { PostListItemContainer } from '../PostListComponents'
import { SharedGroup } from '../../posts/SharedGroup'
import { FilledCloseButtonWithWhiteCross } from 'shared/lib/components/buttons/CloseButton'
import { MouseEvent, useState } from 'react'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { useDetailView } from '../../common/detailView/hooks'
import { Post } from '../../posts/Post'
import { delay } from 'shared/lib/utils/Utils'
import { DEFAULT_ANIMATION_DURATION } from 'shared/lib/common/Constants'

interface Properties {
    post?: Post
    hasWhiteBackground?: boolean
    isDeleteButtonEnabled?: boolean
    isEditing?: boolean
    sharedGroupId?: string
    sharedGroup?: SharedGroup
    onDeleteButtonClicked?: () => void
}

export const PostSharedGroupView = ({ sharedGroup, ...properties }: Properties) => {
    const translations = useTranslation()
    const navigate = useNavigate()

    const { openGroupDetailView } = useDetailView()

    const [error, setError] = useState<Error | undefined>()
    const [isRequestingAccess, setIsRequestingAccess] = useState(false)

    const isCardClickable = !properties.isEditing && !properties.isDeleteButtonEnabled
    const hasWhiteBackground = properties.hasWhiteBackground ?? false

    const getButtonTitle = () => {
        switch (sharedGroup?.groupMemberState) {
            case GroupMemberState.APPROVED:
                return translations('go_to_group')
            case GroupMemberState.PENDING:
                return translations('withdraw_request')
            case GroupMemberState.NONE:
                return sharedGroup.type === GroupType.INVITATION_ONLY
                    ? translations('request_access')
                    : translations('become_member')
        }
    }

    const onCardClicked = () => {
        properties.post?.notifyMarkAsReadIfNeeded()

        const groupId = sharedGroup?.id
        if (isCardClickable && groupId) {
            openGroupDetailView(groupId)
        }
    }

    const onActionButtonClicked = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()

        const group = sharedGroup

        if (properties.isEditing || !group) {
            return
        }

        properties.post?.notifyMarkAsReadIfNeeded()

        switch (group.groupMemberState) {
            case GroupMemberState.APPROVED:
                navigate(`${Paths.TIME_LINE}/${group.id}`)
                break
            case GroupMemberState.PENDING:
                withdrawAccessRequest(group.id)
                break
            case GroupMemberState.NONE:
                requestAccess(group.id)
        }
    }

    const requestAccess = (groupId: string) => {
        setIsRequestingAccess(true)

        Promise.all([groupRepository.requestAccess(groupId), delay(DEFAULT_ANIMATION_DURATION)])
            .catch(setError)
            .finally(() => setIsRequestingAccess(false))
    }

    const withdrawAccessRequest = (groupId: string) => {
        setIsRequestingAccess(true)

        Promise.all([
            groupRepository.withdrawAccessRequest(groupId),
            delay(DEFAULT_ANIMATION_DURATION),
        ])
            .catch(setError)
            .finally(() => setIsRequestingAccess(false))
    }

    const getDeleteButtonIfNeeded = () => {
        return (
            properties.onDeleteButtonClicked && (
                <FilledCloseButtonWithWhiteCross
                    disabled={!properties.isDeleteButtonEnabled}
                    onClick={() => properties.onDeleteButtonClicked!()}
                />
            )
        )
    }

    if (!properties.sharedGroupId) {
        return <></>
    }

    if (!sharedGroup) {
        return (
            <PostListItemContainer>
                <DeletedGroupContainer bgcolor={hasWhiteBackground ? 'white' : grey_3}>
                    <Typography variant="body1">
                        {translations('shared_group_has_been_deleted')}
                    </Typography>
                    {getDeleteButtonIfNeeded()}
                </DeletedGroupContainer>
            </PostListItemContainer>
        )
    }

    return (
        <>
            <PostListItemContainer hasHorizontalMargins={!properties.isEditing}>
                <Container
                    isClickable={isCardClickable}
                    bgcolor={hasWhiteBackground ? 'white' : grey_3}
                    onClick={onCardClicked}
                >
                    {sharedGroup.image && (
                        <ImageView src={fileRepository.getImageUrl(sharedGroup.image)} />
                    )}
                    {sharedGroup.image === undefined && (
                        <FallbackImageView>
                            <FallbackImage />
                        </FallbackImageView>
                    )}
                    <ContentView>
                        <NameTextView variant="body1">{sharedGroup.name}</NameTextView>

                        <NumberOfMembersTextView variant="body2">
                            {translations(
                                'plural_group_with_members',
                                [sharedGroup.numberOfMembers],
                                sharedGroup.numberOfMembers
                            )}
                        </NumberOfMembersTextView>

                        <Box mt={2}>
                            <PrimaryContainedButton
                                isLoading={isRequestingAccess}
                                sx={{ height: '40px' }}
                                title={getButtonTitle()}
                                onClick={onActionButtonClicked}
                            />
                        </Box>
                    </ContentView>
                    <DeleteButtonContainer>{getDeleteButtonIfNeeded()}</DeleteButtonContainer>
                </Container>
            </PostListItemContainer>

            <ErrorHandler error={error} translations={translations} />
        </>
    )
}

const DeletedGroupContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}))

const Container = styled(({ isClickable, ...properties }: BoxProps & { isClickable: boolean }) => (
    <Box {...properties} />
))(({ isClickable }) => ({
    cursor: isClickable ? 'pointer' : 'default',
    display: 'flex',
    height: '140px',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '16px',
}))

const ImageView = styled('img')(() => ({
    width: '140px',
    height: '140px',
    borderTopLeftRadius: '16px',
    borderBottomLeftRadius: '16px',
    objectFit: 'cover',
}))

const FallbackImageView = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '140px',
    height: '140px',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: '16px',
    borderBottomLeftRadius: '16px',
    backgroundColor: theme.palette.primary.main,
}))

const FallbackImage = styled(GroupIcon)(() => ({
    height: '64px',
    width: '64px',
    fill: 'white',
}))

const ContentView = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(3),
    paddingRight: theme.spacing(2),
    overflow: 'hidden',
}))

const NameTextView = styled(Typography)(() => ({
    width: '100%',
    display: 'inline-block',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}))

const NumberOfMembersTextView = styled(Typography)(() => ({
    fontSize: '12px',
}))

const DeleteButtonContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
}))
