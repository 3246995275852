import { styled, Typography, TypographyProps } from '@mui/material'

export const TitleTextView = styled((props: TypographyProps) => (
    <Typography variant="h2" {...props} />
))(({ theme }) => ({
    fontWeight: 800,
    marginTop: theme.spacing(4),
    padding: theme.spacing(0, 3),
    inlineSize: '100%',
    overflowWrap: 'break-word',
}))
