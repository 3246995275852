import { SVGProps } from './SVGProps'

export const EyeShow = (properties: SVGProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={properties.color}
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="Variant=Show">
            <g id="Union">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.74997 12.0001C2.74997 10.379 3.68767 8.8347 5.359 7.66477C7.02796 6.4965 9.37356 5.75009 12 5.75009C14.6264 5.75009 16.972 6.4965 18.6409 7.66477C20.3123 8.8347 21.25 10.379 21.25 12.0001C21.25 13.6212 20.3123 15.1655 18.6409 16.3354C16.972 17.5037 14.6264 18.2501 12 18.2501C9.37356 18.2501 7.02796 17.5037 5.359 16.3354C3.68767 15.1655 2.74997 13.6212 2.74997 12.0001ZM12 4.25009C9.10354 4.25009 6.44913 5.07069 4.4988 6.43592C2.55084 7.79949 1.24997 9.75519 1.24997 12.0001C1.24997 14.245 2.55084 16.2007 4.4988 17.5643C6.44913 18.9295 9.10354 19.7501 12 19.7501C14.8964 19.7501 17.5508 18.9295 19.5011 17.5643C21.4491 16.2007 22.75 14.245 22.75 12.0001C22.75 9.75519 21.4491 7.79949 19.5011 6.43592C17.5508 5.07069 14.8964 4.25009 12 4.25009ZM9.74997 12.0001C9.74997 10.7575 10.7573 9.75009 12 9.75009C13.2426 9.75009 14.25 10.7575 14.25 12.0001C14.25 13.2427 13.2426 14.2501 12 14.2501C10.7573 14.2501 9.74997 13.2427 9.74997 12.0001ZM12 8.25009C9.9289 8.25009 8.24997 9.92902 8.24997 12.0001C8.24997 14.0712 9.9289 15.7501 12 15.7501C14.071 15.7501 15.75 14.0712 15.75 12.0001C15.75 9.92902 14.071 8.25009 12 8.25009Z"
                    fill="current"
                />
            </g>
        </g>
    </svg>
)
