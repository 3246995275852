import { Group } from './Group'

export enum GroupEventKey {
    GROUP_CREATED = 'group-created',
    GROUP_UPDATED = 'group-updated',
    GROUP_ACCESS_REQUESTED = 'group-access-requested',
    GROUP_ACCESS_WITHDRAWN = 'group-access-withdrawn',
    GROUP_LEFT = 'group-left',
    GROUP_DELETED = 'group-deleted',
}

export class GroupCreatedEvent extends CustomEvent<Group> {
    constructor(group: Group) {
        super(GroupEventKey.GROUP_CREATED, { detail: group })
    }
}

export class GroupUpdatedEvent extends CustomEvent<{ id: string; name: string }> {
    constructor(groupId: string, name: string) {
        super(GroupEventKey.GROUP_UPDATED, {
            detail: { id: groupId, name },
        })
    }
}

export class GroupAccessRequestedEvent extends CustomEvent<{ group: Group }> {
    constructor(group: Group) {
        super(GroupEventKey.GROUP_ACCESS_REQUESTED, { detail: { group } })
    }
}

export class GroupAccessWithdrawnEvent extends CustomEvent<{ group: Group }> {
    constructor(group: Group) {
        super(GroupEventKey.GROUP_ACCESS_WITHDRAWN, { detail: { group } })
    }
}

export class GroupLeftEvent extends CustomEvent<{ groupId: string }> {
    constructor(groupId: string) {
        super(GroupEventKey.GROUP_LEFT, { detail: { groupId } })
    }
}

export class GroupDeletedEvent extends CustomEvent<{ groupId: string }> {
    constructor(groupId: string) {
        super(GroupEventKey.GROUP_DELETED, { detail: { groupId } })
    }
}
