import { DateTime } from 'luxon'
import i18n from '../../../i18n'
import { FromToLabel } from './FromToLabel'

interface Properties {
    date: DateTime
    isSameDay: boolean
    isAllDay: boolean
    label: string
}

export const StartEndDateMultiDay = (properties: Properties) => {
    if (properties.isSameDay) {
        return null
    }

    if (properties.isAllDay) {
        return (
            <FromToLabel>
                {properties.label}&nbsp;
                <span>
                    {properties.date.toLocaleString(
                        {
                            weekday: 'long',
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                        },
                        {
                            locale: i18n.language,
                        }
                    )}
                </span>
            </FromToLabel>
        )
    }

    return (
        <FromToLabel>
            {properties.label}&nbsp;
            <span>
                {properties.date.toLocaleString(
                    {
                        weekday: 'short',
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    },
                    {
                        locale: i18n.language,
                    }
                )}
            </span>
            &nbsp;&#183;&nbsp;
            <span>{properties.date.toLocaleString(DateTime.TIME_24_SIMPLE)}</span>
        </FromToLabel>
    )
}
