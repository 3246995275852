import { DateTime } from 'luxon'
import { TranslationFn } from '../WithTranslations'

export enum MonthlyOption {
    CURRENT_DAY = '0',
    FIRST = '+1',
    SECOND = '+2',
    THIRD = '+3',
    FOURTH = '+4',
    LAST = '-1',
}

export function toMonthlyOption(value: string): MonthlyOption | undefined {
    switch (value) {
        case '0':
            return MonthlyOption.CURRENT_DAY
        case '+1':
            return MonthlyOption.FIRST
        case '+2':
            return MonthlyOption.SECOND
        case '+3':
            return MonthlyOption.THIRD
        case '+4':
            return MonthlyOption.FOURTH
        case '+5':
            return MonthlyOption.LAST
        default:
            return undefined
    }
}

export function toMonthlyOptionStringValue({
    translations,
    ...params
}: {
    option: MonthlyOption
    date: DateTime
    translations: TranslationFn
}): string {
    switch (params.option) {
        case MonthlyOption.CURRENT_DAY:
            return `${params.date.day}${translations('th')}`
        case MonthlyOption.FIRST:
            return translations('first').toLowerCase()
        case MonthlyOption.SECOND:
            return translations('second').toLowerCase()
        case MonthlyOption.THIRD:
            return translations('third').toLowerCase()
        case MonthlyOption.FOURTH:
            return translations('fourth').toLowerCase()
        case MonthlyOption.LAST:
            return translations('last').toLowerCase()
        default:
            return ''
    }
}

export function getLocalisedMonthlyOptionString(params: {
    option: MonthlyOption
    date: DateTime
    locale: string
    translations: TranslationFn
}): string {
    const value =
        toMonthlyOptionStringValue(params) +
        (params.option === MonthlyOption.CURRENT_DAY
            ? ''
            : ` ${params.date.toFormat('EEEE', { locale: params.locale })}`)
    return params.translations('of_the_month', [value])
}
