'use client'

import Markdown, { Options } from 'react-markdown'
import { Link, styled, Typography, TypographyProps } from '@mui/material'
import { ReadLessTextView } from './ReadLessTextView'
import { PropsWithChildren } from 'react'
import remarkGfm from 'remark-gfm'

export const READ_LESS_LINK = 'read_less'

export const MarkdownTextView = styled(
    ({
        isCollapsed,
        maxLines,
        onCollapseClicked,
        translations,
        ...properties
    }: Options & {
        isCollapsed?: boolean
        maxLines: number
        translations: {
            readLess: string
        }
        onCollapseClicked?: () => void
    }) => (
        <Markdown
            {...properties}
            remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
            components={{
                h1: (properties) => <HeadingTextView children={properties.children} />,
                h2: (properties) => <HeadingTextView children={properties.children} />,
                h3: (properties) => <HeadingTextView children={properties.children} />,
                h4: (properties) => <HeadingTextView children={properties.children} />,
                h5: (properties) => <HeadingTextView children={properties.children} />,
                h6: (properties) => <HeadingTextView children={properties.children} />,
                a: ({ node, ref, ...props }) => {
                    if (node?.properties.href === READ_LESS_LINK) {
                        return (
                            <ReadLessTextView
                                translations={translations}
                                onClicked={() => onCollapseClicked?.()}
                            />
                        )
                    }

                    return <Link component="a" rel="noreferrer" target="_blank" {...props} />
                },
            }}
            disallowedElements={['img', 'hr', 'code', 'pre']}
            unwrapDisallowed={true}
        />
    )
)(({ isCollapsed, maxLines }) => ({
    display: isCollapsed ?? true ? '-webkit-box' : 'block',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: isCollapsed ?? true ? maxLines : 'unset',
    maxHeight: isCollapsed ?? true ? `${21 * maxLines}px` : '',
    lineHeight: '21px',
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',

    p: {
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },

    'ul, ol': {
        display: 'flex',
        flexDirection: 'column',
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
}))

const HeadingTextView = styled(({ children, ...props }: PropsWithChildren<TypographyProps>) => (
    <Typography variant="body1" component="span" {...props}>
        {children}
    </Typography>
))(() => ({
    fontWeight: 'bold',
}))
