import { firstBy } from 'thenby'
import { MinimalUserInfo } from 'shared/lib/users/MinimalUserInfo'

export const sortUsersByNameFn = firstBy((a: MinimalUserInfo, b: MinimalUserInfo) => {
    let getWordOfLastnameA = getWordOfLastNameToSortOn(a)
    let getWordOfLastnameB = getWordOfLastNameToSortOn(b)

    const isNumberA = !!Number(getWordOfLastnameA[0])
    const isNumberB = !!Number(getWordOfLastnameB[0])

    if (isNumberA && isNumberB) {
        return Number(getWordOfLastnameA[0]) - Number(getWordOfLastnameB[0])
    } else if (isNumberA && !isNumberB) {
        return 1
    } else if (!isNumberA && isNumberB) {
        return -1
    }

    return getWordOfLastnameA.localeCompare(getWordOfLastnameB)
}).thenBy((a: MinimalUserInfo, b: MinimalUserInfo) => a.firstName.localeCompare(b.firstName))

export const sortUsersByLastName = <T extends MinimalUserInfo>(users: T[]): T[] => {
    return users.sort(sortUsersByNameFn)
}

export const groupByAlphabeticKey = <T extends MinimalUserInfo>(
    users: T[]
): Record<string, T[]> => {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz#'

    return sortUsersByLastName(users).reduce((acc, user) => {
        const letter = getWordOfLastNameToSortOn(user)[0].toLowerCase()
        const key = alphabet.includes(letter) ? letter : '#'

        return {
            ...acc,
            [key]: [...(acc[key] || []), user].filter(
                (user) => getWordOfLastNameToSortOn(user)[0].toLowerCase() === key || key === '#'
            ),
        }
    }, {})
}

export const getWordOfLastNameToSortOn = (user: MinimalUserInfo) => {
    for (const word of user.lastName.split(' ')) {
        if (/[A-Z]/.test(word)) {
            return word
        }
    }

    return (user.lastName.split(' ') || []).pop() || ''
}

export const getInitials = (user: MinimalUserInfo): string => {
    return `${user.firstName[0]}${getWordOfLastNameToSortOn(user)[0]}`.toUpperCase()
}
