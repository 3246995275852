import { useCallback, useEffect, useRef, useState } from 'react'
import { postRepository } from '../index'
import { Post } from './Post'
import { GroupEventKey, GroupUpdatedEvent } from '../groups/events'

export const usePost = (postFromState?: Post) => {
    const isLoadingRef = useRef(false)

    const [error, setError] = useState<Error | undefined>()
    const [post, setPost] = useState(postFromState)

    const getPost = useCallback((postId: string) => {
        if (isLoadingRef.current) {
            return
        }

        isLoadingRef.current = true

        postRepository
            .getPost(postId)
            .then(setPost)
            .catch(setError)
            .finally(() => (isLoadingRef.current = false))
    }, [])

    useEffect(() => {
        const onGroupUpdated = (event: Event) => {
            const { id, name } = (event as GroupUpdatedEvent).detail

            if (post?.groupId === id) {
                setPost((post) => post?.cloneWith({ groupName: name }))
            }
        }

        document.addEventListener(GroupEventKey.GROUP_UPDATED, onGroupUpdated)

        return () => {
            document.removeEventListener(GroupEventKey.GROUP_UPDATED, onGroupUpdated)
        }
    }, [post?.groupId])

    return { isLoading: isLoadingRef.current, error, post, setPost, getPost }
}
