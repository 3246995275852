import { BeautifulMentionsMenuItem } from 'lexical-beautiful-mentions'
import { MenuOption } from 'lexical-beautiful-mentions/Menu'
import { MinimalUserInfo } from 'shared/lib/users/MinimalUserInfo'

export class TagOption extends MenuOption {
    readonly menuItem: BeautifulMentionsMenuItem
    constructor(
        public readonly trigger: string,
        value: string,
        displayValue: string,
        public readonly user: MinimalUserInfo
    ) {
        const data = JSON.parse(JSON.stringify(user))
        super(value, displayValue, data)

        this.menuItem = {
            trigger,
            value,
            displayValue,
            data,
        }
    }
}
