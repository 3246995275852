import { Typography } from '@mui/material'
import { useState } from 'react'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { GreyContainedButton } from 'shared/lib/components/buttons/GreyButtons'
import { TextField } from 'shared/lib/components/textfields/TextField'
import { isValidEmail } from 'shared/lib/utils/StringUtils'
import { useTranslation } from 'shared/lib/i18n'
import { sessionRepository } from '../../index'
import {
    PrimaryActionButtonContainer,
    PrimaryActionButtonsContainer,
} from 'shared/lib/components/PrimaryActionButtonsContainer'
import { Title } from '../SessionPage'
import { SignUpStepProperties } from './SignUpStepProperties'

interface Properties extends SignUpStepProperties {
    emailAddress: string

    onEmailAddressChanged(emailAddress: string): void
    setIsEmailAlreadyRegistered(value: boolean): void
    setIsLoading(isLoading: boolean): void
    setError(error: any): void
}

export const EnterEmailAddressStep = (properties: Properties) => {
    const { emailAddress } = properties
    const translations = useTranslation()

    const [invalidEmailError, setInvalidEmailError] = useState<string | undefined>()

    const canContinue = isValidEmail(emailAddress) && invalidEmailError === undefined

    let abortController: AbortController | undefined = undefined

    const onEmailAddressChanged = (value: string) => {
        properties.onEmailAddressChanged(value)

        setInvalidEmailError(undefined)

        if (isValidEmail(value)) {
            checkEmailAddress(value)
        }
    }

    const onEmailAddressBlur = () => {
        if (emailAddress.trim().length === 0) {
            setInvalidEmailError(undefined)
        }
    }

    const onNextButtonClicked = async () => {
        if (!canContinue) return

        properties.setIsLoading(true)
        await checkEmailAddress(emailAddress, (canContinue) => {
            properties.setIsLoading(false)

            if (canContinue) {
                properties.onNextButtonClicked()
            }
        })
    }

    const checkEmailAddress = async (
        emailAddress: string,
        continueCallback?: (canContinue: boolean) => void
    ) => {
        abortController?.abort()
        abortController = new AbortController()

        try {
            const result = await sessionRepository.checkEmailAddress(emailAddress, abortController)

            if (!result) {
                return
            }

            properties.setIsEmailAlreadyRegistered(result.isAlreadyRegistered)

            switch (true) {
                case !result.isValidHostname:
                    setInvalidEmailError(translations('invalid_email_address'))
                    continueCallback?.(false)
                    break
                case result.isConfirmed:
                    setInvalidEmailError(translations('email_address_already_registered'))
                    continueCallback?.(false)
                    break
                default:
                    setInvalidEmailError(undefined)
                    continueCallback?.(true)
            }
        } catch (error) {
            properties.setError(error)
        }
    }

    return (
        <>
            <Title variant="h1" mt={8}>
                {translations('email_address')}
            </Title>

            <Typography variant="body2" mt={2}>
                {translations('sign_up_description')}
            </Typography>

            <TextField
                fullWidth={true}
                autoFocus={true}
                value={emailAddress}
                error={invalidEmailError !== undefined}
                helperText={invalidEmailError}
                label={translations('email_address')}
                onChanged={onEmailAddressChanged}
                onBlur={() => onEmailAddressBlur()}
                onEnterKeyPressed={() => onNextButtonClicked()}
                sx={{
                    marginTop: 5,
                }}
            />

            <PrimaryActionButtonsContainer>
                <GreyContainedButton
                    fullWidth
                    title={translations('back')}
                    onClick={properties.onBackButtonClicked}
                />

                <PrimaryActionButtonContainer>
                    <PrimaryContainedButton
                        fullWidth={true}
                        isLoading={properties.isLoading}
                        title={translations('next')}
                        disabled={!canContinue}
                        onClick={() => onNextButtonClicked()}
                    />
                </PrimaryActionButtonContainer>
            </PrimaryActionButtonsContainer>
        </>
    )
}
