import { Box } from '@mui/material'
import { StyledVirtuoso } from '../../../common/StyledVirtuoso'
import { User } from '../../../user/User'
import { NoResults } from '../../../user/userList/NoResults'
import { MemberListRow } from './MemberListRow'
import { MEMBER_LOADING_ROW_HEIGHT, MemberLoadingRow } from './MemberLoadingRow'
import { MemberItemViewModel, MemberLoadingViewModel, MemberViewModel } from './MemberViewModel'

interface Properties {
    hasNoResults: boolean
    height: number
    width: number
    users: User[]
    selectedUsers: User[]
    onUserSelected: (user: User) => void
}

export const MemberList = (properties: Properties) => {
    const { users } = properties

    const rowsThatFitScreen = Math.ceil(properties.height / MEMBER_LOADING_ROW_HEIGHT)

    const viewModels =
        users.length > 0
            ? users.map(
                  (user) =>
                      new MemberItemViewModel(
                          user,
                          properties.selectedUsers.find(
                              (selectedUser) => user.id === selectedUser.id
                          ) !== undefined,
                          properties.onUserSelected
                      )
              )
            : Array.from({ length: rowsThatFitScreen }).map(() => new MemberLoadingViewModel())

    if (properties.hasNoResults) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                height={properties.height}
                width={properties.width}
            >
                <NoResults />
            </Box>
        )
    }

    return (
        <StyledVirtuoso
            totalCount={viewModels.length}
            itemContent={(index) =>
                getRow({
                    index,
                    viewModels: viewModels,
                    onUserSelected: properties.onUserSelected,
                })
            }
            overscan={500}
            style={{
                height: properties.height,
                width: properties.width,
                backgroundColor: 'white',
            }}
        />
    )
}

const getRow = (properties: {
    index: number
    viewModels: MemberViewModel[]
    onUserSelected: (user: User) => void
}) => {
    const viewModel = properties.viewModels[properties.index]

    if (viewModel instanceof MemberItemViewModel) {
        return <MemberListRow viewModel={viewModel} />
    } else if (viewModel instanceof MemberLoadingViewModel) {
        return <MemberLoadingRow />
    }

    return <></>
}
