import { Box, BoxProps, styled, Typography, Unstable_Grid2 as MuiGrid } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { SelectLanguageButton } from 'shared/lib/components/buttons/SelectLanguageButton'
import { TextField } from 'shared/lib/components/textfields/TextField'
import { grey_1 } from 'shared/lib/theme/Theme'
import donkey from '../../assets/images/donkey.jpg'
import salvationArmy from '../../assets/images/salvationArmy.jpg'
import salvationArmyLogo from '../../assets/images/salvationArmyLogo.png'
import selectChurchLogo from '../../assets/images/selectChurchLogo.png'
import selectChurchLogoEnglish from '../../assets/images/selectChurchLogoEnglish.png'
import SessionContext from '../../authentication/SessionContextProvider'
import i18n, { SUPPORTED_LANGUAGES } from '../../i18n'
import { useTranslation } from 'shared/lib/i18n'
import { NoResults } from '../../user/userList/NoResults'
import ChurchContext from '../ChurchContextProvider'
import { filterChurches } from '../Utils'
import { useChurches } from '../hooks'
import { SelectChurchCard } from './SelectChurchCard'
import { SelectChurchLoadingCard } from './SelectChurchLoadingCard'
import { SelectChurchItemViewModel, SelectChurchLoadingViewModel } from './SelectChurchViewModel'
import i18next from 'i18next'

interface Properties {
    isForSalvationArmy?: boolean
}

export const SelectChurchPage = ({ isForSalvationArmy }: Properties) => {
    const translations = useTranslation()

    const { clearData } = useContext(ChurchContext)!
    const { getIsSignedIn } = useContext(SessionContext)!

    const { isLoading, churches } = useChurches()
    const salvationArmyChurches = churches.filter(
        (church) => church.developerName === 'Leger des Heils'
    )

    const [query, setQuery] = useState('')

    const isSignedIn = getIsSignedIn()
    const filteredChurches = filterChurches(
        isForSalvationArmy ? salvationArmyChurches : churches,
        query
    ).sort((a, b) => a.name.localeCompare(b.name))

    const viewModels = isLoading
        ? Array.from({ length: 7 }, () => new SelectChurchLoadingViewModel())
        : filteredChurches.map((church) => new SelectChurchItemViewModel(church))

    const hasNoResults = filteredChurches.length === 0 && query.trim() !== ''

    useEffect(() => {
        if (!isSignedIn) {
            clearData()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isSignedIn) {
        return <></>
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container columns={{ mobile: 4, tablet: 8, desktop: 12 }}>
                <LeftGrid container mobile={4} tablet={5} desktop={8}>
                    <HeaderContainer flexDirection="column">
                        <HeaderTextContainer>
                            <HeaderTextTopContainer>
                                {isForSalvationArmy && <SalvationArmyLogoMobile />}
                                <Box display="flex" flexDirection="column" flexGrow={1}>
                                    <HeaderTitleTextView variant="h1">
                                        {isForSalvationArmy
                                            ? translations('stay_connected_and_informed')
                                            : translations('welcome_to_donkey_web')}
                                    </HeaderTitleTextView>

                                    {isForSalvationArmy && (
                                        <HeaderSubTitleTextView>
                                            {translations('go_to_your_region_or_organisation')}
                                        </HeaderSubTitleTextView>
                                    )}
                                </Box>
                                {isForSalvationArmy && <SalvationArmyLogo />}
                            </HeaderTextTopContainer>
                            <TextField
                                fullWidth={true}
                                isSearch={true}
                                variant="outlined"
                                autoComplete="off"
                                value={query}
                                placeholder={translations('find_your_app_to_log_in')}
                                onChanged={setQuery}
                            />
                        </HeaderTextContainer>
                        <GradientBox />
                    </HeaderContainer>

                    <ChurchesGrid
                        container
                        columns={{ mobile: 1, tablet: 2, desktop: 2 }}
                        spacing={2}
                    >
                        {viewModels.map((viewModel, index) => {
                            return (
                                <ChurchGrid mobile={1} tablet={1} key={`church_grid_${index}`}>
                                    {viewModel instanceof SelectChurchItemViewModel ? (
                                        <SelectChurchCard church={viewModel.church} />
                                    ) : (
                                        <SelectChurchLoadingCard />
                                    )}
                                </ChurchGrid>
                            )
                        })}
                        {hasNoResults && <NoResults />}
                    </ChurchesGrid>
                </LeftGrid>

                <RightGrid
                    mobile={0}
                    tablet={3}
                    desktop={4}
                    backgroundimage={isForSalvationArmy ? salvationArmy : donkey}
                >
                    <LanguageButtonContainer>
                        <SelectLanguageButton
                            changeLanguage={async (language: string) => {
                                await i18next.changeLanguage(language)
                            }}
                            currentLanguage={i18n.language}
                            hasNegativeMargin={true}
                            supportedLanguages={SUPPORTED_LANGUAGES}
                        />
                    </LanguageButtonContainer>
                    {!isForSalvationArmy && <DonkeyMobileLogo />}
                </RightGrid>
            </Grid>
        </Box>
    )
}

const Grid = styled(MuiGrid)(() => ({
    flexGrow: 1,
    display: 'flex',
}))

const LeftGrid = styled(MuiGrid)(() => ({
    height: '100dvh',
    background: 'white',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'start',
    alignContent: 'start',
    position: 'relative',
}))

const HeaderContainer = styled(Box)(({ theme }) => ({
    position: 'sticky',
    display: 'flex',
    width: '100%',
    top: 0,
    [theme.breakpoints.only('mobile')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },

    [theme.breakpoints.up('phablet')]: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
    },
    zIndex: 2,
}))

const HeaderTextContainer = styled(Box)(({ theme }) => ({
    backdropFilter: 'blur(16px)',
    backgroundColor: `rgba(255, 255, 255, 0.6)`,
    paddingTop: theme.spacing(5),
    [theme.breakpoints.up('tablet')]: {
        paddingTop: theme.spacing(15),
    },
}))

const HeaderTextTopContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('tablet')]: {
        marginBottom: theme.spacing(5),
    },
}))

const HeaderTitleTextView = styled(Typography)(({ theme }) => ({
    whiteSpace: 'pre-wrap',
    fontSize: '48px',
    marginRight: theme.spacing(1),

    [theme.breakpoints.only('mobile')]: {
        fontSize: '24px',
    },
}))

const HeaderSubTitleTextView = styled(Box)(({ theme }) => ({
    color: grey_1,
    fontSize: '24px',
    fontWeight: 800,
    marginTop: theme.spacing(3),

    [theme.breakpoints.only('mobile')]: {
        fontSize: '14px',
        fontWeight: 'normal',
        marginTop: theme.spacing(1),
    },
}))

const GradientBox = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)',
    height: 16,
    [theme.breakpoints.up('tablet')]: {
        height: 60,
    },
}))

const ChurchesGrid = styled(Grid)(({ theme }) => ({
    maxHeight: '100dvh',
    zIndex: 1,
    alignItems: 'start',

    [theme.breakpoints.only('mobile')]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },

    [theme.breakpoints.up('phablet')]: {
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(6),
    },
}))

const ChurchGrid = styled(Grid)(() => ({
    width: '100%',
    alignSelf: 'stretch',
}))

const RightGrid = styled(MuiGrid)<{ backgroundimage: any }>(({ backgroundimage }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    backgroundImage: `url(${backgroundimage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
}))

const SalvationArmyLogoMobile = styled((props: BoxProps) => (
    <Box component="img" src={salvationArmyLogo} {...props} />
))(({ theme }) => ({
    alignSelf: 'start',
    maxHeight: '72px',
    aspectRatio: '1/1',
    marginRight: theme.spacing(3),

    [theme.breakpoints.up('phablet')]: {
        display: 'none',
        marginRight: 0,
    },
}))

const SalvationArmyLogo = styled((props: BoxProps) => (
    <Box component="img" src={salvationArmyLogo} {...props} />
))(({ theme }) => ({
    alignSelf: 'start',
    maxHeight: '100px',
    aspectRatio: '1/1',

    [theme.breakpoints.up('tablet')]: {
        marginTop: '-50px',
    },

    [theme.breakpoints.only('mobile')]: {
        display: 'none',
    },
}))

const DonkeyMobileLogo = styled((props: BoxProps) => (
    <Box
        component="a"
        href={'https://donkeymobile.app'}
        {...props}
        target="_blank"
        rel="noopener noreferrer"
    >
        <Box
            component="img"
            src={i18n.language === 'nl' ? selectChurchLogo : selectChurchLogoEnglish}
            alt="Logo"
            style={{ maxWidth: '100%', height: 'auto' }}
        />
    </Box>
))(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: theme.spacing(8),
    width: '100%',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.5%)',
    cursor: 'pointer',
    textDecoration: 'none',

    [theme.breakpoints.down('tablet')]: {
        display: 'none',
    },
}))

const LanguageButtonContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(5),
    zIndex: 10,

    [theme.breakpoints.only('mobile')]: {
        right: theme.spacing(2),
    },
}))
