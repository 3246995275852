export class Image {
    constructor(
        readonly key: string,
        readonly thumbnailKey: string,
        readonly width: number,
        readonly height: number
    ) {}

    static fromResponse(response: any): Image {
        return new Image(response.key, response.thumbnailKey, response.width, response.height)
    }
}
