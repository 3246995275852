import { Box } from '@mui/material'
import React from 'react'
import { useUsers } from '../../../user/hooks'
import { sortUsersByLastName } from '../../../user/Utils'
import { filterUsers } from 'shared/lib/users/UserUtils'
import { User } from '../../../user/User'
import AutoSizer from 'react-virtualized-auto-sizer'
import { TextField } from 'shared/lib/components/textfields/TextField'
import theme from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'
import { MemberList } from './MemberList'
import { SelectedMemberList } from './SelectedMemberList'
import { sessionRepository } from '../../../index'

interface Properties {
    selectedUsers: User[]
    excludedUserIds?: User['id'][]
    onUserSelected: (user: User) => void
    query: string
    setQuery: (query: string) => void
}

export const SelectMembersStepView = (properties: Properties) => {
    const { users, isLoading } = useUsers({ isApproved: true })

    const signedInUser = sessionRepository.signedInUser
    const selectableUsers = users.filter(
        (user) =>
            user.id !== signedInUser?.id && !(properties.excludedUserIds ?? []).includes(user.id)
    )
    const filteredUsers = sortUsersByLastName(filterUsers(selectableUsers, properties.query))

    return (
        <>
            <AutoSizer>
                {({ height, width }) => (
                    <MemberList
                        users={filteredUsers}
                        selectedUsers={properties.selectedUsers}
                        hasNoResults={!isLoading && !filteredUsers.length}
                        height={height}
                        onUserSelected={properties.onUserSelected}
                        width={width}
                    />
                )}
            </AutoSizer>
        </>
    )
}

export const SearchUsers = (properties: Properties) => {
    const translations = useTranslation()

    return (
        <Box mr={3} ml={3}>
            <TextField
                isSearch={true}
                variant="outlined"
                inputProps={{
                    sx: {
                        backgroundColor: theme.palette.background.default,
                    },
                }}
                placeholder={translations('search')}
                value={properties.query}
                onChanged={properties.setQuery}
                width="100%"
            />
            {properties.selectedUsers.length > 0 && (
                <SelectedMemberList
                    users={properties.selectedUsers}
                    onUserSelected={properties.onUserSelected}
                />
            )}
        </Box>
    )
}
