import {
    IconButton,
    AppBar as MuiAppBar,
    Tab as MuiTab,
    Tabs as MuiTabList,
    Toolbar,
    styled,
    tabsClasses,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as SignOutIcon } from 'shared/lib/assets/svg/logout.svg'
import { TextButton } from 'shared/lib/components/buttons/TextButtons'
import { useIsDesktop, useIsMobile } from 'shared/lib/theme/BreakPointHooks'
import { grey_3, textColor, toolbarHeight } from 'shared/lib/theme/Theme'
import SessionContext from '../../authentication/SessionContextProvider'
import ChurchContext from '../../churches/ChurchContextProvider'
import {
    CalendarIcon,
    MyChurchGroupIcon,
    NotificationsIcon,
    UserListIcon,
} from '../../groups/TabBarIcons'
import { useTranslation } from 'shared/lib/i18n'
import { fileRepository } from '../../index'
import { NotificationsContext } from '../../notifications/NotificationsContextProvider'
import { useSelectedRootPathName } from '../../routing/hooks'
import { Paths } from '../../routing/paths'
import { ProfileButton } from './ProfileButton'

export const TabletDesktopHeader = () => {
    const translations = useTranslation()
    const navigate = useNavigate()

    const [isMobile, isDesktop] = [useIsMobile(), useIsDesktop()]

    const { church } = useContext(ChurchContext)!
    const { signOut, getSignedInUser } = useContext(SessionContext)!
    const { hasUnreadNotifications } = useContext(NotificationsContext)

    const user = getSignedInUser()!
    const selectedRootPathName = useSelectedRootPathName() || false

    return isMobile ? null : (
        <AppBar position="static">
            <Toolbar sx={{ gap: 1 }}>
                <ChurchImage
                    src={fileRepository.getImageUrl(church?.image)}
                    alt={church?.name}
                    onClick={() => navigate(Paths.TIME_LINE)}
                />
                <TabsGrid tablet>
                    <TabList value={selectedRootPathName} onChange={(_, value) => navigate(value)}>
                        <Tab
                            label={church?.getMyChurchGroupName(translations)}
                            icon={
                                <MyChurchGroupIcon
                                    isSelected={selectedRootPathName === Paths.TIME_LINE}
                                />
                            }
                            value={Paths.TIME_LINE}
                            iconPosition="start"
                        />
                        <Tab
                            label={translations('page_calendar')}
                            icon={
                                <CalendarIcon isSelected={selectedRootPathName === Paths.EVENTS} />
                            }
                            value={Paths.EVENTS}
                            iconPosition="start"
                        />
                        <Tab
                            label={
                                church
                                    ? translations(church.getUserListPageNameTranslationKey())
                                    : undefined
                            }
                            icon={
                                <UserListIcon isSelected={selectedRootPathName === Paths.MEMBERS} />
                            }
                            value={Paths.MEMBERS}
                            iconPosition="start"
                        />
                        <Tab
                            label={translations('notifications')}
                            icon={
                                <NotificationsIcon
                                    isSelected={selectedRootPathName === Paths.NOTIFICATIONS}
                                    hasUnreadNotifications={hasUnreadNotifications}
                                />
                            }
                            value={Paths.NOTIFICATIONS}
                            iconPosition="start"
                        />
                    </TabList>
                </TabsGrid>
                <ProfileButton user={user} />
                {isDesktop && (
                    <SignOutButton
                        title={translations('sign_out')}
                        startIcon={<SignOutIcon />}
                        onClick={() => signOut()}
                    />
                )}
                {!isDesktop && <IconButton onClick={() => signOut()}>{<SignOutIcon />}</IconButton>}
            </Toolbar>
        </AppBar>
    )
}

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    height: toolbarHeight,
    boxShadow: 'none',
    borderBottom: `2px solid ${grey_3}`,
    zIndex: theme.zIndex.drawer + 1,
}))

const ChurchImage = styled('img')(({ theme }) => ({
    width: 40,
    height: 40,
    borderRadius: theme.spacing(1.5),
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
}))

const TabsGrid = styled(Grid)(({ theme }) => ({
    minWidth: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
}))

const TabList = styled(MuiTabList)(({ theme }) => ({
    height: toolbarHeight,
    '& .MuiTabs-flexContainer': {
        height: toolbarHeight,
    },

    '& .Mui-selected': {
        fill: theme.palette.primary.main,
    },

    [`& .${tabsClasses.indicator}`]: {
        height: 4,
        borderRadius: 2,
    },
}))

const Tab = styled(MuiTab)(({ theme }) => ({
    textTransform: 'none',
    gap: theme.spacing(2),
    color: textColor,

    '& .MuiTab-iconWrapper': {
        width: 24,
        height: 24,
    },
}))

const SignOutButton = styled(TextButton)(() => ({
    color: textColor,
    '& svg': {
        fill: textColor,
    },
}))
