import { ListItem as MuiListItem, ListItemText, styled } from '@mui/material'
import { MobileUserSectionViewModel } from './MobileUserViewModels'

interface Properties {
    viewModel: MobileUserSectionViewModel
}

export const UserSectionRow = ({ viewModel }: Properties) => {
    return (
        <SectionHeader>
            <ListItemText primary={viewModel.letter.toUpperCase()} />
        </SectionHeader>
    )
}

const SectionHeader = styled(MuiListItem)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
}))
