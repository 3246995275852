'use client'

import { ButtonProperties, DefaultButton } from './DefaultButton'
import React, { PropsWithChildren } from 'react'
import {
    darkGrey,
    disabledGreenColor,
    grey_1,
    successColor,
    successColorHover,
} from '../../theme/Theme'

export const PrimaryContainedButton = React.forwardRef(
    (properties: PropsWithChildren<ButtonProperties>, ref: React.Ref<HTMLButtonElement>) => {
        const { sx, ...rest } = properties
        return (
            <DefaultButton
                variant="contained"
                color="primary"
                innerRef={ref}
                {...rest}
                sx={{
                    ...sx,
                    color: 'white',
                }}
            />
        )
    }
)

export const InfoContainedButton = React.forwardRef(
    (properties: PropsWithChildren<ButtonProperties>, ref: React.Ref<HTMLButtonElement>) => {
        const { sx, ...rest } = properties
        return (
            <DefaultButton
                variant="contained"
                color="info"
                innerRef={ref}
                {...rest}
                sx={{
                    ...sx,
                }}
            />
        )
    }
)

export const GreenContainedButton = React.forwardRef(
    (properties: PropsWithChildren<ButtonProperties>, ref: React.Ref<HTMLButtonElement>) => {
        const { sx, ...rest } = properties
        return (
            <DefaultButton
                variant="contained"
                color="primary"
                innerRef={ref}
                {...rest}
                sx={{
                    ...sx,
                    backgroundColor: successColor,
                    color: 'white',
                    '&.Mui-disabled': {
                        backgroundColor: disabledGreenColor,
                        color: 'white',
                    },
                    '&:hover': {
                        backgroundColor: successColorHover,
                        color: 'white',
                    },
                }}
            />
        )
    }
)

export const BlackContainedButton = React.forwardRef(
    (properties: PropsWithChildren<ButtonProperties>, ref: React.Ref<HTMLButtonElement>) => {
        const { sx, ...rest } = properties
        return (
            <DefaultButton
                variant="contained"
                color="primary"
                innerRef={ref}
                {...rest}
                sx={{
                    ...sx,
                    backgroundColor: darkGrey,
                    color: 'white',

                    '&.Mui-disabled': {
                        backgroundColor: grey_1,
                    },
                    '&:hover': {
                        backgroundColor: grey_1,
                    },
                }}
            />
        )
    }
)
