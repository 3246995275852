import { Box, MenuItem, styled, Typography } from '@mui/material'
import { useState } from 'react'
import { FilledCloseButtonWithWhiteCross } from '../../components/buttons/CloseButton'
import { PrimaryContainedButton } from '../../components/buttons/ContainedButtons'
import { HorizontalDivider } from '../../components/Divider'
import { Popup } from '../../components/Popup'
import { GreyContainedButton } from '../../components/buttons/GreyButtons'
import { Select } from '../../components/Select'
import { range } from '../../utils/ArrayUtil'
import { TranslationFn } from '../../WithTranslations'
import { Frequency } from '../Frequency'
import { RecurrenceRule } from '../RecurrenceRule'
import { EndsOnView } from './EndsOnView'
import { WeekdayView } from './WeekdayView'
import { MonthlyOptionsView } from './MonthlyOptionsView'
import { DateTime } from 'luxon'
import { toWeekdayFromDate } from '../Weekday'
import { MonthlyOption } from '../MonthlyOption'

interface Properties {
    withBackdrop?: boolean
    locale: string
    start: DateTime
    recurrenceRule: RecurrenceRule | undefined
    translations: TranslationFn
    onDoneButtonClicked: (recurrenceRule: RecurrenceRule) => void
    onCloseButtonClicked: () => void
}

export const CustomRecurringPopup = ({ translations, start, ...properties }: Properties) => {
    const weekday = toWeekdayFromDate(start)

    const [recurrenceRule, setRecurrenceRule] = useState(
        properties.recurrenceRule ??
            new RecurrenceRule(start, 1, Frequency.WEEKLY, weekday ? new Set([weekday]) : undefined)
    )

    const getLocalizedFrequency = (frequency: Frequency) => {
        switch (frequency) {
            case Frequency.DAILY:
                return translations('plural_days', [], recurrenceRule?.interval)
            case Frequency.WEEKLY:
                return translations('plural_weeks', [], recurrenceRule?.interval)
            case Frequency.MONTHLY:
                return translations('plural_months', [], recurrenceRule?.interval)
            case Frequency.YEARLY:
                return translations('plural_years', [], recurrenceRule?.interval)
        }
    }

    const setFrequency = (frequency: Frequency) => {
        var newRecurrenceRule = new RecurrenceRule(
            recurrenceRule.start,
            recurrenceRule.interval,
            frequency,
            undefined,
            undefined,
            recurrenceRule.count,
            recurrenceRule.until
        )

        if (frequency === Frequency.WEEKLY) {
            newRecurrenceRule = new RecurrenceRule(
                recurrenceRule.start,
                recurrenceRule.interval,
                frequency,
                weekday ? new Set([weekday]) : undefined,
                undefined,
                recurrenceRule.count,
                recurrenceRule.until
            )
        } else if (frequency === Frequency.MONTHLY) {
            newRecurrenceRule = new RecurrenceRule(
                recurrenceRule.start,
                recurrenceRule.interval,
                frequency,
                undefined,
                MonthlyOption.CURRENT_DAY,
                recurrenceRule.count,
                recurrenceRule.until
            )
        }

        setRecurrenceRule(newRecurrenceRule)
    }

    return (
        <>
            <Popup isVisible={true} withBackdrop={properties.withBackdrop ?? true}>
                <Box p={3} display={'flex'} alignItems={'center'}>
                    <Box flexGrow={1}>
                        <Title>{translations('repeat')}</Title>
                    </Box>
                    <FilledCloseButtonWithWhiteCross onClick={properties.onCloseButtonClicked} />
                </Box>

                <HorizontalDivider />

                <ContentContainer>
                    <Typography variant="body1">{translations('repeats_every')}</Typography>

                    <Box display="flex" mt={2} gap={1}>
                        <Select
                            MenuProps={{
                                sx: {
                                    maxHeight: '350px',
                                },
                            }}
                            value={recurrenceRule.interval}
                            onChange={(event) =>
                                setRecurrenceRule(
                                    recurrenceRule.copy({ interval: event.target.value as number })
                                )
                            }
                        >
                            {range(1, 100).map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>

                        <Select
                            value={recurrenceRule.frequency}
                            onChange={(event) => setFrequency(event.target.value as Frequency)}
                        >
                            {Object.keys(Frequency).map((value) => (
                                <MenuItem key={value} value={value}>
                                    {getLocalizedFrequency(value as Frequency)}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                    {recurrenceRule.frequency === Frequency.MONTHLY && (
                        <>
                            <HorizontalDivider my={4} />
                            <MonthlyOptionsView
                                recurrenceRule={recurrenceRule}
                                locale={properties.locale}
                                translations={translations}
                                onRecurrenceRuleChanged={setRecurrenceRule}
                            />
                        </>
                    )}

                    {recurrenceRule.frequency === Frequency.WEEKLY && (
                        <>
                            <HorizontalDivider my={4} />
                            <WeekdayView
                                locale={properties.locale}
                                recurrences={recurrenceRule}
                                translations={translations}
                                onRecurrenceRuleChanged={setRecurrenceRule}
                            />
                        </>
                    )}

                    <HorizontalDivider my={4} />

                    <EndsOnView
                        recurrenceRule={recurrenceRule}
                        translations={translations}
                        onRecurrenceRuleChanged={setRecurrenceRule}
                    />
                </ContentContainer>

                <HorizontalDivider />

                <BottomContainer>
                    <GreyContainedButton
                        sx={{
                            minWidth: '30%',
                        }}
                        title={translations('cancel')}
                        onClick={() => properties.onCloseButtonClicked()}
                    />
                    <PrimaryContainedButton
                        sx={{
                            minWidth: '30%',
                        }}
                        title={translations('done')}
                        onClick={() => properties.onDoneButtonClicked(recurrenceRule)}
                    />
                </BottomContainer>
            </Popup>
        </>
    )
}

const Title = styled(Typography)(() => ({
    fontSize: '18px',
    fontWeight: 700,
    marginTop: 'auto',
    marginBottom: 'auto',
}))

const ContentContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'auto',

    '& svg': {
        fill: theme.palette.primary.main,
    },
}))

const BottomContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
}))
