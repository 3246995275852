import { useCallback, useEffect, useRef, useState } from 'react'
import { notificationRepository } from '../index'
import { Notification } from './Notification'
import { NotificationsListViewModel } from './list/NotificationsListViewModels'
import { NotificationsListViewModelBuilder } from './list/NotificationsListViewModelBuilder'
import {
    GroupMemberApprovedEvent,
    GroupMemberDeniedEvent,
    GroupMemberEventKey,
} from '../groups/members/events'
import { NotificationType } from './NotificationType'

export const useNotifications = () => {
    const [notifications, setNotifications] = useState<Notification[]>([])
    const [viewModels, setViewModels] = useState<NotificationsListViewModel[]>([])
    const [nextFrom, setNextFrom] = useState<string | undefined>()

    const isLoadingRef = useRef(false)
    const abortControllerRef = useRef<AbortController>()

    const getNotifications = useCallback((params?: { nextFrom: string }) => {
        if (isLoadingRef.current) {
            return
        }

        isLoadingRef.current = true

        notificationRepository
            .getNotifications({
                from: params?.nextFrom,
                abortSignal: abortControllerRef.current?.signal,
            })
            .then((result) => {
                setNextFrom(result.nextFrom)
                setNotifications((prevState) => {
                    const allNotifications = params?.nextFrom
                        ? [...prevState, ...result.notifications]
                        : result.notifications

                    const viewModels = NotificationsListViewModelBuilder.buildViewModels(
                        allNotifications,
                        !!result.nextFrom
                    )

                    setViewModels(viewModels)

                    return allNotifications
                })
            })
            .catch((error) => {})
            .finally(() => {
                isLoadingRef.current = false
            })
    }, [])

    const loadMoreNotificationsIfNeed = () => {
        if (!nextFrom) return

        getNotifications({ nextFrom })
    }

    const markNotificationAsRead = useCallback(
        (notificationId: string) => {
            notificationRepository.markNotificationAsRead(notificationId)

            const index = notifications.findIndex((n) => n.id === notificationId)
            notifications[index] = notifications[index].cloneWith({ isRead: true })

            setViewModels(
                NotificationsListViewModelBuilder.buildViewModels(notifications, !!nextFrom)
            )
        },
        [notifications, nextFrom]
    )

    const onGroupMemberApprovedOrDenied = useCallback(
        (event: Event) => {
            const { groupId } = (event as GroupMemberApprovedEvent | GroupMemberDeniedEvent).detail
            const notificationsToMarkAsRead = notifications.filter(
                (notification) =>
                    notification.type === NotificationType.USER_REQUESTED_ACCESS_TO_GROUP &&
                    notification.group?.id === groupId
            )

            notificationsToMarkAsRead.forEach((notification) =>
                markNotificationAsRead(notification.id)
            )
        },
        [notifications, markNotificationAsRead]
    )

    useEffect(() => {
        document.addEventListener(
            GroupMemberEventKey.GROUP_MEMBER_APPROVED,
            onGroupMemberApprovedOrDenied
        )
        document.addEventListener(
            GroupMemberEventKey.GROUP_MEMBER_DENIED,
            onGroupMemberApprovedOrDenied
        )

        return () => {
            document.removeEventListener(
                GroupMemberEventKey.GROUP_MEMBER_APPROVED,
                onGroupMemberApprovedOrDenied
            )
            document.removeEventListener(
                GroupMemberEventKey.GROUP_MEMBER_DENIED,
                onGroupMemberApprovedOrDenied
            )
        }
    }, [onGroupMemberApprovedOrDenied])

    useEffect(() => {
        abortControllerRef.current?.abort()
        abortControllerRef.current = new AbortController()

        setNotifications([])
        getNotifications()

        return () => {
            abortControllerRef.current?.abort()
        }
    }, [getNotifications])

    return {
        viewModels,
        loadMoreNotificationsIfNeed,
        markNotificationAsRead,
    }
}
