import { useContext } from 'react'
import { DotBadge } from 'shared/lib/components/DotBadge'
import { ReactComponent as NotificationsActive } from '../assets/svg/bell_active.svg'
import { ReactComponent as NotificationsInActive } from '../assets/svg/bell_inactive.svg'
import { ReactComponent as NotificationsInActiveCrooked } from '../assets/svg/bell_inactive_crooked.svg'
import { ReactComponent as CalendarIconActive } from '../assets/svg/calendar_active.svg'
import { ReactComponent as CalendarIconInactive } from '../assets/svg/calendar_inactive.svg'
import { ReactComponent as ChurchIconActive } from '../assets/svg/church_active.svg'
import { ReactComponent as ChurchIconInActive } from '../assets/svg/church_inactive.svg'
import { ReactComponent as OrganizationIconActive } from '../assets/svg/organization_active.svg'
import { ReactComponent as OrganizationIconInactive } from '../assets/svg/organization_inactive.svg'
import { ReactComponent as SynagogueIconActive } from '../assets/svg/synagogue_active.svg'
import { ReactComponent as SynagogueIconInactive } from '../assets/svg/synagogue_inactive.svg'
import { ReactComponent as UserListIconActive } from 'shared/lib/assets/svg/multiple_persons_filled.svg'
import { ReactComponent as UserListIconInactive } from '../assets/svg/user_list_inactive.svg'
import { AppType } from '../churches/AppType'
import ChurchContext from '../churches/ChurchContextProvider'

interface Properties {
    isSelected: boolean
}

export const MyChurchGroupIcon = ({ isSelected }: Properties) => {
    const { church } = useContext(ChurchContext)!

    if (
        church?.applicationId === 'app.donkeymobile.reimertgroep' ||
        church?.applicationId === 'app.donkeymobile.pcbofiersicht' ||
        church?.applicationId === 'app.donkeymobile.mdt' ||
        church?.applicationId === 'app.donkeymobile.winkeltheater'
    ) {
        return isSelected ? <OrganizationIconActive /> : <OrganizationIconInactive />
    }

    switch (church?.appType) {
        case AppType.JEWISH:
            return isSelected ? <SynagogueIconActive /> : <SynagogueIconInactive />
        default:
            return isSelected ? <ChurchIconActive /> : <ChurchIconInActive />
    }
}

export const CalendarIcon = ({ isSelected }: Properties) => {
    return isSelected ? <CalendarIconActive /> : <CalendarIconInactive />
}

export const UserListIcon = ({ isSelected }: Properties) => {
    return isSelected ? <UserListIconActive /> : <UserListIconInactive />
}

export const NotificationsIcon = ({
    isSelected,
    hasUnreadNotifications,
}: Properties & { hasUnreadNotifications: boolean }) => {
    return isSelected ? (
        <NotificationsActive />
    ) : hasUnreadNotifications ? (
        <DotBadge>
            <NotificationsInActiveCrooked />
        </DotBadge>
    ) : (
        <NotificationsInActive />
    )
}
