import { Box, styled } from '@mui/material'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import { TextField } from 'shared/lib/components/textfields/TextField'
import { DetailViewContainer } from '../../common/detailView'
import { DETAIL_VIEW_HEADER_BOTTOM_Y } from '../../common/detailView/DetailViewHeader'
import { DetailViewProperties } from '../../common/detailView/DetailViewProperties'
import { useTranslation } from 'shared/lib/i18n'
import { filterUsers } from 'shared/lib/users/UserUtils'
import { useGroup, useGroupMembers } from '../hooks'
import { GroupMembersList } from './GroupMembersList'

interface Properties extends DetailViewProperties {
    groupId: string
}

export const GroupMembersListView = ({ groupId, ...properties }: Properties) => {
    const translations = useTranslation()
    const location = useLocation()

    const { isLoading, groupMembers } = useGroupMembers(groupId, {
        isApproved: true,
    })
    const { group, isLoading: isLoadingGroup } = useGroup(groupId)

    const [query, setQuery] = useState('')

    const approvedGroupMembers = groupMembers.filter((groupMember) => groupMember.isApproved)
    const filteredGroupMembers = filterUsers(approvedGroupMembers, query)
    const groupMemberCount = (groupMembers.length || location.state?.groupMemberCount) ?? 0

    if (!group && !isLoadingGroup) {
        properties.onCloseClicked()

        return <></>
    }

    return (
        <DetailViewContainer
            onCloseClicked={properties.onCloseClicked}
            closeIcon={properties.closeIcon}
            title={translations('plural_group_members', [groupMemberCount], groupMemberCount)}
            contentMarginTop={DETAIL_VIEW_HEADER_BOTTOM_Y}
        >
            <Box m={2}>
                <TextField
                    variant="outlined"
                    fullWidth={true}
                    isSearch={true}
                    value={query}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={translations('search_placeholder_user_list_page')}
                />
            </Box>

            <GroupMembersListContainer>
                <AutoSizer>
                    {({ height, width }) => (
                        <GroupMembersList
                            width={width}
                            height={height}
                            isLoading={isLoading}
                            group={group}
                            groupMembers={filteredGroupMembers}
                        />
                    )}
                </AutoSizer>
            </GroupMembersListContainer>
        </DetailViewContainer>
    )
}

const GroupMembersListContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    height: `100%`,
    padding: theme.spacing(0, 2, 0, 2),
}))
