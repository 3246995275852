import { WebAppHttpRepository } from '../common/WebAppHttpRepository'
import { SessionRepository } from '../authentication/SessionRepository'

export class GivingRepository extends WebAppHttpRepository {
    constructor(private readonly sessionRepository: SessionRepository) {
        super()
    }

    async getBalanceOfSignedInUser(): Promise<number> {
        const { amount } = (await this.sessionRepository.withAccessToken(() =>
            this.get(`${this.apiUrl}/api/v1/giving/balance`)
        )) as { amount: number }

        return amount
    }
}
