import {
    IconButton,
    List as MuiList,
    ListItem,
    ListItemButton as MuiListItemButton,
    ListItemIcon as MuiListItemIcon,
    ListItemText,
    styled,
} from '@mui/material'
import { useState } from 'react'
import { ReactComponent as More } from 'shared/lib/assets/svg/more.svg'
import { MenuPopup } from 'shared/lib/components/MenuPopup'
import theme, { darkGrey } from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'
import { ReactComponent as SignOutIcon } from 'shared/lib/assets/svg/logout.svg'
import { ReactComponent as Delete } from '../../assets/svg/delete.svg'
import { ReactComponent as Edit } from 'shared/lib/assets/svg/write.svg'

interface Properties {
    canEditGroup: boolean

    onEditGroupClicked(): void
    onLeaveButtonClicked(): void
    onDeleteButtonClicked(): void
}

export const GroupActionsButton = (properties: Properties) => {
    const translations = useTranslation()

    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)

    const onMoreButtonClicked = (event: any) => {
        setAnchor(event.currentTarget)
    }

    const onEditGroupClicked = () => {
        setAnchor(null)
        properties.onEditGroupClicked()
    }

    const onLeaveButtonClicked = () => {
        setAnchor(null)
        properties.onLeaveButtonClicked()
    }

    const onDeleteButtonClicked = () => {
        setAnchor(null)
        properties.onDeleteButtonClicked()
    }

    return (
        <>
            <IconButton
                sx={{ backgroundColor: 'white', margin: theme.spacing(1) }}
                onClick={onMoreButtonClicked}
            >
                <MoreIcon />
            </IconButton>

            <MenuPopup
                anchor={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                popupMarginTop={'8px'}
                arrowRightPosition={'16px'}
                onPopupClosed={() => setAnchor(null)}
            >
                <List>
                    {properties.canEditGroup && (
                        <ListItem disablePadding>
                            <ListItemButton onClick={onEditGroupClicked}>
                                <ListItemIcon>
                                    <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary={translations('edit_group')} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    <ListItem disablePadding>
                        <ListItemButton onClick={onLeaveButtonClicked}>
                            <ListItemIcon>
                                <LeaveGroup />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    color: (theme) => theme.palette.error.main,
                                }}
                                primary={translations('leave_group')}
                            />
                        </ListItemButton>
                    </ListItem>
                    {properties.canEditGroup && (
                        <ListItem disablePadding>
                            <ListItemButton onClick={onDeleteButtonClicked}>
                                <ListItemIcon>
                                    <DeleteGroup />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        color: (theme) => theme.palette.error.main,
                                    }}
                                    primary={translations('delete_group')}
                                />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            </MenuPopup>
        </>
    )
}

const MoreIcon = styled(More)(() => ({
    fill: darkGrey,
}))

const LeaveGroup = styled(SignOutIcon)(({ theme }) => ({
    fill: theme.palette.error.main,
}))

const DeleteGroup = styled(Delete)(({ theme }) => ({
    fill: theme.palette.error.main,
}))

const EditIcon = styled(Edit)(({ theme }) => ({
    fill: theme.palette.text.primary,
}))

const List = styled(MuiList)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
}))

const ListItemButton = styled(MuiListItemButton)(() => ({
    borderRadius: '16px',
}))

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
    minWidth: 'unset',
    marginRight: theme.spacing(2),
    '& svg': {
        height: '20px',
        width: '20px',
    },
}))
