import { Box, styled, Typography } from '@mui/material'
import { ReactComponent as CancelIconSVG } from 'shared/lib/assets/svg/cancel_white.svg'
import { Avatar } from 'shared/lib/components/Avatar'
import { grey_1 } from 'shared/lib/theme/Theme'
import { fileRepository } from '../../../index'
import { User } from '../../../user/User'
import { getInitials } from '../../../user/Utils'
import { getFullName } from 'shared/lib/utils/StringUtils'

interface UserListCellProperties {
    user: User
    onUserSelected: (user: User) => void
}

export const SelectedMemberCell = ({ user, onUserSelected }: UserListCellProperties) => {
    const onUserClicked = () => {
        onUserSelected(user)
    }

    return user ? (
        <Content onClick={onUserClicked}>
            <AvatarContainer>
                <Avatar src={fileRepository.getThumbnailUrl(user.image)} width={40} height={40}>
                    {getInitials(user)}
                </Avatar>
                <RemoveIconContainer>
                    <RemoveIcon />
                </RemoveIconContainer>
            </AvatarContainer>
            <Name>{getFullName(user)}</Name>
        </Content>
    ) : null
}

const Content = styled(Box)(() => ({
    width: '80px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
}))

const AvatarContainer = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    position: 'relative',
}))

const Name = styled(Typography)(({ theme }) => ({
    width: '72px',
    fontSize: 12,
    color: grey_1,
    fontWeight: 400,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
}))

const RemoveIconContainer = styled(Box)(() => ({
    position: 'absolute',
    top: '2px',
    right: '9.5px',
    width: '27px',
    height: '27px',
    borderRadius: '50%',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
}))

const RemoveIcon = styled(CancelIconSVG)(({ theme }) => ({
    fill: theme.palette.primary.main,
    margin: 'auto',
    height: '24px',
    width: '24px',
}))
