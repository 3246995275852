import { SVGProps } from './SVGProps'

export const EyeHide = (properties: SVGProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={properties.color || 'current'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.6144 4.56998C22.8519 4.90932 22.7694 5.37697 22.4301 5.6145L20.3285 7.08557C21.8087 8.39018 22.75 10.0905 22.75 12.0001C22.75 14.245 21.4491 16.2007 19.5011 17.5643C17.5508 18.9295 14.8964 19.7501 12 19.7501C9.31682 19.7501 6.84138 19.0459 4.94093 17.8569L2.43005 19.6145C2.09072 19.852 1.62307 19.7695 1.38553 19.4302C1.148 19.0908 1.23052 18.6232 1.56986 18.3857L3.67138 16.9146C2.19123 15.61 1.24996 13.9097 1.24996 12.0001C1.24996 9.75518 2.55083 7.79948 4.49879 6.43591C6.44912 5.07068 9.10353 4.25008 12 4.25008C14.6831 4.25008 17.1585 4.95427 19.059 6.14327L21.5699 4.38566C21.9092 4.14812 22.3768 4.23065 22.6144 4.56998ZM12 5.75008C14.1665 5.75008 16.142 6.25799 17.7042 7.09164L14.5799 9.27862C13.9077 8.64114 12.9995 8.25008 12 8.25008C9.92889 8.25008 8.24996 9.92901 8.24996 12.0001C8.24996 12.5308 8.36019 13.0357 8.55901 13.4932L4.94298 16.0245C3.53286 14.8985 2.74996 13.4813 2.74996 12.0001C2.74996 10.379 3.68766 8.83468 5.35899 7.66476C7.02795 6.49649 9.37354 5.75008 12 5.75008ZM9.42 14.7215L6.29575 16.9085C7.85794 17.7422 9.8334 18.2501 12 18.2501C14.6264 18.2501 16.972 17.5037 18.6409 16.3354C20.3123 15.1655 21.25 13.6212 21.25 12.0001C21.25 10.5188 20.4671 9.10169 19.0569 7.97569L15.4409 10.5069C15.6397 10.9645 15.75 11.4694 15.75 12.0001C15.75 14.0711 14.071 15.7501 12 15.7501C11.0004 15.7501 10.0922 15.359 9.42 14.7215ZM14.1684 11.3977C14.2216 11.5894 14.25 11.7914 14.25 12.0001C14.25 13.2427 13.2426 14.2501 12 14.2501C11.5122 14.2501 11.0606 14.0949 10.692 13.8311L14.1684 11.3977ZM13.3079 10.1691L9.83151 12.6025C9.77836 12.4108 9.74996 12.2087 9.74996 12.0001C9.74996 10.7574 10.7573 9.75008 12 9.75008C12.4877 9.75008 12.9393 9.9053 13.3079 10.1691Z"
            fill="current"
        />
    </svg>
)
