import {
    ListItem as MuiListItem,
    ListItemButton as MuiListItemButton,
    ListItemIcon as MuiListItemIcon,
    ListItemText,
    styled,
} from '@mui/material'
import { grey_3 } from 'shared/lib/theme/Theme'
import { ReactElement } from 'react'

export interface SettingItemProperties {
    hasTopDivider: boolean
    title: string
    leftIcon: ReactElement
    rightIcon?: ReactElement
    color?: string
    onItemClicked: () => void
}

export const SettingItem = (properties: SettingItemProperties) => {
    return (
        <ListItem hastopdivider={properties.hasTopDivider ? 1 : 0} disablePadding={true}>
            <ListItemButton onClick={properties.onItemClicked}>
                <ListItemLeftIcon properties={properties}>{properties.leftIcon}</ListItemLeftIcon>
                <ListItemText
                    primary={properties.title}
                    primaryTypographyProps={{
                        color: properties.color,
                    }}
                />
                <ListItemRightIcon properties={properties}>
                    {properties.rightIcon}
                </ListItemRightIcon>
            </ListItemButton>
        </ListItem>
    )
}

const ListItem = styled(MuiListItem)<{ hastopdivider: number }>(({ theme, hastopdivider }) => ({
    borderTop: hastopdivider ? `2px solid ${grey_3}` : 'none',
    '&:hover': {
        borderTopColor: 'transparent',
        overflow: 'hidden',
        borderRadius: '16px',
        marginLeft: theme.spacing(-2),
        width: `calc(100% + ${theme.spacing(4)})`,
    },
    '&:hover > *': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    '&:hover + li': {
        borderTopColor: 'transparent',
    },
}))

const ListItemLeftIcon = styled(MuiListItemIcon)<{ properties: SettingItemProperties }>(
    ({ properties }) => ({
        fill: properties.color,
    })
)

const ListItemRightIcon = styled(MuiListItemIcon)<{ properties: SettingItemProperties }>(
    ({ properties }) => ({
        fill: properties.color,
        minWidth: '0',
    })
)

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
}))
