import { useScrollTrigger } from '@mui/material'
import { cloneElement } from 'react'

export const ElevateOnScroll = (properties: {
    targetId?: string
    children: React.ReactElement
}) => {
    const target = properties.targetId
        ? (document.getElementById(properties.targetId) as Node) ?? undefined
        : undefined

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target,
    })

    return cloneElement(properties.children, {
        elevation: trigger ? 4 : 0,
    })
}
