import { Box, BoxProps, styled, Typography } from '@mui/material'
import MuiGrid from '@mui/material/Unstable_Grid2'
import i18next from 'i18next'
import { PropsWithChildren } from 'react'
import { SelectLanguageButton } from 'shared/lib/components/buttons/SelectLanguageButton'
import { fileRepository, sessionRepository } from '..'
import logo from '../assets/images/logo.png'
import logoPolski from '../assets/images/logoPolski.png'
import logoEnglish from '../assets/images/LogoEnglish.png'
import logoGerman from '../assets/images/LogoGerman.png'
import logoFrysk from '../assets/images/LogoFrysk.png'
import logoFrench from '../assets/images/LogoFrench.png'
import { Church } from '../churches/Church'
import { useNavigate } from 'react-router-dom'
import { Paths } from '../routing/paths'
import i18n, { SUPPORTED_LANGUAGES } from '../i18n'
import { SmartBanner } from '../components/SmartBanner'

interface Properties {
    backgroundImage: string
    church?: Church
}

export const SessionPage = (properties: PropsWithChildren<Properties>) => {
    const navigate = useNavigate()
    const church = properties.church

    return (
        <Grid container columns={{ mobile: 4, tablet: 8, desktop: 12 }}>
            <LeftGrid mobile={4} tablet={4} desktop={5}>
                {church && (
                    <>
                        <SmartBanner />
                        <HeaderContainer>
                            <ChurchLogoImageView
                                src={fileRepository.getThumbnailUrl(church.image)}
                                onClick={() => {
                                    sessionRepository.clearData()
                                    navigate(
                                        `${Paths.SIGN_IN}?applicationId=${church.applicationId}`
                                    )
                                }}
                            />
                            <ChurchNameTextView variant="h1">{church.name}</ChurchNameTextView>
                            <SelectLanguageButton
                                changeLanguage={async (language: string) => {
                                    await i18next.changeLanguage(language)
                                }}
                                currentLanguage={i18n.language}
                                supportedLanguages={SUPPORTED_LANGUAGES}
                            />
                        </HeaderContainer>
                        <ContentGrid
                            container
                            disableEqualOverflow={true}
                            columns={{ mobile: 4, tablet: 4, desktop: 5 }}
                        >
                            <ContentGridItem mobile={4} tablet={3} desktop={3}>
                                {properties.children}
                            </ContentGridItem>
                        </ContentGrid>
                    </>
                )}
            </LeftGrid>
            <RightGrid
                mobile={0}
                tablet={4}
                desktop={7}
                backgroundimage={properties.backgroundImage}
            >
                <DonkeyMobileLogo />
            </RightGrid>
        </Grid>
    )
}

const Grid = styled(MuiGrid)(() => ({
    flexGrow: 1,
    display: 'flex',
}))

const LeftGrid = styled(MuiGrid)(() => ({
    height: '100dvh',
    background: 'white',
    overflow: 'auto',
}))

const HeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(2),
    alignItems: 'center',
}))

const ChurchLogoImageView = styled('img')(() => ({
    width: '48px',
    height: '48px',
    borderRadius: '16px',
    objectFit: 'fill',
    cursor: 'pointer',
}))

const ChurchNameTextView = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    display: '-webkit-box',
    flexGrow: 1,
    WebkitLineClamp: 2,
    marginRight: theme.spacing(0.5),
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
}))

const ContentGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(14),
    [theme.breakpoints.down('tablet')]: {
        marginTop: theme.spacing(4),
    },
}))

const ContentGridItem = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('tablet')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}))

export const Title = styled(Typography)(() => ({
    fontSize: '48px',
}))

const RightGrid = styled(MuiGrid)<{ backgroundimage: any }>(({ backgroundimage }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    backgroundImage: `url(${backgroundimage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
}))

const getDonkeyLogo = () => {
    switch (i18n.language) {
        case 'nl':
            return logo
        case 'pl':
            return logoPolski
        case 'en':
            return logoEnglish
        case 'de':
            return logoGerman
        case 'fr':
            return logoFrench
        case 'fy':
            return logoFrysk
        default:
            return logo
    }
}

const DonkeyMobileLogo = styled((props: BoxProps) => (
    <Box
        component="a"
        href={'https://donkeymobile.app'}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
    >
        <Box
            component="img"
            src={getDonkeyLogo()}
            alt="Logo"
            style={{ width: '380px%', height: '62px' }}
        />
    </Box>
))(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: theme.spacing(8),
    width: '100%',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.5%)',
    cursor: 'pointer',
    textDecoration: 'none',

    [theme.breakpoints.down('tablet')]: {
        display: 'none',
    },
}))
