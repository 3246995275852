import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { PasswordResetView } from 'shared/lib/components/PasswordResetView'
import vault from '../../assets/images/vault.jpg'
import ChurchContext from '../../churches/ChurchContextProvider'
import { sessionRepository } from '../../index'
import { Paths } from '../../routing/paths'
import { SessionPage } from '../SessionPage'

export const ForgotPasswordPage = () => {
    const navigate = useNavigate()

    const { church } = useContext(ChurchContext)!

    if (sessionRepository.isSignedIn) {
        return <></>
    }

    const passwordForgotten = (emailAddress: string) => {
        return sessionRepository
            .passwordForgotten(emailAddress)
            .then(() => navigate(Paths.FORGOT_PASSWORD_SEND, { state: { emailAddress } }))
    }

    return (
        <SessionPage backgroundImage={vault} church={church}>
            <PasswordResetView
                onBackButtonClicked={() => navigate(Paths.SIGN_IN)}
                onSendLinkButtonClicked={passwordForgotten}
            />
        </SessionPage>
    )
}
