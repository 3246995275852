import {
    Box,
    Drawer as MuiDrawer,
    DrawerProps,
    styled,
    Typography,
    TypographyProps,
} from '@mui/material'
import React, { memo, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'shared/lib/i18n'
import {
    GroupListCreateGroupViewModel,
    GroupListItemLoadingViewModel,
    GroupListItemViewModel,
    GroupListViewModel,
} from '../groupList/GroupListViewModels'
import {
    TabletDesktopCreateGroupListItem,
    TabletDesktopGroupListItem,
} from '../groupList/TabletDesktopGroupListItems'
import AutoSizer from 'react-virtualized-auto-sizer'
import { StyledVirtuoso } from '../../common/StyledVirtuoso'
import { VirtuosoHandle } from 'react-virtuoso'
import { defaultTransition } from 'shared/lib/theme/Theme'
import { useIsTabletDesktopSideBarCollapsed } from './hooks'
import { TabletDesktopGroupListItemLoadingState } from '../groupList/TabletDesktopGroupListItemLoadingState'

interface Properties {
    isBadgeVisible: boolean
    viewModels: GroupListViewModel[]
    selectedGroupId?: string
}

const Component = (properties: Properties) => {
    const { isBadgeVisible, selectedGroupId } = properties

    const viewModels = useMemo(() => {
        return properties.viewModels.length > 0
            ? properties.viewModels
            : [...Array.from({ length: 8 }).map(() => new GroupListItemLoadingViewModel())]
    }, [properties.viewModels])

    const translations = useTranslation()
    const listRef = useRef<VirtuosoHandle | null>(null)

    const isCollapsed = useIsTabletDesktopSideBarCollapsed()

    useEffect(() => {
        const index = viewModels.findIndex(
            (viewModel) =>
                viewModel instanceof GroupListItemViewModel &&
                viewModel.group.id === selectedGroupId
        )

        setTimeout(() => {
            listRef.current?.scrollToIndex({
                index,
                align: 'center',
                behavior: 'smooth',
            })
        }, 100)
    }, [viewModels.length]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Drawer>
            <Header>{isCollapsed ? <div>&nbsp;</div> : translations('my_groups')}</Header>
            <Box height="100%">
                <AutoSizer>
                    {({ height, width }) => (
                        <StyledVirtuoso
                            totalCount={viewModels.length}
                            itemContent={(index) =>
                                getRow({
                                    isBadgeVisible,
                                    index,
                                    selectedGroupId,
                                    viewModels,
                                })
                            }
                            overscan={500}
                            ref={listRef}
                            style={{
                                height,
                                width,
                            }}
                        />
                    )}
                </AutoSizer>
            </Box>
        </Drawer>
    )
}

export const TabletDesktopGroupsSideBar = memo(Component, (previous, next) => {
    return (
        previous.isBadgeVisible === next.isBadgeVisible &&
        previous.selectedGroupId === next.selectedGroupId &&
        previous.viewModels === next.viewModels
    )
})

const getRow = (properties: {
    isBadgeVisible: boolean
    index: number
    selectedGroupId?: string
    viewModels: GroupListViewModel[]
}) => {
    const viewModel = properties.viewModels[properties.index]
    const isLastItem = properties.index === properties.viewModels.length - 1

    if (viewModel instanceof GroupListItemViewModel) {
        const group = viewModel.group

        const isOnlyOneGroup =
            properties.viewModels.filter((viewModel) => viewModel instanceof GroupListItemViewModel)
                .length === 1

        const isSelected = isOnlyOneGroup || group.id === properties.selectedGroupId

        return (
            <TabletDesktopGroupListItem
                key={group.id}
                isSelected={isSelected}
                isBadgeVisible={properties.isBadgeVisible}
                isLastItem={isLastItem}
                group={group}
                onGroupSelected={() => viewModel.onClick()}
            />
        )
    } else if (viewModel instanceof GroupListCreateGroupViewModel) {
        return (
            <TabletDesktopCreateGroupListItem
                key={'create-group'}
                onClick={() => viewModel.onClick()}
            />
        )
    } else if (viewModel instanceof GroupListItemLoadingViewModel) {
        return <TabletDesktopGroupListItemLoadingState />
    }

    return <></>
}

const Drawer = styled((props: DrawerProps) => (
    <MuiDrawer variant="permanent" anchor="left" {...props} />
))(({ theme }) => ({
    '& .MuiDrawer-paper': {
        width: '100%',
        height: 'calc(100vh - 80px)',
        position: 'relative',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(3),
        borderRight: 'none',
        overflow: 'hidden',
    },
}))

const Header = styled((props: TypographyProps) => (
    <Typography variant="h3" {...props} noWrap={true} />
))(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(3),
    transition: defaultTransition,
}))
