import { WebAppHttpRepository } from '../common/WebAppHttpRepository'
import { SessionRepository } from '../authentication/SessionRepository'
import { Comment } from './Comment'
import { CommentCreatedEvent, CommentDeletedEvent, CommentUpdatedEvent } from './events'

export class CommentRepository extends WebAppHttpRepository {
    constructor(private readonly sessionRepository: SessionRepository) {
        super()
    }

    async createComment(postId: string, message: string): Promise<Comment> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.post<any>(`${this.apiUrl}/api/v2/comments`, {
                postId,
                message,
            })
        )

        const comment = Comment.fromResponse(response)

        document.dispatchEvent(new CommentCreatedEvent(postId, comment))

        return comment
    }

    async getComments(postId: string) {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.get<any[]>(`${this.apiUrl}/api/v2/comments?postId=${postId}`)
        )

        return response.map(Comment.fromResponse)
    }

    async updateComment(postId: string, commentId: string, message: string): Promise<Comment> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.patch<Record<string, any>>(`${this.apiUrl}/api/v2/comments/${commentId}`, {
                message,
            })
        )

        const comment = Comment.fromResponse(response)

        document.dispatchEvent(new CommentUpdatedEvent(postId, comment))

        return comment
    }

    async deleteComment(postId: string, commentId: string): Promise<void> {
        await this.sessionRepository.withAccessToken(async () =>
            this.delete(`${this.apiUrl}/api/v1/comments/${commentId}`)
        )

        document.dispatchEvent(new CommentDeletedEvent(postId))
    }
}
