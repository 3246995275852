import { ButtonProperties, DefaultButton } from './DefaultButton'
import { warningColor, warningColorHover } from '../../theme/Theme'

export const YellowContainedButton = (properties: ButtonProperties) => {
    const { sx, ...rest } = properties
    return (
        <DefaultButton
            {...{
                ...rest,
                variant: 'contained',
                color: 'primary',
                sx: {
                    ...sx,
                    backgroundColor: warningColor,
                    color: 'white',
                    '&:hover': {
                        backgroundColor: warningColorHover,
                        color: 'white',
                    },
                },
            }}
        />
    )
}
