import { ListItem as MuiListItem, ListItemAvatar, ListItemText, styled } from '@mui/material'
import { Avatar } from 'shared/lib/components/Avatar'
import { defaultHoverState } from 'shared/lib/theme/Theme'
import { fileRepository } from '../../../index'
import { getInitials } from '../../Utils'
import { getFullName } from 'shared/lib/utils/StringUtils'
import { MobileUserItemViewModel } from './MobileUserViewModels'

interface Properties {
    viewModel: MobileUserItemViewModel

    onRowClicked(userId: string): void
}

export const UserListRow = ({ viewModel, onRowClicked }: Properties) => {
    const user = viewModel.user

    const onClick = () => onRowClicked(user.id)

    return (
        <ListItem onClick={onClick}>
            <ListItemAvatar>
                <Avatar src={fileRepository.getThumbnailUrl(user.image)}>
                    {!user.image ? getInitials(user) : undefined}
                </Avatar>
            </ListItemAvatar>
            <UserListItemText
                primary={getFullName(user)}
                secondary={user.functions.join(', ')}
                primaryTypographyProps={{
                    noWrap: true,
                }}
                secondaryTypographyProps={{
                    noWrap: true,
                }}
            />
        </ListItem>
    )
}

const ListItem = styled(MuiListItem)(() => ({
    cursor: 'pointer',
    backgroundColor: 'white',
    '&:hover': defaultHoverState,
}))

const UserListItemText = styled(ListItemText)(() => ({
    '& .MuiListItemText-primary': {
        textOverflow: 'ellipsis',
        fontSize: 14,
    },
    '& .MuiListItemText-secondary': {
        textOverflow: 'ellipsis',
        fontSize: 12,
    },
}))
