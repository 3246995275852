import { Box, Typography } from '@mui/material'
import { GreyContainedButton } from 'shared/lib/components/buttons/GreyButtons'
import { SendAgainTextView } from 'shared/lib/components/SendAgainTextView'
import { useTranslation } from 'shared/lib/i18n'
import { Title } from '../SessionPage'
import { SignUpStepProperties } from './SignUpStepProperties'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { useNavigate } from 'react-router-dom'
import { Paths } from '../../routing/paths'
import { PrimaryActionButtonsContainer } from 'shared/lib/components/PrimaryActionButtonsContainer'

interface Properties extends SignUpStepProperties {
    emailAddress: string

    resendSignUpConfirmation(): void
}

export const ConfirmAccountStep = (properties: Properties) => {
    const { emailAddress } = properties
    const navigate = useNavigate()
    const translations = useTranslation()

    return (
        <>
            <Title variant="h1" mt={8}>
                {translations('confirm_account_title')}
            </Title>

            <Typography variant="body2" mt={5}>
                {translations('confirm_account_info', [emailAddress])}
            </Typography>

            <Box display="flex" alignItems="center" mt={5}>
                <Typography variant="body2" mr={1}>
                    {translations('no_mail_received')}
                </Typography>
                <SendAgainTextView onClicked={properties.resendSignUpConfirmation} />
            </Box>

            <PrimaryActionButtonsContainer>
                <GreyContainedButton
                    fullWidth
                    title={translations('back')}
                    onClick={properties.onBackButtonClicked}
                />

                <PrimaryContainedButton
                    fullWidth={true}
                    title={translations('signin')}
                    onClick={() => navigate(Paths.SIGN_IN)}
                />
            </PrimaryActionButtonsContainer>
        </>
    )
}
