import { Church } from './Church'
import { WebAppHttpRepository } from '../common/WebAppHttpRepository'
import { SessionRepository } from '../authentication/SessionRepository'

const CHURCH_KEY = 'church'

export class ChurchRepository extends WebAppHttpRepository {
    church: Church | undefined = undefined

    public get applicationId(): string {
        return super.applicationId
    }

    constructor(private readonly sessionRepository: SessionRepository) {
        super()
        this.church = this.getChurchFromLocalStorage()
    }

    async getAll(): Promise<Church[]> {
        const churches = await this.getWithoutAuthorization<Church[]>(
            `${this.apiUrl}/api/v1/churches`
        )

        return churches.map(Church.fromResponse)
    }

    async getChurchByApplicationId(applicationId: string): Promise<Church> {
        this.updateApplicationId(applicationId)

        const path = `${this.apiUrl}/api/v1/churches?applicationId=${applicationId}`
        const churches = this.sessionRepository.isSignedIn
            ? await this.sessionRepository.withAccessToken(() => this.get<Church[]>(path))
            : await this.getWithoutAuthorization<Church[]>(path)
        const church = Church.fromResponse(churches[0])

        if (church) {
            this.church = church
            this.storeChurch(church)

            return church
        } else {
            this.clearData({ resetApplicationId: true })
        }

        throw new Error('No church found')
    }

    clearData(params?: { resetApplicationId: boolean }) {
        this.church = undefined
        localStorage.removeItem(CHURCH_KEY)

        if (params?.resetApplicationId) {
            this.updateApplicationId(undefined)
        }
    }

    private storeChurch(church: Church) {
        localStorage.setItem(CHURCH_KEY, JSON.stringify(church))
    }

    private getChurchFromLocalStorage(): Church | undefined {
        const church = localStorage.getItem(CHURCH_KEY)
        return church ? Church.fromResponse(JSON.parse(church)) : undefined
    }
}
