import { Box, styled } from '@mui/material'
import React from 'react'
import { Components } from 'react-virtuoso'
import { useIsTabletOrBigger } from 'shared/lib/theme/BreakPointHooks'
import { paperColor } from 'shared/lib/theme/Theme'
import { StyledVirtuoso } from '../../common/StyledVirtuoso'
import { AppAccessRequestsButton } from '../../user/userList/AppAccessRequestsButton'
import { useNotifications } from '../hooks'
import { MobileGroupAccessRequestsButton } from './MobileGroupAccessRequestsButton'
import {
    NotificationsListFooterViewModel,
    NotificationsListGroupHeaderLoadingViewModel,
    NotificationsListGroupHeaderViewModel,
    NotificationsListItemLoadingViewModel,
    NotificationsListItemViewModel,
    NotificationsListViewModel,
} from './NotificationsListViewModels'
import {
    NotificationListGroupHeaderLoadingRow,
    NotificationListGroupHeaderRow,
    NotificationListItemLoadingRow,
    NotificationListItemLoadingRowHeight,
    NotificationListItemRow,
} from './rows/'

interface Properties {
    height: number
    unapprovedUsersCount: number
    groupAccessRequestsCount: number
}

export const NOTIFICATION_LIST_ID = `notification-list-${Math.random()}`

export const NotificationsList = ({
    height,
    unapprovedUsersCount,
    groupAccessRequestsCount,
}: Properties) => {
    const isTabletOrBigger = useIsTabletOrBigger()
    const { viewModels, loadMoreNotificationsIfNeed, markNotificationAsRead } = useNotifications()

    const loadingRowsThatFitScreen = Math.ceil(height / NotificationListItemLoadingRowHeight)
    const allViewModels =
        viewModels.length === 0
            ? [
                  new NotificationsListGroupHeaderLoadingViewModel(),
                  ...Array.from(
                      { length: loadingRowsThatFitScreen },
                      (v, index) =>
                          new NotificationsListItemLoadingViewModel(
                              index === loadingRowsThatFitScreen - 1
                          )
                  ),
                  new NotificationsListFooterViewModel(),
              ]
            : viewModels

    return (
        <StyledVirtuoso
            id={NOTIFICATION_LIST_ID}
            totalCount={allViewModels.length}
            itemContent={(index) =>
                getRow({ index, viewModels: allViewModels, markNotificationAsRead })
            }
            overscan={500}
            endReached={loadMoreNotificationsIfNeed}
            components={{
                Header:
                    !isTabletOrBigger && unapprovedUsersCount > 0 && groupAccessRequestsCount > 0
                        ? () => (
                              <AccessRequestsContainer>
                                  <AppAccessRequestsButton
                                      withGroupBadge={true}
                                      fullWidth={false}
                                      appAccessRequestCount={unapprovedUsersCount}
                                  />
                                  <MobileGroupAccessRequestsButton
                                      groupAccessRequestsCount={groupAccessRequestsCount}
                                  />
                              </AccessRequestsContainer>
                          )
                        : () => (
                              <Box
                                  sx={(theme) => ({
                                      [theme.breakpoints.only('phablet')]: {
                                          paddingTop: theme.spacing(2),
                                      },
                                  })}
                              />
                          ),
                List,
            }}
            style={{
                height,
                width: '100%',
            }}
        />
    )
}

const getRow = (params: {
    index: number
    viewModels: NotificationsListViewModel[]
    markNotificationAsRead: (notificationId: string) => void
}) => {
    const viewModel = params.viewModels[params.index]

    if (viewModel instanceof NotificationsListGroupHeaderLoadingViewModel) {
        return <NotificationListGroupHeaderLoadingRow />
    } else if (viewModel instanceof NotificationsListItemLoadingViewModel) {
        return <NotificationListItemLoadingRow viewModel={viewModel} />
    } else if (viewModel instanceof NotificationsListGroupHeaderViewModel) {
        return <NotificationListGroupHeaderRow viewModel={viewModel} />
    } else if (viewModel instanceof NotificationsListItemViewModel) {
        return (
            <NotificationListItemRow
                viewModel={viewModel}
                markNotificationAsRead={params.markNotificationAsRead}
            />
        )
    } else if (viewModel instanceof NotificationsListFooterViewModel) {
        return <ListFooter />
    }
}

const ListComponent = styled(Box)(({ theme }) => ({
    backgroundColor: paperColor,

    [theme.breakpoints.up('phablet')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },

    [theme.breakpoints.only('phablet')]: {
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
    },
}))

const ListFooter = styled(ListComponent)(({ theme }) => ({
    height: theme.spacing(1),
}))

const List: Components['List'] = React.forwardRef(({ style, children }, ref) => {
    return (
        <ListComponent
            style={style}
            sx={(theme) => ({
                minHeight: '100%',
                [theme.breakpoints.only('phablet')]: {
                    minHeight: 'calc(100% - 16px)',
                },
            })}
            ref={ref}
        >
            {children}
        </ListComponent>
    )
})

const AccessRequestsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    button: {
        borderRadius: 0,
    },

    [theme.breakpoints.only('phablet')]: {
        flexDirection: 'row',
        gap: theme.spacing(2),
        padding: theme.spacing(3, 0),
        flexWrap: 'wrap',

        button: {
            borderRadius: 16,
            flexGrow: 1,
        },
    },
}))
