import { useEffect, useRef } from 'react'
import { TextField } from 'shared/lib/components/textfields/TextField'
import { useIsDesktop, useIsTablet } from 'shared/lib/theme/BreakPointHooks'
import { paperColor } from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'

interface Properties {
    value: string
    disabled?: boolean
    width?: string | number

    onChanged(query: string): void
}

export const UserListSearchField = ({ value, disabled, onChanged, width }: Properties) => {
    const translations = useTranslation()
    const [isTablet, isDesktop] = [useIsTablet(), useIsDesktop()]
    const searchFieldRef = useRef<HTMLInputElement>(null)

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.metaKey && event.key === 'f') {
            event.preventDefault()
            if (searchFieldRef.current) {
                searchFieldRef.current.focus()
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [searchFieldRef])

    return (
        <TextField
            isSearch={true}
            variant="outlined"
            inputProps={{
                sx: {
                    height: '56px',
                    backgroundColor: paperColor,
                },
            }}
            placeholder={translations('search_placeholder_user_list_page')}
            value={value}
            onChanged={onChanged}
            width={width}
            maxWidth={isTablet ? 350 : isDesktop ? 450 : undefined}
            disabled={disabled}
            inputRef={searchFieldRef}
            sx={{
                flexGrow: 1,
            }}
        />
    )
}
