import { Box, BoxProps, Popover, PopoverOrigin, styled } from '@mui/material'
import { PropsWithChildren } from 'react'
import { heavyBoxShadow } from '../theme/constants'

type Variant = { desktop?: string; tablet?: string; phablet?: string; mobile?: string } | string

interface Properties {
    anchor: HTMLElement | null

    anchorOrigin?: PopoverOrigin
    transformOrigin?: PopoverOrigin

    popupWidth?: Variant
    popupMarginTop?: Variant
    arrowLeftPosition?: Variant
    arrowRightPosition?: Variant
    arrowTopPosition?: Variant

    onPopupClosed: () => void
}

export const MenuPopup = (properties: PropsWithChildren<Properties>) => {
    const { anchor, anchorOrigin, transformOrigin, popupWidth } = properties

    return (
        <Popover
            open={!!anchor}
            anchorEl={anchor}
            onClose={() => properties.onPopupClosed()}
            anchorOrigin={{
                vertical: anchorOrigin?.vertical ?? 'bottom',
                horizontal: anchorOrigin?.horizontal ?? 'center',
            }}
            transformOrigin={{
                vertical: transformOrigin?.vertical ?? 'top',
                horizontal: transformOrigin?.horizontal ?? 'center',
            }}
            slotProps={{
                paper: {
                    sx: (theme) => ({
                        backgroundColor: 'transparent',
                        borderRadius: 0,
                        boxShadow: 'none',
                        overflow: 'unset',

                        [theme.breakpoints.only('desktop')]: {
                            width: getDesktopVariant(popupWidth),
                        },

                        [theme.breakpoints.only('tablet')]: {
                            width: getTabletVariant(popupWidth),
                        },

                        [theme.breakpoints.only('phablet')]: {
                            width: getPhabletVariant(popupWidth),
                        },

                        [theme.breakpoints.only('mobile')]: {
                            width: getMobileVariant(popupWidth),
                        },
                    }),
                },
            }}
        >
            <PopoverContainer
                popupMarginTop={properties.popupMarginTop}
                arrowLeftPosition={properties.arrowLeftPosition}
                arrowRightPosition={properties.arrowRightPosition}
                arrowTopPosition={properties.arrowTopPosition}
            >
                <ChildContainer>{properties.children}</ChildContainer>
            </PopoverContainer>
        </Popover>
    )
}

const getDesktopVariant = (variant?: Variant) => {
    return variant ? (typeof variant === 'string' ? variant : variant.desktop) : undefined
}

const getTabletVariant = (variant?: Variant) => {
    return variant ? (typeof variant === 'string' ? variant : variant.tablet) : undefined
}

const getPhabletVariant = (variant?: Variant) => {
    return variant ? (typeof variant === 'string' ? variant : variant.phablet) : undefined
}

const getMobileVariant = (variant?: Variant) => {
    return variant ? (typeof variant === 'string' ? variant : variant.mobile) : undefined
}

const PopoverContainer = styled(
    ({
        popupMarginTop,
        arrowLeftPosition,
        arrowRightPosition,
        arrowTopPosition,
        ...properties
    }: BoxProps & {
        popupMarginTop?: Variant
        arrowLeftPosition?: Variant
        arrowRightPosition?: Variant
        arrowTopPosition?: Variant
    }) => <Box {...properties} />
)(({ theme, popupMarginTop, arrowLeftPosition, arrowRightPosition, arrowTopPosition }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '24px',
    boxShadow: heavyBoxShadow,

    [theme.breakpoints.only('desktop')]: {
        marginTop: getDesktopVariant(popupMarginTop),
    },

    [theme.breakpoints.only('tablet')]: {
        marginTop: getTabletVariant(popupMarginTop),
    },

    [theme.breakpoints.only('phablet')]: {
        marginTop: getPhabletVariant(popupMarginTop),
    },

    [theme.breakpoints.only('mobile')]: {
        marginTop: getMobileVariant(popupMarginTop),
    },

    '&::after': {
        backgroundColor: 'white',
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '20px',
        height: '20px',
        transform: 'rotate(45deg)',
        top: arrowTopPosition ?? -8.5,
        zIndex: 1,

        [theme.breakpoints.only('desktop')]: {
            left: getDesktopVariant(arrowLeftPosition),
            right: getDesktopVariant(arrowRightPosition) ?? 'calc(50% - 10px)',
        },

        [theme.breakpoints.only('tablet')]: {
            left: getTabletVariant(arrowLeftPosition),
            right: getTabletVariant(arrowRightPosition) ?? 'calc(50% - 10px)',
        },

        [theme.breakpoints.only('phablet')]: {
            left: getPhabletVariant(arrowLeftPosition),
            right: getPhabletVariant(arrowRightPosition) ?? 'calc(50% - 10px)',
        },

        [theme.breakpoints.only('mobile')]: {
            left: getMobileVariant(arrowLeftPosition),
            right: getMobileVariant(arrowRightPosition) ?? 'calc(50% - 10px)',
        },
    },
}))

export const ChildContainer = styled(Box)(() => ({
    overflow: 'hidden',
    zIndex: 2,
    borderRadius: '24px',
}))
