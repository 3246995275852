import { Box, styled, Typography } from '@mui/material'
import { useState } from 'react'
import { ImageCropper } from 'shared/lib/images/ImageCropper'
import { LocalImage } from 'shared/lib/forms/LocalImage'
import { DropProfileImageZone } from '../../authentication/registration/DropProfileImageZone'
import { TextField } from 'shared/lib/components/textfields/TextField'
import { Image } from 'shared/lib/models/Image'
import { useTranslation } from 'shared/lib/i18n'

interface Properties {
    groupName: string
    groupDescription: string
    groupImage?: Image
    groupImageFiles:
        | {
              imageFile: LocalImage
              thumbnailFile: LocalImage
          }
        | undefined
    onImageSelected: (params: { imageFile: LocalImage; thumbnailFile: LocalImage }) => void
    setGroupName: (groupName: string) => void
    setGroupDescription: (groupDescription: string) => void
}

export const GroupInfoStepView = (properties: Properties) => {
    const translations = useTranslation()

    const [selectedImage, setSelectedImage] = useState<LocalImage | undefined>()

    const onImageCropped = (imageFile: LocalImage, thumbnailFile: LocalImage) => {
        properties.onImageSelected({
            imageFile,
            thumbnailFile,
        })
        setSelectedImage(undefined)
    }

    return (
        <Container>
            <Typography variant="body1" mb={1.5}>
                {translations('group_picture')}
            </Typography>

            <DropProfileImageZone
                id="GroupInfoStepView"
                image={properties.groupImage}
                imageFile={properties.groupImageFiles?.imageFile}
                onImageChanged={(image) => setSelectedImage(image)}
            />

            <TextField
                fullWidth={true}
                autoFocus={false}
                value={properties.groupName}
                label={translations('group_name')}
                onChanged={properties.setGroupName}
                onBlur={({ target }) => properties.setGroupName(target.value.trim())}
                sx={{
                    marginTop: 3,
                }}
                maxLength={75}
            />

            <TextField
                fullWidth={true}
                autoFocus={false}
                value={properties.groupDescription}
                label={translations('group_description')}
                onChanged={properties.setGroupDescription}
                sx={{
                    marginTop: 3,
                }}
                maxLength={320}
            />

            {selectedImage && (
                <ImageCropper
                    image={selectedImage.file}
                    onCancelCropClicked={() => setSelectedImage(undefined)}
                    onImageCropped={onImageCropped}
                    translations={translations}
                />
            )}
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
}))
