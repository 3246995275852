import { Box, LinearProgress as MuiLinearProgress, styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import { TranslationFn } from '../WithTranslations'
import { blueColor, grey_3 } from '../theme/Theme'

interface Properties {
    compressedProgress: number
    translations: TranslationFn
}

export const CompressBox = (properties: Properties) => {
    return (
        <CompressContainer>
            <CompressTextContainer>
                <CompressingTextView variant="body2">
                    {properties.translations('compressing_files')}
                </CompressingTextView>
                <Typography variant="body2" color={blueColor}>
                    {properties.compressedProgress}%
                </Typography>
            </CompressTextContainer>
            <LinearProgress
                variant="determinate"
                color="secondary"
                value={properties.compressedProgress}
            />
        </CompressContainer>
    )
}

const CompressContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '56px',
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: '16px',
    backgroundColor: grey_3,
    justifyContent: 'center',
}))

const CompressTextContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
}))

const CompressingTextView = styled(Typography)(() => ({
    display: 'flex',
    flexGrow: 1,
}))

const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
    height: '6px',
    borderRadius: '32px',
    color: blueColor,
    marginTop: theme.spacing(0.5),
}))
