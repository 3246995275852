import { Box, styled, Typography } from '@mui/material'
import { ReactComponent as CalendarIcon } from '../../assets/svg/calendar_large.svg'
import { useTranslation } from 'shared/lib/i18n'
import { ZeroEventsViewModel } from '../EventViewModel'

interface Properties {
    height: number
    viewModel: ZeroEventsViewModel
}

export const ZeroEventsRow = ({ height, viewModel }: Properties) => {
    const translations = useTranslation()

    return (
        <Container height={height}>
            <IconView>
                <CalendarIcon />
            </IconView>
            <Box display="flex" flexDirection="column" ml={2}>
                <Title variant="body1">{translations('agenda_zero_events_title')}</Title>
                <SubTitle variant="body2">
                    {translations('agenda_zero_events_message', [viewModel.group.name])}
                </SubTitle>
            </Box>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    minHeight: '120px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
}))

const IconView = styled(Box)(() => ({
    height: '72px',
    width: '72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '50%',
}))

const Title = styled(Typography)(() => ({
    fontSize: '21px',
    fontWeight: 700,
}))

const SubTitle = styled(Typography)(() => ({
    fontSize: '12px',
    maxWidth: '250px',
}))
