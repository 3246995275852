import axios from 'axios'
import { LinkPreview } from '../models/LinkPreview'
import { isValidEmail, isValidUrl } from '../utils/StringUtils'
import { LinkPreviewRepository } from './LinkPreviewRepository'

export enum LinkPreviewError {
    INVALID_URL = 'INVALID_URL',
    FAILED_TO_GENERATE = 'FAILED_TO_GENERATE',
}

export class LinkPreviewHandler {
    private abortController: AbortController | undefined

    constructor(private readonly linkPreviewRepository: LinkPreviewRepository) {}

    async abort() {
        this.abortController?.abort()
    }

    async generateLinkPreview(
        url: string
    ): Promise<{ linkPreview?: LinkPreview; error?: LinkPreviewError }> {
        this.abortController?.abort()

        if (!isValidUrl(url) || isValidEmail(url)) {
            return { error: LinkPreviewError.INVALID_URL }
        }

        this.abortController = new AbortController()

        try {
            const linkPreview = await this.getLinkPreviewContent(url, this.abortController.signal)
            return { linkPreview }
        } catch (error) {
            return { error: LinkPreviewError.FAILED_TO_GENERATE }
        }
    }

    async getLinkPreviewContent(
        url: string,
        abortSignal: AbortSignal
    ): Promise<LinkPreview | undefined> {
        url = url.startsWith('https://')
            ? url
            : url.startsWith('http://')
            ? url.replace('http://', 'https://')
            : `https://${url}`

        try {
            const response = await this.linkPreviewRepository.getLinkPreview(url, abortSignal)
            return LinkPreview.fromResponse(response)
        } catch (error) {
            if (!axios.isCancel(error)) {
                throw error
            }
        }
    }
}
