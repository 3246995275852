import React from 'react'
import { Group } from '../Group'
import { useTranslation } from 'shared/lib/i18n'
import { GroupBadge } from '../GroupBadge'
import { GroupsSidebarListItemText } from '../sidebar/GroupsSidebarListItemText'
import {
    Box,
    ListItemAvatar,
    listItemAvatarClasses,
    ListItemButton as MuiListItemButton,
    ListItemButtonProps,
    styled,
    typographyClasses,
} from '@mui/material'
import { grey_2, grey_3 } from 'shared/lib/theme/Theme'
import { ReactComponent as AddIcon } from 'shared/lib/assets/svg/plus_outlined.svg'
import { useIsTabletDesktopSideBarCollapsed } from '../sidebar/hooks'
import { useDetailView } from '../../common/detailView/hooks'

interface Properties {
    group: Group
    isSelected: boolean
    isBadgeVisible: boolean
    isLastItem: boolean

    onGroupSelected: (groupId: string) => void
}

export const TabletDesktopGroupListItem = (properties: Properties) => {
    const { group } = properties
    const translations = useTranslation()
    const isCollapsed = useIsTabletDesktopSideBarCollapsed()

    const groupHasAccessRequests = (group.numberOfUnapprovedMembers || 0) > 0 && group.canEdit
    let secondaryText: string | undefined = undefined

    const { openGroupDetailView } = useDetailView()

    switch (true) {
        case groupHasAccessRequests:
            secondaryText = translations(
                'plural_access_requests',
                [group.numberOfUnapprovedMembers],
                group.numberOfUnapprovedMembers
            )
            break
        case (group.numberOfMembers || 0) > 0:
            secondaryText = translations(
                'plural_group_members',
                [group.numberOfMembers],
                group.numberOfMembers
            )
            break
    }

    return (
        <Box pb={properties.isLastItem ? 1 : undefined}>
            <ListItemButton
                selected={properties.isSelected}
                onClick={() => properties.onGroupSelected(group.id)}
                onDoubleClick={() => openGroupDetailView(group.id)}
            >
                <GroupImageContainer>
                    <GroupBadge
                        number={properties.isBadgeVisible ? group.numberOfUnreadPosts : undefined}
                        image={group.image}
                        verticalPosition="bottom"
                    />
                </GroupImageContainer>
                {!isCollapsed && (
                    <GroupsSidebarListItemText
                        primaryLabelText={group.name}
                        secondaryLabelText={secondaryText}
                        groupHasAccessRequest={groupHasAccessRequests}
                    />
                )}
            </ListItemButton>
        </Box>
    )
}

export const TabletDesktopCreateGroupListItem = ({ onClick }: { onClick(): void }) => {
    const translations = useTranslation()
    const isCollapsed = useIsTabletDesktopSideBarCollapsed()

    return (
        <Box pb={1}>
            <AddGroupButton onClick={onClick} isSideBarCollapsed={isCollapsed}>
                <ListItemAvatar>
                    <AddIcon />
                </ListItemAvatar>
                {!isCollapsed && (
                    <GroupsSidebarListItemText
                        primaryLabelText={translations('create_group_title')}
                    />
                )}
            </AddGroupButton>
        </Box>
    )
}

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
    borderRadius: `${theme.spacing(3)} 0 0 ${theme.spacing(3)}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: '72px',

    '&.Mui-selected': {
        backgroundColor: grey_3,
    },
    '&.Mui-selected:hover': {
        backgroundColor: `${grey_2}`,
    },
    '&:hover': {
        backgroundColor: `${grey_2}50`,
    },
}))

const AddGroupButton = styled(
    ({ isSideBarCollapsed, ...props }: ListItemButtonProps & { isSideBarCollapsed: boolean }) => (
        <ListItemButton {...props} />
    )
)(({ theme, isSideBarCollapsed }) => ({
    borderRadius: 24,
    backgroundColor: grey_3,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    width: isSideBarCollapsed ? 72 : 'unset',

    '&:hover': {
        backgroundColor: grey_2,
    },

    [`&>.${listItemAvatarClasses.root}`]: {
        minWidth: 24,
        height: 24,
        marginRight: theme.spacing(1),
    },

    [`& .${typographyClasses.root}`]: {
        fontSize: 14,
        fontWeight: 700,
    },
}))

const GroupImageContainer = styled(ListItemAvatar)(() => ({
    minWidth: 40,
}))
