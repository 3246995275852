import React, { useEffect, useState } from 'react'
import { Box, List as MuiList, styled, Theme, Typography } from '@mui/material'
import { useTranslation } from 'shared/lib/i18n'
import {
    GroupMemberListAddGroupMembersViewModel,
    GroupMemberListItemViewModel,
    GroupMemberListSeeAllViewModel,
    GroupMemberListViewModel,
} from '../members/GroupMemberListViewModels'
import { sessionRepository } from '../../index'
import { firstBy } from 'thenby'
import { sortUsersByNameFn } from '../../user/Utils'
import { GroupMember } from '../members/GroupMember'
import { useDetailView } from '../../common/detailView/hooks'
import { SxProps } from '@mui/system/styleFunctionSx'
import { UserListAddMemberRow, UserListItemRow } from '../../user/userList/UserListItemRows'
import { SeeAllButton } from '../../common/lists/SeeAllButton'
import { GroupMemberActionsButton } from './GroupMemberActionsButton'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { Group } from '../Group'
import { AddMembersPopup } from '../AddMembersPopup'

interface Properties {
    group: Group
    groupMembers: GroupMember[]
    canOpenNewDetailView: boolean
    sx?: SxProps<Theme>

    onUserSelected(userId: string): void
}

const MAX_NUMBER_OF_MEMBERS_TO_SHOW = 5

export const GroupDetailMembersList = ({
    group,
    canOpenNewDetailView,
    ...properties
}: Properties) => {
    const translations = useTranslation()

    const { openGroupMembersView } = useDetailView()

    const signedInUser = sessionRepository.signedInUser

    const [isAddingGroupMembers, setIsAddingGroupMembers] = useState(false)
    const [groupMembers, setGroupMembers] = useState<GroupMember[]>([])
    const [viewModels, setViewModels] = useState<GroupMemberListViewModel[]>([])

    const [error, setError] = useState<Error | undefined>(undefined)

    const onClickRow = (userId: string) => () => {
        properties.onUserSelected(userId)
    }

    useEffect(() => {
        setGroupMembers(properties.groupMembers)
    }, [properties.groupMembers])

    useEffect(() => {
        const viewModels: GroupMemberListViewModel[] = groupMembers
            .sort(
                firstBy(
                    (viewModel: GroupMemberListItemViewModel) => viewModel.id === signedInUser?.id,
                    'desc'
                )
                    .thenBy('isAdmin', 'desc')
                    .thenBy(sortUsersByNameFn)
            )
            .slice(0, MAX_NUMBER_OF_MEMBERS_TO_SHOW)
            .map((groupMember) => new GroupMemberListItemViewModel(groupMember))

        if (MAX_NUMBER_OF_MEMBERS_TO_SHOW && groupMembers.length > MAX_NUMBER_OF_MEMBERS_TO_SHOW) {
            viewModels.push(new GroupMemberListSeeAllViewModel())
        }

        if (group.canEdit) {
            viewModels.unshift(new GroupMemberListAddGroupMembersViewModel())
        }

        setViewModels(viewModels)
    }, [groupMembers]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container sx={properties.sx}>
            <Title>
                {translations('plural_group_members', [groupMembers.length], groupMembers.length)}
            </Title>
            <List>
                {viewModels.map((viewModel, index) =>
                    viewModel instanceof GroupMemberListItemViewModel ? (
                        <UserListItemRow
                            key={index}
                            user={viewModel.groupMember}
                            isClickable={canOpenNewDetailView}
                            onClick={onClickRow(viewModel.groupMember.id)}
                            endLabel={
                                viewModel.groupMember.isAdmin ? translations('admin') : undefined
                            }
                            useUForSignedInUser={true}
                            actionsButton={
                                viewModel.groupMember.id !== signedInUser?.id && group.canEdit ? (
                                    <GroupMemberActionsButton
                                        groupId={group.id}
                                        groupMember={viewModel.groupMember}
                                        onError={setError}
                                    />
                                ) : undefined
                            }
                        />
                    ) : viewModel instanceof GroupMemberListAddGroupMembersViewModel ? (
                        <UserListAddMemberRow
                            key={index}
                            onClick={() => setIsAddingGroupMembers(true)}
                        />
                    ) : (
                        <SeeAllButton
                            key={index}
                            translationKey="show_all_members"
                            totalMembersCount={groupMembers.length}
                            onClick={() =>
                                openGroupMembersView({ groupMemberCount: groupMembers.length })
                            }
                        />
                    )
                )}
            </List>
            {isAddingGroupMembers && (
                <AddMembersPopup
                    groupId={group.id}
                    onCloseClicked={() => setIsAddingGroupMembers(false)}
                />
            )}
            <ErrorHandler error={error} translations={translations} horizontal="right" />
        </Container>
    )
}

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',
}))

const Title = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(0, 3),
    fontSize: 14,
    fontWeight: 700,
}))

const List = styled(MuiList)(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(2),
}))
