import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import SessionContext from '../authentication/SessionContextProvider'
import ChurchContext from '../churches/ChurchContextProvider'
import { useTranslation } from 'shared/lib/i18n'
import { groupRepository } from '../index'
import { MinimalUserInfo } from 'shared/lib/users/MinimalUserInfo'
import { Group } from './Group'
import { GroupWithAccessRequests } from './GroupWithAccessRequests'
import { GroupMember } from './members/GroupMember'
import {
    GroupMemberDeletedEvent,
    GroupMemberDeniedEvent,
    GroupMemberEventKey,
    GroupMemberUpdatedEvent,
} from './members/events'
import { useApproveGroupMember, useDenyGroupMember } from './members/hooks'

export const useGroupsSignedInUserIsMemberOf = () => {
    const translations = useTranslation()

    const { church } = useContext(ChurchContext)!
    const { getSignedInUser } = useContext(SessionContext)!

    const [groups, setGroups] = useState<Group[]>([])

    const isLoadingRef = useRef(false)
    const abortControllerRef = useRef<AbortController>()

    const signedInUser = getSignedInUser()
    const signedInUserId = signedInUser?.id
    const signedInUserImage = signedInUser?.image
    const myChurchGroupName = church?.getMyChurchGroupName(translations)

    const getGroups = useCallback(async () => {
        if (!signedInUserId || !signedInUserImage || !myChurchGroupName || isLoadingRef.current) {
            return
        }

        isLoadingRef.current = true

        const groups = await groupRepository.getGroupsUserIsMemberOf(signedInUserId, {
            abortSignal: abortControllerRef.current?.signal,
        })

        setGroups(
            groups.length === 1
                ? groups
                : [
                      Group.createMyChurchGroup(myChurchGroupName, signedInUserImage, groups),
                      ...groups,
                  ]
        )
        isLoadingRef.current = false
    }, [signedInUserId, signedInUserImage, myChurchGroupName])

    const onGroupLeftOrDeleted = useCallback((groupId: string) => {
        setGroups((groups) => groups.filter((group) => group.id !== groupId))
    }, [])

    const onGroupUpdated = useCallback((params: { groupId: string; numberOfReadPosts: number }) => {
        setGroups((groups) =>
            groups.map((group) => {
                if (group.id === params.groupId || group.isMyChurch) {
                    const numberOfUnreadPosts = group.numberOfUnreadPosts - params.numberOfReadPosts
                    return group.copy({
                        numberOfUnreadPosts: Math.max(0, numberOfUnreadPosts),
                    })
                }
                return group
            })
        )
    }, [])

    useEffect(() => {
        if (!signedInUser?.id) {
            return
        }

        abortControllerRef.current?.abort()
        abortControllerRef.current = new AbortController()

        getGroups()

        return () => {
            abortControllerRef.current?.abort()
        }
    }, [signedInUser?.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return {
        isLoading: isLoadingRef.current,
        groups,
        getGroups,
        onGroupLeftOrDeleted,
        onGroupUpdated,
    }
}

export const useGroupsUserIsMemberOf = (userId: string) => {
    const [isLoading, setIsLoading] = useState(true)
    const [groups, setGroups] = useState<Group[]>([])

    const abortControllerRef = useRef<AbortController>()

    const getGroupsUserIsMemberOf = useCallback(() => {
        setIsLoading(true)

        groupRepository
            .getGroupsUserIsMemberOf(userId, {
                sortBy: 'NAME',
                abortSignal: abortControllerRef.current?.signal,
            })
            .then(setGroups)
            .finally(() => setIsLoading(false))
    }, [userId])

    useEffect(() => {
        abortControllerRef.current?.abort()
        abortControllerRef.current = new AbortController()

        getGroupsUserIsMemberOf()

        return () => {
            abortControllerRef.current?.abort()
        }
    }, [getGroupsUserIsMemberOf])

    return { isLoading, groups }
}

export const useGroup = (groupId: string) => {
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<Error | undefined>()
    const [group, setGroup] = useState<Group | undefined>()

    const abortControllerRef = useRef<AbortController>()

    const getGroup = useCallback(() => {
        groupRepository
            .getGroup(groupId, abortControllerRef.current?.signal)
            .then(setGroup)
            .catch(setError)
            .finally(() => setIsLoading(false))
    }, [groupId])

    useEffect(() => {
        abortControllerRef.current?.abort()
        abortControllerRef.current = new AbortController()

        getGroup()

        return () => {
            abortControllerRef.current?.abort()
        }
    }, [groupId, getGroup])

    return { isLoading, error, group, getGroup, setGroup }
}

export const useGroupMembers = (
    groupId: string,
    options?: { isApproved: boolean; initialState?: GroupMember[] }
) => {
    const initialState = useMemo(() => options?.initialState ?? [], [options?.initialState])
    const isApproved = options?.isApproved

    const [groupMembers, setGroupMembers] = useState<GroupMember[]>(initialState)

    const isLoadingRef = useRef(false)
    const abortControllerRef = useRef<AbortController>()

    const onGroupMemberUpdated = useCallback(
        (event: Event) => {
            const detail = (event as GroupMemberUpdatedEvent).detail

            if (detail.groupId !== groupId) return

            setGroupMembers((previousGroupMembers) => {
                const index = previousGroupMembers.findIndex(
                    (groupMember) => groupMember.id === detail.userId
                )
                previousGroupMembers[index] = previousGroupMembers[index].cloneWith({
                    isAdmin: detail.isAdmin,
                })

                return [...previousGroupMembers]
            })
        },
        [groupId]
    )

    const onGroupMemberDeleted = useCallback(
        (event: Event) => {
            if (
                event instanceof GroupMemberDeniedEvent ||
                event instanceof GroupMemberDeletedEvent
            ) {
                const detail = event.detail

                if (detail.groupId !== groupId) return

                setGroupMembers((previousGroupMembers) =>
                    previousGroupMembers.filter((groupMember) => groupMember.id !== detail.userId)
                )
            }
        },
        [groupId]
    )

    const getGroupMembers = useCallback(() => {
        if (isLoadingRef.current) return

        isLoadingRef.current = true

        groupRepository
            .getGroupMembers(groupId, {
                isApproved: isApproved,
                abortSignal: abortControllerRef.current?.signal,
            })
            .then(setGroupMembers)
            .finally(() => {
                isLoadingRef.current = false
            })
    }, [groupId, isApproved])

    useEffect(() => {
        setGroupMembers(initialState)

        if (initialState.length === 0) {
            abortControllerRef.current?.abort()
            abortControllerRef.current = new AbortController()

            getGroupMembers()
        }

        return () => {
            abortControllerRef.current?.abort()
        }
    }, [initialState, getGroupMembers])

    useEffect(() => {
        document.addEventListener(GroupMemberEventKey.GROUP_MEMBERS_ADDED, getGroupMembers)
        document.addEventListener(GroupMemberEventKey.GROUP_MEMBER_APPROVED, getGroupMembers)
        document.addEventListener(GroupMemberEventKey.GROUP_MEMBER_UPDATED, onGroupMemberUpdated)
        document.addEventListener(GroupMemberEventKey.GROUP_MEMBER_DENIED, onGroupMemberDeleted)
        document.addEventListener(GroupMemberEventKey.GROUP_MEMBER_DELETED, onGroupMemberDeleted)

        return () => {
            document.removeEventListener(GroupMemberEventKey.GROUP_MEMBERS_ADDED, getGroupMembers)
            document.removeEventListener(GroupMemberEventKey.GROUP_MEMBER_APPROVED, getGroupMembers)
            document.removeEventListener(
                GroupMemberEventKey.GROUP_MEMBER_UPDATED,
                onGroupMemberUpdated
            )
            document.removeEventListener(
                GroupMemberEventKey.GROUP_MEMBER_DENIED,
                onGroupMemberDeleted
            )
            document.removeEventListener(
                GroupMemberEventKey.GROUP_MEMBER_DELETED,
                onGroupMemberDeleted
            )
        }
    }, [getGroupMembers, onGroupMemberUpdated, onGroupMemberDeleted])

    return {
        isLoading: isLoadingRef.current,
        groupMembers,
        getGroupMembers,
    }
}

export const useGroupAccessRequests = () => {
    const { approveGroupMember } = useApproveGroupMember()
    const { denyGroupMember } = useDenyGroupMember()

    const [groupAccessRequests, setGroupAccessRequests] = useState<GroupWithAccessRequests[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | undefined>()

    const abortControllerRef = useRef<AbortController>()

    const getGroupAccessRequests = useCallback(() => {
        setIsLoading(true)

        groupRepository
            .getAccessRequests(abortControllerRef.current?.signal)
            .then(setGroupAccessRequests)
            .catch(setError)
            .finally(() => setIsLoading(false))
    }, [])

    const getUpdateGroupAccessRequests = (
        accessRequest: GroupWithAccessRequests,
        groupId: string,
        userId: string
    ) => {
        if (accessRequest.id !== groupId) {
            return accessRequest
        }

        const members = accessRequest.members.filter((member) => member.id !== userId)

        if (members.length === 0) {
            return undefined
        }

        return {
            id: accessRequest.id,
            name: accessRequest.name,
            members,
        }
    }

    const onApproveGroupMember = async (groupId: string, userId: string) => {
        const originalGroupAccessRequests = groupAccessRequests

        setGroupAccessRequests(
            (prevState) =>
                prevState
                    .map((accessrequest) => {
                        return getUpdateGroupAccessRequests(accessrequest, groupId, userId)
                    })
                    .filter(Boolean) as GroupWithAccessRequests[]
        )

        approveGroupMember({ groupId, userId }).catch((error: any) => {
            setError(error)
            setGroupAccessRequests(originalGroupAccessRequests)
        })
    }

    const onDenyGroupMember = (groupMemberToDeny: {
        groupMember: MinimalUserInfo
        groupId: string
    }) => {
        const {
            groupId,
            groupMember: { id: userId },
        } = groupMemberToDeny
        const originalGroupAccessRequests = groupAccessRequests

        setGroupAccessRequests(
            (prevState) =>
                prevState
                    .map((accessRequest) => {
                        return getUpdateGroupAccessRequests(accessRequest, groupId, userId)
                    })
                    .filter(Boolean) as GroupWithAccessRequests[]
        )

        denyGroupMember({ groupId, userId }).catch((error: any) => {
            setError(error)
            setGroupAccessRequests(originalGroupAccessRequests)
        })
    }

    useEffect(() => {
        abortControllerRef.current?.abort()
        abortControllerRef.current = new AbortController()

        getGroupAccessRequests()

        return () => {
            abortControllerRef.current?.abort()
        }
    }, [getGroupAccessRequests])

    return {
        groupAccessRequests,
        setGroupAccessRequests,
        getGroupAccessRequests,
        onApproveGroupMember,
        onDenyGroupMember,
        isLoading,
        error,
    }
}
