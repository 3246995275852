import { LikeType } from './LikeType'
import { ReactComponent as LikeFaith } from '../../assets/svg/like_faith.svg'
import { ReactComponent as LikeFaithOutlined } from '../../assets/svg/like_faith_small.svg'
import { ReactComponent as LikeFlower } from '../../assets/svg/like_flower.svg'
import { ReactComponent as LikeFlowerOutlined } from '../../assets/svg/like_flower_small.svg'
import { ReactComponent as LikeHope } from '../../assets/svg/like_hope.svg'
import { ReactComponent as LikeHopeOutlined } from '../../assets/svg/like_hope_small.svg'
import { ReactComponent as LikeLove } from '../../assets/svg/like_love.svg'
import { ReactComponent as LikeLoveOutlined } from '../../assets/svg/like_love_small.svg'
import { ReactComponent as LikePraise } from '../../assets/svg/like_praise.svg'
import { ReactComponent as LikePraiseOutlined } from '../../assets/svg/like_praise_small.svg'
import { ReactComponent as LikePray } from '../../assets/svg/like_pray.svg'
import { ReactComponent as LikePrayOutlined } from '../../assets/svg/like_pray_small.svg'
import { ReactComponent as LikeThumbsUp } from '../../assets/svg/like_thumbs_up.svg'
import { ReactComponent as LikeThumbsUpOutlined } from '../../assets/svg/like_thumbs_up_small.svg'
import { ReactComponent as LikeChai } from '../../assets/svg/like_chai.svg'
import { ReactComponent as LikeChaiUpOutlined } from '../../assets/svg/like_chai_small.svg'

interface Properties {
    likeType?: LikeType
}

export const LikeIcon = ({ likeType }: Properties) => {
    switch (likeType) {
        case LikeType.LOVE:
            return <LikeLove />
        case LikeType.FAITH:
            return <LikeFaith />
        case LikeType.HOPE:
            return <LikeHope />
        case LikeType.PRAISE:
            return <LikePraise />
        case LikeType.THUMBS_UP:
            return <LikeThumbsUp />
        case LikeType.FLOWER:
            return <LikeFlower />
        case LikeType.PRAY:
            return <LikePray />
        case LikeType.CHAI:
            return <LikeChai />
        default:
            return null
    }
}

export const LikeIconOutlined = ({ likeType }: Properties) => {
    switch (likeType) {
        case LikeType.LOVE:
            return <LikeLoveOutlined />
        case LikeType.FAITH:
            return <LikeFaithOutlined />
        case LikeType.HOPE:
            return <LikeHopeOutlined />
        case LikeType.PRAISE:
            return <LikePraiseOutlined />
        case LikeType.THUMBS_UP:
            return <LikeThumbsUpOutlined />
        case LikeType.FLOWER:
            return <LikeFlowerOutlined />
        case LikeType.PRAY:
            return <LikePrayOutlined />
        case LikeType.CHAI:
            return <LikeChaiUpOutlined />
        default:
            return null
    }
}
