import { Skeleton } from 'shared/lib/components/Skeleton'
import { AvatarSkeleton, TitleTextView } from '../../common/detailView'
import { FunctionsTextView } from './UserDetailComponents'

export const UserDetailLoadingState = () => {
    return (
        <>
            <AvatarSkeleton />
            <TitleTextView width="75%">
                <Skeleton variant="rounded" height={30} />
            </TitleTextView>
            <FunctionsTextView width="90%" mt={1}>
                <Skeleton variant="rounded" height={20} />
            </FunctionsTextView>
        </>
    )
}
