import {
    $applyNodeReplacement,
    DecoratorNode,
    DOMConversionMap,
    type DOMConversionOutput,
    DOMExportOutput,
    LexicalEditor,
    type LexicalNode,
    type NodeKey,
    SerializedLexicalNode,
    Spread,
} from 'lexical'
import React from 'react'
import TagComponent from './TagComponent'
import { TextMatchTransformer } from '@lexical/markdown'
import { USER_TAG_REGEX } from './'

export type SerializedTagNode = Spread<
    {
        trigger: string
        userId: string
        userName: string
    },
    SerializedLexicalNode
>

function convertElement(domNode: HTMLElement): DOMConversionOutput | null {
    const trigger = domNode.getAttribute('data-tag-trigger')

    const user = JSON.parse(domNode.getAttribute('data-tag-data') ?? 'null')

    if (trigger != null && user !== null) {
        const node = $createTagNode(trigger, user)
        return { node }
    }
    return null
}

/**
 * This node is used to represent a tag used in the TagPlugin.
 */
export class TagNode extends DecoratorNode<React.JSX.Element> {
    __trigger: string
    __userId: string
    __userName: string

    static getType(): string {
        return 'tag'
    }

    static clone(node: TagNode): TagNode {
        return new TagNode(node.__trigger, { id: node.__userId, name: node.__userName }, node.__key)
    }

    constructor(trigger: string, user: { id: string; name: string }, key?: NodeKey) {
        super(key)
        this.__trigger = trigger
        this.__userId = user.id
        this.__userName = user.name
    }

    createDOM(): HTMLElement {
        return document.createElement('span')
    }

    updateDOM(): boolean {
        return false
    }

    exportDOM(): DOMExportOutput {
        return {
            element: null,
        }
    }

    static importDOM(): DOMConversionMap | null {
        return {
            span: (domNode: HTMLElement) => {
                if (!domNode.hasAttribute('data-tag')) {
                    return null
                }
                return {
                    conversion: convertElement,
                    priority: 1,
                }
            },
        }
    }

    static importJSON(serializedNode: SerializedTagNode): TagNode {
        return $createTagNode(serializedNode.trigger, {
            id: serializedNode.userId,
            name: serializedNode.userName,
        })
    }

    exportJSON(): SerializedTagNode {
        return {
            trigger: this.__trigger,
            userId: this.__userId,
            userName: this.__userName,
            type: 'tag',
            version: 1,
        }
    }

    getTextContent(): string {
        const self = this.getLatest()
        return self.__trigger + self.__userName
    }

    getTrigger(): string {
        const self = this.getLatest()
        return self.__trigger
    }

    getUserId(): string {
        const self = this.getLatest()
        return self.__userId
    }

    decorate(_editor: LexicalEditor): React.JSX.Element {
        return (
            <TagComponent
                nodeKey={this.getKey()}
                trigger={this.getTrigger()}
                user={{
                    id: this.getUserId(),
                    name: this.__userName,
                }}
            />
        )
    }
}

export function $createTagNode(trigger: string, user: { id: string; name: string }): TagNode {
    const tagNode = new TagNode(trigger, user)

    return $applyNodeReplacement(tagNode)
}

export function $isTagNode(node: LexicalNode | null | undefined): node is TagNode {
    return node instanceof TagNode
}

export const TagNodeTransformer = ({
    apiUrl,
    applicationId,
}: {
    apiUrl: string
    applicationId: string
}): TextMatchTransformer => ({
    dependencies: [TagNode],
    export: (node, exportChildren, _) => {
        if (!$isTagNode(node)) {
            return null
        }

        return `[${node.getTextContent()}](${apiUrl}/share/${applicationId}?userId=${node.getUserId()})`
    },
    importRegExp: USER_TAG_REGEX,
    regExp: USER_TAG_REGEX,
    replace: (textNode, match) => {
        const [, name, userId] = match

        const tagNode = $createTagNode('@', {
            id: userId,
            name,
        })

        textNode.replace(tagNode)
    },
    trigger: ')',
    type: 'text-match',
})
