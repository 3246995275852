import React from 'react'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { AvatarSkeleton, TitleTextView } from '../../common/detailView'

export const FeedDetailLoadingState = () => {
    return (
        <>
            <AvatarSkeleton />
            <TitleTextView width="75%">
                <Skeleton variant="rounded" height={30} />
            </TitleTextView>
        </>
    )
}
