import { Image } from 'shared/lib/models/Image'
import { GroupType } from 'shared/lib/groups/GroupType'

export const MY_CHURCH_GROUP_ID = 'my_church_group_id'

export enum GroupMemberState {
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    NONE = 'NONE',
}

export interface MinimalGroupInfo {
    id: string
    name: string
    image?: Image
}

export class Group implements MinimalGroupInfo {
    get isMyChurch() {
        return this.type === GroupType.MY_CHURCH
    }

    get isHome() {
        return this.type === GroupType.HOME
    }

    get isStartGroup() {
        return this.type === GroupType.START
    }

    constructor(
        public readonly id: string,
        public readonly type: GroupType,
        public readonly name: string,
        public readonly description: string | undefined,
        public readonly image: Image | undefined,
        public readonly canEdit: boolean,
        public readonly numberOfUnreadPosts: number,
        public readonly numberOfMembers: number,
        public readonly numberOfUnapprovedMembers: number,
        public readonly canCreatePosts: boolean,
        public readonly canCreateEvents: boolean,
        public readonly groupMemberState: GroupMemberState,
        readonly shareUrl: string,
        public readonly isRestricted: boolean
    ) {}

    copy(params: { numberOfUnreadPosts?: number }): Group {
        return new Group(
            this.id,
            this.type,
            this.name,
            this.description,
            this.image,
            this.canEdit,
            params.numberOfUnreadPosts ?? this.numberOfUnreadPosts,
            this.numberOfMembers,
            this.numberOfUnapprovedMembers,
            this.canCreatePosts,
            this.canCreateEvents,
            this.groupMemberState,
            this.shareUrl,
            this.isRestricted
        )
    }

    static fromApiResponse = (response: Record<string, any>) => {
        return new Group(
            response.id,
            response.type,
            response.name,
            response.description,
            response.image,
            response.type !== GroupType.HOME && response.canEdit,
            response.numberOfUnreadPosts,
            response.numberOfMembers,
            response.numberOfUnapprovedMembers,
            response.canCreatePosts,
            response.canCreateEvents,
            response.groupMemberState,
            response.shareUrl,
            response.isRestricted
        )
    }

    static createMyChurchGroup = (name: string, image: Image, groups: Group[]) => {
        return new Group(
            MY_CHURCH_GROUP_ID,
            GroupType.MY_CHURCH,
            name,
            undefined,
            image,
            false,
            groups.reduce((acc, group) => acc + (group.numberOfUnreadPosts || 0), 0),
            0,
            0,
            groups.some((group) => group.canCreatePosts),
            groups.some((group) => group.canCreateEvents),
            GroupMemberState.APPROVED,
            '',
            false
        )
    }
}
