import { SVGProps } from 'react'

export const AtIcon = (props: SVGProps<any>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 19.5834C15.2927 19.5834 19.5833 15.2928 19.5833 10.0001C19.5833 4.70735 15.2927 0.416748 10 0.416748C4.70727 0.416748 0.416666 4.70735 0.416666 10.0001C0.416666 15.2928 4.70727 19.5834 10 19.5834ZM10.0598 3.73522C6.56678 3.73522 3.73514 6.56686 3.73514 10.0599C3.73514 13.5529 6.56678 16.3845 10.0598 16.3845C10.8214 16.3845 11.5501 16.2502 12.2245 16.0046C12.5797 15.8752 12.9726 16.0583 13.102 16.4135C13.2313 16.7687 13.0483 17.1615 12.693 17.2909C11.8707 17.5905 10.9835 17.7536 10.0598 17.7536C5.81067 17.7536 2.36609 14.309 2.36609 10.0599C2.36609 5.81076 5.81067 2.36617 10.0598 2.36617C14.3089 2.36617 17.7535 5.81076 17.7535 10.0599C17.7535 10.3927 17.7323 10.721 17.6912 11.0433C17.6896 11.0557 17.6876 11.0681 17.6854 11.0803L17.6864 11.0805C17.4644 12.2989 16.9163 13.1845 16.1611 13.7193C15.4107 14.2506 14.5222 14.3877 13.7119 14.233C12.8015 14.0592 11.9403 13.4969 11.4513 12.6644C10.2501 14.0577 8.42801 14.2944 7.26119 13.6495C6.50337 13.2307 6.11448 12.4201 5.99919 11.6007C5.88218 10.7691 6.02552 9.82633 6.43613 8.96694C6.84976 8.10123 7.54364 7.30192 8.54313 6.7861C9.54513 6.26897 10.8102 6.05928 12.3286 6.30007C12.9886 6.40474 13.3888 7.0514 13.2366 7.67395C13.1121 8.18307 12.9667 8.72975 12.808 9.3263C12.6959 9.74779 12.5771 10.1944 12.4543 10.6707C12.2062 11.8275 12.9772 12.6989 13.9687 12.8883C14.455 12.9811 14.9564 12.8948 15.3699 12.602C15.7737 12.316 16.1591 11.7861 16.3332 10.8695C16.367 10.6047 16.3844 10.3345 16.3844 10.0599C16.3844 6.56686 13.5528 3.73522 10.0598 3.73522ZM7.35488 11.4099C7.43404 11.9726 7.67028 12.3114 7.92341 12.4513C8.6602 12.8585 10.5328 12.6406 11.1253 10.3416C11.2561 9.83429 11.378 9.37599 11.4911 8.95112C11.6186 8.47211 11.7351 8.03427 11.8408 7.61422C10.7007 7.47843 9.82524 7.66503 9.17099 8.00268C8.46288 8.36813 7.96952 8.93324 7.67142 9.55715C7.37031 10.1874 7.27743 10.8594 7.35488 11.4099Z"
            fill="white"
            {...props}
        />
    </svg>
)
