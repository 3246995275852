import { Box } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import i18next from 'i18next'
import { DateTime } from 'luxon'
import { memo, useState } from 'react'
import { DatePicker } from 'shared/lib/components/textfields/DatePicker'
import { TimePicker } from 'shared/lib/components/textfields/TimePicker'
import { DateTimeValidator } from 'shared/lib/utils/DateTimeValidator'
import { SwitchRow } from 'shared/lib/components/switch/SwitchRow'
import { useTranslation } from 'shared/lib/i18n'
import { ReactComponent as TimeIcon } from '../../../src/assets/svg/time.svg'
import { RecurringIcon } from 'shared/lib/assets/svg/RecurringIcon'
import { FrequencyButton } from 'shared/lib/recurring/FrequencyButton'
import { RecurrenceRule } from 'shared/lib/recurring/RecurrenceRule'

interface Properties {
    isAllDay: boolean
    isLoading: boolean
    allowStartInPast: boolean
    canMakeRecurring: boolean
    startDate: DateTime
    endDate: DateTime
    recurrenceRule?: RecurrenceRule

    onIsAllDayChanged(value: boolean): void
    onStartDateChanged(value: DateTime): void
    onEndDateChanged(value: DateTime): void
    onRecurrenceRuleChanged(value: RecurrenceRule | undefined): void
}

const Component = ({
    startDate,
    endDate,
    isAllDay,
    allowStartInPast,
    ...properties
}: Properties) => {
    const translations = useTranslation()

    const [startDateErrorMessage, setStartDateErrorMessage] = useState<string | undefined>()
    const [endDateErrorMessage, setEndDateErrorMessage] = useState<string | undefined>()

    const onIsAllDayChanged = (value: boolean) => {
        properties.onIsAllDayChanged(value)
    }

    const onStartDateChanged = (value: DateTime) => {
        if (value > endDate) {
            const end = value.plus({ hours: 1 })
            validatePeriod(value, end, isAllDay)
            properties.onStartDateChanged(value)
            properties.onEndDateChanged(end)
            return
        }

        validatePeriod(startDate, endDate, isAllDay)
        properties.onStartDateChanged(value)
    }

    const onEndDateChanged = (value: DateTime) => {
        if (value < startDate) {
            const start = value.minus({ hours: 1 })
            validatePeriod(start, value, isAllDay)
            properties.onStartDateChanged(start)
            properties.onEndDateChanged(value)
            return
        }

        properties.onEndDateChanged(value)
    }

    const validatePeriod = (startDate: DateTime, endDate: DateTime, isAllDay: boolean) => {
        const startDateErrorMessageKey = DateTimeValidator.startDateErrorKey(
            startDate,
            endDate,
            isAllDay,
            { allowStartInPast }
        )
        const endDateErrorMessageKey = DateTimeValidator.endDateErrorKey(
            endDate,
            startDate,
            isAllDay
        )
        setStartDateErrorMessage(
            startDateErrorMessageKey ? translations(startDateErrorMessageKey) : undefined
        )
        setEndDateErrorMessage(
            endDateErrorMessageKey ? translations(endDateErrorMessageKey) : undefined
        )
    }

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18next.language}>
            <Box display="flex" alignItems="start">
                <Box mt={1}>
                    <TimeIcon />
                </Box>

                <Box ml={2} display="flex" flexGrow={1} flexDirection="column">
                    <Box pl={1}>
                        <SwitchRow
                            mt={0}
                            title={translations('agenda_all_day')}
                            isChecked={isAllDay}
                            onChanged={onIsAllDayChanged}
                            isDisabled={properties.isLoading}
                        />
                    </Box>

                    <Box display="flex" mt={2}>
                        <Box>
                            <DatePicker
                                disablePast={true}
                                label={translations('start_date')}
                                value={startDate}
                                maxDate={DateTimeValidator.MAX_DATE}
                                helperText={startDateErrorMessage}
                                onChange={(value) => {
                                    if (value) onStartDateChanged(value)
                                }}
                                disabled={properties.isLoading}
                            />
                        </Box>
                        {!isAllDay && (
                            <Box ml={3}>
                                <TimePicker
                                    sx={{
                                        width: '120px',
                                    }}
                                    ampm={false}
                                    label={translations('start_time')}
                                    value={startDate}
                                    onChange={(value) => {
                                        if (value) onStartDateChanged(value)
                                    }}
                                    disabled={properties.isLoading}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box display="flex" flexDirection="row" mt={2}>
                        <Box>
                            <DatePicker
                                disablePast={true}
                                label={translations('end_date')}
                                value={endDate}
                                maxDate={DateTimeValidator.MAX_DATE}
                                helperText={endDateErrorMessage}
                                onChange={(value) => {
                                    if (value) onEndDateChanged(value)
                                }}
                                onBlur={() => {
                                    if (endDate) onEndDateChanged(endDate)
                                }}
                                disabled={properties.isLoading}
                            />
                        </Box>
                        {!isAllDay && (
                            <Box ml={3}>
                                <TimePicker
                                    sx={{
                                        width: '120px',
                                    }}
                                    ampm={false}
                                    label={translations('end_time')}
                                    value={endDate}
                                    onChange={(value) => {
                                        if (value) onEndDateChanged(value)
                                    }}
                                    onBlur={() => {
                                        if (endDate) onEndDateChanged(endDate)
                                    }}
                                    disabled={properties.isLoading}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>

            {properties.canMakeRecurring && (
                <Box display="flex" mt={2} gap={2} alignItems="center">
                    <RecurringIcon />

                    <Box overflow="hidden">
                        <FrequencyButton
                            withBackdrop={false}
                            locale={i18next.language}
                            start={startDate}
                            recurrenceRule={properties.recurrenceRule}
                            translations={translations}
                            onRecurrenceRuleChanged={properties.onRecurrenceRuleChanged}
                        />
                    </Box>
                </Box>
            )}
        </LocalizationProvider>
    )
}

export const EventDurationView = memo(Component, (previous, next) => {
    return (
        previous.startDate === next.startDate &&
        previous.endDate === next.endDate &&
        previous.isAllDay === next.isAllDay &&
        previous.recurrenceRule === next.recurrenceRule &&
        previous.isLoading === next.isLoading
    )
})
