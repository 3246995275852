import { Box, styled } from '@mui/material'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { grey_3 } from 'shared/lib/theme/Theme'

export const SelectChurchLoadingCard = () => {
    return (
        <Card>
            <Skeleton variant="rectangular" width={40} height={40} sx={{ borderRadius: '8px' }} />
            <Box display="flex" flexDirection="column" overflow="hidden" flexGrow={1}>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="100%" />
            </Box>
        </Card>
    )
}

const Card = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    borderRadius: '16px',
    border: `solid 1px ${grey_3}`,
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
}))
