export const SupportedTopLevelDomains = [
    'com',
    'net',
    'org',
    'gov',
    'edu',
    'info',
    'app',

    'nl',
    'be',
    'de',
    'fr',
    'uk',
    'eu',
]
