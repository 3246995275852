import { Box, BoxProps, styled } from '@mui/material'

export const Card = styled((props: BoxProps) => <Box className="card" {...props} />)(() => ({
    overflow: 'hidden',
    backgroundColor: 'white',
    borderRadius: '32px',
}))

export const CardWithTopLeftBorderRadius = styled(Box)(() => ({
    overflow: 'hidden',
    backgroundColor: 'white',
    borderTopLeftRadius: '16px',
}))
