import { Box, BoxProps, Fade, Snackbar, styled, Typography as MuiTypography } from '@mui/material'
import { ReactElement } from 'react'
import theme from '../theme/Theme'
import { ReactComponent as RoundedCheckWhite } from '../assets/svg/rounded_check_white.svg'

interface PopupModalProperties {
    message: string | undefined
    vertical?: 'top' | 'center' | 'bottom'
    horizontal?: 'left' | 'center' | 'right'
    icon?: ReactElement
    iconColor?: string
    duration?: number | null
    onClose: () => void
    closeButton?: ReactElement
}

export const PopupModal = (properties: PopupModalProperties) => {
    const vertical = properties.vertical === 'center' ? 'top' : properties.vertical ?? 'bottom'
    const horizontal = properties.horizontal ?? 'left'
    const duration = properties.duration === undefined ? 3000 : properties.duration

    return (
        <Snackbar
            open={properties.message !== undefined}
            autoHideDuration={duration}
            TransitionComponent={Fade}
            onClose={properties.onClose}
            anchorOrigin={{ vertical, horizontal }}
            sx={{
                height: properties.vertical === 'center' ? '100%' : undefined,
                pointerEvents: 'none',
            }}
        >
            <div>
                <PopupModalContainer iconColor={properties.iconColor ?? theme.palette.primary.main}>
                    <Box alignSelf={'center'}>{properties.icon ?? <RoundedCheckWhite />}</Box>

                    <Box alignSelf={'center'}>
                        <Typography gutterBottom>{properties.message}</Typography>
                    </Box>

                    {properties.closeButton !== undefined && (
                        <Box alignSelf={'center'}>{properties.closeButton}</Box>
                    )}
                </PopupModalContainer>
            </div>
        </Snackbar>
    )
}

const PopupModalContainer = styled(({ iconColor, ...props }: BoxProps & { iconColor: string }) => (
    <Box {...props} />
))(({ theme, iconColor }) => ({
    height: '88px',
    backgroundColor: 'white',
    display: 'flex',
    gap: theme.spacing(2),
    boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: '32px',
    pointerEvents: 'none',
    '& svg': {
        fill: iconColor,
    },
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(3),
}))

const Typography = styled(MuiTypography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 700,
}))
