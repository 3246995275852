import { WebAppHttpRepository } from '../common/WebAppHttpRepository'
import { SessionRepository } from '../authentication/SessionRepository'
import { LinkPreviewRepository } from 'shared/lib/linkPreviews/LinkPreviewRepository'

export class WebAppLinkPreviewRepository
    extends WebAppHttpRepository
    implements LinkPreviewRepository
{
    constructor(private readonly sessionRepository: SessionRepository) {
        super()
    }

    async getLinkPreview(url: string, abortSignal: AbortSignal): Promise<any> {
        return await this.sessionRepository.withAccessToken(() =>
            this.get(
                `${this.apiUrl}/api/v1/linkpreviews?url=${encodeURIComponent(url)}`,
                abortSignal
            )
        )
    }
}
