import { Box } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { TextField } from 'shared/lib/components/textfields/TextField'
import { LocalImage } from 'shared/lib/forms/LocalImage'
import { ImageCropper } from 'shared/lib/images/ImageCropper'
import donkey from '../../assets/images/donkey.jpg'
import ChurchContext from '../../churches/ChurchContextProvider'
import { LinearProgressBar } from '../../common/LinearProgressBar'
import { Paths } from '../../routing/paths'
import SessionContext from '../SessionContextProvider'
import { SessionPage, Title } from '../SessionPage'
import { DropProfileImageZone } from './DropProfileImageZone'
import { PrimaryActionButtonContainer } from 'shared/lib/components/PrimaryActionButtonsContainer'
import { isMobile } from 'mobile-device-detect'
import { useTranslation } from 'shared/lib/i18n'

export const CreateProfilePage = () => {
    const translations = useTranslation()
    const navigate = useNavigate()

    const { church } = useContext(ChurchContext)!
    const { getIsSignedIn, getSignedInUser, updateSignedInUser } = useContext(SessionContext)!
    const isSignedIn = getIsSignedIn()
    const signedInUser = getSignedInUser()!

    const [firstName, setFirstName] = useState<string>(signedInUser?.firstName || '')
    const [lastName, setLastName] = useState<string>(signedInUser?.lastName || '')
    const [profileImageFiles, setProfileImageFiles] = useState<
        | {
              imageFile: LocalImage
              thumbnailFile: LocalImage
          }
        | undefined
    >()
    const [selectedImage, setSelectedImage] = useState<LocalImage | undefined>()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | undefined>()

    const isProfileCreated = signedInUser?.isProfileCreated === true
    const canContinue = firstName && lastName && profileImageFiles

    const onImageChanged = (image: LocalImage) => {
        setSelectedImage(image)
    }

    const onImageCropped = (imageFile: LocalImage, thumbnailFile: LocalImage) => {
        setProfileImageFiles({ imageFile, thumbnailFile })
        setSelectedImage(undefined)
    }

    const onSubmit = () => {
        if (!canContinue) {
            return
        }

        setIsLoading(true)

        updateSignedInUser({
            firstName,
            lastName,
            fullSizeImage: profileImageFiles!.imageFile!,
            thumbnailImage: profileImageFiles!.thumbnailFile!,
        })
            .then(() => navigate(Paths.TIME_LINE, { replace: true }))
            .catch((error) => {
                setIsLoading(false)
                setError(error)
            })
    }

    useEffect(() => {
        if (isProfileCreated) {
            navigate(Paths.TIME_LINE, { replace: true })
        }
    }, [isProfileCreated, navigate])

    if (isSignedIn && isProfileCreated) {
        return <></>
    }

    return (
        <SessionPage backgroundImage={donkey} church={church}>
            <LinearProgressBar variant="determinate" value={100} />

            <Title variant="h1" mt={isMobile ? 4 : 8}>
                {translations('create_profile_title')}
            </Title>

            <TextField
                fullWidth={true}
                autoFocus={false}
                value={firstName}
                label={translations('first_name')}
                onChanged={setFirstName}
                sx={{
                    marginTop: 5,
                }}
            />

            <TextField
                label={translations('last_name')}
                fullWidth={true}
                autoFocus={false}
                value={lastName}
                onChanged={setLastName}
                onEnterKeyPressed={() => onSubmit()}
                sx={{
                    marginTop: 4,
                }}
            />

            <Box mt={4}>
                <DropProfileImageZone
                    id="CreateProfilePage"
                    imageFile={profileImageFiles?.imageFile}
                    onImageChanged={(image) => onImageChanged(image)}
                />

                {selectedImage && (
                    <ImageCropper
                        image={selectedImage.file}
                        onCancelCropClicked={() => setSelectedImage(undefined)}
                        onImageCropped={onImageCropped}
                        translations={translations}
                    />
                )}
            </Box>

            <PrimaryActionButtonContainer mt={4}>
                <PrimaryContainedButton
                    fullWidth={true}
                    title={translations('create_an_account')}
                    isLoading={isLoading}
                    disabled={!canContinue || isLoading}
                    onClick={onSubmit}
                />
            </PrimaryActionButtonContainer>

            <ErrorHandler error={error} horizontal="left" translations={translations} />
        </SessionPage>
    )
}
