import { Box, styled } from '@mui/material'
import { Card } from 'shared/lib/components/Card'
import { Skeleton } from 'shared/lib/components/Skeleton'

export const SkeletonCard = () => {
    return (
        <StyledCard p={3}>
            <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                <Skeleton variant="circular" width={130} height={130} />

                <Skeleton
                    variant="text"
                    width="90%"
                    sx={{
                        marginTop: (theme) => theme.spacing(2),
                    }}
                />
                <Skeleton variant="text" width="70%" />
            </Box>
        </StyledCard>
    )
}

const StyledCard = styled(Card)(() => ({
    borderRadius: '16px',
    height: '100%',
    width: '100%',
}))
