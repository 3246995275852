import { Box } from '@mui/material'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { AvatarSkeleton } from '../common/detailView'

export const MobileGroupItemLoadingState = () => {
    return (
        <Box
            px={2.5}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '6px',
                gap: 1,
                width: '80px',
            }}
        >
            <AvatarSkeleton width={'40px'} height={'40px'} />
            <Skeleton variant="rounded" width="100%" height={10} />
        </Box>
    )
}
