import {
    Avatar,
    AvatarProps,
    Box,
    ButtonBase,
    List,
    ListItemButton,
    styled,
    Typography,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '../../assets/svg'
import { textColor } from '../../theme/Theme'
import { MenuPopup } from '../MenuPopup'
import dutchFlag from './../../assets/images/flag_dutch.png'
import englishFlag from './../../assets/images/flag_english.png'
import frenchFlag from './../../assets/images/flag_french.png'
import frisianFlag from './../../assets/images/flag_frisian.png'
import germanFlag from './../../assets/images/flag_german.png'
import polishFlag from './../../assets/images/flag_polish.png'

interface Properties {
    hasLargeImage?: boolean
    hasWhiteBackground?: boolean
    hasNegativeMargin?: boolean
    currentLanguage: string
    supportedLanguages: string[]

    changeLanguage(language: string): Promise<void>
}

export const SelectLanguageButton = ({ currentLanguage, ...properties }: Properties) => {
    const isKnownLanguage = properties.supportedLanguages.includes(currentLanguage)

    const [language, setLanguage] = useState(isKnownLanguage ? currentLanguage : 'nl')
    const [anchor, setAnchor] = useState<HTMLElement | null>(null)

    const hasLargeImage = properties.hasLargeImage ?? false
    const hasWhiteBackground = properties.hasWhiteBackground ?? true
    const color = hasWhiteBackground ? textColor : 'white'
    const width = '200px'
    const anchorRef = useRef<HTMLButtonElement>(null)
    const isPopperOpen = Boolean(anchor)

    const onLanguageSelected = (language: string) => {
        properties.changeLanguage(language).then(() => {
            setLanguage(language)
            setAnchor(null)
        })
    }

    useEffect(() => {
        properties.changeLanguage(language)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Box
                display="inline-flex"
                alignItems="center"
                mx={properties.hasNegativeMargin ? -2 : 0}
            >
                <LanguageButtonBase
                    ref={anchorRef}
                    onClick={() => setAnchor(anchorRef.current)}
                    focusRipple={true}
                    sx={{
                        backgroundColor: hasWhiteBackground ? 'white' : undefined,
                    }}
                >
                    <Box height={36} px={2} display="flex" flexDirection="row" alignItems="center">
                        <Box bgcolor="white" padding="2px" borderRadius="50%" mr={1}>
                            <LanguageAvatar
                                hasLargeImage={hasLargeImage}
                                src={flagForLanguage(language)}
                            />
                        </Box>

                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 600,
                                width: '20px',
                                color,
                            }}
                        >
                            {language.toUpperCase()}
                        </Typography>

                        <ChevronContainer>
                            {!isPopperOpen && <ChevronDownIcon color={color} />}
                            {isPopperOpen && <ChevronUpIcon color={color} />}
                        </ChevronContainer>
                    </Box>
                </LanguageButtonBase>
            </Box>

            <MenuPopup
                anchor={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                popupWidth={{
                    desktop: width,
                    tablet: width,
                    phablet: width,
                    mobile: width,
                }}
                popupMarginTop={'16px'}
                arrowRightPosition={'32px'}
                onPopupClosed={() => setAnchor(null)}
            >
                <List sx={{ width: width, overflow: 'auto' }}>
                    {properties.supportedLanguages
                        .filter((item) => item !== language)
                        .map((language) => (
                            <ListItemButton
                                onClick={() => onLanguageSelected(language)}
                                key={language}
                            >
                                <ListLanguageAvatar src={flagForLanguage(language)} />
                                <LanguageName language={language} />
                            </ListItemButton>
                        ))}
                </List>
            </MenuPopup>
        </>
    )
}

const flagForLanguage = (language: string) => {
    let flag

    switch (language) {
        case 'nl':
            flag = dutchFlag
            break
        case 'en':
            flag = englishFlag
            break
        case 'fr':
            flag = frenchFlag
            break
        case 'de':
            flag = germanFlag
            break
        case 'pl':
            flag = polishFlag
            break
        case 'fy':
            flag = frisianFlag
            break
        default:
            return undefined
    }

    return typeof flag === 'string' ? flag : flag.src
}

const LanguageName = ({ language }: { language: string }) => {
    const languageName = () => {
        switch (language) {
            case 'nl':
                return 'Nederlands'
            case 'en':
                return 'English'
            case 'fr':
                return 'Français'
            case 'de':
                return 'Deutsch'
            case 'pl':
                return 'Polski'
            case 'fy':
                return 'Frysk'
            default:
                return null
        }
    }

    return <LanguageTextView variant="body1">{languageName()}</LanguageTextView>
}

const LanguageAvatar = styled(
    ({ hasLargeImage, ...properties }: AvatarProps & { hasLargeImage: boolean }) => (
        <Avatar {...properties} />
    )
)(({ hasLargeImage }) => ({
    width: hasLargeImage ? 24 : 16,
    height: hasLargeImage ? 24 : 16,
}))

const LanguageButtonBase = styled(ButtonBase)(() => ({
    borderRadius: 32,
}))

const ListLanguageAvatar = styled(Avatar)(({ theme }) => ({
    width: '27px',
    height: '27px',
    margin: theme.spacing(0, 2, 0, 0),
}))

const LanguageTextView = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: 700,
}))

const ChevronContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginLeft: theme.spacing(1),
    '& svg': {
        height: '20px',
        width: '20px',
    },
}))
