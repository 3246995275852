import { SVGProps } from 'react'

export const RecurringIcon = (properties: SVGProps<any>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="current"
        {...properties}
    >
        <path
            d="M4.59376 2.52539C6.20695 1.62895 8.06659 1.28366 9.89236 1.54188C11.7149 1.79964 13.4045 2.64476 14.7077 3.94953L17.707 6.78261V2.50016C17.707 2.15499 17.9869 1.87516 18.332 1.87516C18.6772 1.87516 18.957 2.15499 18.957 2.50016V8.3335C18.957 8.67868 18.6772 8.9585 18.332 8.9585H12.4987C12.1535 8.9585 11.8737 8.67868 11.8737 8.3335C11.8737 7.98832 12.1535 7.7085 12.4987 7.7085H16.8669L13.8426 4.85187C13.838 4.84754 13.8335 4.84314 13.829 4.83868C12.7163 3.72205 11.2732 2.99961 9.71731 2.77956C8.16145 2.55952 6.57642 2.85367 5.20094 3.61802C3.82539 4.3824 2.73361 5.57582 2.09071 7.01901C1.44779 8.46224 1.28882 10.0766 1.63794 11.6188C1.98705 13.1609 2.82516 14.5467 4.02532 15.5675C5.22543 16.5883 6.7226 17.189 8.29117 17.2798C9.85973 17.3705 11.4154 16.9464 12.7239 16.0709C14.0324 15.1954 15.023 13.9157 15.5458 12.4241C15.66 12.0984 16.0166 11.9268 16.3424 12.041C16.6681 12.1552 16.8396 12.5118 16.7254 12.8376C16.1134 14.5837 14.9533 16.0833 13.419 17.1098C11.8847 18.1364 10.0597 18.6342 8.21897 18.5277C6.37824 18.4212 4.62222 17.7163 3.21543 16.5197C1.8087 15.3231 0.827419 13.6999 0.418785 11.8948C0.0101585 10.0898 0.19614 8.20011 0.948881 6.51035C1.70164 4.82055 2.98063 3.4218 4.59376 2.52539Z"
            fill="current"
        />
    </svg>
)
