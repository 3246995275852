import { Post } from '../posts/Post'

export enum PostEvent {
    MARK_AS_READ = 'mark-as-read',
    MARKED_AS_READ = 'marked-as-read',
}

export class MarkAsReadEvent extends CustomEvent<Post> {
    constructor(post: Post) {
        super(PostEvent.MARK_AS_READ, { detail: post })
    }
}

export class MarkedAsReadEvent extends CustomEvent<{ groupId: string; numberOfReadPosts: number }> {
    constructor(groupId: string, numberOfReadPosts: number) {
        super(PostEvent.MARKED_AS_READ, { detail: { groupId, numberOfReadPosts } })
    }
}
