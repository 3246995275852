import { Box, styled, Typography } from '@mui/material'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'
import AutoSizer from 'react-virtualized-auto-sizer'
import { GroupType } from 'shared/lib/groups/GroupType'
import { useGroupsUserIsMemberOf } from '../../groups/hooks'
import { useTranslation } from 'shared/lib/i18n'
import { GroupCard } from './UserDetailComponents'

interface Properties {
    userId: string
    canOpenNewDetailView: boolean

    onGroupSelected(groupId: string): void
}

export const GroupsList = ({ userId, canOpenNewDetailView, onGroupSelected }: Properties) => {
    const translations = useTranslation()
    const { groups } = useGroupsUserIsMemberOf(userId)

    const groupsWithoutHomeGroup = groups.filter((group) => group.type !== GroupType.HOME)

    return groupsWithoutHomeGroup.length > 0 ? (
        <Container>
            <Header>{translations('member_of')}</Header>
            <AutoSizer disableHeight={true}>
                {({ width }) => (
                    <ListContainer sx={{ width }}>
                        {groupsWithoutHomeGroup.map((group) => (
                            <ItemContainer key={group.id} columnCount={getColumnCount(width)}>
                                <GroupCard
                                    group={group}
                                    isClickable={canOpenNewDetailView}
                                    onGroupSelected={onGroupSelected}
                                />
                            </ItemContainer>
                        ))}
                    </ListContainer>
                )}
            </AutoSizer>
        </Container>
    ) : null
}

const getColumnCount = (availableWidth: number) => {
    switch (true) {
        case availableWidth < 300:
            return 2
        case availableWidth > 500:
            return 5
        case availableWidth > 400:
            return 4
        case availableWidth > 300:
            return 3
        default:
            return 5
    }
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: theme.spacing(3),
    width: '100%',
}))

const Header = styled(Typography)(() => ({
    fontSize: 14,
    fontWeight: 700,
}))

const GAP = 1

const ListContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    gap: theme.spacing(GAP),
}))

const ItemContainer = styled(({ columnCount, ...props }: DefaultComponentProps<any>) => (
    <div {...props} />
))(({ theme, columnCount }) => ({
    width: `calc((100% - ${theme.spacing((columnCount - 1) * GAP)}) / ${columnCount})`,
    height: 100,
}))
