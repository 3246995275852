import { useTranslation } from 'shared/lib/i18n'
import { useEffect, useState } from 'react'
import { primaryColor } from 'shared/lib/theme/Theme'

export const ConsoleAdvertisement = () => {
    const translations = useTranslation()
    const [isDisplayed, setIsDisplayed] = useState(false)

    useEffect(() => {
        if (isDisplayed) return

        console.log(
            `
%cDonkey Mobile
%c${translations('slogan')}

%cWe're hiring! https://donkeymobile.app/vacatures#OpenstaandeVacatures
        `,
            `font-size: 40px; color: ${primaryColor}`,
            'font-size: 28px',
            'font-size: 14px'
        )

        setIsDisplayed(true)
    }, [translations, isDisplayed])

    return null
}
