import { styled } from '@mui/material'
import MuiGrid from '@mui/material/Unstable_Grid2'
import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useIsTabletOrBigger } from 'shared/lib/theme/BreakPointHooks'
import {
    defaultTransition,
    mobileBottomBarHeight,
    mobileHeaderWithGroupsHeight,
    smartBannerHeight,
} from 'shared/lib/theme/Theme'
import ChurchContext from '../churches/ChurchContextProvider'
import { DetailView } from '../common/detailView/DetailView'
import { useDetailView } from '../common/detailView/hooks'
import { ContentContainer } from '../common/layout/ContentContainer'
import { MobileHeaderWithGroups } from '../common/layout/MobileHeaderWithGroups'
import { MY_CHURCH_GROUP_ID } from '../groups/Group'
import { GroupsContext } from '../groups/GroupsContextProvider'
import { useGroupListViewModels } from '../groups/groupList/GroupListViewModels'
import { TabletDesktopGroupsSideBar } from '../groups/sidebar/TabletDesktopGroupsSideBar'
import { Paths } from '../routing/paths'
import { EVENT_LIST_ID } from './EventList'
import { EventsGrid } from './EventsGrid'

export const EventsPage = () => {
    const navigate = useNavigate()
    const params = useParams()

    const { isSmartBannerOpen } = useContext(ChurchContext)!
    const { isLoading, groups, getGroups } = useContext(GroupsContext)

    const isTabletOrBigger = useIsTabletOrBigger()
    const { isDetailViewOpen, isDetailViewDisplayedAsPopup, openGroupDetailView } = useDetailView()

    const heightOfSmartBanner = isSmartBannerOpen ? smartBannerHeight : 0

    const selectedGroupId =
        groups.length === 1
            ? groups[0].id
            : groups.length > 1
            ? params.groupId || MY_CHURCH_GROUP_ID
            : undefined
    const selectedGroup = groups.find((group) => group.id === selectedGroupId)
    const homeGroupId = groups.find((group) => group.isHome)?.id

    const onGroupSelected = (groupId: string) => {
        if (groupId === selectedGroupId) {
            openGroupDetailView(groupId, { resetHistory: true })
            return
        }

        navigate(
            Paths.GROUP_EVENTS.replace(':groupId', groupId === MY_CHURCH_GROUP_ID ? '' : groupId)
        )
    }

    const groupListViewModels = useGroupListViewModels({
        groups,
        canCreateGroups: false,
        selectedGroupId,
        onGroupSelected,
    })

    useEffect(() => {
        getGroups()
    }, [getGroups])

    useEffect(() => {
        if (isLoading || groups.length === 0) {
            return
        }

        const selectedGroup = groups.find((group) => group.id === selectedGroupId)
        if (!selectedGroup) {
            navigate(Paths.EVENTS)
        }
    }, [isLoading, selectedGroupId, groups, navigate])

    return (
        <>
            {!isTabletOrBigger && (
                <>
                    <MobileHeaderWithGroups
                        isBadgeVisible={false}
                        canShowSmartBanner={true}
                        groupListViewModels={groupListViewModels}
                        selectedGroupId={selectedGroupId}
                        scrollableContainerId={EVENT_LIST_ID}
                    />
                </>
            )}

            <ContentContainer
                mt={
                    !isTabletOrBigger
                        ? mobileHeaderWithGroupsHeight + heightOfSmartBanner
                        : undefined
                }
                mb={!isTabletOrBigger ? mobileBottomBarHeight : undefined}
            >
                <Grid container columns={{ mobile: 4, phablet: 4, tablet: 8, desktop: 12 }}>
                    {isTabletOrBigger && (
                        <Grid
                            tablet={isDetailViewOpen && !isDetailViewDisplayedAsPopup ? 1 : 2}
                            desktop={3}
                        >
                            <TabletDesktopGroupsSideBar
                                isBadgeVisible={false}
                                viewModels={groupListViewModels}
                                selectedGroupId={selectedGroupId}
                            />
                        </Grid>
                    )}

                    <Grid
                        mobile={4}
                        phablet={isDetailViewOpen && !isDetailViewDisplayedAsPopup ? 2 : 4}
                        tablet={4}
                        desktop={5}
                    >
                        {selectedGroup && (
                            <EventsGrid selectedGroup={selectedGroup} homeGroupId={homeGroupId} />
                        )}
                    </Grid>

                    {isDetailViewOpen && (
                        <Grid phablet={2} tablet={3} desktop={4}>
                            <DetailView />
                        </Grid>
                    )}
                </Grid>
            </ContentContainer>
        </>
    )
}

const Grid = styled(MuiGrid)(() => ({
    height: '100%',
    transition: defaultTransition,
}))
