import { Box } from '@mui/material'
import { backgroundColor } from 'shared/lib/theme/Theme'
import { StyledVirtuoso } from '../../../common/StyledVirtuoso'
import { AppAccessRequestsButton } from '../AppAccessRequestsButton'
import {
    MobileAppAccessRequestsViewModel,
    MobileUserItemViewModel,
    MobileUserListViewModel,
    MobileUserSectionViewModel,
} from './MobileUserViewModels'
import { SkeletonRow } from './SkeletonRow'
import { UserListRow } from './UserListRow'
import { UserSectionRow } from './UserSectionRow'

interface ListProperties {
    viewModels: MobileUserListViewModel[]
    height: number
    isLoading: boolean

    onUserSelected(userId: string): void
}

enum RowHeight {
    User = 56,
    Section = 40,
}

export const MOBILE_USER_LIST_ID = `mobile-user-list-${Math.random()}`

export const MobileUserList = (properties: ListProperties) => {
    const rowsThatFitScreen = Math.ceil(properties.height / RowHeight.User)

    const getRow = (index: number) => {
        if (properties.isLoading) {
            return <SkeletonRow index={index} />
        }

        const viewModel = properties.viewModels[index]

        if (viewModel instanceof MobileUserSectionViewModel) {
            return <UserSectionRow viewModel={viewModel} />
        } else if (viewModel instanceof MobileUserItemViewModel) {
            return <UserListRow viewModel={viewModel} onRowClicked={properties.onUserSelected} />
        } else if (viewModel instanceof MobileAppAccessRequestsViewModel) {
            return (
                <Box mt={3}>
                    <AppAccessRequestsButton
                        withGroupBadge={true}
                        withRoundedCorners={false}
                        fullWidth={true}
                        appAccessRequestCount={viewModel.appAccessRequestsCount}
                    />
                </Box>
            )
        } else {
            return <></>
        }
    }

    return (
        <StyledVirtuoso
            id={MOBILE_USER_LIST_ID}
            totalCount={properties.isLoading ? rowsThatFitScreen : properties.viewModels.length}
            itemContent={getRow}
            overscan={500}
            style={{
                height: properties.height,
                width: '100vw',
                backgroundColor: backgroundColor,
            }}
        />
    )
}
