import { IconButton, TextFieldProps } from '@mui/material'
import { forwardRef, Ref, useState } from 'react'
import { TextField, TextFieldProperties } from './TextField'
import { EyeHide } from '../../assets/svg/EyeHide'
import { EyeShow } from '../../assets/svg/EyeShow'
import { textColor } from '../../theme/constants'

export const PasswordTextField = forwardRef(
    (
        properties: Omit<TextFieldProperties & TextFieldProps, 'endIcon' | 'type'>,
        ref: Ref<HTMLTextAreaElement>
    ) => {
        const [isPasswordVisible, setIsPasswordVisible] = useState(false)

        return (
            <TextField
                {...properties}
                ref={ref}
                type={isPasswordVisible ? 'text' : 'password'}
                endIcon={
                    <IconButton
                        tabIndex={-1}
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    >
                        {isPasswordVisible ? (
                            <EyeHide color={textColor} />
                        ) : (
                            <EyeShow color={textColor} />
                        )}
                    </IconButton>
                }
            />
        )
    }
)
