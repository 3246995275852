import { Box, styled, Typography } from '@mui/material'
import { Avatar } from 'shared/lib/components/Avatar'
import { grey_2, grey_3 } from 'shared/lib/theme/Theme'
import { ReactComponent as Reply } from '../assets/svg/reply.svg'
import { useTranslation } from 'shared/lib/i18n'

export const NoCommentsView = () => {
    const translations = useTranslation()

    return (
        <Container>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Avatar
                    width={72}
                    height={72}
                    sx={{
                        backgroundColor: grey_3,
                    }}
                >
                    <ReplyIcon />
                </Avatar>
                <Text>{translations('create_first_comment')}</Text>
            </Box>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3, 3),
}))

const Text = styled(Typography)(({ theme }) => ({
    paddingLeft: theme.spacing(3),
    fontSize: 21,
    fontWeight: 700,
    color: grey_2,
    textAlign: 'left',
}))

const ReplyIcon = styled(Reply)(() => ({
    fill: grey_2,
    width: 40,
    height: 40,
}))
