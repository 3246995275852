import { ListItemText as MuiListItemText, styled } from '@mui/material'
import { grey_1 } from 'shared/lib/theme/Theme'
import React from 'react'

interface Properties {
    name: string
    secondaryLabel?: string
}

const ListItemText = ({ name, secondaryLabel, ...listItemTextSpecificProps }: Properties) => (
    <MuiListItemText
        primaryTypographyProps={{
            noWrap: true,
        }}
        secondaryTypographyProps={{
            noWrap: true,
        }}
        primary={name}
        secondary={secondaryLabel}
        {...listItemTextSpecificProps}
    />
)

export const UserListItemText = styled(ListItemText)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    '& .MuiListItemText-primary': {
        textOverflow: 'ellipsis',
    },
    '& .MuiListItemText-secondary': {
        textOverflow: 'ellipsis',
        color: grey_1,
        fontSize: 12,
    },
}))
