export class Pdf {
    constructor(
        readonly name: string,
        readonly key: string,
        readonly imageKey: string,
        readonly size: number
    ) {}

    static fromResponse(response: any): Pdf {
        return new Pdf(response.name, response.key, response.imageKey, response.size)
    }
}
