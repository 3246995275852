import { DateTime } from 'luxon'
import { Pdf } from 'shared/lib/models/Pdf'
import { MinimalGroupInfo } from '../groups/Group'
import { Cloneable } from '../utils/Misc'
import { RecurrenceRuleFactory } from 'shared/lib/recurring/RecurrenceRuleFactory'
import { range } from 'shared/lib/utils/ArrayUtil'

export class Event extends Cloneable<Event> {
    get isSameDay() {
        return this.start.hasSame(this.end, 'day')
    }

    get hasDurationOfMultipleDays() {
        return this.totalNumberOfDaysFromInitialStart > 0
    }

    get groupId() {
        return this.group.id
    }

    get rrule() {
        return RecurrenceRuleFactory.fromRRuleString(this.start, this.recurrenceRule)
    }

    get isRecurring() {
        return this.rrule !== undefined
    }

    get excludesStartDates(): DateTime[] {
        if (this.totalNumberOfDaysFromInitialStart === 0) {
            return [this.start]
        } else {
            const firstStart = this.start.minus({ days: this.numberOfDaysFromInitialStart })
            return range(0, this.totalNumberOfDaysFromInitialStart + 1).map((i) =>
                firstStart.plus({ days: i })
            )
        }
    }

    constructor(
        readonly id: string,
        readonly parentId: string | undefined,
        readonly title: string,
        readonly group: MinimalGroupInfo & { isHome: boolean },
        readonly initialStart: DateTime,
        readonly initialEnd: DateTime,
        readonly start: DateTime,
        readonly end: DateTime,
        readonly isAllDay: boolean,
        readonly recurrenceRule: string | undefined,
        readonly isFirstRecurrence: boolean | undefined,
        readonly description: string,
        readonly location: string,
        readonly pdfs: Pdf[],
        readonly canEdit: boolean,
        readonly canMakeRecurring: boolean,
        readonly numberOfDaysFromInitialStart: number,
        readonly totalNumberOfDaysFromInitialStart: number
    ) {
        super()
    }

    static fromJSON(response: Record<string, any>): Event {
        return new Event(
            response.id ?? response._id,
            response.parentId,
            response.title,
            response.group,
            DateTime.fromISO(response.initialStart),
            DateTime.fromISO(response.initialEnd),
            DateTime.fromISO(response.start),
            DateTime.fromISO(response.end),
            response.isAllDay,
            response.recurrenceRule,
            response.isFirstRecurrence,
            response.description || '',
            response.location || '',
            response.pdfs.map((pdf: any) => Pdf.fromResponse(pdf)),
            response.canEdit,
            response.canMakeRecurring,
            response.numberOfDaysFromInitialStart,
            response.totalNumberOfDaysFromInitialStart
        )
    }
}
