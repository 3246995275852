import { ReactComponent as ChevronLeft } from '../../assets/svg/chevron_left.svg'
import { styled } from '@mui/material'
import { ButtonProperties, DefaultButton } from './DefaultButton'
import { defaultButtonHeight, grey_3, textColor } from '../../theme/Theme'

export const BackButton = (properties: ButtonProperties) => {
    const StyledButton = styled(DefaultButton)({
        height: properties.title ? undefined : defaultButtonHeight,
        width: properties.title ? undefined : defaultButtonHeight,
        minWidth: properties.title ? undefined : defaultButtonHeight,
        color: textColor,
        backgroundColor: grey_3,
    })

    return properties.title ? (
        <StyledButton startIcon={<ArrowBack />} {...properties} />
    ) : (
        <StyledButton {...properties}>
            <ArrowBack />
        </StyledButton>
    )
}

const ArrowBack = styled(ChevronLeft)({
    fill: 'black',
})
