import { ListItemText as MuiListItemText, styled } from '@mui/material'
import { defaultTransition, grey_1 } from 'shared/lib/theme/Theme'
import React from 'react'

interface Properties {
    primaryLabelText: string | React.JSX.Element
    secondaryLabelText?: string
    groupHasAccessRequest?: boolean
}

const ListItemText = ({
    groupHasAccessRequest,
    primaryLabelText,
    secondaryLabelText,
    ...listItemTextSpecificProps
}: Properties) => (
    <MuiListItemText
        primaryTypographyProps={{
            noWrap: true,
        }}
        secondaryTypographyProps={{
            noWrap: true,
        }}
        primary={primaryLabelText}
        secondary={secondaryLabelText}
        {...listItemTextSpecificProps}
    />
)

export const GroupsSidebarListItemText = styled(ListItemText)(
    ({ theme, groupHasAccessRequest }) => ({
        marginLeft: theme.spacing(1.5),
        transition: defaultTransition,

        '& .MuiListItemText-primary': {
            textOverflow: 'ellipsis',
            fontSize: 14,
        },
        '& .MuiListItemText-secondary': {
            textOverflow: 'ellipsis',
            color: groupHasAccessRequest ? theme.palette.primary.main : grey_1,
            fontSize: 12,
        },
    })
)
