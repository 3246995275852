import { Box, styled, Typography } from '@mui/material'
import { useState } from 'react'
import { HeaderTitle } from './mfa/MfaComponents'
import { SendAgainTextView } from './SendAgainTextView'
import { PrimaryActionButtonsContainer } from './PrimaryActionButtonsContainer'
import { ErrorHandler } from './ErrorHandler'
import { PrimaryContainedButton } from './buttons/ContainedButtons'
import { useTranslation } from '../i18n'

interface Properties {
    emailAddress: string
    onSignInButtonClicked: () => void
    onClickSendAgain: () => Promise<void>
}

export const PasswordResetSendView = (properties: Properties) => {
    const translations = useTranslation()

    const [error, setError] = useState<Error | undefined>()

    const onClickSendAgain = () => {
        properties.onClickSendAgain().catch(setError)
    }

    return (
        <>
            <HeaderTitle>
                {translations?.('forgot_password_email_title') ?? 'Reset link send'}
            </HeaderTitle>

            <Typography variant="body2" mt={2}>
                {translations?.('forgot_password_email_info', [properties.emailAddress]) ??
                    'We have send you an reset password email'}
            </Typography>

            <Box display="flex" alignItems="center" mt={2}>
                <Typography variant="body2" mr={1}>
                    {translations?.('no_mail_received') ?? 'No mail received?'}
                </Typography>
                <SendAgainTextView onClicked={onClickSendAgain} />
            </Box>

            <PrimaryActionButtonsContainer>
                <GoToLoginButton
                    title={translations?.('signin') ?? 'Sign in'}
                    onClick={properties.onSignInButtonClicked}
                />
            </PrimaryActionButtonsContainer>

            <ErrorHandler error={error} translations={translations} />
        </>
    )
}

const GoToLoginButton = styled(PrimaryContainedButton)(({ theme }) => ({
    width: '50%',
}))
