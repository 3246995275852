import { forwardRef, Ref } from 'react'
import { VirtuosoHandle } from 'react-virtuoso'
import { backgroundColor } from 'shared/lib/theme/Theme'
import { StyledVirtuoso } from '../common/StyledVirtuoso'
import {
    CreateEventLoadingViewModel,
    CreateEventViewModel,
    EventItemLoadingViewModel,
    EventItemViewModel,
    EventSectionLoadingViewModel,
    EventSectionViewModel,
    EventViewModel,
    GroupHeaderViewModel,
    LoadEventsErrorViewModel,
    ZeroEventsViewModel,
} from './EventViewModel'
import { EVENT_LOADING_ROW_HEIGHT, EventItemLoadingRow } from './rows/EventItemLoadingRow'
import { EventSectionRow } from './rows/EventSectionRow'
import {
    CREATE_EVENT_ROW_HEIGHT,
    CreateEventRow,
    GROUP_TITLE_ROW_HEIGHT,
} from './rows/CreateEventRow'
import { EventItemRow } from './rows/EventItemRow'
import { ZeroEventsRow } from './rows/ZeroEventsRow'
import { EventSectionLoadingRow } from './rows/EventSectionLoadingRow'
import { CreateButtonLoadingRow } from './rows/CreateButtonLoadingRow'
import { GroupHeaderRow } from './rows/GroupHeaderRow'
import { EventListItem } from './components/EventListComponents'
import { Box } from '@mui/material'

interface Properties {
    height: number
    width: number
    viewModels: EventViewModel[]

    onLoadMore(): void
}

export const EVENT_LIST_ID = `event-list-${Math.random()}`

export const EventList = forwardRef((properties: Properties, ref: Ref<VirtuosoHandle>) => {
    const rowsThatFitScreen = Math.ceil(properties.height / EVENT_LOADING_ROW_HEIGHT)

    const viewModels =
        properties.viewModels.length > 0
            ? properties.viewModels
            : [
                  new CreateEventLoadingViewModel(),
                  new EventSectionLoadingViewModel(),
                  ...Array.from({ length: rowsThatFitScreen - 2 }).map(
                      () => new EventItemLoadingViewModel()
                  ),
              ]

    const onEndReached = () => {
        if (properties.viewModels.length > 0) {
            properties.onLoadMore()
        }
    }

    return (
        <StyledVirtuoso
            id={EVENT_LIST_ID}
            ref={ref}
            totalCount={viewModels.length}
            itemContent={(index) =>
                getRow({
                    height: properties.height,
                    width: properties.width,
                    index,
                    viewModels,
                })
            }
            overscan={1500}
            endReached={onEndReached}
            style={{
                height: properties.height,
                width: properties.width,
                backgroundColor: backgroundColor,
            }}
        />
    )
})

const getRow = (properties: {
    height: number
    width: number
    index: number
    viewModels: EventViewModel[]
}) => {
    const viewModels = properties.viewModels
    const viewModel = viewModels[properties.index]
    const isLastItem = properties.index === viewModels.length - 1

    if (viewModel instanceof EventSectionViewModel) {
        return <EventSectionRow viewModel={viewModel} />
    } else if (viewModel instanceof EventSectionLoadingViewModel) {
        return <EventSectionLoadingRow />
    } else if (viewModel instanceof EventItemViewModel) {
        return <EventItemRow isLastItem={isLastItem} viewModel={viewModel} />
    } else if (viewModel instanceof EventItemLoadingViewModel) {
        return <EventItemLoadingRow isLastItem={isLastItem} />
    } else if (viewModel instanceof CreateEventViewModel) {
        return <CreateEventRow viewModel={viewModel} />
    } else if (viewModel instanceof CreateEventLoadingViewModel) {
        return (
            <EventListItem isLastItem={false} hasMobilePaddingLeft={false}>
                <CreateButtonLoadingRow />
            </EventListItem>
        )
    } else if (viewModel instanceof ZeroEventsViewModel) {
        let height = properties.height

        const viewModelNames = viewModels.map((viewModel) => viewModel.constructor.name)

        if (viewModelNames.includes(GroupHeaderViewModel.name)) {
            height = height - GROUP_TITLE_ROW_HEIGHT
        }

        if (viewModelNames.includes(CreateEventViewModel.name)) {
            height = height - CREATE_EVENT_ROW_HEIGHT
        }

        return <ZeroEventsRow height={height} viewModel={viewModel} />
    } else if (viewModel instanceof GroupHeaderViewModel) {
        return <GroupHeaderRow group={viewModel.group} />
    } else if (viewModel instanceof LoadEventsErrorViewModel) {
        return <Box height={10} width={'100%'}></Box>
    }

    return <></>
}
