import { Address } from 'shared/lib/models/Address'
import { Image } from 'shared/lib/models/Image'
import { TranslationFn } from 'shared/lib/WithTranslations'
import { MinimalUserInfo } from 'shared/lib/users/MinimalUserInfo'

export class User implements MinimalUserInfo {
    constructor(
        public readonly id: string,
        public readonly firstName: string,
        public readonly lastName: string,
        public readonly image: Image | undefined,
        public readonly birthday: Date | undefined,
        public readonly hasProfile: boolean,
        public readonly functions: string[],
        public readonly about: string,
        public readonly emailAddress: string | undefined,
        public readonly isEmailAddressHidden: boolean,
        public readonly mobilePhoneNumber: string,
        public readonly phoneNumber: string,
        public readonly address: Address | undefined,
        public readonly isApproved?: boolean
    ) {}

    postalCodeWithCity(): string | undefined {
        if (!this.address) {
            return undefined
        }

        return `${this.address.zipCode} ${this.address.city}`
    }

    age(translations: TranslationFn): string | undefined {
        if (!this.birthday) {
            return undefined
        }

        const age = new Date().getFullYear() - this.birthday.getFullYear()
        return `${age} ${translations('year', [age], age)}`
    }

    static fromApiResponse(response: Record<string, any>): User {
        return new User(
            response.id,
            response.firstName,
            response.lastName,
            response.image,
            response.birthday ? new Date(response.birthday) : undefined,
            response.hasProfile,
            response.functions,
            response.about,
            response.emailAddress,
            response.isEmailAddressHidden,
            response.mobilePhoneNumber,
            response.phoneNumber,
            response.address,
            response.isApproved
        )
    }
}
