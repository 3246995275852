import { Box, styled, Typography } from '@mui/material'
import { useState } from 'react'
import { ReactComponent as File } from 'shared/lib/assets/svg/file_outlined.svg'
import { ReactComponent as Media } from 'shared/lib/assets/svg/media_outlined.svg'
import { AddAttachmentButton } from 'shared/lib/components/buttons/AddAttachmentButton'
import { LocalFile } from 'shared/lib/forms/LocalFile'
import { MediaItemType } from 'shared/lib/models/MediaItem'
import { defaultHoverState, grey_3 } from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'
import { CreatePostPopup } from '../posts/createpostpopup/CreatePostPopup'
import { PostListItem } from './PostListComponents'
import { CreatePostViewModel } from './PostViewModel'
import { Post } from '../posts/Post'
import { useNavigate } from 'react-router-dom'
import { Paths } from '../routing/paths'

interface CreatePostRowProperties {
    viewModel: CreatePostViewModel
}

export const CreatePostRow = (properties: CreatePostRowProperties) => {
    const translations = useTranslation()
    const navigate = useNavigate()

    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(false)
    const [localFiles, setLocalFiles] = useState<LocalFile[]>([])

    const selectedGroupId = properties.viewModel.groupId

    const onFilesSelected = (localFiles: LocalFile[]) => {
        setLocalFiles(localFiles)
        setIsCreatePopupVisible(true)
    }

    const closePopup = () => {
        setIsCreatePopupVisible(false)
        setLocalFiles([])
    }

    const onSubmitButtonClicked = (post: Post) => {
        closePopup()

        if (selectedGroupId === post.groupId) {
            properties.viewModel.onPostCreated()
            return
        }

        navigate(Paths.GROUP_TIME_LINE.replace(':groupId', post.groupId), { replace: true })
    }

    return (
        <>
            <PostListItem isLastItem={false}>
                <Card>
                    <WritePostButton onClick={() => setIsCreatePopupVisible(true)}>
                        <WritePostTextView variant="body1">
                            {translations('write_post')}
                        </WritePostTextView>
                    </WritePostButton>

                    <Box display="flex" height="100%">
                        <VerticalDivider />

                        <AddAttachmentButton
                            canSelectMultipleFiles={false}
                            acceptedFiles={[MediaItemType.PDF]}
                            translations={translations}
                            onFilesSelected={onFilesSelected}
                            button={
                                <Button>
                                    <FileIcon />
                                </Button>
                            }
                        />

                        <VerticalDivider />

                        <AddAttachmentButton
                            acceptedFiles={[MediaItemType.IMAGE, MediaItemType.VIDEO]}
                            translations={translations}
                            onFilesSelected={onFilesSelected}
                            button={
                                <Button
                                    sx={{
                                        borderTopRightRadius: '16px',
                                        borderBottomRightRadius: '16px',
                                    }}
                                >
                                    <MediaIcon />
                                </Button>
                            }
                        />
                    </Box>
                </Card>
            </PostListItem>

            {isCreatePopupVisible && (
                <CreatePostPopup
                    selectedGroupId={selectedGroupId}
                    localFiles={localFiles}
                    onCloseButtonClicked={closePopup}
                    onSubmitButtonClicked={onSubmitButtonClicked}
                />
            )}
        </>
    )
}

const CARD_HEIGHT = '65px'

const Card = styled(Box)(() => ({
    height: CARD_HEIGHT,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '16px',
    backgroundColor: 'white',
    cursor: 'pointer',

    '&:hover': defaultHoverState,
}))

const WritePostButton = styled(Box)(({ theme }) => ({
    height: CARD_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    overflow: 'hidden',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
}))

const WritePostTextView = styled(Typography)(() => ({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 700,
}))

const Button = styled(Box)(() => ({
    height: CARD_HEIGHT,
    width: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '&:hover': {
        backgroundColor: grey_3,
    },
}))

const VerticalDivider = styled(Box)(() => ({
    height: '52px',
    width: '2px',
    marginTop: '6px',
    backgroundColor: grey_3,
}))

const MediaIcon = styled(Media)(({ theme }) => ({
    fill: theme.palette.text.primary,
}))

const FileIcon = styled(File)(({ theme }) => ({
    fill: theme.palette.text.primary,
}))
