import { Box, styled, Typography } from '@mui/material'
import { useState } from 'react'
import { ReactComponent as File } from 'shared/lib/assets/svg/file_outlined.svg'
import { AddAttachmentButton } from 'shared/lib/components/buttons/AddAttachmentButton'
import { LocalFile } from 'shared/lib/forms/LocalFile'
import { MediaItemType } from 'shared/lib/models/MediaItem'
import { defaultHoverState, grey_3 } from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'
import { CreateEventPopup } from '../createOrEdit/CreateEventPopup'
import { CreateEventViewModel } from '../EventViewModel'
import { EventListItem } from '../components/EventListComponents'
import { Event } from '../Event'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { useNavigate } from 'react-router-dom'
import { Paths } from '../../routing/paths'

const CARD_HEIGHT = 65

export const CREATE_EVENT_ROW_HEIGHT = CARD_HEIGHT + 32
export const GROUP_TITLE_ROW_HEIGHT = 45

interface Properties {
    viewModel: CreateEventViewModel
}

export const CreateEventRow = ({ viewModel }: Properties) => {
    const translations = useTranslation()
    const navigate = useNavigate()

    const [isCreatePostPopupVisible, setIsCreatePostPopupVisible] = useState(false)
    const [selectedPdfs, setSelectedPdfs] = useState<LocalPdf[]>([])

    const onFilesSelected = (localFiles: LocalFile[]) => {
        setIsCreatePostPopupVisible(true)
        setSelectedPdfs(localFiles as LocalPdf[])
    }

    const closePopup = () => {
        setSelectedPdfs([])
        setIsCreatePostPopupVisible(false)
    }

    const onEventCreated = (event: Event) => {
        closePopup()

        if (viewModel.groupId !== event.groupId) {
            navigate(Paths.GROUP_EVENTS.replace(':groupId', event.groupId), { replace: true })
        }
    }

    return (
        <>
            <EventListItem isLastItem={false} hasMobilePaddingLeft={false}>
                <Card>
                    <WriteEventButton onClick={() => setIsCreatePostPopupVisible(true)}>
                        <WriteEventTextView variant="body1">
                            {translations('agenda_add_item')}
                        </WriteEventTextView>
                    </WriteEventButton>

                    <Box display="flex" height="100%">
                        <VerticalDivider />

                        <AddAttachmentButton
                            acceptedFiles={[MediaItemType.PDF]}
                            translations={translations}
                            onFilesSelected={onFilesSelected}
                            button={
                                <PdfButton>
                                    <FileIcon />
                                </PdfButton>
                            }
                        />
                    </Box>
                </Card>
            </EventListItem>

            {isCreatePostPopupVisible && (
                <CreateEventPopup
                    groupId={viewModel.groupId}
                    selectedPdfs={selectedPdfs}
                    onCloseButtonClicked={closePopup}
                    onEventCreated={onEventCreated}
                />
            )}
        </>
    )
}

const Card = styled(Box)(() => ({
    height: CARD_HEIGHT,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '16px',
    backgroundColor: 'white',
    cursor: 'pointer',

    '&:hover': defaultHoverState,
}))

const WriteEventButton = styled(Box)(({ theme }) => ({
    height: CARD_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    overflow: 'hidden',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
}))

const WriteEventTextView = styled(Typography)(() => ({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 700,
}))

const PdfButton = styled(Box)(() => ({
    height: CARD_HEIGHT,
    width: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderTopRightRadius: '16px',
    borderBottomRightRadius: '16px',

    '&:hover': {
        backgroundColor: grey_3,
    },
}))

const VerticalDivider = styled(Box)(() => ({
    height: '52px',
    width: '2px',
    marginTop: '6px',
    backgroundColor: grey_3,
}))

const FileIcon = styled(File)(({ theme }) => ({
    fill: theme.palette.text.primary,
}))
