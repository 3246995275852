import { Box, BoxProps, ButtonBase as MuiButtonBase, styled, Typography } from '@mui/material'
import { memo } from 'react'
import { Avatar } from 'shared/lib/components/Avatar'
import { darkGrey, defaultHoverState } from 'shared/lib/theme/Theme'
import { fileRepository } from '../../../index'
import { User } from '../../User'
import { getInitials } from '../../Utils'
import { getFullName } from 'shared/lib/utils/StringUtils'
import { Card } from 'shared/lib/components/Card'

interface Properties {
    user: User
    isHighlighted: boolean
    onClick(userId: string): void
}

const Component = ({ user, ...properties }: Properties) => {
    const onClick = () => (user ? properties.onClick(user.id) : undefined)

    return (
        <ButtonBase onClick={onClick} disableRipple={true}>
            <StyledCard p={3} isHighlighted={properties.isHighlighted}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Avatar
                        src={fileRepository.getThumbnailUrl(user.image)}
                        width={130}
                        height={130}
                    >
                        {getInitials(user)}
                    </Avatar>
                    <Name>{getFullName(user)}</Name>
                    {Boolean(user.functions.length) && (
                        <Functions>{user.functions.join(', ')}</Functions>
                    )}
                </Box>
            </StyledCard>
        </ButtonBase>
    )
}

export const UserListCard = memo(Component, (previous, next) => {
    return previous.user === next.user
})

const ButtonBase = styled(MuiButtonBase)(() => ({
    height: '100%',
    width: '100%',
    borderRadius: 16,

    '&:hover': defaultHoverState,
}))

const StyledCard = styled(
    ({ isHighlighted, ...properties }: BoxProps & { isHighlighted: boolean }) => (
        <Card {...properties} />
    )
)(({ isHighlighted }) => ({
    height: '100%',
    width: '100%',
    borderRadius: '16px',
    boxShadow: isHighlighted ? '0px 2px 16px 0px rgba(0, 0, 0, 0.2)' : 'none',
}))

const Name = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    fontSize: 18,
    color: darkGrey,
    fontWeight: 700,
    textAlign: 'center',

    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
}))

const Functions = styled(Typography)(() => ({
    fontSize: 12,
    color: darkGrey,
    fontWeight: 400,
    textAlign: 'center',

    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
}))
