import { Comment } from './Comment'
import { CommentRowViewModel } from './CommentRow'

export interface CommentListViewModel {}

export class CommentListLoadingViewModel implements CommentListViewModel {}

export class CommentListHeaderViewModel implements CommentListViewModel {
    constructor(public readonly numberOfComments: number) {}
}

export class CommentListItemViewModel implements CommentListViewModel, CommentRowViewModel {
    constructor(public readonly comment: Comment, public readonly isHighlighted?: boolean) {}
}

export class NoCommentsListViewModel implements CommentListViewModel {}
