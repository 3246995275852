import {
    BottomNavigation as MuiBottomNavigation,
    BottomNavigationAction,
    Box,
    Paper,
    styled,
} from '@mui/material'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ChurchContext from '../../churches/ChurchContextProvider'
import { CalendarIcon, MyChurchGroupIcon, UserListIcon } from '../../groups/TabBarIcons'
import { Paths } from '../../routing/paths'
import { useSelectedRootPathName } from '../../routing/hooks'
import { useTranslation } from 'shared/lib/i18n'

export const MobileBottomNavigationBar = () => {
    const translations = useTranslation()
    const navigate = useNavigate()

    const { church } = useContext(ChurchContext)!

    const selectedRootPathName = useSelectedRootPathName() || ''

    const isStandalone =
        (window.navigator as any).standalone === true ||
        window.matchMedia('(display-mode: standalone)').matches

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1000,
                paddingBottom: isStandalone ? 2 : 0,
            }}
            elevation={3}
        >
            <BottomNavigation
                showLabels
                value={selectedRootPathName}
                onChange={(_, value) => navigate(value)}
            >
                <BottomNavigationAction
                    label={church?.getMyChurchGroupName(translations)}
                    icon={
                        <IconContainer>
                            <MyChurchGroupIcon
                                isSelected={selectedRootPathName === Paths.TIME_LINE}
                            />
                        </IconContainer>
                    }
                    value={Paths.TIME_LINE}
                />
                <BottomNavigationAction
                    label={translations('page_calendar')}
                    icon={
                        <IconContainer>
                            <CalendarIcon isSelected={selectedRootPathName === Paths.EVENTS} />
                        </IconContainer>
                    }
                    value={Paths.EVENTS}
                />
                <BottomNavigationAction
                    label={
                        church
                            ? translations(church.getUserListPageNameTranslationKey())
                            : undefined
                    }
                    icon={
                        <IconContainer>
                            <UserListIcon isSelected={selectedRootPathName === Paths.MEMBERS} />
                        </IconContainer>
                    }
                    value={Paths.MEMBERS}
                />
            </BottomNavigation>
        </Paper>
    )
}

const BottomNavigation = styled(MuiBottomNavigation)(({ theme }) => ({
    '& .Mui-selected': {
        fill: theme.palette.primary.main,
    },
}))

const IconContainer = styled(Box)(() => ({
    '& svg': {
        width: 24,
        height: 24,
    },
}))
