import { filterByQuery } from 'shared/lib//utils/SearchFieldUtils'
import { Church } from './Church'
import { getAllCombinations } from 'shared/lib/utils/Utils'

export const filterChurches = (churches: Church[], query: string): Church[] => {
    if (!query) return churches

    return filterByQuery<Church>(churches, `'"${query}"`, {
        keys: ['name', 'developerName'],
        useExtendedSearch: true,
        getFn: (church, path) => {
            switch (path[0]) {
                case 'name':
                    return getAllCombinations(church.name || '')
                case 'developerName':
                    return getAllCombinations(church.developerName || '')
                default:
                    return church[path as string]
            }
        },
    })
}
