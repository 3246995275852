import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { eventRepository } from '../..'
import { GroupEventKey, GroupUpdatedEvent } from '../../groups/events'
import { Event as DonkeyEvent } from '../Event'

export const useEvent = (eventId: string) => {
    const location = useLocation()

    const parsedEvent = useMemo(() => {
        const parsedEventFromState = location.state?.event
            ? JSON.parse(location.state.event)
            : undefined

        return parsedEventFromState ? DonkeyEvent.fromJSON(parsedEventFromState) : undefined
    }, [location.state?.event])

    const abortControllerRef = useRef<AbortController>()

    const [isLoading, setIsLoading] = useState(!parsedEvent)
    const [error, setError] = useState<Error | undefined>()
    const [event, setEvent] = useState<DonkeyEvent | undefined>(parsedEvent)

    const start = determineStart()
    function determineStart() {
        const eventForStart = parsedEvent || event

        if (!eventForStart) return undefined

        if (eventForStart.hasDurationOfMultipleDays && !eventForStart.isRecurring) {
            return eventForStart.initialStart
        }

        const initialStart = eventForStart.initialStart
        if (eventForStart.hasDurationOfMultipleDays && eventForStart.isRecurring) {
            return eventForStart.end
                .minus({ days: eventForStart.totalNumberOfDaysFromInitialStart })
                .set({
                    hour: initialStart.hour,
                    minute: initialStart.minute,
                    second: initialStart.second,
                })
        }

        return eventForStart.start
    }

    const getEvent = useCallback(() => {
        setIsLoading(true)

        eventRepository
            .getEvent(
                eventId,
                start?.toISO({ includeOffset: false }) || undefined,
                abortControllerRef.current?.signal
            )
            .then(setEvent)
            .catch(setError)
            .finally(() => setIsLoading(false))
    }, [eventId, parsedEvent]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        abortControllerRef.current?.abort()
        abortControllerRef.current = new AbortController()

        if (parsedEvent) {
            setEvent(parsedEvent)
        }

        getEvent()

        return () => {
            abortControllerRef.current?.abort()
        }
    }, [eventId, parsedEvent, getEvent])

    useEffect(() => {
        const onGroupUpdated = (ev: Event) => {
            const { id, name } = (ev as GroupUpdatedEvent).detail

            if (event && event?.groupId === id) {
                setEvent(
                    event.cloneWith({
                        group: {
                            ...event.group,
                            name,
                        },
                    })
                )
            }
        }

        document.addEventListener(GroupEventKey.GROUP_UPDATED, onGroupUpdated)

        return () => {
            document.removeEventListener(GroupEventKey.GROUP_UPDATED, onGroupUpdated)
        }
    }, [event])

    return { isLoading, error, event, start, getEvent, setEvent }
}
