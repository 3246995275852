import { Box, styled } from '@mui/material'

export const ContentSectionContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '100%',
    overflow: 'hidden',

    '& > svg': {
        minWidth: '20px',
        width: '20px',
        minHeight: '20px',
        height: '20px',
        marginRight: theme.spacing(1),
    },
}))
