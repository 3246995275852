import { Collapse, CollapseProps, List as MuiList, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { capitalizeFirstLetter } from 'shared/lib/utils/StringUtils'
import { useDetailView } from '../../common/detailView/hooks'
import { SeeAllButton } from '../../common/lists/SeeAllButton'
import { UnapprovedUserListItem } from '../../common/lists/UnapprovedUserListItem'
import { useTranslation } from 'shared/lib/i18n'
import { sortUsersByLastName } from '../../user/Utils'
import { GroupMember, GroupMemberInfo } from './GroupMember'
import { useApproveGroupMember, useDenyGroupMember } from './hooks'

interface Properties {
    groupId: string
    groupMembers: GroupMember[]
    bottomDivider?: React.JSX.Element
    showTitle: boolean
    maxNumberOfMembersToShow?: number
}

export const UnapprovedGroupMembersList = ({ groupId, ...properties }: Properties) => {
    const translations = useTranslation()

    const [unapprovedGroupMembers, setUnapprovedGroupMembers] = useState<GroupMemberInfo[]>([])
    const [groupMemberToDeny, setGroupMemberToDeny] = useState<GroupMemberInfo | undefined>()
    const [error, setError] = useState<string | undefined>()

    const { openGroupAccessRequests } = useDetailView()
    const { approveGroupMember } = useApproveGroupMember()
    const { denyGroupMember } = useDenyGroupMember()

    const sortedGroupMembers = sortUsersByLastName(unapprovedGroupMembers)
    const visibleUnApprovedGroupMemberIds = sortedGroupMembers.map((groupMember) => groupMember.id)

    const onApproveGroupMember = async (userId: string) => {
        const groupMemberToApprove = unapprovedGroupMembers.find(
            (groupMember) => groupMember.id === userId
        )

        if (!groupMemberToApprove) {
            return
        }

        const originalUnapprovedGroupMembers = unapprovedGroupMembers
        setUnapprovedGroupMembers(
            originalUnapprovedGroupMembers.filter(
                (groupMember) => groupMember.id !== groupMemberToApprove.id
            )
        )

        try {
            await approveGroupMember({ groupId, userId })
        } catch (error: any) {
            setError(error)
            setUnapprovedGroupMembers(originalUnapprovedGroupMembers)
        }
    }

    const onDenyGroupMemberClicked = (userId: string) => {
        setGroupMemberToDeny(
            unapprovedGroupMembers.find((groupMember) => groupMember.id === userId)
        )
    }

    const onDenyGroupMember = async () => {
        if (!groupMemberToDeny) {
            return
        }

        const originalUnapprovedGroupMembers = unapprovedGroupMembers

        setUnapprovedGroupMembers(
            originalUnapprovedGroupMembers.filter(
                (groupMember) => groupMember.id !== groupMemberToDeny.id
            )
        )

        try {
            await denyGroupMember({ groupId, userId: groupMemberToDeny.id })
        } catch (error: any) {
            setError(error)
            setUnapprovedGroupMembers(originalUnapprovedGroupMembers)
        } finally {
            setGroupMemberToDeny(undefined)
        }
    }

    const openGroupAccessRequestsView = () => {
        openGroupAccessRequests({ groupId })
    }

    useEffect(() => {
        setUnapprovedGroupMembers(
            properties.groupMembers.filter((groupMember) => !groupMember.isApproved)
        )
    }, [properties.groupMembers])

    return (
        <Container isVisible={unapprovedGroupMembers.length > 0}>
            {properties.showTitle && (
                <Title>
                    {capitalizeFirstLetter(
                        translations(
                            'plural_access_requests',
                            [sortedGroupMembers.length],
                            sortedGroupMembers.length
                        )
                    )}
                </Title>
            )}
            <List>
                {sortedGroupMembers
                    .slice(0, properties.maxNumberOfMembersToShow ?? unapprovedGroupMembers.length)
                    .map((groupMember) => {
                        const shouldShow =
                            !visibleUnApprovedGroupMemberIds.length ||
                            visibleUnApprovedGroupMemberIds.includes(groupMember.id)

                        return (
                            <Collapse in={shouldShow} key={groupMember.id}>
                                <UnapprovedUserListItem
                                    userToApprove={groupMember}
                                    propertyToUseForSecondaryLabel="functions"
                                    onApprove={onApproveGroupMember}
                                    onDeny={onDenyGroupMemberClicked}
                                />
                            </Collapse>
                        )
                    })}
                {properties.maxNumberOfMembersToShow &&
                    unapprovedGroupMembers.length > properties.maxNumberOfMembersToShow && (
                        <SeeAllButton
                            translationKey="show_all_access_requests"
                            totalMembersCount={unapprovedGroupMembers.length}
                            onClick={openGroupAccessRequestsView}
                        />
                    )}
            </List>

            <AlertDialog
                isVisible={!!groupMemberToDeny}
                title={translations('are_you_sure')}
                message={translations('user_requests_access_deny_confirmation', [
                    groupMemberToDeny?.firstName || '',
                    groupMemberToDeny?.lastName || '',
                ])}
                continueButtonType={ButtonType.RED}
                continueButtonTitle={translations('deny')}
                cancelButtonTitle={translations('cancel')}
                onContinueButtonClicked={onDenyGroupMember}
                onCancelButtonClicked={() => setGroupMemberToDeny(undefined)}
            />
            <ErrorHandler error={error} translations={translations} horizontal="right" />

            {properties.bottomDivider}
        </Container>
    )
}

const Container = styled(
    ({
        isVisible,
        ...props
    }: Omit<CollapseProps, 'in'> & {
        isVisible: boolean
    }) => <Collapse in={isVisible} easing={{ enter: 'none', exit: 'ease-out' }} {...props} />
)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',
    textAlign: 'start',
}))

const Title = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(0, 3),
    fontSize: 14,
    fontWeight: 700,
}))

const List = styled(MuiList)(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(2, 2, 0, 2),
}))
