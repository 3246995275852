import { Tab as MuiTab, Tabs as MuiTabList, styled } from '@mui/material'
import { ReactComponent as AddIcon } from 'shared/lib/assets/svg/plus_filled.svg'
import { grey_2, grey_3, mobileGroupTabsHeight } from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'
import { GroupBadge } from './GroupBadge'
import { MobileGroupItemLoadingState } from './MobileGroupItemLoadingState'
import {
    GroupListCreateGroupViewModel,
    GroupListItemLoadingViewModel,
    GroupListItemViewModel,
    GroupListViewModel,
} from './groupList/GroupListViewModels'

interface Properties {
    isBadgeVisible: boolean
    viewModels: GroupListViewModel[]
    selectedGroupId?: string
}

export const MobileGroupTabList = (properties: Properties) => {
    const { selectedGroupId } = properties

    const viewModels =
        properties.viewModels.length > 0
            ? properties.viewModels
            : [...Array.from({ length: 8 }).map(() => new GroupListItemLoadingViewModel())]

    const translations = useTranslation()

    const renderTab = (viewModel: GroupListViewModel, index: number) => {
        if (viewModel instanceof GroupListItemViewModel) {
            const group = viewModel.group

            return (
                <Tab
                    key={viewModel.group.id}
                    icon={
                        <GroupBadge
                            number={
                                properties.isBadgeVisible ? group.numberOfUnreadPosts : undefined
                            }
                            verticalPosition="top"
                            image={group.image}
                        />
                    }
                    label={<p>{group.name}</p>}
                    value={viewModel.group.id}
                    wrapped={true}
                    disableRipple={true}
                    onClick={() => viewModel.onClick()}
                />
            )
        } else if (viewModel instanceof GroupListCreateGroupViewModel) {
            return (
                <AddGroupButton
                    key={'create-group'}
                    icon={<AddIcon />}
                    label={<p>{translations('create_group')}</p>}
                    wrapped={true}
                    disableRipple={true}
                    onClick={() => viewModel.onClick()}
                />
            )
        } else if (viewModel instanceof GroupListItemLoadingViewModel) {
            return <MobileGroupItemLoadingState key={`loading-state-${index}`} />
        }

        return <></>
    }

    return (
        <TabList variant="scrollable" scrollButtons={false} value={selectedGroupId || false}>
            {viewModels.map(renderTab)}
        </TabList>
    )
}

const TabList = styled(MuiTabList)(() => ({
    height: mobileGroupTabsHeight,
    display: 'flex',
    flexDirection: 'row',

    '& .MuiTabs-flexContainer': {
        gap: '6px',
    },
}))

const Tab = styled(MuiTab)(() => ({
    textTransform: 'none',
    padding: 0,
    minWidth: 80,
    width: 80,

    '& .MuiTab-iconWrapper': {
        marginBottom: '4px',
    },

    '& p': {
        maxWidth: '100%',
        fontSize: 11,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        margin: 0,
    },
}))

const AddGroupButton = styled(Tab)(({ theme }) => ({
    '& svg': {
        backgroundColor: grey_3,
        padding: theme.spacing(1),
        width: 40,
        height: 40,
        borderRadius: 24,

        '&:hover': {
            backgroundColor: grey_2,
        },
    },
}))
