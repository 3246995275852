import { Link, Breadcrumbs as MuiBreadcrumbs, Typography, styled } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { ReactComponent as RightChevron } from 'shared/lib/assets/svg/chevron_right.svg'
import { Paths } from '../../routing/paths'

export interface DonkeyBreadcrumbItem {
    title: string
    path: Paths
}

interface BreadcrumbItemsProps {
    breadcrumbsItems: DonkeyBreadcrumbItem[]
}

export const DonkeyBreadcrumbs = ({ breadcrumbsItems }: BreadcrumbItemsProps) => {
    return (
        <Breadcrumbs aria-label="breadcrumb" separator={<RightChevronIcon />}>
            {breadcrumbsItems.map((item, index) =>
                index !== breadcrumbsItems.length - 1 ? (
                    <Link key={index} component={RouterLink} to={item.path}>
                        {item.title}
                    </Link>
                ) : (
                    <Typography key={index}>{item.title}</Typography>
                )
            )}
        </Breadcrumbs>
    )
}

const Breadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}))

const RightChevronIcon = styled(RightChevron)(({ theme }) => ({
    fill: theme.palette.text.primary,
}))
