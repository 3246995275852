import { DateTime } from 'luxon'
import { WebAppHttpRepository } from '../common/WebAppHttpRepository'
import { UpdateUserParams, UpdateUserParamsToFormData } from '../user/models/UpdateUserParams'
import { ConfirmChangePasswordParams } from './models/ConfirmChangePasswordParams'
import { CheckEmailAddressResponse } from './models/CheckEmailAddressResponse'
import { ConfirmSignInParams } from './models/ConfirmSignInParams'
import { CreateAccountParams } from './models/CreateAccountParams'
import { ExtendSessionResponse } from './models/ExtendSessionResponse'
import { SignInParams } from './models/SignInParams'
import { SignInResponse } from './models/SignInResponse'
import { SignedInUser } from './models/SignedInUser'
import { DEFAULT_MAX_SESSION_AGE } from 'shared/lib/common/Constants'
import { ErrorCode } from 'shared/lib/utils/ErrorUtils'

export const ACCESS_TOKEN_KEY = 'accessToken'
const ACCESS_TOKEN_EXPIRATION_DATE_KEY = 'accessTokenExpirationDate'
const SIGNED_IN_USER_KEY = 'signedInUser'
const ACCESS_TOKEN_MAX_AGE_DATE_KEY = 'accessTokenMaxAgeDate'

export type OnSignedOutObserver = (applicationId: string, isIntentionalSignOut: boolean) => void

export class SessionRepository extends WebAppHttpRepository {
    private observers: OnSignedOutObserver[] = []

    private isRefreshingAccessToken = false
    private accessTokenRefreshPromise: Promise<void> | undefined

    private _signedInUser: SignedInUser | undefined

    get signedInUser(): SignedInUser | undefined {
        return this._signedInUser
    }

    get canSeeGroups(): boolean {
        return Boolean(this._signedInUser?.canSeeGroups)
    }

    get canSeeUsers(): boolean {
        return Boolean(this._signedInUser?.canSeeChurchMembers)
    }

    get canApproveOrDenyChurchMembers(): boolean {
        return !!this._signedInUser?.canApproveOrDenyChurchMembers
    }

    get isSignedIn(): boolean {
        return Boolean(this.bearerToken) && this._signedInUser !== undefined
    }

    get isExpired(): boolean {
        const sessionExpirationDate = this.sessionExpirationDate

        if (!sessionExpirationDate) {
            return true
        }

        const expirationDate = DateTime.fromJSDate(sessionExpirationDate)
        return expirationDate.diff(DateTime.now()).as('minutes') <= 0
    }

    get isUsingSessionStorage() {
        return sessionStorage.getItem(ACCESS_TOKEN_KEY) !== null
    }

    get sessionExpirationDate(): Date | undefined {
        const expirationDate = localStorage.getItem(ACCESS_TOKEN_EXPIRATION_DATE_KEY)
        return expirationDate ? new Date(expirationDate) : undefined
    }

    constructor() {
        super()

        this._signedInUser = this.getSignedInUser()
    }

    addObserver(observer: OnSignedOutObserver) {
        this.observers.push(observer)
    }

    removeObserver(observer: OnSignedOutObserver) {
        this.observers = this.observers.filter((currentObserver) => currentObserver !== observer)
    }

    async createAccount(params: CreateAccountParams): Promise<void> {
        await this.postWithoutAuthorization(`${this.apiUrl}/api/v1/session/signup`, {
            emailAddress: params.emailAddress,
            password: params.password,
            applicationId: this.applicationId,
            locale: params.locale,
        })
    }

    async resendSignUpConfirmation(emailAddress: string): Promise<void> {
        await this.postWithoutAuthorization(
            `${this.apiUrl}/api/v1/session/signup/resendconfirmationcode`,
            {
                emailAddress,
                applicationId: this.applicationId,
            }
        )
    }

    async checkEmailAddress(
        emailAddress: string,
        abortController?: AbortController
    ): Promise<CheckEmailAddressResponse | undefined> {
        try {
            return await this.postWithoutAuthorization(
                `${this.apiUrl}/api/v1/session/checkemailaddress`,
                {
                    emailAddress,
                    applicationId: this.applicationId,
                },
                abortController?.signal
            )
        } catch (error) {
            if (!this.isCanceled(error)) {
                throw error
            }

            return undefined
        }
    }

    async signIn(params: SignInParams): Promise<SignInResponse> {
        const response = await this.postWithoutAuthorization<SignInResponse>(
            `${this.apiUrl}/api/v1/session/signin`,
            {
                applicationId: this.applicationId,
                emailAddress: params.emailAddress,
                password: params.password,
            }
        )

        if (response.accessToken) {
            this.storeSessionInfo(response, params.useSessionStorage)
            this.storeSignedInUser(SignedInUser.fromJSON(response.user!))
        }

        return response
    }

    async confirmSign(params: ConfirmSignInParams): Promise<SignInResponse> {
        const response = await this.post<SignInResponse>(
            `${this.apiUrl}/api/v1/session/signin/confirm`,
            {
                emailAddress: params.emailAddress,
                applicationId: this.applicationId,
                session: params.mfaSession,
                confirmationCode: params.confirmationCode,
            }
        )

        if (response.accessToken) {
            this.storeSessionInfo(response, params.useSessionStorage)
            this.storeSignedInUser(SignedInUser.fromJSON(response.user!))
        }

        return response
    }

    async passwordForgotten(emailAddress: string): Promise<void> {
        await this.postWithoutAuthorization(`${this.apiUrl}/api/v1/session/forgotpassword`, {
            emailAddress,
            applicationId: this.applicationId,
        })
    }

    async confirmChangePassword(params: ConfirmChangePasswordParams): Promise<void> {
        await this.postWithoutAuthorization(
            `${this.apiUrl}/api/v1/session/forgotpassword/confirm`,
            {
                emailAddress: params.emailAddress,
                password: params.password,
                confirmationCode: params.confirmationCode,
                applicationId: this.applicationId,
            }
        )
    }

    async changePassword(currentPassword: string, newPassword: string): Promise<void> {
        await this.withAccessToken(() =>
            this.post(`${this.apiUrl}/api/v1/session/changepassword`, {
                currentPassword,
                newPassword,
            })
        )
    }

    async refreshSignedInUser(): Promise<void> {
        const signedInUserId = this._signedInUser?.id

        if (!signedInUserId) {
            return
        }

        const response = await this.withAccessToken(() =>
            this.get<any>(`${this.apiUrl}/api/v1/users/${signedInUserId}`)
        )
        this.storeSignedInUser(SignedInUser.fromJSON(response))
    }

    async updateSignedInUser(params: UpdateUserParams): Promise<void> {
        const signedInUserId = this._signedInUser?.id

        if (!signedInUserId) {
            return
        }

        const response = await this.withAccessToken(() =>
            this.patch<any>(
                `${this.apiUrl}/api/v2/users/${signedInUserId}`,
                UpdateUserParamsToFormData(params)
            )
        )
        this.storeSignedInUser(SignedInUser.fromJSON(response))
    }

    async signOut() {
        this.post(`${this.apiUrl}/api/v1/session/signout`, undefined).catch(() => {})
        this.onSignedOut(true)
    }

    async mfaSettings(params: { isEnabled: boolean; phoneNumber: string }): Promise<any> {
        const signedInUserId = this._signedInUser?.id

        if (!signedInUserId) return

        const response = await this.withAccessToken(() =>
            this.patch<any>(`${this.apiUrl}/api/v1/users/${signedInUserId}/mfasettings`, params)
        )
        this.storeSignedInUser(SignedInUser.fromJSON(response))
    }

    async disableSoftwareToken() {
        await this.withAccessToken(() =>
            this.post(`${this.apiUrl}/api/v1/users/mfasettings/softwaretoken/disable`)
        )
        await this.refreshSignedInUser()
    }

    async enableSoftwareToken(): Promise<{
        secretCode: string
        secretCodeUrl: string
    }> {
        return await this.withAccessToken(() =>
            this.post(`${this.apiUrl}/api/v1/users/mfasettings/softwaretoken/enable`)
        )
    }

    async confirmSoftwareToken(confirmationCode: string) {
        await this.withAccessToken(() =>
            this.post(`${this.apiUrl}/api/v1/users/mfasettings/softwaretoken/confirm`, {
                confirmationCode,
            })
        )
        await this.refreshSignedInUser()
    }

    async deleteAccount() {
        await this.withAccessToken(() =>
            this.delete(`${this.apiUrl}/api/v1/session/account`).then(() => {
                this.signOut()
            })
        )
    }

    async withAccessToken<T>(apiCall: () => Promise<T>): Promise<T> {
        if (!this.isExpired) {
            try {
                return await apiCall()
            } catch (error: any) {
                const status = error.response?.status
                const errorCode = error.response?.data?.code || -1
                const isRevoked = [
                    ErrorCode.REVOKED_ACCESS_TOKEN,
                    ErrorCode.REVOKED_REFRESH_TOKEN,
                ].includes(errorCode)

                if (isRevoked) {
                    this.onSignedOut()
                    throw error
                }

                if (status === 401) {
                    return await this.extendSessionAndRetry(apiCall)
                }

                throw error
            }
        }

        return await this.extendSessionAndRetry(apiCall)
    }

    clearData() {
        this.cookies.remove(ACCESS_TOKEN_KEY, { path: '/' })
        this.cookies.remove(SIGNED_IN_USER_KEY, { path: '/' })
        sessionStorage.removeItem(ACCESS_TOKEN_KEY)
        sessionStorage.removeItem(SIGNED_IN_USER_KEY)
        localStorage.removeItem(ACCESS_TOKEN_EXPIRATION_DATE_KEY)
        localStorage.removeItem(ACCESS_TOKEN_MAX_AGE_DATE_KEY)
    }

    private async extendSessionAndRetry<T>(apiCall: () => Promise<T>): Promise<T> {
        return new Promise(async (resolve, reject) => {
            try {
                await this.extendSession()
                await apiCall().then(resolve).catch(reject)
            } catch (error: any) {
                this.onSignedOut()
            }
        })
    }

    private async extendSession(): Promise<void> {
        if (this.isRefreshingAccessToken) {
            return this.accessTokenRefreshPromise
        }

        this.isRefreshingAccessToken = true

        const refreshTokenPromise = (async () => {
            try {
                const response = await this.postWithoutAuthorization<ExtendSessionResponse>(
                    `${this.apiUrl}/api/v1/session/extend`,
                    {
                        accessToken: this.bearerToken,
                    }
                )

                this.storeSessionInfo(response, this.isUsingSessionStorage)
            } finally {
                this.isRefreshingAccessToken = false
                this.accessTokenRefreshPromise = undefined
            }
        })()

        this.accessTokenRefreshPromise = refreshTokenPromise

        await refreshTokenPromise
    }

    private storeSessionInfo(
        params: SignInResponse | ExtendSessionResponse,
        useSessionStorage: boolean
    ) {
        const maxAge = params.sessionMaxAge || DEFAULT_MAX_SESSION_AGE
        const expiresAt = DateTime.now().plus({ seconds: params.expiresIn }).toJSDate()
        const maxAgeDate = DateTime.now().plus({ seconds: maxAge }).toJSDate()

        if (useSessionStorage) {
            sessionStorage.setItem(ACCESS_TOKEN_KEY, params.accessToken!)
        } else {
            this.cookies.set(ACCESS_TOKEN_KEY, params.accessToken, {
                sameSite: true,
                secure: true,
                maxAge: maxAge,
            })
        }

        localStorage.setItem(ACCESS_TOKEN_EXPIRATION_DATE_KEY, expiresAt.toISOString())
        localStorage.setItem(ACCESS_TOKEN_MAX_AGE_DATE_KEY, maxAgeDate.toISOString())
    }

    private storeSignedInUser(signedInUser: SignedInUser) {
        this._signedInUser = signedInUser

        if (this.isUsingSessionStorage) {
            sessionStorage.setItem(SIGNED_IN_USER_KEY, JSON.stringify(signedInUser))
        } else {
            const maxAgeDateString = localStorage.getItem(ACCESS_TOKEN_MAX_AGE_DATE_KEY)
            const maxAgeDate = maxAgeDateString
                ? DateTime.fromJSDate(new Date(maxAgeDateString))
                : undefined
            const maxAge = maxAgeDate
                ? maxAgeDate.diff(DateTime.now()).as('seconds')
                : DEFAULT_MAX_SESSION_AGE
            this.cookies.set(SIGNED_IN_USER_KEY, signedInUser, {
                sameSite: true,
                secure: true,
                maxAge: maxAge,
            })
        }
    }

    private getSignedInUser(): SignedInUser | undefined {
        if (this.isUsingSessionStorage) {
            const signedInUserString = sessionStorage.getItem(SIGNED_IN_USER_KEY)
            return signedInUserString
                ? SignedInUser.fromJSON(JSON.parse(signedInUserString))
                : undefined
        }

        const signedInUserObject = this.cookies.get(SIGNED_IN_USER_KEY)
        return signedInUserObject ? SignedInUser.fromJSON(signedInUserObject) : undefined
    }

    private onSignedOut(isIntentionalSignOut: boolean = false) {
        const applicationId = this.applicationId
        this.clearData()

        this.observers.forEach((observer) => observer(applicationId!, isIntentionalSignOut))
    }
}
