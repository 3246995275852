import { SVGProps } from 'react'

export const CalendarIconOutlined = (properties: SVGProps<any>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={properties.fill || 'current'}
        {...properties}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.5 4.75C1.5 2.95507 2.95507 1.5 4.75 1.5H19.25C21.0449 1.5 22.5 2.95507 22.5 4.75H1.5ZM1.5 6.25V19.254C1.5 21.0489 2.95507 22.504 4.75 22.504H19.25C21.0449 22.504 22.5 21.0489 22.5 19.254V6.25H1.5ZM24 5.5V19.254C24 21.8773 21.8734 24.004 19.25 24.004H4.75C2.12665 24.004 0 21.8773 0 19.254V4.75C0 2.12665 2.12665 0 4.75 0H19.25C21.8734 0 24 2.12665 24 4.75V5.5Z"
            fill={properties.fill || 'current'}
        />
    </svg>
)
