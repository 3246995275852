import { Box, BoxProps, ListItem as MuiListItem, ListItemProps, styled } from '@mui/material'

export const PostListItem = styled(
    ({ isLastItem, ...properties }: ListItemProps & { isLastItem: boolean }) => (
        <MuiListItem {...properties} />
    )
)(({ theme, isLastItem }) => ({
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: isLastItem ? theme.spacing(3) : 0,

    [theme.breakpoints.only('mobile')]: {
        paddingTop: theme.spacing(1),
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: isLastItem ? theme.spacing(1) : 0,
    },
}))

export const PostListItemContainer = styled(
    ({ hasHorizontalMargins, ...properties }: BoxProps & { hasHorizontalMargins?: boolean }) => (
        <Box {...properties} />
    )
)(({ theme, hasHorizontalMargins = true }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
    paddingLeft: hasHorizontalMargins ? theme.spacing(2) : 0,
    paddingRight: hasHorizontalMargins ? theme.spacing(2) : 0,
}))
