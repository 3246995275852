import { Box } from '@mui/material'
import { Skeleton } from 'shared/lib/components/Skeleton'

export const PostTopBarLoadingView = () => {
    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <Skeleton variant="circular" width={40} height={40} />
            <Box display="flex" flexDirection="column" ml={1} flex={1}>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="40%" />
            </Box>
        </Box>
    )
}
