import React from 'react'
import { Box, BoxProps, IconButton, styled, Typography } from '@mui/material'
import { paperColor } from 'shared/lib/theme/Theme'

const DETAIL_VIEW_HEADER_HEIGHT = 56
const DETAIL_VIEW_HEADER_MARGIN_TOP = 8
export const DETAIL_VIEW_HEADER_BOTTOM_Y = DETAIL_VIEW_HEADER_HEIGHT + DETAIL_VIEW_HEADER_MARGIN_TOP

interface Properties {
    title?: string | React.JSX.Element
    closeIcon: React.JSX.Element
    actionButton?: React.JSX.Element
    shareButton?: React.JSX.Element
    onCloseClicked(): void
}

export const DetailViewHeader = ({ title, ...properties }: Properties) => {
    return (
        <Header hasTitle={!!title} mt={`${DETAIL_VIEW_HEADER_MARGIN_TOP}px`}>
            <CloseButton onClick={properties.onCloseClicked}>{properties.closeIcon}</CloseButton>

            <TitleContainer>
                {typeof title === 'string' ? <Title variant="h1">{title}</Title> : title}
            </TitleContainer>

            {properties.shareButton}
            {properties.actionButton}
        </Header>
    )
}

const Header = styled(({ hasTitle, ...props }: BoxProps & { hasTitle: boolean }) => (
    <Box {...props} />
))(({ theme, hasTitle }) => ({
    backgroundColor: hasTitle ? paperColor : 'transparent',
    height: DETAIL_VIEW_HEADER_HEIGHT,
    zIndex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    position: 'absolute',
    overflow: 'hidden',
    paddingRight: theme.spacing(1),

    [theme.breakpoints.down('tablet')]: {
        paddingLeft: theme.spacing(1),
        paddingRigth: theme.spacing(1),
    },
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: 'white',

    '& svg': {
        width: 24,
        height: 24,
    },

    [theme.breakpoints.up('tablet')]: {
        margin: theme.spacing(1),
    },
}))

const TitleContainer = styled(Box)(({ theme }) => ({
    paddingRight: theme.spacing(2),
    textAlign: 'left',
    overflow: 'hidden',
    width: '100%',
}))

const Title = styled(Typography)(() => ({
    fontSize: '19px',
}))
