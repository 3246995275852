import { Box, Divider, SxProps } from '@mui/material'
import { grey_3 } from '../theme/Theme'

export const HorizontalDivider = (properties: SxProps) => (
    <Divider
        sx={{
            height: '2px',
            borderColor: grey_3,
            ...properties,
        }}
    />
)

export const VerticalDivider = (properties: SxProps) => (
    <Box
        sx={{
            height: '100%',
            width: '2px',
            backgroundColor: grey_3,
            ...properties,
        }}
    />
)
