import {
    Box,
    IconButton,
    StepLabel as MuiStepLabel,
    Stepper as MuiStepper,
    Step,
    Typography,
    styled,
} from '@mui/material'
import { QRCodeSVG } from 'qrcode.react'
import { useState } from 'react'
import { HorizontalDivider } from 'shared/lib/components/Divider'
import { PopupModal } from 'shared/lib/components/PopupModal'
import { useIsMobile } from 'shared/lib/theme/BreakPointHooks'
import { grey_1, grey_3 } from 'shared/lib/theme/Theme'
import { ReactComponent as Copy } from '../../../../assets/svg/copy.svg'
import { useTranslation } from 'shared/lib/i18n'

interface SetupSoftwareTokenProperties {
    secretCode: string
    secretCodeUrl: string
}

export const SetupSoftwareTokenStep = (properties: SetupSoftwareTokenProperties) => {
    const isMobile = useIsMobile()
    const translations = useTranslation()

    const [copiedText, setCopiedText] = useState<string | undefined>(undefined)

    const onCopySecretCode = () => {
        navigator.clipboard.writeText(properties.secretCode)
        setCopiedText(translations('copied_to_clipboard'))
    }

    return (
        <Box p={4}>
            <Stepper nonLinear orientation="vertical">
                <Step>
                    <CustomStepLabel number={1}>
                        <Typography variant="body1">
                            {translations('open_or_install_authenticator_app')}
                        </Typography>
                    </CustomStepLabel>
                    <HorizontalDivider mt={3} mb={3} />
                </Step>
                <Step>
                    <CustomStepLabel number={2}>
                        <Typography variant="body1">
                            {isMobile
                                ? translations('fill_in_code_in_authenticator_app_and_click_next')
                                : translations('scan_qr_code_in_authenticator_app_and_click_next')}
                        </Typography>

                        {!isMobile && (
                            <SoftwareTokenSetupContainer>
                                <QrCodeBackground>
                                    <QRCodeSVG
                                        value={properties.secretCodeUrl}
                                        size={96}
                                        style={{
                                            display: 'flex',
                                            borderRadius: '8px',
                                        }}
                                    />
                                </QrCodeBackground>
                                <Box>
                                    <Typography variant={'h3'}>
                                        {translations('scan_me_with_your_authenticator_app')}
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        {translations('or_setup_manually')}
                                    </Typography>
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                    >
                                        <Typography variant={'body2'}>
                                            {properties.secretCode}
                                        </Typography>
                                        <IconButton
                                            sx={{
                                                height: '100%',
                                            }}
                                            onClick={onCopySecretCode}
                                        >
                                            <Copy fill={grey_1} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </SoftwareTokenSetupContainer>
                        )}
                    </CustomStepLabel>
                </Step>
                {isMobile && (
                    <SoftwareTokenSetupContainer>
                        <Typography variant={'body2'}>{properties.secretCode}</Typography>
                        <IconButton
                            sx={{
                                height: '100%',
                            }}
                            onClick={onCopySecretCode}
                        >
                            <Copy fill={grey_1} />
                        </IconButton>
                    </SoftwareTokenSetupContainer>
                )}
            </Stepper>
            <PopupModal
                vertical={isMobile ? 'bottom' : 'center'}
                horizontal={isMobile ? 'left' : 'center'}
                message={copiedText}
                onClose={() => setCopiedText(undefined)}
            />
        </Box>
    )
}

const Stepper = styled(MuiStepper)(() => ({
    padding: 0,
    '& .MuiStepConnector-root': {
        display: 'none',
    },
}))

const CustomStepLabel = ({ number, children }) => {
    return (
        <MuiStepLabel
            StepIconComponent={() => (
                <Typography variant={'body1'} sx={{ fontWeight: 'bold' }}>
                    Stap {number}:
                </Typography>
            )}
            sx={{
                alignItems: 'start',
                padding: 0,
                overflowWrap: 'anywhere',
            }}
        >
            {children}
        </MuiStepLabel>
    )
}

const SoftwareTokenSetupContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
    [theme.breakpoints.only('mobile')]: {
        paddingLeft: theme.spacing(3),
        padding: theme.spacing(2),
    },
    borderRadius: '24px',
    wordWrap: 'break-word',
    gap: theme.spacing(3),
    width: '100%',
    backgroundColor: grey_3,
    overflowWrap: 'anywhere',
    justifyContent: 'space-between',
}))

const QrCodeBackground = styled(Box)(({ theme }) => ({
    background: 'white',
    borderRadius: '24px',
    padding: theme.spacing(1),
    cursor: 'none',
}))
