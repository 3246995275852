import { Box, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { GreyContainedButton } from 'shared/lib/components/buttons/GreyButtons'
import { ConfirmationCodeTextField } from 'shared/lib/components/textfields/ConfirmationCodeTextField'
import donkey from '../../assets/images/vault.jpg'
import { Paths } from '../../routing/paths'
import ChurchContext from '../../churches/ChurchContextProvider'
import SessionContext from '../SessionContextProvider'
import { SessionPage, Title } from '../SessionPage'
import {
    PrimaryActionButtonContainer,
    PrimaryActionButtonsContainer,
} from 'shared/lib/components/PrimaryActionButtonsContainer'
import { MFAMethod } from 'shared/lib/models/MFAMethod'
import { SendAgainTextView } from 'shared/lib/components/SendAgainTextView'
import { useTranslation } from 'shared/lib/i18n'
import { getErrorMessage } from 'shared/lib/utils/ErrorUtils'

interface LocationState {
    emailAddress: string
    password: string
    mfaSession: string
    mfaMethod: MFAMethod
    mfaPhoneNumber: string
    useSessionStorage: boolean
}

export const ConfirmSignInPage = () => {
    const translations = useTranslation()
    const { church } = useContext(ChurchContext)!
    const sessionContext = useContext(SessionContext)!
    const navigate = useNavigate()
    const location = useLocation()
    const locationState: LocationState | undefined = location.state ? location.state : undefined

    const emailAddress = locationState?.emailAddress || ''
    const password = locationState?.password || ''
    const mfaPhoneNumber = locationState?.mfaPhoneNumber?.replaceAll('*', '•')
    const useSessionStorage = locationState?.useSessionStorage || false
    const isSMS = locationState?.mfaMethod === MFAMethod.SMS_MFA

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [error, setError] = useState<Error | undefined>()
    const [mfaSession, setMFASession] = useState(locationState?.mfaSession || '')
    const [confirmationCode, setConfirmationCode] = useState('')

    const canContinue = confirmationCode.length === 6

    const confirmSignIn = (confirmationCode: string) => {
        setIsLoading(true)
        setErrorMessage(undefined)
        setConfirmationCode(confirmationCode)

        sessionContext
            .confirmSignIn({
                emailAddress,
                mfaSession,
                confirmationCode,
                useSessionStorage,
            })
            .catch((error) => {
                setIsLoading(false)
                setErrorMessage(getErrorMessage({ error, translations }))
                setConfirmationCode('')
            })
    }

    const resendMFACode = async () => {
        if (!isSMS) return

        sessionContext
            .resendSMSMFACode({
                emailAddress,
                password,
                useSessionStorage,
            })
            .then((response) => setMFASession(response.mfaSession))
            .catch(setError)
    }

    useEffect(() => {
        if (!locationState) {
            navigate(Paths.SIGN_IN)
        }
    }, [locationState, navigate])

    if (!locationState) {
        return <></>
    }

    return (
        <SessionPage backgroundImage={donkey} church={church}>
            <Title variant="h1">{translations('enter_confirmation_code')}</Title>

            <Typography variant="body2" mt={3} mb={4}>
                {isSMS && mfaPhoneNumber
                    ? translations('confirm_sign_in_message', [mfaPhoneNumber])
                    : translations('use_verification_code_from_app')}
            </Typography>

            <ConfirmationCodeTextField
                length={6}
                error={errorMessage}
                onConfirmationCodeEntered={confirmSignIn}
                onConfirmationCodeChanged={() => setErrorMessage(undefined)}
            />

            {isSMS && (
                <Box display="flex" alignItems="center" marginTop={2}>
                    <Typography variant="body2" mr={1}>
                        {translations('nothing_received_question')}
                    </Typography>
                    <SendAgainTextView onClicked={resendMFACode} />
                </Box>
            )}

            <PrimaryActionButtonsContainer>
                <GreyContainedButton
                    fullWidth={true}
                    title={translations('back')}
                    onClick={() => navigate(Paths.SIGN_IN)}
                />

                <PrimaryActionButtonContainer>
                    <PrimaryContainedButton
                        fullWidth={true}
                        isLoading={isLoading}
                        title={translations('confirm')}
                        disabled={!canContinue}
                        onClick={() => confirmSignIn(confirmationCode)}
                    />
                </PrimaryActionButtonContainer>
            </PrimaryActionButtonsContainer>

            <ErrorHandler error={error} translations={translations} />
        </SessionPage>
    )
}
