import { AppBar, Box, IconButton, styled, Toolbar as MuiToolbar, Typography } from '@mui/material'
import i18next from 'i18next'
import React, { forwardRef, PropsWithChildren, Ref, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as CloseIcon } from 'shared/lib/assets/svg/close.svg'
import { SelectLanguageButton } from 'shared/lib/components/buttons/SelectLanguageButton'
import { mobileToolbarHeight } from 'shared/lib/theme/Theme'
import { ReactComponent as Bell } from '../../assets/svg/bell_inactive.svg'
import { ReactComponent as BellCrooked } from '../../assets/svg/bell_inactive_crooked.svg'
import SessionContext from '../../authentication/SessionContextProvider'
import i18n, { SUPPORTED_LANGUAGES } from '../../i18n'
import { Paths } from '../../routing/paths'
import { ProfileButton } from './ProfileButton'
import { ElevateOnScroll } from 'shared/lib/components/ElevateOnScroll'
import { NotificationsContext } from '../../notifications/NotificationsContextProvider'
import { DotBadge } from 'shared/lib/components/DotBadge'
import { SmartBanner } from '../../components/SmartBanner'

interface Properties {
    hasBackButton?: boolean
    hasSubTitle?: boolean
    hasActions?: boolean
    hasLanguageButton?: boolean
    isApprovedUser?: boolean
    canShowSmartBanner?: boolean
    title: string | React.JSX.Element
    subTitle?: string
    scrollableContainerId?: string
    onBackButtonClicked?: () => void
}

export const MobileHeader = forwardRef(
    (properties: PropsWithChildren<Properties>, ref: Ref<HTMLDivElement>) => {
        const navigate = useNavigate()
        const location = useLocation()

        const { getSignedInUser } = useContext(SessionContext)!
        const { hasUnreadNotifications } = useContext(NotificationsContext)

        const user = getSignedInUser()!

        const hasBackButton = properties.hasBackButton ?? true
        const hasSubTitle = properties.hasSubTitle ?? false
        const hasActions = properties.hasActions ?? true
        const isApprovedUser = properties.isApprovedUser ?? true
        const canShowSmartBanner = properties.canShowSmartBanner ?? false

        const onNotificationsButtonClicked = () => {
            navigate(Paths.NOTIFICATIONS, {
                state: {
                    from: location.pathname,
                },
            })
        }

        return (
            <ElevateOnScroll targetId={properties.scrollableContainerId}>
                <AppBar ref={ref}>
                    {canShowSmartBanner && <SmartBanner />}
                    <Toolbar>
                        {hasBackButton && (
                            <IconButton onClick={() => properties.onBackButtonClicked?.()}>
                                <CloseIcon />
                            </IconButton>
                        )}

                        <TitleContainer>
                            <Title variant="h1">{properties.title}</Title>
                            {hasSubTitle && (
                                <SubTitle variant="body2">{properties.subTitle}</SubTitle>
                            )}
                        </TitleContainer>

                        {properties.hasLanguageButton && (
                            <SelectLanguageButton
                                changeLanguage={async (language: string) => {
                                    await i18next.changeLanguage(language)
                                }}
                                currentLanguage={i18n.language}
                                supportedLanguages={SUPPORTED_LANGUAGES}
                            />
                        )}
                        {hasActions && (
                            <Box display="flex" gap={1}>
                                {isApprovedUser && (
                                    <IconButton size="large" onClick={onNotificationsButtonClicked}>
                                        {hasUnreadNotifications ? (
                                            <DotBadge>
                                                <BellCrooked />
                                            </DotBadge>
                                        ) : (
                                            <Bell />
                                        )}
                                    </IconButton>
                                )}

                                <ProfileButton user={user} />
                            </Box>
                        )}
                    </Toolbar>

                    {properties.children}
                </AppBar>
            </ElevateOnScroll>
        )
    }
)

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
    height: mobileToolbarHeight,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between',
}))

const Title = styled(Typography)(() => ({
    fontSize: 19,
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
}))

const SubTitle = styled(Typography)(() => ({
    height: '16px',
    fontSize: 11,
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
}))

const TitleContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: 1,
    overflow: 'hidden',
}))
