import { LocalImage } from 'shared/lib/forms/LocalImage'
import { Address } from 'shared/lib/models/Address'
import { DateTime } from 'luxon'

export type UpdateUserParams = {
    firstName: string
    lastName: string
    fullSizeImage?: LocalImage
    thumbnailImage?: LocalImage
    phoneNumber?: string | null
    mobilePhoneNumber?: string | null
    birthday?: DateTime | undefined
    address?: Address | null
    functions?: string[]
    about?: string | null
    isEmailAddressHidden?: boolean
}

export const UpdateUserParamsToFormData = (params: UpdateUserParams): FormData => {
    const formData = new FormData()
    formData.append('firstName', params.firstName)

    formData.append('lastName', params.lastName)

    if (params.fullSizeImage && params.fullSizeImage.file) {
        formData.append('fullSizeImage', params.fullSizeImage.file)
    }

    if (params.thumbnailImage && params.thumbnailImage.file) {
        formData.append('thumbnailImage', params.thumbnailImage.file)
    }

    formData.append('phoneNumber', params.phoneNumber || 'null')

    formData.append('mobilePhoneNumber', params.mobilePhoneNumber || 'null')

    formData.append('birthday', params.birthday?.toFormat('yyyy-MM-dd') || 'null')

    formData.append('address{}', JSON.stringify(params.address) || 'null')

    formData.append('about', params.about || 'null')

    if (params.functions !== undefined && params.functions.length > 0) {
        params.functions.forEach((func) => formData.append('functions[]{}', func))
    }

    if (params.isEmailAddressHidden !== undefined) {
        formData.append('isEmailAddressHidden', String(params.isEmailAddressHidden))
    }

    return formData
}
