import {
    Box,
    BoxProps,
    IconButton as MuiIconButton,
    Paper as MuiPaper,
    Slider as MuiSlider,
    Typography,
    styled,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import { Area } from 'react-easy-crop/types'
import { WithTranslations } from '../WithTranslations'
import { DonkeyBackdrop } from '../components/Backdrop'
import { LocalImage } from '../forms/LocalImage'
import { ImageHelper } from './ImageHelper'
import { PrimaryContainedButton } from '../components/buttons/ContainedButtons'
import { HorizontalDivider } from '../components/Divider'
import { TextButton } from '../components/buttons/TextButtons'
import { ReactComponent as MinusIconSVG } from 'shared/lib/assets/svg/minus.svg'
import { ReactComponent as PlusIconSVG } from 'shared/lib/assets/svg/plus_filled.svg'
import { ReactComponent as CancelIconSVG } from 'shared/lib/assets/svg/cancel_gray.svg'
import { grey_3 } from '../theme/Theme'
import { delay } from '../utils/Utils'
import { DEFAULT_ANIMATION_DURATION } from '../common/Constants'

interface Properties {
    image?: File
    continueButtonTitle?: string
    onCancelCropClicked: () => void
    onImageCropped: (fullSizeImage: LocalImage, thumbnail: LocalImage) => void
}

export const ImageCropper = (properties: WithTranslations<Properties>) => {
    const { image, translations } = properties

    const [isCropping, setIsCropping] = useState(false)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | undefined>(undefined)
    const [zoom, setZoom] = useState(1)
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined)

    const sliderStep = 0.1
    const onCropComplete = useCallback((_: any, croppedAreaPixels: Area | undefined) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const onZoomButtonsClicked = (zoom: number) => {
        const roundedZoom = Math.round(zoom * 10) / 10
        if (roundedZoom < 1 || roundedZoom > 3) return
        setZoom(zoom)
    }

    const onCancelButtonClicked = () => {
        if (isCropping) return
        properties.onCancelCropClicked()
        resetState()
    }

    const onSaveButtonClicked = async () => {
        if (!image || !imageSrc || !croppedAreaPixels || isCropping) return

        try {
            setIsCropping(true)

            await delay(DEFAULT_ANIMATION_DURATION)

            const blob = await ImageHelper.cropImage({
                src: imageSrc,
                pixelCrop: croppedAreaPixels,
            })

            const fullSizeFile = await ImageHelper.compressImage({
                blob,
                fileName: image.name,
            })

            const thumbnailFile = await ImageHelper.compressImage({
                blob,
                fileName: image.name,
            })

            properties.onImageCropped(fullSizeFile, thumbnailFile)
            resetState()
        } catch (error) {
            resetState()
        }
    }

    const resetState = () => {
        setIsCropping(false)
        setCroppedAreaPixels(undefined)
        setCrop({ x: 0, y: 0 })
        setZoom(1)
    }

    useEffect(() => {
        setImageSrc(image ? URL.createObjectURL(image) : undefined)
    }, [image])

    return (
        <DonkeyBackdrop isVisible={image !== undefined}>
            <Paper elevation={0} onClick={(event) => event.stopPropagation()}>
                <PaperContent>
                    <ImageCropperBox>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            showGrid={false}
                            cropShape={'round'}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                        <CloseButton onClick={onCancelButtonClicked}>
                            <CancelIcon />
                        </CloseButton>
                    </ImageCropperBox>

                    <Box padding={2}>
                        <SliderBox isCropping={isCropping}>
                            <IconButton
                                onClick={() => onZoomButtonsClicked(zoom - sliderStep)}
                                disabled={isCropping}
                            >
                                <MinusIcon />
                            </IconButton>
                            <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={sliderStep}
                                disabled={isCropping}
                                onChange={(e, zoom) => setZoom(zoom as number)}
                            />
                            <IconButton
                                onClick={() => onZoomButtonsClicked(zoom + sliderStep)}
                                disabled={isCropping}
                            >
                                <PlusIcon />
                            </IconButton>
                        </SliderBox>

                        <Divider />

                        <ButtonContainer>
                            {isCropping && (
                                <CompressingTextView variant="body1">
                                    {translations?.('compressing_image') ?? 'Compressing image...'}
                                </CompressingTextView>
                            )}
                            {!isCropping && (
                                <TextButton
                                    title={translations?.('cancel') ?? 'Cancel'}
                                    onClick={onCancelButtonClicked}
                                    color="inherit"
                                />
                            )}
                            <PrimaryContainedButton
                                isLoading={isCropping}
                                disabled={isCropping}
                                sx={{ minWidth: '120px' }}
                                onClick={onSaveButtonClicked}
                                title={translations?.('save') ?? 'Save'}
                            />
                        </ButtonContainer>
                    </Box>
                </PaperContent>
            </Paper>
        </DonkeyBackdrop>
    )
}

const Paper = styled(MuiPaper)(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 32,

    width: '30%',

    [theme.breakpoints.only('tablet')]: {
        width: '40%',
    },
    [theme.breakpoints.only('phablet')]: {
        width: '60%',
    },
    [theme.breakpoints.only('mobile')]: {
        width: '80%',
    },
}))

const PaperContent = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 32,
    width: '100%',
    maxHeight: '100vh',
}))

const ImageCropperBox = styled(Box)(() => ({
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 32,
    backgroundColor: 'black',
    transform: 'translateZ(0)',
    aspectRatio: '1/1',
}))

const SliderBox = styled(({ isCropping, ...props }: BoxProps & { isCropping: boolean }) => (
    <Box {...props} />
))(({ theme, isCropping }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    opacity: isCropping ? 0.4 : 1,

    [theme.breakpoints.up('phablet')]: {
        marginTop: theme.spacing(2),
    },
}))

const Slider = styled(MuiSlider)(({ theme }) => ({
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    '& .Mui-disabled': {
        color: theme.palette.primary.main,
    },
    '.MuiSlider-track': {
        color: theme.palette.primary.main,
    },

    [theme.breakpoints.only('mobile')]: {
        marginRight: '0px',
        marginLeft: '0px',
    },
}))

const Divider = styled(HorizontalDivider)(({ theme }) => ({
    height: '1px',
    display: 'block',
    marginTop: theme.spacing(4),

    [theme.breakpoints.down('phablet')]: {
        marginTop: theme.spacing(2),
    },
}))

const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    marginTop: theme.spacing(3),
    gap: theme.spacing(2),

    [theme.breakpoints.down('phablet')]: {
        marginTop: theme.spacing(2),
        gap: theme.spacing(1),
    },
}))

const CompressingTextView = styled(Typography)(() => ({
    display: 'flex',
    minHeight: '50px',
    alignItems: 'center',
    wordBreak: 'break-all',
}))

const IconButton = styled(MuiIconButton)(({ theme }) => ({
    height: '24px',
    width: '24px',

    [theme.breakpoints.only('mobile')]: {
        display: 'none',
    },
}))

const MinusIcon = styled(MinusIconSVG)(() => ({
    minWidth: '24px',
}))

const PlusIcon = styled(PlusIconSVG)(() => ({
    minWidth: '24px',
}))

const CloseButton = styled(MuiIconButton)(() => ({
    height: '24px',
    width: '24px',
    position: 'absolute',
    top: '24px',
    right: '24px',
}))

const CancelIcon = styled(CancelIconSVG)(() => ({
    minWidth: '24px',
    fill: grey_3,
}))
