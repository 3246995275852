import { InputAdornment, TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'
import React, { Ref, forwardRef } from 'react'
import { SearchIcon } from '../../assets/svg/SearchIcon'
import { grey_2 } from '../../theme/Theme'

export interface TextFieldProperties {
    isSearch?: boolean
    variant?: 'filled' | 'outlined'
    testId?: string
    minWidth?: any
    maxWidth?: any
    width?: any
    maxLength?: number
    startIcon?: React.JSX.Element
    endIcon?: React.JSX.Element
    onChanged?: (value: string) => void
    onEnterKeyPressed?: () => void
}

export const TextField = forwardRef(
    (properties: TextFieldProperties & TextFieldProps, ref?: Ref<HTMLTextAreaElement>) => {
        const {
            isSearch,
            endIcon,
            onChanged,
            onEnterKeyPressed,
            value,
            startIcon,
            testId,
            width,
            minWidth,
            maxWidth,
            sx,
            variant: variantProp,
            inputProps,
            ...rest
        } = properties

        const startAdornment = isSearch ? <SearchIcon color={grey_2} /> : startIcon

        const variant = variantProp || 'filled'
        const disableUnderline = variant === 'filled' ? { disableUnderline: true } : {}

        const margins = properties.label
            ? {
                  '& .MuiInputLabel-root': {
                      marginLeft: '4px',
                      marginTop: '4px',
                  },
                  '& input, & textarea': {
                      marginLeft: '4px',
                      marginTop: '4px',
                      marginBottom: '4px',
                  },
              }
            : {}

        return (
            <MuiTextField
                inputRef={ref}
                value={value}
                variant={variant}
                onChange={(event) => {
                    if (onChanged) {
                        onChanged(event.target.value)
                    }
                }}
                onKeyUp={(event) => {
                    if (event.key === 'Enter' && onEnterKeyPressed) {
                        onEnterKeyPressed()
                    }
                }}
                inputProps={{
                    'data-testid': testId,
                    maxLength: properties.maxLength,
                }}
                InputProps={{
                    startAdornment: startAdornment ? (
                        <InputAdornment position="start">{startAdornment}</InputAdornment>
                    ) : undefined,
                    endAdornment: endIcon ? (
                        <InputAdornment position="end">{endIcon}</InputAdornment>
                    ) : undefined,
                    ...disableUnderline,
                    ...(inputProps as Partial<StandardInputProps>),
                }}
                sx={{
                    width,
                    minWidth,
                    maxWidth,
                    ...margins,
                    ...sx,
                }}
                {...rest}
            />
        )
    }
)
