import { Box, styled } from '@mui/material'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { EventListItem } from '../components/EventListComponents'

interface Properties {
    isLastItem: boolean
}

export const EVENT_LOADING_ROW_HEIGHT = 120

export const EventItemLoadingRow = (properties: Properties) => {
    return (
        <EventListItem isLastItem={properties.isLastItem}>
            <Container>
                <TimeContainer>
                    <Skeleton variant="text" width="48px" sx={{ bgcolor: 'white' }} />
                </TimeContainer>

                <EventContainer>
                    <Skeleton variant="text" width="30%" />
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="50%" sx={{ mb: 1 }} />
                </EventContainer>
            </Container>
        </EventListItem>
    )
}

const Container = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
}))

const TimeContainer = styled(Box)(({ theme }) => ({
    width: '64px',
    minWidth: '64px',
    minHeight: '88px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
}))

const EventContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: 'white',
    overflow: 'hidden',
    borderRadius: '16px',
    marginLeft: theme.spacing(1),
    padding: theme.spacing(3),
}))
