import {
    IconButton,
    List as MuiList,
    ListItem,
    ListItemButton as MuiListItemButton,
    ListItemIcon as MuiListItemIcon,
    ListItemText,
    styled,
} from '@mui/material'
import { SyntheticEvent, useState } from 'react'
import { ReactComponent as MoreIcon } from 'shared/lib/assets/svg/more.svg'
import { ReactComponent as Edit } from 'shared/lib/assets/svg/write.svg'
import { MenuPopup } from 'shared/lib/components/MenuPopup'
import { ReactComponent as Delete } from '../../assets/svg/delete.svg'
import { useTranslation } from 'shared/lib/i18n'
import { Event } from '../Event'

interface Properties {
    event: Event
    onEditButtonClicked: () => void
    onDeleteButtonClicked: () => void
}

export const EventActionsButton = (properties: Properties) => {
    const translations = useTranslation()

    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)

    const onMoreButtonClicked = (event: SyntheticEvent<HTMLButtonElement>) => {
        event.stopPropagation()

        setAnchor(event.currentTarget)
    }

    const onEditButtonClicked = (event: SyntheticEvent<HTMLDivElement>) => {
        event.stopPropagation()
        setAnchor(null)

        properties.onEditButtonClicked()
    }

    const onDeleteButtonClicked = (event: SyntheticEvent<HTMLDivElement>) => {
        event.stopPropagation()
        setAnchor(null)

        properties.onDeleteButtonClicked()
    }

    return (
        <>
            <IconButton sx={{ maxWidth: '40px', maxHeight: '40px' }} onClick={onMoreButtonClicked}>
                <MoreIcon />
            </IconButton>

            <MenuPopup
                anchor={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                popupMarginTop={'8px'}
                arrowRightPosition={'16px'}
                onPopupClosed={() => setAnchor(null)}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={onEditButtonClicked}>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary={translations('agenda_edit_item')} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={onDeleteButtonClicked}>
                            <ListItemIcon>
                                <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    color: (theme) => theme.palette.error.main,
                                }}
                                primary={translations('delete_event')}
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </MenuPopup>
        </>
    )
}

const EditIcon = styled(Edit)(({ theme }) => ({
    fill: theme.palette.text.primary,
}))

const DeleteIcon = styled(Delete)(({ theme }) => ({
    fill: theme.palette.error.main,
}))

const List = styled(MuiList)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
}))

const ListItemButton = styled(MuiListItemButton)(() => ({
    borderRadius: '16px',
}))

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
    minWidth: 'unset',
    marginRight: theme.spacing(2),
    '& svg': {
        height: '20px',
        width: '20px',
    },
}))
