import { PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react'
import { notificationRepository } from '..'
import { NotificationEventKey } from './events'

export const NotificationsContext = createContext<{
    hasUnreadNotifications: boolean
}>({ hasUnreadNotifications: false })

export const NotificationsContextProvider = ({ children }: PropsWithChildren) => {
    const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false)

    const getHasUnreadNotifications = async () => {
        await notificationRepository.loadUnreadNotifications()
        setHasUnreadNotifications(notificationRepository.hasUnreadNotifications)
    }

    const onNotificationPageMounted = useCallback(() => {
        setHasUnreadNotifications(false)
    }, [])

    const onNotificationPageUnmounted = useCallback(() => {
        setHasUnreadNotifications(false)
        notificationRepository.markAsReadFromNotificationCenter()
    }, [])

    const onNotificationsMarkedAsRead = () => {
        setHasUnreadNotifications(notificationRepository.hasUnreadNotifications)
    }

    useEffect(() => {
        document.addEventListener(
            NotificationEventKey.NOTIFICATION_PAGE_MOUNTED,
            onNotificationPageMounted
        )

        document.addEventListener(
            NotificationEventKey.NOTIFICATION_PAGE_UNMOUNTED,
            onNotificationPageUnmounted
        )
        document.addEventListener(
            NotificationEventKey.NOTIFICATIONS_MARKED_AS_READ,
            onNotificationsMarkedAsRead
        )

        return () => {
            document.removeEventListener(
                NotificationEventKey.NOTIFICATION_PAGE_MOUNTED,
                onNotificationPageMounted
            )
            document.removeEventListener(
                NotificationEventKey.NOTIFICATION_PAGE_UNMOUNTED,
                onNotificationPageUnmounted
            )
            document.removeEventListener(
                NotificationEventKey.NOTIFICATIONS_MARKED_AS_READ,
                onNotificationsMarkedAsRead
            )
        }
    }, [onNotificationPageMounted, onNotificationPageUnmounted])

    useEffect(() => {
        getHasUnreadNotifications()
    }, [])

    return (
        <NotificationsContext.Provider
            value={{
                hasUnreadNotifications,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    )
}
