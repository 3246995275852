import React, { useEffect, useMemo, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VirtuosoHandle } from 'react-virtuoso'
import { ReactComponent as AlertIcon } from 'shared/lib/assets/svg/alert.svg'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { errorColor } from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'
import { PostList } from './PostList'
import { usePosts } from './hooks'
import { ScrollListener } from 'shared/lib/common/ScrollListener'
import { EditPostPopup } from '../posts/createpostpopup/EditPostPopup'
import { PopupModal } from 'shared/lib/components/PopupModal'
import { Group } from '../groups/Group'
import { PostItemViewModel } from './PostViewModel'
import { Post } from '../posts/Post'
import { notificationRepository } from '..'
import { Box } from '@mui/material'

interface Properties {
    selectedGroup: Group
    homeGroupId?: string
}

export const PostListContainer = ({ selectedGroup, homeGroupId }: Properties) => {
    const translations = useTranslation()

    const listRef = useRef<VirtuosoHandle | null>(null)
    const [scrollListener] = useState(new ScrollListener())
    const [hasSavedChanges, setHasSavedChanges] = useState(false)

    const currentListRef = useMemo(() => listRef.current, [listRef])

    const previousSelectedGroupRef = useRef<Group | undefined>()
    const previousFirstPostOfSelectedGroupRef = useRef<Post | undefined>()

    const {
        isDeletingPost,
        viewModels,
        postToEdit,
        postIdToDelete,
        error,
        setPostToEdit,
        setPostIdToDelete,
        loadMorePostsIfNeeded,
        onPostUpdated,
        deletePost,
        markAllPostsAsReadIfNeeded,
    } = usePosts({
        selectedGroup,
        homeGroupId: homeGroupId,
        listRef: currentListRef,
    })

    useEffect(() => {
        const firstPostViewModel = viewModels.find(
            (viewModel) => viewModel instanceof PostItemViewModel
        ) as PostItemViewModel
        previousFirstPostOfSelectedGroupRef.current = firstPostViewModel?.post
    }, [viewModels])

    useEffect(() => {
        const previousGroup = previousSelectedGroupRef.current || selectedGroup
        const previousPost = previousFirstPostOfSelectedGroupRef.current

        previousSelectedGroupRef.current = selectedGroup

        notificationRepository.markUnreadAddedToGroupOrAccessGrantedToGroupNotificationsAsRead(
            selectedGroup.id
        )

        if (
            !previousGroup.isMyChurch &&
            previousGroup.id !== selectedGroup.id &&
            previousGroup.numberOfUnreadPosts > 0 &&
            previousPost
        ) {
            const fromDate = previousPost.createdAt.plus({ seconds: 5 })
            markAllPostsAsReadIfNeeded(previousGroup, fromDate)
        }
    }, [selectedGroup, markAllPostsAsReadIfNeeded])

    return (
        <Box height="100%" width="100%" id="postList">
            <AutoSizer>
                {({ height, width }) => (
                    <PostList
                        ref={listRef}
                        canCreatePosts={selectedGroup.canCreatePosts}
                        height={height}
                        width={width}
                        viewModels={viewModels}
                        scrollListener={scrollListener}
                        onLoadMore={loadMorePostsIfNeeded}
                    />
                )}
            </AutoSizer>

            <AlertDialog
                isLoading={isDeletingPost}
                isVisible={!!postIdToDelete}
                titleIcon={<AlertIcon fill={errorColor} />}
                title={translations('are_you_sure')}
                message={translations('remove_post_message')}
                cancelButtonTitle={translations('cancel')}
                onCancelButtonClicked={() => setPostIdToDelete(undefined)}
                continueButtonType={ButtonType.RED}
                continueButtonTitle={translations('remove_post')}
                onContinueButtonClicked={deletePost}
            />

            {!!postToEdit && (
                <EditPostPopup
                    postToEdit={postToEdit}
                    onCloseButtonClicked={() => setPostToEdit(undefined)}
                    onSubmitButtonClicked={(post) => {
                        setHasSavedChanges(true)
                        onPostUpdated(post)
                    }}
                />
            )}

            {hasSavedChanges && (
                <PopupModal
                    vertical={'center'}
                    horizontal={'center'}
                    message={translations('save_changes_succeeded_message')}
                    duration={1500}
                    onClose={() => setHasSavedChanges(false)}
                />
            )}

            <ErrorHandler error={error} translations={translations} />
        </Box>
    )
}
