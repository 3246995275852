'use client'

import { PropsWithChildren } from 'react'
import { ButtonProperties, DefaultButton } from './DefaultButton'

export const TextButton = (properties: PropsWithChildren<ButtonProperties>) => (
    <DefaultButton variant="text" {...properties} />
)

interface Properties extends ButtonProperties {
    textcolor: string
}

export const OnlyTextButton = (properties: PropsWithChildren<Properties>) => (
    <DefaultButton
        variant="text"
        {...properties}
        disableRipple
        sx={{
            height: 'auto',
            padding: '0',
            '&:hover': {
                backgroundColor: 'transparent',
            },
            color: properties.textcolor,
        }}
    />
)
