export class Video {
    constructor(
        readonly key: string,
        readonly imageKey: string,
        readonly imageWidth: number,
        readonly imageHeight: number
    ) {}

    static fromResponse(response: any): Video {
        return new Video(response.key, response.imageKey, response.imageWidth, response.imageHeight)
    }
}
