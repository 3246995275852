import Grid from '@mui/material/Unstable_Grid2'
import { List as MuiList, styled, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Alert } from 'shared/lib/assets/svg/alert.svg'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { useIsMobile, useIsPhablet } from 'shared/lib/theme/BreakPointHooks'
import { errorColor, greenColor, mobileToolbarHeight } from 'shared/lib/theme/Theme'
import { WizardPopup, WizardPopupFooterProperties } from 'shared/lib/wizard/WizardPopup'
import { ReactComponent as Delete } from 'shared/lib/assets/svg/delete_bin.svg'
import SessionContext from '../../../authentication/SessionContextProvider'
import { ContentContainer } from '../../../common/layout/ContentContainer'
import { MobileHeader } from '../../../common/layout/MobileHeader'
import { useTranslation } from 'shared/lib/i18n'
import { sessionRepository } from '../../../index'
import { Paths } from '../../../routing/paths'
import { DonkeyBreadcrumbItem, DonkeyBreadcrumbs } from '../DonkeyBreadcrumbs'
import { BackgroundContainer, Header } from '../SettingComponents'
import { MFAMethodItem, MFAMethodProperties } from './MFAMethodItem'
import { ConfirmSoftwareTokenStep } from './softwaretoken/ConfirmSoftwareTokenStep'
import { SetupSoftwareTokenStep } from './softwaretoken/SetupSoftwareTokenStep'
import { ActivityIndicator } from 'shared/lib/components/ActivityIndicator'
import { getErrorMessage } from 'shared/lib/utils/ErrorUtils'

export enum ConfigureSoftwareTokenStep {
    SETUP = 1,
    CONFIRM = 2,
}

export const TwoFactorAuthenticationPage = () => {
    const navigate = useNavigate()
    const translations = useTranslation()

    const [isMobile, isPhablet] = [useIsMobile(), useIsPhablet()]

    const breadcrumbsItems: DonkeyBreadcrumbItem[] = [
        {
            title: translations('settings'),
            path: Paths.SETTINGS,
        },
        {
            title: translations('two_steps_verification'),
            path: Paths.SETTINGS_CHANGE_PASSWORD,
        },
    ]

    if (isMobile || isPhablet) {
        return (
            <>
                <MobileHeader
                    hasActions={false}
                    title={translations('change_password')}
                    onBackButtonClicked={() => navigate(Paths.SETTINGS)}
                />

                <ContentContainer mt={mobileToolbarHeight}>
                    <BackgroundContainer>
                        <Content />
                    </BackgroundContainer>
                </ContentContainer>
            </>
        )
    }

    return (
        <>
            <ContentContainer>
                <Grid container tablet={7} tabletOffset={2.5} desktop={6} desktopOffset={3}>
                    <BackgroundContainer>
                        <DonkeyBreadcrumbs breadcrumbsItems={breadcrumbsItems} />

                        <Header>{translations('two_steps_verification')}</Header>

                        <Content />
                    </BackgroundContainer>
                </Grid>
            </ContentContainer>
        </>
    )
}

const Content = () => {
    const translations = useTranslation()

    const { getSignedInUser } = useContext(SessionContext)!
    const signedInUser = getSignedInUser()

    const isSoftwareTokenActive = signedInUser!.isMFAEnabled && !signedInUser!.mfaPhoneNumber
    const isSMSActive = signedInUser!.isMFAEnabled && signedInUser!.mfaPhoneNumber

    const [error, setError] = useState<Error | undefined>()
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    const [isLoading, setIsLoading] = useState(true)
    const [isDisableSoftwareTokenLoading, setIsDisableSoftwareTokenLoading] = useState(false)
    const [isEnableSoftwareTokenLoading, setIsEnableSoftwareTokenLoading] = useState(false)
    const [isConfirmSoftwareTokenLoading, setIsConfirmSoftwareTokenLoading] = useState(false)
    const [isDisableSMSLoading, setIsDisableSMSLoading] = useState(false)

    const [isConfirmDisableSMSDialogVisible, setIsConfirmDisableSMSDialogVisible] = useState(false)
    const [
        isConfirmDisableSoftwareTokenDialogVisible,
        setIsConfirmDisableSoftwareTokenDialogVisible,
    ] = useState(false)

    const [isConfirmEnableSoftwareTokenModalVisible, setIsConfirmEnableSoftwareTokenDialogVisible] =
        useState(false)
    const [isSoftwareTokenConfigureModalVisible, setIsSoftwareTokenConfigureModalVisible] =
        useState(false)

    const [secretCode, setSecretCode] = useState('secretCode')
    const [secretCodeUrl, setSecretCodeUrl] = useState('secretCodeUrl')

    const [step, setStep] = useState<ConfigureSoftwareTokenStep>(ConfigureSoftwareTokenStep.SETUP)

    const list: MFAMethodProperties[] = useMemo(() => {
        const updatedList: MFAMethodProperties[] = [
            {
                hasTopDivider: false,
                title: translations('authenticator_app'),
                subTitle: isSoftwareTokenActive ? translations('active') : translations('inactive'),
                subTitleColor: isSoftwareTokenActive ? greenColor : undefined,
                rightIcon: <DeleteIcon />,
                isButton: !isSoftwareTokenActive,
                buttonTitle: translations('setup'),
                isLoading: isEnableSoftwareTokenLoading,
                onItemClicked: () => onSoftwareTokenButtonClicked(),
            },
        ]

        if (isSMSActive) {
            updatedList.push({
                hasTopDivider: true,
                title: 'SMS',
                subTitle: signedInUser!.mfaPhoneNumber!,
                rightIcon: <DeleteIcon />,
                onItemClicked: () => setIsConfirmDisableSMSDialogVisible(true),
            })
        }

        return updatedList
    }, [signedInUser, isEnableSoftwareTokenLoading]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSoftwareTokenButtonClicked = () => {
        setStep(ConfigureSoftwareTokenStep.SETUP)

        if (isSoftwareTokenActive && !isSMSActive) {
            return setIsConfirmDisableSoftwareTokenDialogVisible(true)
        }

        if (!isSoftwareTokenActive && !isSMSActive) {
            return onEnableSoftwareToken()
        }

        setIsConfirmEnableSoftwareTokenDialogVisible(true)
    }

    const onBackButtonClicked = async () => {
        setStep(ConfigureSoftwareTokenStep.SETUP)
    }

    const onNextButtonClicked = async () => {
        setStep(ConfigureSoftwareTokenStep.CONFIRM)
    }

    const onDisableSoftwareToken = async () => {
        setIsDisableSoftwareTokenLoading(true)
        await sessionRepository
            .disableSoftwareToken()
            .then(() => {
                setIsConfirmDisableSoftwareTokenDialogVisible(false)
            })
            .catch(setError)
            .finally(() => setIsDisableSoftwareTokenLoading(false))
    }

    const onEnableSoftwareToken = async () => {
        setIsEnableSoftwareTokenLoading(true)
        await sessionRepository
            .enableSoftwareToken()
            .then(({ secretCode, secretCodeUrl }) => {
                sessionRepository.refreshSignedInUser()
                setSecretCode(secretCode)
                setSecretCodeUrl(secretCodeUrl)
                setIsSoftwareTokenConfigureModalVisible(true)
                setIsConfirmEnableSoftwareTokenDialogVisible(false)
            })
            .catch(setError)
            .finally(() => setIsEnableSoftwareTokenLoading(false))
    }

    const onConfirmSoftwareToken = async (confirmationCode: string) => {
        setIsConfirmSoftwareTokenLoading(true)
        await sessionRepository
            .confirmSoftwareToken(confirmationCode)
            .then(() => {
                setIsSoftwareTokenConfigureModalVisible(false)
            })
            .catch((error) => {
                setErrorMessage(getErrorMessage({ error, translations }))
            })
            .finally(() => setIsConfirmSoftwareTokenLoading(false))
    }

    const onDisableSMS = async () => {
        setIsDisableSMSLoading(true)
        await sessionRepository
            .mfaSettings({ isEnabled: false, phoneNumber: '' })
            .then(() => setIsConfirmDisableSMSDialogVisible(false))
            .catch(setError)
            .finally(() => setIsDisableSMSLoading(false))
    }

    const getTitle = () => {
        switch (step) {
            case ConfigureSoftwareTokenStep.SETUP:
                return translations('setup_authenticator_app')
            case ConfigureSoftwareTokenStep.CONFIRM:
                return translations('confirm_authenticator_app')
        }
    }

    const getFooter = (): WizardPopupFooterProperties => {
        switch (step) {
            case ConfigureSoftwareTokenStep.SETUP:
                return {
                    hasBackButton: false,
                    canContinue: true,
                    nextButtonTitle: translations('next'),
                    onNextButtonClicked,
                }
            case ConfigureSoftwareTokenStep.CONFIRM:
                return {
                    hasBackButton: true,
                    canContinue: false,
                    backButtonTitle: translations('back'),
                    onBackButtonClicked,
                    onNextButtonClicked,
                    nextButtonTitle: translations('confirm'),
                }
        }
    }

    useEffect(() => {
        sessionRepository
            .refreshSignedInUser()
            .catch(setError)
            .finally(() => setIsLoading(false))
    }, [])

    return (
        <>
            <Typography variant={'body1'} mb={2}>
                {translations('two_steps_verification_info')}
            </Typography>

            {isLoading && <ActivityIndicator />}
            {!isLoading && <MFAMethodList list={list} />}

            <WizardPopup
                isVisible={isSoftwareTokenConfigureModalVisible}
                title={getTitle()}
                footer={getFooter()}
                onCloseButtonClicked={() => setIsSoftwareTokenConfigureModalVisible(false)}
                isLoading={isConfirmSoftwareTokenLoading}
            >
                {step === ConfigureSoftwareTokenStep.SETUP && (
                    <SetupSoftwareTokenStep secretCode={secretCode} secretCodeUrl={secretCodeUrl} />
                )}
                {step === ConfigureSoftwareTokenStep.CONFIRM && (
                    <ConfirmSoftwareTokenStep
                        errorMessage={errorMessage}
                        onConfirmSoftwareToken={onConfirmSoftwareToken}
                        clearErrorMessage={() => setErrorMessage(undefined)}
                    />
                )}
            </WizardPopup>

            <AlertDialog
                isVisible={isConfirmDisableSoftwareTokenDialogVisible}
                continueButtonType={ButtonType.RED}
                cancelButtonType={ButtonType.RED}
                titleIcon={<Alert fill={errorColor} />}
                title={translations('confirm_to_deactivate_softwaretoken_title')}
                message={translations('confirm_to_deactivate_softwaretoken_message')}
                continueButtonTitle={translations('deactivate')}
                cancelButtonTitle={translations('cancel')}
                onContinueButtonClicked={() => onDisableSoftwareToken()}
                onCancelButtonClicked={() => setIsConfirmDisableSoftwareTokenDialogVisible(false)}
                isLoading={isDisableSoftwareTokenLoading}
            />

            <AlertDialog
                isVisible={isConfirmEnableSoftwareTokenModalVisible}
                continueButtonType={ButtonType.RED}
                cancelButtonType={ButtonType.RED}
                titleIcon={<Alert fill={errorColor} />}
                title={translations('setup_authenticator_app')}
                message={translations(
                    'confirm_to_deactivate_sms_when_enable_softwaretoken_message'
                )}
                continueButtonTitle={translations('proceed')}
                cancelButtonTitle={translations('cancel')}
                onContinueButtonClicked={() => onEnableSoftwareToken()}
                onCancelButtonClicked={() => setIsConfirmEnableSoftwareTokenDialogVisible(false)}
                isLoading={isConfirmSoftwareTokenLoading}
            />

            <AlertDialog
                isVisible={isConfirmDisableSMSDialogVisible}
                continueButtonType={ButtonType.RED}
                cancelButtonType={ButtonType.RED}
                titleIcon={<Alert fill={errorColor} />}
                title={translations('confirm_to_deactivate_sms_verification_title')}
                message={translations('confirm_to_deactivate_sms_verification_message')}
                continueButtonTitle={translations('deactivate')}
                cancelButtonTitle={translations('cancel')}
                onContinueButtonClicked={() => onDisableSMS()}
                onCancelButtonClicked={() => setIsConfirmDisableSMSDialogVisible(false)}
                isLoading={isDisableSMSLoading}
            />

            <ErrorHandler error={error} horizontal="center" translations={translations} />
        </>
    )
}

const MFAMethodList = (properties: { list: MFAMethodProperties[] }) => {
    return (
        <List>
            {properties.list.map((item, index) => (
                <MFAMethodItem key={index} {...item} />
            ))}
        </List>
    )
}

const List = styled(MuiList)(() => ({
    backgroundColor: 'white',
}))

const DeleteIcon = styled(Delete)(({ theme }) => ({
    fill: theme.palette.error.main,
}))
