import { SessionRepository } from '../authentication/SessionRepository'
import { WebAppHttpRepository } from '../common/WebAppHttpRepository'
import { Group } from './Group'
import { CreateGroupBody } from './CreateGroupBody'
import { GroupMember } from './members/GroupMember'
import { GroupWithAccessRequests } from './GroupWithAccessRequests'
import {
    GroupAccessRequestedEvent,
    GroupAccessWithdrawnEvent,
    GroupCreatedEvent,
    GroupDeletedEvent,
    GroupLeftEvent,
    GroupUpdatedEvent,
} from './events'
import {
    GroupMemberApprovedEvent,
    GroupMemberDeletedEvent,
    GroupMemberDeniedEvent,
    GroupMemberEventKey,
    GroupMemberUpdatedEvent,
} from './members/events'

export class GroupRepository extends WebAppHttpRepository {
    private _homeGroupId?: string

    get homeGroupId() {
        return this._homeGroupId
    }

    constructor(private readonly sessionRepository: SessionRepository) {
        super()
    }

    async createGroup(body: CreateGroupBody): Promise<Group> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.post<any>(`${this.apiUrl}/api/v2/groups`, body.toFormData())
        )
        const group = Group.fromApiResponse(response)

        document.dispatchEvent(new GroupCreatedEvent(group))

        return group
    }

    async getGroup(groupId: string, abortSignal?: AbortSignal) {
        const group = await this.sessionRepository.withAccessToken(async () =>
            this.get<any>(`${this.apiUrl}/api/v1/groups/${groupId}`, abortSignal)
        )

        return Group.fromApiResponse(group)
    }

    async getGroupsUserIsMemberOf(
        userId: string,
        {
            sortBy = 'MOST_RECENT_POST_DATE',
            abortSignal,
        }: {
            sortBy?: 'MOST_RECENT_POST_DATE' | 'NAME'
            abortSignal?: AbortSignal
        }
    ): Promise<Group[]> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.get<any[]>(
                `${this.apiUrl}/api/v2/groups?isMember=true&sortBy=${sortBy}${
                    userId ? `&userId=${userId}` : ''
                }`,
                abortSignal
            )
        )

        const groups = response.map(Group.fromApiResponse)

        this._homeGroupId = groups.find((group) => group.isHome)?.id

        return groups
    }

    async getGroupMembers(
        groupId: string,
        {
            isApproved,
            abortSignal,
        }: {
            isApproved?: boolean
            abortSignal?: AbortSignal
        } = {}
    ) {
        const groupMembers = await this.sessionRepository.withAccessToken(async () =>
            this.get<Record<string, any>[]>(
                `${this.apiUrl}/api/v2/groups/${groupId}/members?${
                    isApproved !== undefined ? `isApproved=${isApproved ? 'true' : 'false'}` : ''
                }`,
                abortSignal
            )
        )

        return groupMembers.map(GroupMember.fromApiResponse)
    }

    async getGroupMemberIds(
        groupId: string,
        {
            abortSignal,
        }: {
            abortSignal?: AbortSignal
        } = {}
    ): Promise<string[]> {
        return this.sessionRepository.withAccessToken(async () =>
            this.get<string[]>(`${this.apiUrl}/api/v1/groups/${groupId}/memberids`, abortSignal)
        )
    }

    async getAccessRequests(abortSignal?: AbortSignal): Promise<GroupWithAccessRequests[]> {
        return this.sessionRepository.withAccessToken(async () =>
            this.get<GroupWithAccessRequests[]>(
                `${this.apiUrl}/api/v1/groups/accessrequests`,
                abortSignal
            )
        )
    }

    async addGroupMembers(groupId: string, userIds: string[]): Promise<void> {
        await this.sessionRepository.withAccessToken(async () =>
            this.patch(
                `${this.apiUrl}/api/v2/groups/${groupId}/members`,
                userIds.map((userId) => ({ userId, isAdmin: false }))
            )
        )
        document.dispatchEvent(new CustomEvent(GroupMemberEventKey.GROUP_MEMBERS_ADDED))
    }

    async approveGroupMember(params: { groupId: string; userId: string }): Promise<void> {
        await this.sessionRepository.withAccessToken(async () =>
            this.patch(`${this.apiUrl}/api/v2/groups/${params.groupId}/grantaccess`, {
                userIds: [params.userId],
            })
        )

        document.dispatchEvent(new GroupMemberApprovedEvent(params.groupId, params.userId))
    }

    async denyGroupMember(params: { groupId: string; userId: string }): Promise<void> {
        await this.sessionRepository.withAccessToken(async () =>
            this.patch(`${this.apiUrl}/api/v2/groups/${params.groupId}/denyaccess`, {
                userIds: [params.userId],
            })
        )

        document.dispatchEvent(new GroupMemberDeniedEvent(params.groupId, params.userId))
    }

    async requestAccess(groupId: string): Promise<Group> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.post<Record<string, any>[]>(
                `${this.apiUrl}/api/v2/groups/${groupId}/requestaccess`
            )
        )
        const group = Group.fromApiResponse(response)

        document.dispatchEvent(new GroupAccessRequestedEvent(group))

        return group
    }

    async withdrawAccessRequest(groupId: string): Promise<Group> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.post<Record<string, any>[]>(
                `${this.apiUrl}/api/v1/groups/${groupId}/withdrawaccessrequest`
            )
        )
        const group = Group.fromApiResponse(response)

        document.dispatchEvent(new GroupAccessWithdrawnEvent(group))

        return group
    }

    async updateGroup(
        groupId: Group['id'],
        data: {
            name: string
            description: string
            fullSizeImage: File | undefined
            thumbnailImage: File | undefined
            isStart: boolean
            isRestricted: boolean
        }
    ): Promise<void> {
        const formData = new FormData()
        formData.append('name', data.name)
        formData.append('description', data.description)

        if (data.fullSizeImage) {
            formData.append('fullSizeImage', data.fullSizeImage)
        }

        if (data.thumbnailImage) {
            formData.append('thumbnailImage', data.thumbnailImage)
        }

        formData.append('isStart', data.isStart.toString())
        formData.append('isRestricted', data.isRestricted.toString())

        await this.sessionRepository.withAccessToken(async () =>
            this.patch(`${this.apiUrl}/api/v2/groups/${groupId}`, formData)
        )

        document.dispatchEvent(new GroupUpdatedEvent(groupId, data.name))
    }

    async leaveGroup(groupId: string): Promise<void> {
        await this.sessionRepository.withAccessToken(async () =>
            this.patch(`${this.apiUrl}/api/v1/groups/${groupId}/leave`)
        )

        document.dispatchEvent(new GroupLeftEvent(groupId))
    }

    async deleteGroup(groupId: string): Promise<void> {
        await this.sessionRepository.withAccessToken(async () =>
            this.delete(`${this.apiUrl}/api/v1/groups/${groupId}`)
        )

        document.dispatchEvent(new GroupDeletedEvent(groupId))
    }

    async updateGroupMember(params: {
        groupId: string
        userId: string
        isAdmin: boolean
    }): Promise<void> {
        await this.sessionRepository.withAccessToken(async () =>
            this.patch(`${this.apiUrl}/api/v1/groups/${params.groupId}/members/${params.userId}`, {
                isAdmin: params.isAdmin,
            })
        )

        document.dispatchEvent(
            new GroupMemberUpdatedEvent(params.groupId, params.userId, params.isAdmin)
        )
    }

    async deleteGroupMember(params: { groupId: string; userId: string }): Promise<void> {
        await this.sessionRepository.withAccessToken(async () =>
            this.delete(`${this.apiUrl}/api/v1/groups/${params.groupId}/members/${params.userId}`)
        )

        document.dispatchEvent(new GroupMemberDeletedEvent(params.groupId, params.userId))
    }
}
