import { createContext, PropsWithChildren, useCallback, useEffect } from 'react'
import { Group } from './Group'
import { useGroupsSignedInUserIsMemberOf } from './hooks'
import {
    GroupAccessRequestedEvent,
    GroupDeletedEvent,
    GroupEventKey,
    GroupLeftEvent,
} from './events'
import { GroupMemberEventKey } from './members/events'
import { PostEvent } from '../mychurch/events'

export const GroupsContext = createContext<{
    isLoading: boolean
    groups: Group[]
    getGroups: () => Promise<void>
}>({ isLoading: false, groups: [], getGroups: () => Promise.resolve() })

export const GroupsContextProvider = ({ children }: PropsWithChildren) => {
    const { isLoading, groups, getGroups, onGroupLeftOrDeleted, onGroupUpdated } =
        useGroupsSignedInUserIsMemberOf()

    const postMarkedAsRead = useCallback(
        (event: Event) => {
            const detail = (event as CustomEvent).detail
            onGroupUpdated(detail)
        },
        [onGroupUpdated]
    )

    const accessRequested = useCallback(
        (event: Event) => {
            const group = (event as GroupAccessRequestedEvent).detail.group

            if (group.isStartGroup) {
                getGroups()
            }
        },
        [getGroups]
    )

    const removeGroup = useCallback(
        (event: Event) => {
            if (event instanceof GroupLeftEvent) {
                onGroupLeftOrDeleted(event.detail.groupId)
            } else if (event instanceof GroupDeletedEvent) {
                onGroupLeftOrDeleted(event.detail.groupId)
            }
        },
        [onGroupLeftOrDeleted]
    )

    useEffect(() => {
        document.addEventListener(GroupEventKey.GROUP_UPDATED, getGroups)
        document.addEventListener(GroupEventKey.GROUP_ACCESS_REQUESTED, accessRequested)
        document.addEventListener(GroupEventKey.GROUP_LEFT, removeGroup)
        document.addEventListener(GroupEventKey.GROUP_DELETED, removeGroup)
        document.addEventListener(GroupMemberEventKey.GROUP_MEMBERS_ADDED, getGroups)
        document.addEventListener(GroupMemberEventKey.GROUP_MEMBER_APPROVED, getGroups)
        document.addEventListener(GroupMemberEventKey.GROUP_MEMBER_DENIED, getGroups)
        document.addEventListener(GroupMemberEventKey.GROUP_MEMBER_DELETED, getGroups)
        document.addEventListener(PostEvent.MARKED_AS_READ, postMarkedAsRead)

        return () => {
            document.removeEventListener(GroupEventKey.GROUP_UPDATED, getGroups)
            document.removeEventListener(GroupEventKey.GROUP_ACCESS_REQUESTED, getGroups)
            document.removeEventListener(GroupEventKey.GROUP_LEFT, removeGroup)
            document.removeEventListener(GroupEventKey.GROUP_DELETED, removeGroup)
            document.removeEventListener(GroupMemberEventKey.GROUP_MEMBERS_ADDED, getGroups)
            document.removeEventListener(GroupMemberEventKey.GROUP_MEMBER_APPROVED, getGroups)
            document.removeEventListener(GroupMemberEventKey.GROUP_MEMBER_DENIED, getGroups)
            document.removeEventListener(GroupMemberEventKey.GROUP_MEMBER_DELETED, getGroups)
            document.removeEventListener(PostEvent.MARKED_AS_READ, postMarkedAsRead)
        }
    }, [getGroups, accessRequested, removeGroup, postMarkedAsRead])

    return (
        <GroupsContext.Provider
            value={{
                isLoading,
                groups,
                getGroups,
            }}
        >
            {children}
        </GroupsContext.Provider>
    )
}
