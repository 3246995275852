import { useCallback, useEffect, useState } from 'react'
import { postRepository } from '../../index'
import { Like } from './Like'
import { delay } from 'shared/lib/utils/Utils'

export const useLikes = (postId: string) => {

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | undefined>()
    const [likes, setLikes] = useState<Like[]>([])

    const getLikes = useCallback(() => {
        setIsLoading(true)

        Promise.all([postRepository.getLikes(postId), delay(300)])
            .then(([likes]) => setLikes(likes))
            .catch(setError)
            .finally(() => setIsLoading(false))
    }, [postId])

    useEffect(() => {
        getLikes()
    }, [postId, getLikes])

    return { isLoading, error, likes }
}
