import { MobileGroupTabList } from '../../groups/MobileGroupTabList'
import { MobileHeader } from './MobileHeader'
import {
    GroupListItemViewModel,
    GroupListViewModel,
} from '../../groups/groupList/GroupListViewModels'
import { Skeleton } from 'shared/lib/components/Skeleton'

interface Properties {
    isBadgeVisible: boolean
    canShowSmartBanner: boolean
    groupListViewModels: GroupListViewModel[]
    selectedGroupId?: string
    scrollableContainerId?: string
}

export const MobileHeaderWithGroups = (properties: Properties) => {
    const { groupListViewModels, selectedGroupId } = properties

    const selectedGroup = groupListViewModels.find(
        (viewModel) =>
            viewModel instanceof GroupListItemViewModel && viewModel.group.id === selectedGroupId
    ) as GroupListItemViewModel | undefined

    return (
        <MobileHeader
            hasBackButton={false}
            canShowSmartBanner={properties.canShowSmartBanner}
            title={
                selectedGroup?.group.name || <Skeleton variant={'text'} width={'30%'}></Skeleton>
            }
            scrollableContainerId={properties.scrollableContainerId}
        >
            <MobileGroupTabList
                isBadgeVisible={properties.isBadgeVisible}
                viewModels={groupListViewModels}
                selectedGroupId={properties.selectedGroupId}
            />
        </MobileHeader>
    )
}
