import { TranslationFn } from 'shared/lib/WithTranslations'
import { LikeType } from './LikeType'

export class PostLikeInfo {
    constructor(
        readonly likeFromSignedInUser: LikeType | undefined,
        readonly totalLikeCount: number,
        readonly mostPopularLikes: LikeType[],
        readonly mostRecentLikerNameOtherThanSignedInUser: string | undefined
    ) {}

    updateWith(like?: LikeType): PostLikeInfo {
        return new PostLikeInfo(
            like,
            this.getTotalLikeCount(like),
            this.getMostPopularLikes(like),
            this.mostRecentLikerNameOtherThanSignedInUser
        )
    }

    private getTotalLikeCount(newLike?: LikeType): number {
        if (newLike && this.likeFromSignedInUser) {
            return this.totalLikeCount
        } else if (newLike) {
            return this.totalLikeCount + 1
        } else {
            return this.totalLikeCount - 1
        }
    }

    private getMostPopularLikes(newLike?: LikeType): LikeType[] {
        const currentLike = this.likeFromSignedInUser
        const doNotRemoveCurrentLike =
            (this.totalLikeCount > 1 && this.mostPopularLikes.length === 1) ||
            (this.totalLikeCount > 2 && this.mostPopularLikes.length === 2)

        if (
            this.totalLikeCount <= 3 &&
            this.mostPopularLikes.length <= 3 &&
            !newLike &&
            currentLike
        ) {
            return doNotRemoveCurrentLike
                ? this.mostPopularLikes
                : this.mostPopularLikes.filter((like) => like !== currentLike)
        }

        if (
            this.totalLikeCount <= 3 &&
            this.mostPopularLikes.length <= 3 &&
            newLike &&
            currentLike
        ) {
            const likes = doNotRemoveCurrentLike
                ? this.mostPopularLikes.concat([newLike])
                : this.mostPopularLikes.filter((like) => like !== currentLike).concat([newLike])
            return [...new Set(likes)]
        }

        if (
            newLike &&
            !this.mostPopularLikes.includes(newLike) &&
            this.mostPopularLikes.length < 3
        ) {
            return this.mostPopularLikes.concat([newLike])
        }

        return this.mostPopularLikes
    }

    getPostCreators(translations: TranslationFn): string {
        if (
            this.totalLikeCount > 2 &&
            this.mostRecentLikerNameOtherThanSignedInUser &&
            this.likeFromSignedInUser
        ) {
            return `${translations('you')}, ${
                this.mostRecentLikerNameOtherThanSignedInUser
            } ${translations('and_others')}`
        }

        if (this.totalLikeCount > 1 && this.likeFromSignedInUser) {
            return `${translations('you')} ${translations('and_others')}`
        }

        if (this.totalLikeCount > 1 && this.mostRecentLikerNameOtherThanSignedInUser) {
            return `${this.mostRecentLikerNameOtherThanSignedInUser} ${translations('and_others')}`
        }

        if (this.totalLikeCount === 1 && this.mostRecentLikerNameOtherThanSignedInUser) {
            return this.mostRecentLikerNameOtherThanSignedInUser
        }

        if (this.likeFromSignedInUser) {
            return translations('you')
        }

        return ''
    }

    static fromResponse(response: any): PostLikeInfo {
        return new PostLikeInfo(
            response.likeFromSignedInUser,
            response.totalLikeCount,
            response.mostPopularLikes,
            response.mostRecentLikerNameOtherThanSignedInUser
        )
    }
}
