import { Box, BoxProps, ButtonBase as MuiButtonBase, styled, Typography } from '@mui/material'
import { Card } from 'shared/lib/components/Card'
import { defaultGrey2HoverState, grey_3 } from 'shared/lib/theme/Theme'
import { Group } from '../../../groups/Group'
import { GroupAvatar } from '../../../groups/GroupAvatar'
import { fileRepository } from '../../../index'

interface Properties {
    group: Group
    isClickable: boolean

    onGroupSelected(groupId: string): void
}

export const GroupCard = ({ group, isClickable, onGroupSelected }: Properties) => {
    const onClick = () => {
        onGroupSelected(group.id)
    }

    return (
        <ButtonBase onClick={isClickable ? onClick : undefined} disableRipple={true}>
            <StyledCard canOpenNewDetailView={isClickable} p={1}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <GroupAvatar src={fileRepository.getThumbnailUrl(group.image)} />
                    <GroupNameTextView>{group.name}</GroupNameTextView>
                </Box>
            </StyledCard>
        </ButtonBase>
    )
}

const StyledCard = styled(
    ({ canOpenNewDetailView, ...props }: BoxProps & { canOpenNewDetailView: boolean }) => (
        <Card {...props} />
    )
)(({ canOpenNewDetailView }) => ({
    height: '100%',
    width: '100%',
    backgroundColor: grey_3,
    borderRadius: '16px',
    ...(canOpenNewDetailView
        ? defaultGrey2HoverState
        : {
              cursor: 'default',
          }),
}))

const ButtonBase = styled(MuiButtonBase)(() => ({
    height: '100%',
    width: '100%',
    borderRadius: 16,
}))

const GroupNameTextView = styled(Typography)(({ theme }) => ({
    fontSize: 11,
    marginTop: theme.spacing(1),
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflowWrap: 'anywhere',
}))
